import { isNotNil } from '@woorcs/utils'
import { applyAll, useAllCallbacks } from '@woorcs/hooks'
import { useCallback } from 'react'

import { system, PropsOf } from '../../../system'

import {
  useToggleButtonGroupContext,
  ToggleButtonGroupContext
} from './context'

type Value = Pick<PropsOf<typeof system.button>, 'value'>['value']

export interface UseToggleButtonProps
  extends Omit<PropsOf<typeof system.button>, 'onChange' | 'value'>,
    Omit<
      ToggleButtonGroupContext,
      | 'multiple'
      | 'isSelected'
      | 'value'
      | 'onChange'
      | 'isSelected'
      | 'clear'
      | 'hasValue'
    > {
  selected?: boolean
  value?: Value | null
  onChange?(value: Value): void
}

export const useToggleButton = (props: UseToggleButtonProps) => {
  const group = useToggleButtonGroupContext()
  const selected = props.selected ?? group?.isSelected(props.value)

  const handleClick = useCallback(() => {
    applyAll(props.onChange, group?.onChange)(props.value)
  }, [group, props.onChange, props.value])

  const onClick = useAllCallbacks([props.onClick, handleClick])

  return {
    ...props,
    selected,
    insideGroup: isNotNil(group),
    onClick
  }
}
