import React, { useState, ReactElement, useCallback } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  Input,
  Modal,
  ModalFooter,
  ModalDialog,
  ModalDisclosure,
  SecondaryButton,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalDismiss
} from '@woorcs/design-system/src'
import * as t from 'io-ts'
import { Email, getFormikValidator } from '@woorcs/utils/formik'

import { FormField, SubmitButton } from '@app/components'

const SuperuserForm = t.type({
  email: Email
})

export type SuperUserFormUser = t.TypeOf<typeof SuperuserForm>

const validateForm = getFormikValidator(SuperuserForm)

const defaultInitialValues = {
  email: ''
}

interface Props {
  title: string
  initialValues?: Record<keyof SuperUserFormUser, unknown>
  children: ReactElement
  onSubmit(
    user: SuperUserFormUser,
    actions: FormikHelpers<SuperUserFormUser>
  ): void
}

export const AddSuperuserModal = ({
  title,
  initialValues = defaultInitialValues,
  children,
  onSubmit
}: Props) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  const handleSubmit = useCallback(
    (user: SuperUserFormUser, actions: FormikHelpers<SuperUserFormUser>) => {
      onSubmit(user, actions)
      setVisible(false)
    },
    [onSubmit]
  )

  return (
    <Modal open={visible} setOpen={setVisible}>
      <ModalDisclosure>{children}</ModalDisclosure>
      <ModalDialog aria-label={title} role='dialog'>
        <ModalHeader>
          <ModalTitle mb={6}>{title}</ModalTitle>
        </ModalHeader>
        <Formik
          validate={validateForm}
          initialValues={initialValues as any}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <ModalBody>
                <FormField name='email' label={t('common:email')} required>
                  <Input type='email' />
                </FormField>
              </ModalBody>

              <ModalFooter>
                <ModalDismiss render={<SecondaryButton mr={2} />}>
                  {t('common:cancel')}
                </ModalDismiss>
                <SubmitButton label={t('common:save')} />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalDialog>
    </Modal>
  )
}
