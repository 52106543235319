import React, { memo, ReactNode } from 'react'
import { isNotNilOrEmpty } from '@woorcs/utils'

import { Box } from '../../layout'
import { FormFieldMessage, FormFieldMessageStatus } from '../FormFieldMessage'
import { Collapse } from '../../Collapse'

import { useFormFieldMessages } from './useFormField'

const useActiveMessage = (): [ReactNode, FormFieldMessageStatus] | null => {
  const { invalid, errorMessage, infoMessage, warningMessage } =
    useFormFieldMessages()
  const hasError = isNotNilOrEmpty(errorMessage)
  const hasWarning = isNotNilOrEmpty(warningMessage)
  const hasInfo = isNotNilOrEmpty(infoMessage)

  if (hasError && invalid) {
    return [errorMessage, 'error']
  }

  if (hasWarning) {
    return [warningMessage, 'warning']
  }

  if (hasInfo) {
    return [infoMessage, 'info']
  }

  return null
}

export const FormFieldMessages = memo(() => {
  const [message, status] = useActiveMessage() ?? []
  const hasMessage = isNotNilOrEmpty(message)

  return (
    <Collapse isOpen={hasMessage}>
      <Box pt={1}>
        {status && (
          <FormFieldMessage status={status}>{message}</FormFieldMessage>
        )}
      </Box>
    </Collapse>
  )
})
