import { pipe } from 'fp-ts/function'
import { v4 as uuidv4, NIL } from 'uuid'

import * as S from './Schemable'

// -------------------------------------------------------------------------------------
// uuid
// -------------------------------------------------------------------------------------

interface UUIDBrand {
  readonly UUIDBrand: unique symbol
}

export type UUID = string & UUIDBrand

export const UUID = S.type((S) =>
  pipe(
    S.string,
    S.refine((s): s is UUID => s.length > 0, 'uuid')
  )
)

export const uuid = () => uuidv4() as UUID

export const uuidNull = () => NIL as UUID

export const isValid = (uuid: UUID) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    uuid
  )
