import { ReactElement, cloneElement, forwardRef } from 'react'
import * as Ariakit from '@ariakit/react'

interface PopoverDisclosureProps extends Ariakit.PopoverDisclosureProps {
  children: ReactElement
}

export const PopoverDisclosure = forwardRef<
  HTMLButtonElement,
  PopoverDisclosureProps
>(({ children, ...props }, ref) => {
  return (
    <Ariakit.PopoverDisclosure
      ref={ref}
      render={(disclosureProps) => cloneElement(children, disclosureProps)}
      {...props}
    />
  )
})
