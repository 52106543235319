import * as V from './validation'

// -------------------------------------------------------------------------------------
// text field
// -------------------------------------------------------------------------------------

export interface MaxLengthError extends V.ValidationError {
  _tag: 'MaxLength'
  expected: number
}

const maxLengthError = (actual: string, expected: number): MaxLengthError => ({
  _tag: 'MaxLength',
  actual,
  expected
})

export interface MinLengthError extends V.ValidationError {
  _tag: 'MinLength'
  expected: number
}

const minLengthError = (actual: string, expected: number): MinLengthError => ({
  _tag: 'MinLength',
  actual,
  expected
})

export interface InvalidPatternError extends V.ValidationError {
  _tag: 'InvalidPattern'
  pattern: string
}

const invalidPatternError =
  (pattern: string) =>
  (actual: string): InvalidPatternError => ({
    _tag: 'InvalidPattern',
    actual,
    pattern
  })

export type TextValidatorError =
  | MaxLengthError
  | MinLengthError
  | InvalidPatternError

const maxLength = (expected = Infinity) =>
  V.validatorFromPredicate(
    (s: string) => s.length <= expected,
    (s) => maxLengthError(s, expected)
  )

const minLength = (expected = 0) =>
  V.validatorFromPredicate(
    (s: string) => s.length >= expected,
    (s) => minLengthError(s, expected)
  )

const pattern = (pattern?: string) =>
  pattern
    ? V.validatorFromPredicate(
        (s: string) => new RegExp(pattern).test(s),
        invalidPatternError(pattern)
      )
    : V.identity<string>()

export interface TextValidatorOptions {
  maxLength: number
  minLength: number
  pattern: string
}

export const textValidator = (options: TextValidatorOptions) =>
  V.combine(
    minLength(options.minLength),
    maxLength(options.maxLength),
    pattern(options.pattern)
  )

// -------------------------------------------------------------------------------------
// number
// -------------------------------------------------------------------------------------

export interface MaxError extends V.ValidationError {
  _tag: 'Max'
  expected: number
}

const maxError = (actual: number, expected: number): MaxError => ({
  _tag: 'Max',
  actual,
  expected
})

export interface MinError extends V.ValidationError {
  _tag: 'Min'
  expected: number
}

const minError = (actual: number, expected: number): MinError => ({
  _tag: 'Min',
  actual,
  expected
})

export type NumberValidationError = MaxError | MinError

const max = (expected = Infinity) =>
  V.validatorFromPredicate(
    (n: number) => n <= expected,
    (s) => maxError(s, expected)
  )

const min = (expected = Infinity) =>
  V.validatorFromPredicate(
    (n: number) => n > expected,
    (s) => minError(s, expected)
  )

export interface NumberValidatorOptions {
  /**
   * inclusive max
   */
  max?: number

  /**
   * inclusive min
   */
  min?: number

  exclusiveMax?: number
  exclusiveMin?: number
}

export const numberValidator = (options: NumberValidatorOptions) =>
  V.combine(max(options.max), min(options.min))

// -------------------------------------------------------------------------------------
// date
// -------------------------------------------------------------------------------------

export interface DateValidatorOptions {
  maxDate?: Date
  minDate?: Date
}
