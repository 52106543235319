import { forkRefs } from '@woorcs/utils'
import { useFocusable } from '@woorcs/hooks'
import { useRef, useCallback, MutableRefObject, ForwardedRef } from 'react'

import { useFormField, UseFormFieldProps } from '../FormField'

import { InputSize, InputVariant } from './variants'

interface UseInputProps<T extends HTMLElement> extends UseFormFieldProps<T> {
  variant: InputVariant
  size: InputSize
}

export const useInput = <T extends HTMLElement>(
  propRef: MutableRefObject<T | null> | ForwardedRef<T>,
  props: UseInputProps<T>
) => {
  const ref = useRef<T | null>(null)
  const field = useFormField(props)
  const [focusRef, focusProps] = useFocusable(field)

  const stateProps = {
    disabled: field.disabled,
    readOnly: field.readOnly,
    focused: focusProps.focused,
    invalid: field.invalid,
    required: field.required
  }

  const setFocused = useCallback(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [ref])

  const getGroupProps = () => ({
    variant: props.variant,
    size: props.size,
    onClick: setFocused,
    ...stateProps
  })

  const getInputProps = useCallback(
    () => ({
      ref: forkRefs(ref, focusRef, propRef),
      ...props,
      ...field.getInputProps(),
      ...focusProps
    }),
    [field, focusProps, focusRef, propRef, props]
  )

  return {
    ...field,
    setFocused,
    getGroupProps,
    focused: focusProps.focused,
    getInputProps,
    variant: props.variant,
    size: props.size
  }
}
