/* eslint-disable @typescript-eslint/ban-types */
import createStyled from '@emotion/styled'
import { isFunction, Dict, Pretty, DistributiveUnion } from '@woorcs/utils'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import { ComponentProps, ElementType, JSXElementConstructor } from 'react'

import { Theme } from '../theme'

import { css } from './css'
import { SystemComponent } from './systemComponent'
import { shouldForwardProp } from './shouldForwardProps'
import { domElements } from './domElements'
import { systemProps } from './props'
import { Interpolation } from './interpolation'

export type PropsOf<
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
> = ComponentProps<T>

interface Options {
  shouldForwardProp?(prop: string): boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const cast = <P = { theme: object }>(arg: any) => arg as Interpolation<P>

const __css = (props: Dict) => {
  const result = {} as Dict

  if (isFunction(props.__css)) {
    return props.__css(props)
  }

  for (const key in props.__css) {
    const exists = key in props

    if (!exists || props[key] == null) {
      result[key] = props.__css[key]
    }
  }

  return pipe(props.theme, css(result))
}

function systemStyled<T extends ElementType>(
  component: T,
  options: Options = {}
) {
  return <P extends Dict = Dict>(
    ...interpolators: Interpolation<P>[]
  ): SystemComponent<T, P> => {
    // ): SystemComponent<T, Omit<P, 'theme' | 'className'>> => {
    const styledComponent = createStyled(component as any, {
      ...options,
      shouldForwardProp
    })
    const styles = pipe(
      interpolators,
      A.map((style) => {
        if (!style) {
          return
        }

        return isFunction(style) ? style : css(style)
      })
    )

    return styledComponent(
      ...(styles as any),
      cast(__css) as any,
      systemProps
    ) as any
  }
}

export type JsxElement<T extends ElementType, P extends Dict> =
  T extends SystemComponent<infer A, infer B>
    ? SystemComponent<A, Pretty<DistributiveUnion<P, B>>>
    : SystemComponent<T, P>

type JsxFactory = {
  <T extends ElementType>(
    component: T,
    options?: Options
    // eslint-disable-next-line @typescript-eslint/ban-types
  ): <P extends Dict = Dict>(
    ...xs: Interpolation<P & { theme: Theme }>[]
  ) => SystemComponent<T, P>
}

type SystemJSXElements = {
  [K in keyof JSX.IntrinsicElements]: SystemComponent<K, {}>
}

type SystemFactoryFn = JsxFactory & SystemJSXElements

export const system = systemStyled as unknown as SystemFactoryFn

domElements.forEach((tag) => {
  system[tag] = system(tag)() as any
})
