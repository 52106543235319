/* eslint-disable max-params */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { NonEmptyArray } from 'fp-ts/NonEmptyArray'
import * as R from 'fp-ts/Reader'
import * as M from 'fp-ts/Monoid'
import * as b from 'fp-ts/boolean'
import * as O from 'fp-ts/Option'
import { absurd, constFalse, pipe } from 'fp-ts/function'
import * as S from '@woorcs/types/Schemable'
import { ReadonlyNonEmptyArray } from 'fp-ts/ReadonlyNonEmptyArray'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export const Operator = S.type((S) =>
  S.literal('eq', 'ne', 'gt', 'gte', 'lt', 'lte')
)

export type Operator = S.TypeOf<typeof Operator>

export interface CompositeCondition {
  readonly conditions: ReadonlyNonEmptyArray<Condition>
}

export interface AndCondition extends CompositeCondition {
  readonly _tag: 'and'
}

export interface OrCondition extends CompositeCondition {
  readonly _tag: 'or'
}

export interface NotCondition extends CompositeCondition {
  readonly _tag: 'not'
}

export interface LeafCondition {
  _tag: 'leaf'
  key: string
  expectedValue: unknown
  operator: Operator
}

export type ConditionGroup = OrCondition | AndCondition | NotCondition

export type Condition = LeafCondition | ConditionGroup

export const ConditionGroup: S.Type<ConditionGroup> = S.type((S) =>
  S.lazy('ConditionGroup', () =>
    S.sum('_tag')({
      and: AndCondition.schema(S),
      or: OrCondition.schema(S),
      not: NotCondition.schema(S)
    })
  )
)

export const Condition: S.Type<Condition> = S.type((S) =>
  S.lazy('Condition', () =>
    S.sum('_tag')({
      and: AndCondition.schema(S),
      or: OrCondition.schema(S),
      not: NotCondition.schema(S),
      leaf: LeafCondition.schema(S)
    })
  )
)

export const AndCondition: S.Type<AndCondition> = S.type((S) =>
  S.lazy('AndCondition', () =>
    S.struct({
      _tag: S.literal('and'),
      conditions: S.readonlyNonEmptyArray(Condition.schema(S))
    })
  )
)

export const OrCondition: S.Type<OrCondition> = S.type((S) =>
  S.lazy('OrCondition', () =>
    S.struct({
      _tag: S.literal('or'),
      conditions: S.readonlyNonEmptyArray(Condition.schema(S))
    })
  )
)

export const NotCondition: S.Type<NotCondition> = S.type((S) =>
  S.lazy('NotCondition', () =>
    S.struct({
      _tag: S.literal('not'),
      conditions: S.readonlyNonEmptyArray(Condition.schema(S))
    })
  )
)

export const LeafCondition: S.Type<LeafCondition> = S.type((S) =>
  S.struct({
    _tag: S.literal('leaf'),
    key: S.string,
    expectedValue: S.unknown,
    operator: Operator.schema(S)
  })
)

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const and = (conditions: NonEmptyArray<Condition>): AndCondition => ({
  _tag: 'and',
  conditions
})

export const or = (conditions: NonEmptyArray<Condition>): OrCondition => ({
  _tag: 'or',
  conditions
})

export const not = (conditions: NonEmptyArray<Condition>): NotCondition => ({
  _tag: 'not',
  conditions
})

export const leaf = (
  key: string,
  expectedValue: unknown,
  operator: Operator
): LeafCondition => ({
  _tag: 'leaf',
  key,
  expectedValue,
  operator
})

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export const match =
  <A>(
    onAnd: (a: AndCondition) => A,
    onOr: (o: OrCondition) => A,
    onNot: (o: NotCondition) => A,
    onLeaf: (l: LeafCondition) => A
  ) =>
  (c: Condition) => {
    switch (c._tag) {
      case 'and': {
        return onAnd
      }

      case 'or': {
        return onOr
      }

      case 'not': {
        return onNot
      }

      case 'leaf': {
        return onLeaf
      }
    }
  }

// -------------------------------------------------------------------------------------
// eval
// -------------------------------------------------------------------------------------

const foldAll = M.concatAll(b.MonoidAll)
const foldOr = M.concatAll(b.MonoidAny)

export interface ConditionEnvironment {
  getValue(id: string): O.Option<unknown>
  getCompare(condition: LeafCondition): <A>(a: A, b: A) => boolean
}

export interface ConditionEvaluator {
  evaluate(condition: Condition): boolean
}

const evaluateAnd = (condition: AndCondition) =>
  pipe(
    condition.conditions,
    R.traverseArray((condition) => evaluate(condition)),
    R.map(foldAll)
  )

const evaluateOr = (condition: OrCondition) =>
  pipe(
    condition.conditions,
    R.traverseArray((condition) => evaluate(condition)),
    R.map(foldOr)
  )

const evaluateNot = (condition: NotCondition) =>
  pipe(
    condition.conditions,
    R.traverseArray((condition) =>
      pipe(
        evaluate(condition),
        R.map((result) => !result)
      )
    ),
    R.map(foldAll)
  )

const evaluateLeafCondition = (condition: LeafCondition) =>
  pipe(
    R.ask<ConditionEnvironment>(),
    R.map(({ getValue, getCompare }) =>
      pipe(
        getValue(condition.key),
        O.map((value) => {
          const compare = getCompare(condition)

          return compare(value, condition.expectedValue)
        }),
        O.getOrElse(constFalse)
      )
    )
  )

export const evaluate = (
  condition: Condition
): R.Reader<ConditionEnvironment, boolean> => {
  switch (condition._tag) {
    case 'and':
      return evaluateAnd(condition)

    case 'or':
      return evaluateOr(condition)

    case 'not': {
      return evaluateNot(condition)
    }

    case 'leaf':
      return evaluateLeafCondition(condition)

    default: {
      return absurd(condition)
    }
  }
}

export const getEvaluator = (
  config: ConditionEnvironment
): ConditionEvaluator => {
  return {
    evaluate: (condition) => pipe(config, evaluate(condition))
  }
}
