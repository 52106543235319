import { createContext } from '@woorcs/utils'

type Placement = 'left' | 'right'

export type DialogSidebarContext = {
  placement: Placement
}

export const [DialogSidebarProvider, useDialogSidebarContext] =
  createContext<DialogSidebarContext>({
    errorMessage:
      'DialogSidebar components must be used inside the context of a DialogSidebar element'
  })

export type UseDialogSidebarProps = {
  placement?: Placement
}

export const useDialogSidebar = ({
  placement = 'left'
}: UseDialogSidebarProps) => {
  return {
    placement
  }
}
