import { useMemo, useRef, useState } from 'react'
import * as RR from 'fp-ts/ReadonlyRecord'
import { pipe } from 'fp-ts/function'

import * as FormSubmission from '../../FormSubmission'
import { Fields } from '../../Fields'

export const useSubmissionState = (
  fields: Fields,
  defaults: FormSubmission.FormSubmissionData
): [FormSubmission.FormSubmissionData, FormSubmission.FormSubmissionStore] => {
  const initialData = useRef(
    FormSubmission.fromFields(fields, defaults)
  ).current
  const [data, setData] = useState(initialData)

  const store = useMemo(
    (): FormSubmission.FormSubmissionStore => ({
      get: (id) => pipe(data, RR.lookup(id)),
      set: (id, value) => pipe(data, RR.upsertAt(id, value), setData)
    }),
    [data]
  )

  return [data, store]
}
