import { forwardRef } from 'react'

import { css, system } from '../../../system'
import { Stack, StackProps } from '../../layout'

import { NAV_SIDEBAR_WIDTH } from './NavSidebar'

type NavSidebarMenuProps = StackProps

export const NavSidebarMenu = forwardRef<
  typeof system.nav,
  NavSidebarMenuProps
>((props, ref) => (
  <Stack
    ref={ref}
    as='nav'
    css={css({
      willChange: 'transform'
    })}
    width={NAV_SIDEBAR_WIDTH}
    aria-label='main'
    spacing={3}
    px={4}
    {...props}
  />
))
