import { FormElement, ElementRule } from '@woorcs/form'
import { pipe } from 'fp-ts/function'
import React, { ReactElement, useCallback } from 'react'

import { useEditorContext } from '../../state'
import { QuestionRuleFormModal } from '../ElementRuleFormModal'

interface EditElementRuleModalProps {
  element: FormElement.FormElement
  rule: ElementRule.ElementRule
  children: ReactElement
}

export const EditElementRuleModal = ({
  element,
  rule,
  children
}: EditElementRuleModalProps) => {
  const { editor } = useEditorContext()
  const handleSubmit = useCallback(
    (rule: ElementRule.ElementRule) => {
      const updateQuestion = editor.updateElement(
        pipe(element, FormElement.updateRule(rule))
      )

      updateQuestion()
    },
    [editor, element]
  )

  return (
    <QuestionRuleFormModal initialValue={rule} onSubmit={handleSubmit}>
      {children}
    </QuestionRuleFormModal>
  )
}
