import { space } from '@woorcs/utils'

import { Box } from '../../layout'
import { PropsOf, system } from '../../../system'

type MenuItemIconProps = PropsOf<typeof system.div>

export const MenuItemIcon = (props: MenuItemIconProps) => (
  <Box
    __css={{
      width: space(6),
      height: space(6),
      ml: space(-2),
      mr: 1
    }}
    justifyContent='center'
    alignItems='center'
    aria-hidden
    {...props}
  />
)
