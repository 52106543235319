import React, { useCallback, useEffect, useState } from 'react'
import {
  Body,
  Button,
  Card,
  Flex,
  Box,
  H4,
  useSnackbar
} from '@woorcs/design-system'
import { rem } from '@woorcs/utils'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Email } from '@woorcs/utils/formik'

import { FullscreenPage } from '@app/components'
import { useAuth } from '@app/auth'

const authUrl = import.meta.env.VITE_REACT_APP_AUTH_DOMAIN?.toString() || ''

export const AcceptOrganizationInvitePage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { email } = useParams()
  const [sendingEmail, setSendingEmail] = useState(false)
  const [resetEmailSent, setResetEmailSent] = useState(false)
  const [, { loginWithRedirect }] = useAuth()
  const { showSnackbar } = useSnackbar()

  const handleSignup = useCallback(() => {
    setSendingEmail(true)

    fetch(`https://${authUrl}/dbconnections/change_password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        client_id: import.meta.env.VITE_REACT_APP_AUTH_CLIENT_ID,
        email,
        connection: 'Username-Password-Authentication'
      })
    })
      .then(() => {
        setResetEmailSent(true)
      })
      .catch(() => {
        showSnackbar({
          title: t(
            'organizations:acceptInvitePage.sendEmailErrorSnackbar.title'
          ),
          variant: 'danger'
        })
      })
      .finally(() => setSendingEmail(false))
  }, [email, showSnackbar, t])

  useEffect(() => {
    if (!Email.is(email)) {
      navigate('/')
    }
  }, [email, navigate])

  return (
    <FullscreenPage>
      <Card width={rem(540)} p={8}>
        {!resetEmailSent && (
          <>
            <Box mb={8}>
              <H4 mb={2}>{t('organizations:acceptInvitePage.title')}</H4>
              <Body>{t('organizations:acceptInvitePage.subtitle')}</Body>
            </Box>
            <Flex justifyContent='space-between'>
              <Button loading={sendingEmail} onClick={handleSignup}>
                {t(
                  'organizations:acceptInvitePage.activateAccountButton.label'
                )}
              </Button>
              <Button
                variant='plain'
                colorVariant='secondary'
                onClick={() =>
                  loginWithRedirect({
                    prompt: 'login',
                    login_hint: email
                  })
                }
              >
                {t('organizations:acceptInvitePage.loginButton.label')}
              </Button>
            </Flex>
          </>
        )}

        {resetEmailSent && (
          <>
            <H4 mb={2}>
              {t('organizations:acceptInvitePage.emailSent.title')}
            </H4>
            <Body>
              {t('organizations:acceptInvitePage.emailSent.subtitle')}
            </Body>
          </>
        )}
      </Card>
    </FullscreenPage>
  )
}
