import { ElementType } from 'react'
import { splitObject } from '@woorcs/utils'

import { systemPropNames, SystemProps } from './props'

interface StyleProps extends SystemProps {
  className?: string
  as?: ElementType
}

export const EMOTION_PROPS = ['className', 'as']

const STYLED_PROPS = [
  ...EMOTION_PROPS,
  ...systemPropNames
] as (keyof StyleProps)[]

export const splitStyledProps = <P extends StyleProps>(props: P) =>
  splitObject(props, STYLED_PROPS)
