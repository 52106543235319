import { useParams } from 'react-router'
import { useQuery } from 'urql'
import { Suspense, useMemo } from 'react'
import {
  Badge,
  Box,
  Flex,
  LargeBody,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  Tabs
} from '@woorcs/design-system'
import { Role } from '@woorcs/graphql/src/schema'
import { useTranslation } from 'react-i18next'

import {
  ActitivityLogList,
  FiltersProvider,
  ListPage,
  ListPageContent,
  ListPageHeader,
  PageContent,
  PageTitle,
  SubmissionList,
  useSubmissionFilters,
  FullscreenLoader
} from '@app/components'

import { UserDetailsQueryDocument } from './__generated__/UserDetails'

interface UserDetailsHeaderProps {
  userId: string
}

const UserDetailsHeader = ({ userId }: UserDetailsHeaderProps) => {
  const { t } = useTranslation('users')
  const [query] = useQuery({
    query: UserDetailsQueryDocument,
    variables: { id: userId as string },
    context: useMemo(
      () => ({
        suspense: true
      }),
      []
    )
  })

  const user = query.data?.user

  return (
    <ListPageHeader borderBottom='base' mb={0} pb={0}>
      <Box width='100%'>
        <Box mb={6}>
          <Flex flexDirection='row' alignItems='center' gridColumnGap={4}>
            <PageTitle mb={2}>{user?.name}</PageTitle>
            {user?.role === Role.Admin && <Badge>Admin</Badge>}
          </Flex>

          <LargeBody>{user?.account.email} </LargeBody>
        </Box>
        <TabList aria-label='User tabs' size='large' marginBottom='-1px'>
          <Tab>{t('details.tabs.activity')}</Tab>
          <Tab>{t('details.tabs.submissions')}</Tab>
        </TabList>
      </Box>
    </ListPageHeader>
  )
}

interface UserSubmissionsListProps {
  userId: string
}

const UserSubmissionsList = ({ userId }: UserSubmissionsListProps) => {
  const filtersContext = useSubmissionFilters({
    creatorId: userId
  })

  return (
    <FiltersProvider value={filtersContext}>
      <SubmissionList />
    </FiltersProvider>
  )
}

export const UserDetailsPage = () => {
  const { userId } = useParams()

  return (
    <Suspense fallback={<FullscreenLoader />}>
      <Tabs>
        <ListPage>
          <UserDetailsHeader userId={userId as string} />
          <ListPageContent px={0}>
            <TabPanel>
              <Suspense fallback={<FullscreenLoader />}>
                <PageContent pt={4}>
                  <ActitivityLogList
                    userId={userId as string}
                    separatorColor='grey.50'
                  />
                </PageContent>
              </Suspense>
            </TabPanel>
            <TabPanel
              display='flex'
              flexDirection='column'
              height='100%'
              flexGrow={1}
            >
              <Suspense fallback={<FullscreenLoader />}>
                <UserSubmissionsList userId={userId as string} />
              </Suspense>
            </TabPanel>
          </ListPageContent>
        </ListPage>
      </Tabs>
    </Suspense>
  )
}
