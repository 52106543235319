import React from 'react'

import { Page, ResourceNotFound } from '@app/components'

export const NotFoundPage = () => {
  return (
    <Page justifyContent='center'>
      <ResourceNotFound
        title='Not found'
        description={`We cant find the page that you're looking for`}
      />
    </Page>
  )
}
