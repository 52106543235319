import * as t from 'io-ts'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isURL from 'validator/lib/isURL'

import { isValidHex } from '../isValidHex'

export interface NonEmptyStringBrand {
  readonly NonEmptyString: unique symbol
}

export const NonEmptyString = t.brand(
  t.string,
  (s): s is t.Branded<string, NonEmptyStringBrand> => s.length > 0,
  'NonEmptyString'
)

export interface TrimmedStringBrand {
  readonly TrimmedString: unique symbol
}

export const TrimmedString = t.brand(
  t.string,
  (s): s is t.Branded<string, TrimmedStringBrand> =>
    s.trim().length === s.length,
  'TrimmedString'
)

export const NonEmptyTrimmedString = t.intersection([
  // The order matters. UI can show the first error only.
  NonEmptyString,
  TrimmedString
])

export interface Max64StringBrand {
  readonly Max64String: unique symbol
}

export const Max64String = t.brand(
  t.string,
  (s): s is t.Branded<string, Max64StringBrand> => s.length <= 64,
  'Max64String'
)

export interface Max512StringBrand {
  readonly Max512String: unique symbol
}

export const Max512String = t.brand(
  t.string,
  (s): s is t.Branded<string, Max512StringBrand> => s.length <= 512,
  'Max512String'
)

export interface Min6StringBrand {
  readonly Min6String: unique symbol
}

export const Min6String = t.brand(
  t.string,
  (s): s is t.Branded<string, Min6StringBrand> => s.length >= 6,
  'Min6String'
)

export interface HexColorStringBrand {
  readonly HexColorString: unique symbol
}

export const HexColorString = t.brand(
  t.string,
  (s): s is t.Branded<string, HexColorStringBrand> => isValidHex(s),
  'HexColorString'
)

export interface EmailStringBrand {
  readonly EmailString: unique symbol
}

export const EmailString = t.brand(
  t.string,
  (s): s is t.Branded<string, EmailStringBrand> => isEmail(s),
  'EmailString'
)

export interface PhoneStringBrand {
  readonly PhoneString: unique symbol
}

export const PhoneString = t.brand(
  t.string,
  (s): s is t.Branded<string, PhoneStringBrand> => isMobilePhone(s),
  'PhoneString'
)

export interface UrlStringBrand {
  readonly UrlString: unique symbol
}

export const UrlString = t.brand(
  t.string,
  (s): s is t.Branded<string, UrlStringBrand> => isURL(s),
  'UrlString'
)

export const String64 = t.intersection([NonEmptyTrimmedString, Max64String])

export type String64 = t.TypeOf<typeof String64>

export const String512 = t.intersection([NonEmptyTrimmedString, Max512String])

export type String512 = t.TypeOf<typeof String512>

export const Email = t.intersection([String64, EmailString])

export type Email = t.TypeOf<typeof Email>

export const Password = t.intersection([String512, Min6String])

export type Password = t.TypeOf<typeof Password>

export const Phone = t.intersection([NonEmptyTrimmedString, PhoneString])

export type Phone = t.TypeOf<typeof Phone>

export const HexColor = t.intersection([NonEmptyTrimmedString, HexColorString])

export type HexColor = t.TypeOf<typeof HexColor>

export const Url = t.intersection([NonEmptyTrimmedString, UrlString])

export type Url = t.TypeOf<typeof Url>
