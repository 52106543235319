import { whenPropEq } from '@woorcs/utils'
import { Group } from '@ariakit/react'

import { system, PropsOf } from '../../../system'

const isAlignRight = whenPropEq('align', 'right')

interface GroupProps extends PropsOf<typeof system.div> {
  align?: string
}

export const ButtonGroup = system(Group)<GroupProps>(
  {
    button: {
      mr: 2
    }
  },
  isAlignRight({
    button: {
      mr: 0,
      ml: 2
    }
  })
)

ButtonGroup.defaultProps = {
  align: 'left'
}
