import i18n from 'i18next'
// import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { setDefaultOptions } from 'date-fns'
import { sv as svLocale, enUS as enLocale } from 'date-fns/locale'

import { sv, en } from './locales'

const setDefaultDateFnsOptions = (lng: string) => {
  setDefaultOptions({
    locale: lng === 'sv' ? svLocale : enLocale
  })
}
export const initI18n = () =>
  i18n
    // load translation using http -> see /public/locales
    // .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: import.meta.env.DEV,
      ns: ['app', 'common', 'users'],
      defaultNS: 'app',
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
      },
      load: 'languageOnly',
      interpolation: {
        escapeValue: false
      },
      resources: {
        sv,
        en
      }
    })
    .then(() => {
      setDefaultDateFnsOptions(i18n.language)
    })

i18n.on('languageChanged', (lng) => {
  setDefaultDateFnsOptions(lng)
})
