import { ReactNode, useState, useCallback } from 'react'
import { format } from 'date-fns'
import { FormElement } from '@woorcs/form'
import * as O from 'fp-ts/Option'
import { DateRange } from '@woorcs/types'
import { pipe } from 'fp-ts/function'
import { useTranslation } from 'react-i18next'
import { system, Box, BoxProps, Text, Body, H4 } from '@woorcs/design-system'

import { submissionImageUrl } from '@app/utils/s3PublicUrl'

type HeaderProps = {
  title: string
  subtitle: string
}

export const Header = ({ title, subtitle }: HeaderProps) => {
  const { t } = useTranslation()

  return (
    <Box px='screenXInset' mb={12}>
      <Box>
        <H4>{title}</H4>
        <Body>{subtitle}</Body>
      </Box>
    </Box>
  )
}

type ContainerProps = {
  children: ReactNode
}

export const Container = ({ children }: ContainerProps) => {
  return <Box>{children}</Box>
}

interface PageProps {
  title: string
  children: ReactNode
}

export const Page = ({ title, children }: PageProps) => {
  const [collapsed, setCollapsed] = useState(false)

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  return (
    <Box px='screenXInset' mb={4}>
      <Box bg='greyLighter' borderRadius='m'>
        <Box
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          onClick={toggleCollapsed}
        >
          <Text as='h3' variant='subtitle'>
            {title}
          </Text>

          <Box
            style={{
              transform: [
                {
                  rotateZ: collapsed ? '-90deg' : '0deg'
                }
              ]
            }}
            ml={6}
          >
            {/* <CircleIcon
              name='chevron-down'
              backgroundColor='primaryLight'
              color='primary'
            /> */}
          </Box>
        </Box>
        <Box
          style={{ display: collapsed ? 'none' : 'block' }}
          borderTopColor='white'
          borderTopWidth={1}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

interface TextValueProps {
  children: ReactNode
}

const TextValue = ({ children }: TextValueProps) => (
  <Text variant='body'>{children}</Text>
)

interface ImageValueProps {
  src: string
}

const ImageValue = ({ src }: ImageValueProps) => (
  <system.img
    style={{
      aspectRatio: 1,
      width: '100%',
      height: undefined
    }}
    src={submissionImageUrl(src)}
  />
)

// eslint-disable-next-line complexity
export const renderInputElementResponse = <
  T extends FormElement.InputElementType
>(
  element: T,
  value: O.Option<FormElement.ValueOfInputElement<T>>
) => {
  const renderElement = (value: any) =>
    FormElement.InputElementType.match({
      TextInput: () => <TextValue>{value}</TextValue>,
      DateInput: (element) => {
        const formatting = element.options.withTime
          ? 'yyyy-MM-dd kk:mm'
          : 'yyyy-MM-dd'

        const date = format(new Date(value), formatting)

        return <TextValue>{date}</TextValue>
      },
      DateRangeInput: (element) => {
        const range = value as DateRange
        const formatting = element.options.withTime
          ? 'yyyy-MM-dd kk:mm'
          : 'yyyy-MM-dd'
        const fromDate =
          value && range.from ? format(new Date(range.from), formatting) : ''
        const toDate =
          value && range.to ? format(new Date(range.to), formatting) : ''

        return (
          <TextValue>
            {fromDate} - {toDate}
          </TextValue>
        )
      },
      TimeInput: () => {
        try {
          const date = format(new Date(value as string), 'kk:mm')

          return <TextValue>{date}</TextValue>
        } catch (error) {
          return <TextValue>{value}</TextValue>
        }
      },
      SelectInput: () => <TextValue>{value}</TextValue>,
      MultiSelectInput: () => {
        if (!Array.isArray(value)) {
          return <TextValue>-</TextValue>
        }

        return (
          <>
            {value.map((value: string, index: number) => (
              <Box key={index}>
                <TextValue>{value}</TextValue>
              </Box>
            ))}
          </>
        )
      },
      SignatureInput: () => <ImageValue src={value as string} />,
      ImageInput: () => {
        if (Array.isArray(value)) {
          return (
            <>
              {value.map((x, index) => (
                <Box key={index}>
                  <ImageValue src={x as string} />
                </Box>
              ))}
            </>
          )
        }

        return <ImageValue src={value as string} />
      },
      NumberInput: () => <TextValue>{value}</TextValue>,
      EmailInput: () => <TextValue>{value}</TextValue>,
      LocationInput: () => null,
      GroupInput: () => null
    })

  return pipe(
    value,
    O.fold(
      () => <TextValue>-</TextValue>,
      (value) => pipe(element, renderElement(value))
    )
  )
}

interface FieldProps {
  label: string
  borderColor?: BoxProps['borderBottomColor']
  isLast?: boolean
  children: ReactNode
}

export const RenderField = ({
  label,
  borderColor = 'white',
  isLast,
  children
}: FieldProps) => {
  return (
    <Box
      py={4}
      borderBottomColor={borderColor}
      borderBottomWidth={isLast ? 0 : 1}
    >
      <Text as='h4' variant='body' fontWeight='bold' mb={2}>
        {label}
      </Text>
      {children}
    </Box>
  )
}
