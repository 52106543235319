import React, { ReactNode } from 'react'
import { whenPropEq } from '@woorcs/utils'

import { system, PropsOf } from '../../../system'

interface FormControlOptions {
  placement?: 'top' | 'left' | 'right' | 'bottom'
  size?: 'small'
}

const SPACING = 2

const isLeft = whenPropEq('placement', 'left')
const isRight = whenPropEq('placement', 'right')
const isTop = whenPropEq('placement', 'top')
const isBottom = whenPropEq('placement', 'bottom')

const isSmall = whenPropEq('size', 'small')

const Label = system('span')<FormControlOptions>(
  {
    fontSize: 'small',
    fontWeight: 'semi-bold',
    color: 'text.emphasized',
    lineHeight: 1,
    maxWidth: '100%'
  },
  isSmall({
    fontSize: 'mini',
    fontWeight: 'regular',
    // TODO: This should be defined in the theme
    lineHeight: 20 / 14
  }),
  isLeft({
    mr: SPACING
  }),
  isRight({
    ml: SPACING
  }),
  isTop({
    mb: SPACING
  }),
  isBottom({
    mt: SPACING
  })
)

const Container = system('label')<FormControlOptions>(
  {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  isSmall({
    alignItems: 'flex-start'
  }),
  isLeft({
    flexDirection: 'row-reverse'
  }),
  isRight({
    flexDirection: 'row'
  }),
  isTop({
    flexDirection: 'column-reverse'
  }),
  isBottom({
    flexDirection: 'column'
  })
)

interface Props extends PropsOf<typeof system.label>, FormControlOptions {
  label: ReactNode
}

export const FormControlLabel = ({
  label,
  placement = 'right',
  size,
  children,
  ...other
}: Props) => (
  <Container size={size} placement={placement} {...other}>
    {children}
    <Label size={size} placement={placement}>
      {label}
    </Label>
  </Container>
)
