import { whenTrue } from '@woorcs/utils'

import { focusStyle } from '../../styles'
import { system, PropsOf } from '../../system'

const whenIsDisabled = whenTrue(['disabled'])

export const PlainLink = system('a')(
  {
    border: 'none',
    outline: 'none',
    color: 'inherit',
    cursor: 'pointer',
    ':focus': focusStyle
  },
  whenIsDisabled({
    cursor: 'not-allowed'
  })
)

export type PlainLinkProps = PropsOf<typeof PlainLink>
