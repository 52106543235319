import { ReactNode } from 'react'
import { Provider as UrqlProvider } from 'urql'

import { useCreateClient } from './useCreateClient'

interface GraphqlProviderProps {
  organizationId: string
  children: ReactNode
}

export const GraphqlProvider = (props: GraphqlProviderProps) => {
  const client = useCreateClient(props.organizationId)

  if (!client) {
    return null
  }

  return <UrqlProvider value={client} {...props} />
}
