import { applyAll, useControllableProp, useAllCallbacks } from '@woorcs/hooks'
import {
  useState,
  ChangeEvent,
  FocusEvent,
  useCallback,
  useRef,
  Ref
} from 'react'
import { forkRefs } from '@woorcs/utils'

import { useRadioGroupContext } from './RadioGroup'

interface UseRadioState {
  readOnly?: boolean
  disabled: boolean
  checked: boolean
  defaultChecked?: boolean
}

interface UseRadioProps {
  ref?: Ref<HTMLInputElement>
  name?: string
  value?: string | number
  readOnly?: boolean
  disabled?: boolean
  checked?: boolean
  defaultChecked?: boolean
  onChange?(e: ChangeEvent<HTMLInputElement>): void
  onBlur?(e: FocusEvent<HTMLInputElement>): void
}

interface UseRadioReturnProps {
  ref: Ref<HTMLInputElement>
  name?: string
  value?: string | number
  checked: boolean
  disabled: boolean
  onChange(e: ChangeEvent<HTMLInputElement>): void
  onBlur(e: FocusEvent<HTMLInputElement>): void
}

type UseRadioReturn = [UseRadioState, UseRadioReturnProps]

export const useRadio = ({
  ref,
  name,
  value,
  readOnly,
  disabled,
  checked: checkedProp,
  defaultChecked,
  onChange,
  onBlur
}: UseRadioProps): UseRadioReturn => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const group = useRadioGroupContext()
  const handleBlur = useAllCallbacks([onBlur, group?.onBlur])
  const [checkedState, setCheckedState] = useState(!!defaultChecked)
  const [isControlled, checked] = useControllableProp(checkedProp, checkedState)
  const isChecked = group?.value ? group.value === value : checked
  const isDisabled = Boolean(disabled ?? group?.disabled)

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (readOnly) {
        event.preventDefault()
        return
      }

      if (!isControlled) {
        setCheckedState(event.target.checked)
      }

      applyAll(onChange, group?.onChange)(event)
    },
    [readOnly, isControlled, onChange, group]
  )

  return [
    {
      checked: isChecked,
      disabled: isDisabled,
      readOnly
    },
    {
      ref: forkRefs(ref, inputRef),
      name: name ?? group?.name,
      value,
      checked: isChecked,
      disabled: isDisabled,
      onChange: handleChange,
      onBlur: handleBlur
    }
  ]
}
