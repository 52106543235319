import React, { ReactNode } from 'react'

import { Snackbar } from '../../components'

import { SnackbarContextProvider, useSnackbarProvider } from './context'

type SnackbarProviderProps = {
  children: ReactNode
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [{ current }, operations] = useSnackbarProvider()
  const [snackbar, visible] = current ?? []

  return (
    <SnackbarContextProvider value={operations}>
      {snackbar && (
        <Snackbar
          visible={visible}
          autoHideDuration={snackbar.duration ?? 3000}
          variant={snackbar.variant}
          action={snackbar.action}
          onHide={operations.hideCurrent}
          onExited={operations.didHideCurrent}
        >
          {snackbar.title}
        </Snackbar>
      )}

      {children}
    </SnackbarContextProvider>
  )
}
