import { cloneElement, ComponentProps, ReactElement } from 'react'
import { isRenderProp } from '@woorcs/utils'
import { FormField as BaseFormField } from '@woorcs/design-system'

import { useField, UseFieldFieldProps } from './useField'
import { FormFieldErrorMessage } from './ErrorMessage'

export type FormFieldProps = Omit<
  ComponentProps<typeof BaseFormField>,
  'children'
> & {
  name: string
  children: ReactElement | ((props: UseFieldFieldProps) => ReactElement)
}

export const FormField = ({ children, name, ...other }: FormFieldProps) => {
  const [field, meta] = useField(name)
  const firstError = Array.isArray(meta.error) ? meta.error[0] : meta.error
  const input = isRenderProp(children)
    ? children(field)
    : cloneElement(children as ReactElement, field)

  return (
    <BaseFormField
      invalid={Boolean(meta.touched && meta.error)}
      showMessages={false}
      {...other}
    >
      {input}
      <FormFieldErrorMessage error={meta.touched ? firstError : undefined} />
    </BaseFormField>
  )
}
