import * as NEA from 'fp-ts/NonEmptyArray'
import * as A from 'fp-ts/Array'
import * as Optional from 'monocle-ts/Optional'

export function getNonEmptyArrayOptional<A>(): Optional.Optional<
  A[],
  NEA.NonEmptyArray<A>
> {
  return {
    getOption: NEA.fromArray,
    set: (a) => (s) => (A.isNonEmpty(s) ? a : s)
  }
}
