import React from 'react'
import { Badge } from '@woorcs/design-system'

import { FormVersionBadgeFormFragment } from './__generated__/FormVersionBadge'

interface FormVersionBadgeProps {
  form: FormVersionBadgeFormFragment
}

export const FormVersionBadge = ({ form }: FormVersionBadgeProps) => {
  const badges = []
  const active = form.active
  const latest = form.latestRevision

  if (active) {
    badges.push(
      <Badge key='active' mr={2}>
        Active
      </Badge>
    )
  }

  if (latest.status !== 'PUBLISHED') {
    badges.push(
      <Badge key='draft' variant='warning'>
        Draft
      </Badge>
    )
  }

  return <>{badges}</>
}
