import {
  Flex,
  List,
  Text,
  ListItemBody,
  SelectableListItem
} from '@woorcs/design-system'
import * as A from 'fp-ts/Array'
import * as R from 'fp-ts/Record'
import * as string from 'fp-ts/string'
import React, { useCallback } from 'react'
import { pipe } from 'fp-ts/function'
import { ResponseSet } from '@woorcs/form'

import { useValue } from '../../state'

import { ResponseSetOptionsBadge } from './OptionsBadge'

type ResponseSetLabelProps = {
  responseSet: ResponseSet.ResponseSet
}

export const ResponseSetLabel = ({ responseSet }: ResponseSetLabelProps) => {
  return (
    <Flex justifyContent='space-between' width='100%' flexShrink={1}>
      <Text fontWeight='semi-bold' fontSize='small'>
        {responseSet.name}
      </Text>
      <ResponseSetOptionsBadge responseSet={responseSet} />
    </Flex>
  )
}

type ResponseSetListItemProps = {
  responseSet: ResponseSet.ResponseSet
  onClick(responseSet: ResponseSet.ResponseSet): void
}

const ResponseSetListItem = ({
  responseSet,
  onClick
}: ResponseSetListItemProps) => {
  const handleClick = useCallback(() => {
    onClick(responseSet)
  }, [onClick, responseSet])

  return (
    <SelectableListItem css={{ cursor: 'pointer' }} onClick={handleClick}>
      <ListItemBody ml={0}>
        <Flex alignItems='center'>
          <ResponseSetLabel responseSet={responseSet} />
        </Flex>
      </ListItemBody>
    </SelectableListItem>
  )
}

type ResponseSetListProps = {
  onResponseSetClick(responseSet: ResponseSet.ResponseSet): void
}

export const ResponseSetList = ({
  onResponseSetClick
}: ResponseSetListProps) => {
  const root = useValue()

  return pipe(
    root.responseSets,
    R.collect(string.Ord)((_, responseSet) => responseSet),
    A.map((responseSet) => (
      <ResponseSetListItem
        key={responseSet.id}
        responseSet={responseSet}
        onClick={onResponseSetClick}
      />
    )),
    (items) => <List>{items}</List>
  )
}
