import { useCallback } from 'react'
import { pipe } from 'fp-ts/function'
import { InspectionFormDefinition } from '@woorcs/inspection-form'
import { Locale, I18nConfig } from '@woorcs/form'
import { UUID } from '@woorcs/types/UUID'

import { useValue } from './useValue'
import { useElementIO } from './useElementIO'

export const useI18nConfig = () => {
  const root = useValue() as InspectionFormDefinition.InspectionFormDefinition
  const { onChange } = useElementIO(root)

  const addLanguage = useCallback(
    (locale: Locale.Locale) => {
      onChange({
        ...root,
        i18n: pipe(root.i18n, I18nConfig.addLanguage(locale))
      })()
    },
    [onChange, root]
  )

  const removeLanguage = useCallback(
    (locale: Locale.Locale) => {
      onChange({
        ...root,
        i18n: pipe(root.i18n, I18nConfig.removeLanguage(locale))
      })()
    },
    [onChange, root]
  )

  const setDefaultLanguage = useCallback(
    (locale: Locale.Locale) => {
      onChange({
        ...root,
        i18n: pipe(root.i18n, I18nConfig.setDefaultLanguage(locale))
      })()
    },
    [onChange, root]
  )

  const setTranslation = useCallback(
    (locale: Locale.Locale, id: UUID, text: string) => {
      onChange({
        ...root,
        i18n: pipe(root.i18n, I18nConfig.setTranslation(locale, id, text))
      })()
    },
    [onChange, root]
  )

  return [
    root.i18n,
    {
      addLanguage,
      removeLanguage,
      setDefaultLanguage,
      setTranslation
    }
  ] as const
}
