import Color from 'color'
import { palette } from '@woorcs/design-tokens'
import { forwardRef } from 'react'
import { motion } from 'framer-motion'

import { css } from '../../../system'
import { Box, BoxProps } from '../../layout'

const transition = {
  duration: 0.25,
  ease: [0.48, 0.15, 0.25, 0.96]
}

const animations = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition },
  exit: { opacity: 0, transition }
}

interface DialogBackdropProps extends BoxProps {
  centerContent?: boolean
}

export const DialogBackdrop = forwardRef<HTMLDivElement, DialogBackdropProps>(
  ({ centerContent = false, children, ...props }, ref) => (
    <Box
      ref={ref}
      css={css({
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        perspective: '800px',
        zIndex: '1050 !important'
      })}
      {...props}
    >
      <motion.div
        css={css({
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: Color(palette.primary['50']).alpha(0.65).toString(),
          backdropFilter: 'blur(4px)'
        })}
        initial={animations.initial}
        animate={animations.animate}
        exit={animations.exit}
      />
      <Box
        css={css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: centerContent ? 'center' : 'flex-start',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          maxHeight: '100vh',
          overflowY: 'scroll',
          pt: centerContent ? 0 : '20vh',
          pb: 12
        })}
      >
        {children}
      </Box>
    </Box>
  )
)
