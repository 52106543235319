import {
  CombinedError,
  createClient,
  dedupExchange,
  Exchange,
  fetchExchange
} from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'

import {
  createErrorExchange,
  createAuthExchange,
  AuthExchangeProps
} from './exchanges'

export interface GraphqlClientProps extends AuthExchangeProps {
  url: string
  suspense?: boolean
  exchanges?: Exchange[]
  cache?: Exchange
  onRequestError(error: CombinedError): void
}

export const graphqlClient = ({
  url,
  getAccessToken,
  logout,
  suspense = true,
  exchanges = [],
  cache = cacheExchange({}),
  onRequestError
}: GraphqlClientProps) => {
  const auth = createAuthExchange({
    getAccessToken,
    logout
  })
  const errorExchange = createErrorExchange(onRequestError)

  return createClient({
    url,
    suspense,
    exchanges: [
      dedupExchange,
      errorExchange,
      ...exchanges,
      cache,
      auth,
      fetchExchange
    ]
  })
}
