import { UUID } from '@woorcs/types/UUID'
import { constant, flow, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Reader'
import * as RR from 'fp-ts/ReadonlyRecord'

import { getText, TranslatorEnvironment } from './i18n/Translator'
import * as ResponseSet from './types/ResponseSet'

type ResponseSets = Record<string, ResponseSet.ResponseSet>

// -------------------------------------------------------------------------------------
// store
// -------------------------------------------------------------------------------------

export interface ResponseSetRegistry {
  all(): ResponseSets
  get(id: UUID): O.Option<ResponseSet.ResponseSet>
}

export const getResponseSetRegistry = (
  responseSets: ResponseSets
): ResponseSetRegistry => {
  return {
    all: () => responseSets,
    get: (id) => pipe(responseSets, RR.lookup(id))
  }
}

// -------------------------------------------------------------------------------------
// instance
// -------------------------------------------------------------------------------------

export interface ResponseSetRegistryEnvironment extends TranslatorEnvironment {
  responseSets: ResponseSetRegistry
}

export const getResponseSets = (): R.Reader<
  ResponseSetRegistryEnvironment,
  ResponseSets
> =>
  R.asks(({ responseSets }: ResponseSetRegistryEnvironment) =>
    responseSets.all()
  )

export const getResponseSet = (
  id: UUID
): R.Reader<
  ResponseSetRegistryEnvironment,
  O.Option<ResponseSet.ResponseSet>
> =>
  pipe(
    R.asks(({ responseSets }: ResponseSetRegistryEnvironment) =>
      responseSets.get(id)
    )
  )

/**
 * retrieves a translated response set option label
 */
export const getOptionLabel = (
  responseSetID: UUID,
  optionID: UUID
): R.Reader<ResponseSetRegistryEnvironment, string> =>
  pipe(
    getResponseSet(responseSetID),
    R.chain(
      flow(
        O.chain(ResponseSet.getOption(optionID)),
        O.map((option) => option.label),
        O.foldW(constant(R.of('')), getText)
      )
    )
  )
