import React, { ReactElement } from 'react'
import { Box, Flex, Modal, ModalDisclosure } from '@woorcs/design-system'
import { rem } from '@woorcs/utils'
import { Locale } from '@woorcs/form'

import { FullScreenModalDialog } from '../FullScreenModalDialog'
import { TranslateLanguageForm } from '../TranslateLanguageForm'

import { TranslateLanguageModalNavbar } from './Navbar'

type TranslateLanguageViewProps = {
  locale: Locale.Locale
  isSaving: boolean
  isVisible: boolean
  onLocaleChange: (locale: Locale.Locale) => void
  onClose(): void
}

export const TranslateLanguageModal = ({
  locale,
  isSaving,
  isVisible,
  onLocaleChange,
  onClose
}: TranslateLanguageViewProps) => {
  return (
    <Modal
      open={isVisible}
      setOpen={(visible) => {
        if (!visible) {
          onClose()
        }
      }}
      defaultOpen
    >
      <FullScreenModalDialog>
        <Flex width='100%' height='100vh' flexDirection='column'>
          <TranslateLanguageModalNavbar
            isSaving={isSaving}
            selectedLanguage={locale}
            onSelectLanguage={onLocaleChange}
          />

          <Flex
            style={{
              overflowY: 'auto'
            }}
            flexDirection='column'
            alignItems='center'
            width='100%'
            height='100%'
            py={12}
            bg='white'
          >
            <Box css={{ width: rem(960) }}>
              <TranslateLanguageForm locale={locale} />
            </Box>
          </Flex>
        </Flex>
      </FullScreenModalDialog>
    </Modal>
  )
}
