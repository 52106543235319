import React, { memo, ReactElement, useCallback } from 'react'
import {
  SecondaryButton,
  DangerButton,
  ListItemTitle,
  ListItemField,
  ListItemActionField,
  SelectableListItem,
  ListItemBody,
  Text,
  Flex,
  ConfirmAlertDialog
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import { EditTagModal } from '../EditTagModal/EditTagModal'
import { TagAvatar } from '../TagAvatar'

import { TagListTagFragment } from './__generated__/TagsList'

type ConfirmRemoveAlertProps = {
  tag: TagListTagFragment
  onConfirm(): void
  children: ReactElement
}

const ConfirmRemoveAlert = memo(
  ({ tag, onConfirm, children }: ConfirmRemoveAlertProps) => {
    return (
      <ConfirmAlertDialog
        title={`Ta bort \`${tag.name}\``}
        body='Är du säker på att du vill ta bort den här taggen?'
        confirmLabel='Ta bort'
        onConfirm={onConfirm}
      >
        {children}
      </ConfirmAlertDialog>
    )
  }
)

const animationVariants = {
  container: {
    initial: {
      opacity: 1
    },
    hovering: {
      opacity: 1
    }
  },
  actions: {
    initial: {
      opacity: 0
    },
    hovering: {
      opacity: 1
    }
  }
}

interface TagListItemProps {
  tag: TagListTagFragment
  onRemove(id: string): void
}

export const TagListItem = ({ tag, onRemove }: TagListItemProps) => {
  const { t } = useTranslation()

  const handleRemoveTag = useCallback(() => {
    onRemove(tag.id)
  }, [onRemove, tag.id])

  return (
    <motion.div
      whileHover='hovering'
      initial='initial'
      variants={animationVariants.container}
    >
      <SelectableListItem>
        <ListItemField>
          <TagAvatar tag={tag} />
        </ListItemField>
        <ListItemBody>
          <ListItemTitle>
            {tag.name}{' '}
            <Text fontWeight='regular' color='grey.600'>
              {tag.userCount} users
            </Text>
          </ListItemTitle>
        </ListItemBody>
        <ListItemActionField textAlign='right'>
          <motion.div variants={animationVariants.actions}>
            <Flex>
              <ConfirmRemoveAlert tag={tag} onConfirm={handleRemoveTag}>
                <DangerButton
                  size='small'
                  variant='outlined'
                  colorVariant='danger'
                  mr={2}
                >
                  {t('common:remove')}
                </DangerButton>
              </ConfirmRemoveAlert>
              <EditTagModal key={tag.id} tag={tag}>
                <SecondaryButton size='small' variant='outlined'>
                  {t('common:edit')}
                </SecondaryButton>
              </EditTagModal>
            </Flex>
          </motion.div>
        </ListItemActionField>
      </SelectableListItem>
    </motion.div>
  )
}
