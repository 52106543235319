import React, { ReactElement } from 'react'
import { createContext } from '@woorcs/utils'

import { FormControlGroup, FormControlGroupProps } from '../FormControlGroup'
import { SystemComponentProps } from '../../../system'

import {
  useCheckboxGroup,
  UseCheckboxGroupReturn,
  UseCheckboxGroupProps
} from './useCheckboxGroup'

interface CheckboxGroupProps
  extends Partial<Pick<FormControlGroupProps, keyof SystemComponentProps>>,
    UseCheckboxGroupProps {
  children: ReactElement[]
}

export type CheckboxGroupContext = UseCheckboxGroupReturn

const [CheckboxGroupProvider, useCheckboxGroupContext] =
  createContext<CheckboxGroupContext>({
    name: 'CheckboxGroupContext',
    strict: false
  })

export { useCheckboxGroupContext }

export const CheckboxGroup = ({
  name,
  value,
  defaultValue,
  disabled = false,
  onChange,
  onBlur,
  ...other
}: CheckboxGroupProps) => {
  const group = useCheckboxGroup({
    name,
    value,
    defaultValue,
    disabled,
    onChange,
    onBlur
  })

  return (
    <CheckboxGroupProvider value={group}>
      <FormControlGroup {...other} />
    </CheckboxGroupProvider>
  )
}
