import { ResponseSet } from '@woorcs/form'
import { InspectionFormDefinition } from '@woorcs/inspection-form'
import { pipe } from 'fp-ts/function'
import React, { ReactElement, useCallback } from 'react'

import { useEditorContext, useValue } from '../../state'
import { ResponseSetFormModal } from '../ResponseSetFormModal'

interface EditResponseSetModalProps {
  responseSet: ResponseSet.ResponseSet
  children: ReactElement
}

export const EditResponseSetModal = ({
  responseSet,
  children
}: EditResponseSetModalProps) => {
  const { editor } = useEditorContext()
  const root = useValue()

  const handleSubmit = useCallback(
    (responseSet: ResponseSet.ResponseSet) => {
      const updateQuestion = editor.updateElement(
        pipe(root, InspectionFormDefinition.updateResponseSet(responseSet))
      )

      updateQuestion()
    },
    [editor, root]
  )

  return (
    <ResponseSetFormModal initialValue={responseSet} onSubmit={handleSubmit}>
      {children}
    </ResponseSetFormModal>
  )
}
