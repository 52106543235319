import React, { useCallback } from 'react'
import { SecondaryButton } from '@woorcs/design-system'
import { useNavigate } from 'react-router'

import {
  CreateFormModal,
  FormList,
  ListPage,
  ListPageContent,
  ListPageHeader,
  PageTitle
} from '@app/components'
import { CreateFormFormFragment } from '@app/components/CreateFormForm/__generated__/CreateFormForm'

export const FormListPage = () => {
  const navigate = useNavigate()
  const handleCreateForm = useCallback(
    (form: CreateFormFormFragment) => {
      navigate(`/forms/${form.id}/edit`)
    },
    [navigate]
  )

  return (
    <ListPage>
      <ListPageHeader borderBottom='base' pb={8}>
        <PageTitle>Forms</PageTitle>

        <CreateFormModal onCreateForm={handleCreateForm}>
          <SecondaryButton>Create form</SecondaryButton>
        </CreateFormModal>
      </ListPageHeader>
      <ListPageContent py={8}>
        <FormList />
      </ListPageContent>
    </ListPage>
  )
}
