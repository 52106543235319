import { ReactElement } from 'react'

import { PopoverDisclosure } from '../../overlays'

import { useSelectDisclosure } from './useSelectState'

interface SelectDisclosureProps {
  children: ReactElement
}

export const SelectDisclosure = ({ children }: SelectDisclosureProps) => {
  const { disabled } = useSelectDisclosure()

  return <PopoverDisclosure disabled={disabled}>{children}</PopoverDisclosure>
}
