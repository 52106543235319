import * as O from 'fp-ts/Option'
import { useRef, useEffect } from 'react'

export function usePrevious<T>(value: T) {
  const ref = useRef<T | null>()

  useEffect(() => void (ref.current = value), [value])

  return ref.current
}

export function usePreviousStrict<T>(value: T): O.Option<T> {
  const ref = useRef<O.Option<T>>(O.none)

  useEffect(() => void (ref.current = O.fromNullable(value)), [value])

  return ref.current as O.Option<T>
}
