import { useId as useUID } from '@reach/auto-id'

function generatePrefix(prefix: string, id: string) {
  return `${prefix}-${id}`
}

export function useId(idProp?: string, prefix?: string) {
  const uuid = useUID() as string
  const id = idProp ?? uuid
  const result = prefix ? generatePrefix(prefix, id) : id

  return String(result)
}
