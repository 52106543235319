import { PropsOf } from '@emotion/react'

import { system, css } from '../../../system'

const ELEVATION_STYLE_MAP = [
  {},
  {
    boxShadow: 'small'
  },
  {
    boxShadow: 'medium'
  },
  {
    boxShadow: 'large'
  }
]

export interface SurfaceProps extends PropsOf<typeof system.div> {
  elevation?: 0 | 1 | 2 | 3
}

const elevation = ({ elevation = 0 }: SurfaceProps) =>
  css(ELEVATION_STYLE_MAP[elevation])

export const Surface = system('div')<SurfaceProps>(elevation)

Surface.defaultProps = {
  bg: 'background.base'
}
