import React, { useCallback, MouseEvent } from 'react'
import { Input, Box, CloseIcon, IconButton } from '@woorcs/design-system'
import { ResponseSet } from '@woorcs/form'
import { UUID } from '@woorcs/types/UUID'
import * as O from 'fp-ts/Option'
import { constant, constNull, pipe } from 'fp-ts/function'

import { SelectResponseSetOptionMenu } from './Menu'

type SelectResponseSetOptionProps = {
  value: O.Option<UUID>
  responseSet: ResponseSet.ResponseSet
  placeholder: string
  onChange(option: O.Option<UUID>): void
}

export const SelectResponseSetOption = ({
  value,
  placeholder,
  responseSet,
  onChange
}: SelectResponseSetOptionProps) => {
  const handleClear = useCallback(
    (e: MouseEvent) => {
      onChange(O.none)

      e.preventDefault()
    },
    [onChange]
  )

  const handleSelect = useCallback(
    (option: ResponseSet.ResponseSetOption) => {
      onChange(O.some(option.id))
    },
    [onChange]
  )

  return (
    <SelectResponseSetOptionMenu
      responseSet={responseSet}
      onSelect={handleSelect}
    >
      <Box>
        <Input
          value={pipe(
            value,
            O.chain((id) => pipe(responseSet, ResponseSet.getOption(id))),
            O.map((option) => option.label.text),
            O.getOrElse(constant(''))
          )}
          placeholder={placeholder}
          rightAddon={pipe(
            value,
            O.fold(constNull, () => (
              <IconButton size='mini' onClick={handleClear}>
                <CloseIcon />
              </IconButton>
            ))
          )}
          readOnly
        />
      </Box>
    </SelectResponseSetOptionMenu>
  )
}
