import { ReactNode } from 'react'
import { Box, Flex, NAV_SIDEBAR_WIDTH } from '@woorcs/design-system'
import { Outlet } from 'react-router'

import { AppSidebar } from '../Sidebar'
import { AdministratingOrganizationAlert } from '../AdministratingOrganizationAlert'

const ContentContainer = (props: { children: ReactNode }) => (
  <Flex
    width='100%'
    minHeight='100%'
    flexDirection='column'
    flexGrow={1}
    alignItems='center'
    {...props}
  />
)

export const AppLayout = () => (
  <Box position='relative' width='100%' minHeight='100%'>
    <AppSidebar />

    <Flex
      flexDirection='column'
      pl={NAV_SIDEBAR_WIDTH}
      minHeight='100vh'
      width='100%'
    >
      <AdministratingOrganizationAlert />
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </Flex>
  </Box>
)
