import React, { useCallback } from 'react'
import { Box, H3, Body } from '@woorcs/design-system'
import { CreateOrganizationPayload } from '@woorcs/api-client'

import { LobbyLayout, CreateOrganizationForm } from '../../components'

export const CreateOrganizationPage = () => {
  const handleCreateOrganizationSuccess = useCallback(
    (organization: CreateOrganizationPayload) => {
      window.location.replace(`/${organization.id}`)
    },
    []
  )

  return (
    <LobbyLayout>
      <Box mb={12} textAlign='center'>
        <H3 mb={4}>Create organization</H3>
        <Body>To use the app you need to setup your organization.</Body>
      </Box>
      <CreateOrganizationForm
        onSubmitSuccess={handleCreateOrganizationSuccess}
      />
    </LobbyLayout>
  )
}
