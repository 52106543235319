import { useCallback } from 'react'

import { Button, BUTTON_HEIGHT } from '../../buttons'
import { PropsOf, system, css } from '../../../system'
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  MoreHorizontalIcon
} from '../../icons'
import { Stack } from '../../layout'

import {
  usePagination,
  UsePaginationProps,
  PaginationRangeItemType,
  PaginationRangeItem
} from './usePagination'

interface PaginationButtonProps extends PropsOf<typeof system.button> {
  isCurrent?: boolean
  index?: number
}

export const PaginationButton = ({
  isCurrent,
  ...other
}: PaginationButtonProps) => (
  <Button
    css={css({
      width: BUTTON_HEIGHT.small,
      padding: 0
    })}
    size='small'
    variant={isCurrent ? 'filled' : 'plain'}
    {...other}
  />
)

interface PaginiationPageButtonProps
  extends Omit<PaginationButtonProps, 'onClick'> {
  pageIndex: number
  onClick(pageIndex: number): void
}

export const PaginiationPageButton = ({
  pageIndex,
  onClick,
  ...props
}: PaginiationPageButtonProps) => {
  const handleClick = useCallback(() => {
    onClick(pageIndex)
  }, [onClick, pageIndex])

  return (
    <PaginationButton onClick={handleClick} {...props}>
      {pageIndex}
    </PaginationButton>
  )
}

type PaginiationPrevButtonProps = Omit<PaginationButtonProps, 'isCurrent'>

export const PaginiationPrevButton = (props: PaginiationPrevButtonProps) => (
  <PaginationButton {...props}>
    <ChevronLeftIcon />
  </PaginationButton>
)

type PaginiationNextButtonProps = Omit<PaginationButtonProps, 'isCurrent'>

export const PaginiationNextButton = (props: PaginiationNextButtonProps) => (
  <PaginationButton {...props}>
    <ChevronRightIcon />
  </PaginationButton>
)

type PaginiationEllipsisProps = PropsOf<typeof system.div>

export const PaginiationEllipsis = (props: PaginiationEllipsisProps) => (
  <system.div
    display='flex'
    alignItems='center'
    justifyContent='center'
    {...props}
  >
    <MoreHorizontalIcon size='small' color='grey.200' />
  </system.div>
)

type PaginationListProps = Omit<PropsOf<typeof Stack>, 'direction' | 'space'>

export const PaginationList = ({
  align = 'end',
  ...props
}: PaginationListProps) => (
  <Stack
    as='ul'
    display='flex'
    alignItems='center'
    justifyContent={`flex-${align}`}
    direction='horizontal'
    spacing={2}
    align='end'
    {...props}
  />
)

export interface PaginationProps extends UsePaginationProps {
  onChange(pageIndex: number): void
}

export const Pagination = ({
  currentPage,
  totalPages,
  onChange
}: PaginationProps) => {
  const range = usePagination({
    currentPage,
    totalPages: Math.max(totalPages, 1)
  })

  const handlePrevClick = useCallback(() => {
    onChange(currentPage - 1)
  }, [currentPage, onChange])

  const handleNextClick = useCallback(() => {
    onChange(currentPage + 1)
  }, [currentPage, onChange])

  if (totalPages <= 1) {
    return null
  }

  const renderRangeItem = (item: PaginationRangeItem) => {
    if (item[0] === PaginationRangeItemType.ELLIPSIS) {
      return (
        <PaginiationEllipsis key={Math.random().toString(36).substr(2, 5)} />
      )
    }

    const [, index] = item

    return (
      <PaginiationPageButton
        key={index}
        isCurrent={index === currentPage}
        pageIndex={index}
        onClick={onChange}
      />
    )
  }

  return (
    <PaginationList>
      <PaginiationPrevButton
        disabled={currentPage === 1}
        onClick={handlePrevClick}
      />

      {range.map(renderRangeItem) as any}

      <PaginiationNextButton
        disabled={currentPage === totalPages}
        onClick={handleNextClick}
      />
    </PaginationList>
  )
}
