import { FocusEventHandler } from 'react'
import { any } from 'ramda'
import { applyAll } from '@woorcs/hooks'
import { PropsOf } from '@emotion/react'
import { isNotNil, ariaAttr } from '@woorcs/utils'

import { system } from '../../../system'

import { useFormFieldContext, FormFieldInputProps } from './context'

export interface UseFormFieldProps<T extends HTMLElement>
  extends Omit<FormFieldInputProps, 'onFocus' | 'onBlur'> {
  onFocus?: FocusEventHandler<T>
  onBlur?: FocusEventHandler<T>
  disabled?: boolean
}

export const useFormField = <T extends HTMLElement>(
  props: UseFormFieldProps<T>
) => {
  const field = useFormFieldContext() ?? {}
  const id = props.id ?? field.id
  const disabled = props.disabled ?? field.disabled
  const readOnly = props.readOnly ?? field.readOnly
  const required = props.required ?? field.required
  const invalid = props.invalid ?? field.invalid
  const hasMessage = any(isNotNil, [
    field.errorMessage,
    field.infoMessage,
    field.warningMessage
  ])
  const ariaDescribedBy = hasMessage && field.labelId

  const getInputProps = () => ({
    id,
    disabled,
    readOnly,
    required,
    invalid,
    focused: field.focused,
    'aria-invalid': ariaAttr(invalid),
    'aria-required': ariaAttr(required),
    'aria-readonly': ariaAttr(readOnly),
    'aria-describedby': ariaDescribedBy || undefined,
    onFocus: applyAll(field.onFocus, props.onFocus),
    onBlur: applyAll(field.onBlur, props.onBlur)
  })

  return {
    ...props,
    id,
    disabled,
    readOnly,
    required,
    invalid,
    focused: field.focused,
    getInputProps
  }
}

interface UseFormFieldLabelProps extends PropsOf<typeof system.label> {
  required?: boolean
}

export const useFormFieldLabel = (
  props: UseFormFieldLabelProps
): UseFormFieldLabelProps => {
  const field = useFormFieldContext() ?? {}
  const required = props.required ?? field.required

  const id = props.id ?? field.labelId
  const htmlFor = props.htmlFor ?? field.id

  return {
    ...props,
    required,
    id,
    htmlFor
  }
}

export const useFormFieldMessages = () => {
  const { messageId, invalid, errorMessage, infoMessage, warningMessage } =
    useFormFieldContext() ?? {}

  return {
    messageId,
    invalid,
    errorMessage,
    infoMessage,
    warningMessage
  }
}
