import React from 'react'
import { Box, Button, Flex, Navbar, Text } from '@woorcs/design-system'
import { useAccount } from '@woorcs/api-client'

import { useAuth } from '@app/auth'

export const LobbyNavbar = () => {
  const [, { logout }] = useAuth()
  const account = useAccount()

  return (
    <Navbar bg='transparent' position='fixed' top={0} left={0} right={0}>
      <Box>
        <Text fontSize='small'>
          Logged in as <Text fontWeight='bold'>{account.account.email}</Text>
        </Text>
      </Box>

      <Flex>
        {account.account.superuser && (
          <Button
            as='a'
            variant='plain'
            mr={3}
            {...({ href: '/admin' } as any)}
          >
            Go to admin
          </Button>
        )}
        <Button variant='plain' onClick={() => logout()}>
          Logout
        </Button>
      </Flex>
    </Navbar>
  )
}
