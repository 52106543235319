import React, { useCallback } from 'react'
import {
  Flex,
  List,
  Text,
  ListItemBody,
  SelectableListItem
} from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as R from 'fp-ts/Record'
import * as string from 'fp-ts/string'
import { constNull, pipe } from 'fp-ts/function'
import { FormDocument } from '@woorcs/form'

import { useValue } from '../../state'

type DocumentLabelProps = {
  document: FormDocument.FormDocument
}

export const DocumentLabel = ({ document }: DocumentLabelProps) => (
  <Flex justifyContent='space-between' width='100%' flexShrink={1}>
    <Text fontWeight='semi-bold' fontSize='small'>
      {FormDocument.getDefaultTitle(document)}
    </Text>
  </Flex>
)

type DocumentListItemProps = {
  document: FormDocument.FormDocument
  onClick(document: FormDocument.FormDocument): void
}

const DocumentListItem = ({ document, onClick }: DocumentListItemProps) => {
  const handleClick = useCallback(() => {
    onClick(document)
  }, [onClick, document])

  return (
    <SelectableListItem css={{ cursor: 'pointer' }} onClick={handleClick}>
      <ListItemBody ml={0}>
        <Flex alignItems='center'>
          <DocumentLabel document={document} />
        </Flex>
      </ListItemBody>
    </SelectableListItem>
  )
}

type DocumentListProps = {
  onDocumentClick(document: FormDocument.FormDocument): void
}

export const DocumentList = ({ onDocumentClick }: DocumentListProps) => {
  const root = useValue()

  return pipe(
    root.documents,
    R.collect(string.Ord)((_, document) => document),
    NEA.fromArray,
    O.fold(constNull, (documents) =>
      pipe(
        documents,
        NEA.map((document) => (
          <DocumentListItem
            key={document.id}
            document={document}
            onClick={onDocumentClick}
          />
        )),
        (items) => <List mb={4}>{items}</List>
      )
    )
  )
}
