import { ReactElement, useCallback, useState } from 'react'
import {
  PrimaryButton,
  SecondaryButton,
  DialogSidebar,
  DialogSidebarDisclosure,
  DialogSidebarContent,
  Box,
  H4,
  Body
} from '@woorcs/design-system'
import { Condition, ElementRule } from '@woorcs/form'
import { UUID } from '@woorcs/types/UUID'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { DialogDismiss } from '@ariakit/react'

import { PageRuleForm } from '../PageRuleForm'
import { useEditorContext } from '../../state'

const defaultInitialValue: ElementRule.ElementRule = ElementRule.elementRule(
  'hide',
  Condition.and([Condition.leaf('' as UUID, '', 'eq')])
)

interface AddPageRuleModalFormProps {
  initialValue: O.Option<ElementRule.ElementRule>
  onSubmit(rule: ElementRule.ElementRule): void
}

const AddPageRuleModalForm = ({
  initialValue = O.none,
  onSubmit
}: AddPageRuleModalFormProps) => {
  const [value, setValue] = useState<ElementRule.ElementRule>(
    pipe(
      initialValue,
      O.getOrElse(() => defaultInitialValue)
    )
  )

  const handleSubmit = useCallback(() => {
    onSubmit(value)
  }, [onSubmit, value])

  return (
    <>
      <Box bg='grey.50' borderRadius='medium' mb={8} p={4}>
        <PageRuleForm rule={value} onChange={setValue} />
      </Box>

      <Box>
        <DialogDismiss render={<SecondaryButton mr={2} />}>
          Cancel
        </DialogDismiss>
        <PrimaryButton onClick={handleSubmit}>Save rule</PrimaryButton>
      </Box>
    </>
  )
}

interface AddPageRuleModalProps {
  page: InspectionFormDocument.InspectionFormPageElement
  children: ReactElement
  initialValue: O.Option<ElementRule.ElementRule>
}

export const AddPageRuleModal = ({
  page,
  children,
  initialValue
}: AddPageRuleModalProps) => {
  const { editor } = useEditorContext()
  const [visible, setVisible] = useState(false)

  const handleSubmit = useCallback(
    (rule: ElementRule.ElementRule) => {
      const updatePage = editor.updateElement({
        ...page,
        rule
      })

      updatePage()
      setVisible(false)
    },
    [editor, page]
  )

  return (
    <DialogSidebar placement='right' open={visible} setOpen={setVisible}>
      <DialogSidebarDisclosure>{children}</DialogSidebarDisclosure>
      <DialogSidebarContent aria-label='Page rule form'>
        <Box px={5} pt={8} pb={5}>
          <Box mb={8}>
            <H4 mb={2}>Add page rule</H4>
            <Body>
              {`Create conditional rules to dynamically change the page's behaviour.`}
            </Body>
          </Box>
          <AddPageRuleModalForm
            initialValue={initialValue}
            onSubmit={handleSubmit}
          />
        </Box>
      </DialogSidebarContent>
    </DialogSidebar>
  )
}
