import React from 'react'
import { ModalHeader, Flex, Box, H4, ModalDialog } from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { Location } from 'history'

import { ModalRoute } from '@app/components'

import { SettingsRoutes } from './routes'
import { SettingsMenu } from './components'

interface SettingsModalProps {
  prevLocation: Location
}

export const SettingsModal = ({ prevLocation }: SettingsModalProps) => {
  const { t } = useTranslation()

  return (
    <ModalRoute prevLocation={prevLocation}>
      <ModalDialog
        display='flex'
        flexDirection='column'
        width='960px'
        height='70vh'
        aria-label={t('viewer:settings.title')}
        minHeight={0}
        centerContent
      >
        <ModalHeader borderBottom='base' py={6} mb={0}>
          <H4>Settings</H4>
        </ModalHeader>
        <Flex flex={1} overflow='hidden'>
          <Box width={320} borderRight='base' px={8} py={8} height='100%'>
            <SettingsMenu />
          </Box>
          <Box
            width='100%'
            height='100%'
            flexShrink={1}
            overflowY='auto'
            py={12}
            px={18}
          >
            <Box width='100%'>
              <SettingsRoutes />
            </Box>
          </Box>
        </Flex>
      </ModalDialog>
    </ModalRoute>
  )
}
