/* eslint-disable complexity */
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as RA from 'fp-ts/ReadonlyArray'
import * as R from 'fp-ts/Reader'
import { UUID } from '@woorcs/types/UUID'
import * as ET from '@woorcs/types/ElementTree'

import * as FieldType from './ValidationSchema/FieldType'
import * as Condition from './types/Condition'
import { FormDocument } from './FormDocument/FormDocument'
import * as ElementRule from './FormDocument/ElementRule'
import * as FormElement from './FormDocument/Element'
import { Translator } from './i18n/Translator'
import { getOptionLabel, ResponseSetRegistry } from './ResponseSetRegistry'
import { ResponseSet } from './types'
import { getFieldType } from './FormDocument/InputElement'
import {
  NumberValidatorOptions,
  TextValidatorOptions
} from './ValidationSchema/validators'
import * as Field from './ValidationSchema/Field'
import { Locale } from './i18n/Locale'

export interface Environment {
  i18n: Translator
  responseSets: ResponseSetRegistry
  locale: Locale
}

export type Fields = Record<UUID, Field.Field>

const optionsFromResponseSet = (responseSet: ResponseSet.ResponseSet) =>
  pipe(
    responseSet.options,
    R.traverseArray((option) =>
      pipe(
        getOptionLabel(responseSet.id, option.id),
        R.map((label) => ({
          key: option.id,
          label
        }))
      )
    )
  )

const getResponseSetOptions = (id: UUID) =>
  pipe(
    R.ask<Environment>(),
    R.chain(({ responseSets }) =>
      pipe(
        responseSets.get(id),
        O.getOrElse(() => [] as any),
        optionsFromResponseSet
      )
    )
  )

const getCondition = (element: FormElement.FormElementType) => {
  if (!element.rule) {
    return O.none
  }
  const rule = element.rule

  return pipe(
    rule,
    ElementRule.matchRule({
      hide: () => Condition.not([rule.condition]),
      show: () => rule.condition
    }),
    O.some
  )
}

export const fromElement = (element: FormElement.InputElementType) =>
  pipe(
    R.ask<Environment>(),
    R.chainW(({ i18n }): R.Reader<Environment, Field.Field> => {
      const label = i18n.getText(element.label)
      const fieldType = getFieldType(element.type)
      const defaultValue = pipe(
        O.fromNullable(element.defaultValue),
        O.map(fieldType.decode),
        O.chain(O.fromEither)
      )
      const when = getCondition(element)

      if (FormElement.isResponseSetInputElement(element)) {
        return pipe(
          getResponseSetOptions(element.responseSet),
          R.map((options) =>
            pipe(
              element,
              FormElement.ResponseSetInputElement.match({
                SelectInput: (element) =>
                  FieldType.selectField.make({
                    label,
                    defaultValue,
                    options: {
                      options
                    },
                    optional: element.optional,
                    when
                  }),
                MultiSelectInput: (element) =>
                  FieldType.multiSelectField.make({
                    label,
                    defaultValue,
                    options: {
                      options
                    },
                    optional: element.optional,
                    when
                  })
              })
            )
          )
        )
      }

      return pipe(
        element,
        FormElement.InputElementType.exclude([
          'SelectInput',
          'MultiSelectInput'
        ]).match({
          TextInput: (element) =>
            FieldType.textField.make({
              label,
              options: element.options as TextValidatorOptions,
              defaultValue,
              optional: element.optional,
              when
            }),
          NumberInput: (element) =>
            FieldType.numberField.make({
              label,
              options: element.options as NumberValidatorOptions,
              defaultValue,
              optional: element.optional,
              when
            }),

          EmailInput: () =>
            FieldType.emailField.make({
              label,
              defaultValue,
              options: {},
              optional: element.optional,
              when
            }),
          LocationInput: () =>
            FieldType.locationField.make({
              label,
              defaultValue,
              options: {},
              optional: element.optional,
              when
            }),
          ImageInput: () =>
            FieldType.imageField.make({
              label,
              defaultValue,
              options: {},
              optional: element.optional,
              when
            }),
          SignatureInput: () =>
            FieldType.signatureField.make({
              label,
              defaultValue,
              options: {},
              optional: element.optional,
              when
            }),
          DateInput: () =>
            FieldType.dateField.make({
              label,
              defaultValue,
              options: {},
              optional: element.optional,
              when
            }),
          DateRangeInput: () =>
            FieldType.dateRangeField.make({
              label,
              defaultValue,
              options: {},
              optional: element.optional,
              when
            }),
          TimeInput: () =>
            FieldType.timeField.make({
              label,
              defaultValue,
              options: {},
              optional: element.optional,
              when
            }),
          GroupInput: () =>
            FieldType.groupField.make({
              label,
              defaultValue,
              options: {},
              optional: element.optional,
              when
            })
        }),
        R.of
      )
    })
  )

export const fromDocument = (
  document: FormDocument
): R.Reader<Environment, Fields> =>
  pipe(
    ET.toArray(document as any),
    RA.filter(FormElement.isInputElement),
    R.traverseArray((element) =>
      pipe(
        fromElement(element),
        R.map((field) => [element.key, field] as const)
      )
    ),
    R.map((fields) =>
      pipe(
        fields,
        RA.reduce({}, (fields, [key, field]) => ({
          ...fields,
          [key]: field
        }))
      )
    )
  )
