import { useCallback } from 'react'
import { InspectionFormDefinition } from '@woorcs/inspection-form'
import { Locale, TranslateableText, I18nConfig } from '@woorcs/form'
import { pipe } from 'fp-ts/function'

import { useValue } from './useValue'
import { useElementIO } from './useElementIO'

export const useTranslateableText = (
  text: TranslateableText.TranslateableText,
  locale: Locale.Locale
) => {
  const schema = useValue() as InspectionFormDefinition.InspectionFormDefinition
  const { onChange } = useElementIO(schema)

  const set = useCallback(
    (value: string) => {
      const i18n = pipe(
        schema.i18n,
        I18nConfig.setTranslation(locale, text.id, value)
      )

      const update = onChange({
        ...schema,
        i18n
      })

      update()
    },
    [locale, onChange, schema, text]
  )

  const value = pipe(schema.i18n, I18nConfig.getTranslation(locale, text.id))

  return [value, set] as const
}
