import { times } from 'ramda'
import {
  borderRadius,
  borders,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  palette,
  shadows,
  spacing,
  typeScale,
  letterSpacings
} from '@woorcs/design-tokens'
import { space, addAliases } from '@woorcs/utils'

const spacingExtras = times(space, 40)

export const lightTheme = {
  fontSizes,

  lineHeights,
  letterSpacings,

  fonts,
  fontWeights,
  space: addAliases(spacingExtras, spacing),
  radii: borderRadius,
  shadows,
  borders,

  scale: typeScale,

  zIndices: {
    navbar: 1000,
    menu: 1010,
    backdrop: 1040,
    drawer: 1050,
    modal: 1050,
    popover: 1060,
    tooltip: 1070,
    snackbar: 1080
  },

  colors: {
    ...palette,
    components: {
      button: {
        primary: {
          color: palette.primary['50'],
          text: palette.primary['500'],
          light: palette.primary['300']
        },
        inverted: {
          color: palette.white,
          light: 'inverted',
          text: 'primary.500'
        }
      }
    }
  } as const
}

export type Theme = typeof lightTheme

export const darkTheme = {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    bg: {
      content: palette.grey
    }
  }
} as const
