import { ReactElement, cloneElement, forwardRef } from 'react'
import * as Ariakit from '@ariakit/react'

export interface MenuButtonProps extends Ariakit.MenuButtonProps {
  children: ReactElement
}

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Ariakit.MenuButton
        ref={ref}
        render={(disclosureProps) =>
          cloneElement(children as ReactElement, disclosureProps)
        }
        {...props}
      />
    )
  }
)
