import { ReactElement } from 'react'

import { Popover } from '../../overlays'

import { useSelect, SelectProvider, UseSelectProps } from './useSelectState'

interface SelectProps<T> extends UseSelectProps<T> {
  name?: string
  children: ReactElement | ReactElement[]
}

export const Select = <T,>({
  name,
  value,
  multiple,
  onChange,
  children
}: SelectProps<T>) => {
  const context = useSelect({
    name,
    value,
    multiple,
    onChange
  })

  return (
    <SelectProvider value={context as any}>
      <Popover store={context.popoverStore}>{children}</Popover>
    </SelectProvider>
  )
}
