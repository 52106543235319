import { UUID } from '@woorcs/types/UUID'
import { pipe, constant } from 'fp-ts/function'
import * as R from 'fp-ts/Reader'
import * as O from 'fp-ts/Option'

import * as ResponseSetRegistry from '../../ResponseSetRegistry'

import { useFormProgram } from './useFormProgram'

export const useResponseSet = (id: UUID) =>
  useFormProgram(ResponseSetRegistry.getResponseSet(id))

export const useResponseSets = () =>
  useFormProgram(ResponseSetRegistry.getResponseSets())

export const useResponseSetOptions = (id: UUID) =>
  pipe(
    useFormProgram(
      pipe(
        ResponseSetRegistry.getResponseSet(id),
        R.chain((responseSet) =>
          pipe(
            responseSet,
            O.foldW(constant(R.of([])), (responseSet) =>
              pipe(
                responseSet.options,
                R.traverseArray((option) =>
                  pipe(
                    ResponseSetRegistry.getOptionLabel(
                      responseSet.id,
                      option.id
                    ),
                    R.map((label) => ({
                      label,
                      value: option.id
                    }))
                  )
                )
              )
            )
          )
        )
      )
    )
  )
