import { ReactElement } from 'react'
import { createContext } from '@woorcs/utils'

import { UseListboxReturn } from './useListbox'

type ListBoxContext = UseListboxReturn

export const [Provider, useListBoxContext] = createContext<ListBoxContext>()

interface ListboxProviderProps {
  context: ListBoxContext
  children: ReactElement | ReactElement[]
}

export const ListboxProvider = ({
  children,
  context
}: ListboxProviderProps) => <Provider value={context}>{children}</Provider>
