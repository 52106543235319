import React, { ReactNode } from 'react'
import { SmileSadIcon } from '@woorcs/design-system'

import { EmptyState } from '../EmptyState'

type ResourceNotFoundProps = {
  title: string
  description: string
  children?: ReactNode
}

export const ResourceNotFound = ({
  title,
  description,
  children
}: ResourceNotFoundProps) => {
  return (
    <EmptyState Icon={SmileSadIcon} title={title} description={description}>
      {children}
    </EmptyState>
  )
}
