import { ComponentProps, ReactNode, forwardRef } from 'react'
import { space, whenTrue } from '@woorcs/utils'
import { PropsOf } from '@emotion/react'

import { Avatar } from '../Avatar'
import { Flex, FlexProps } from '../../layout'
import { Text, TextProps } from '../../Text'
import { system, css } from '../../../system'

import { useListContext, ListContextProvider } from './Context'

const BareList = system('ul')({
  listStyle: 'none'
})

export const HEIGHT = 48
export const DENSE_HEIGHT = 32

interface ListOptions {
  dense?: boolean
}

interface ListFieldProps extends FlexProps {
  dense?: boolean
  children?: ReactNode
}

export const isDense = whenTrue(['dense'])

export const ListItemField = ({
  dense: propDense,
  ...other
}: ListFieldProps) => {
  const { dense } = useListContext({
    dense: propDense
  })

  return (
    <Flex
      __css={{
        flexDirection: 'column',
        flexShrink: 0,
        minHeight: 0,
        position: 'relative',
        mx: dense ? 1 : 2
      }}
      {...other}
    />
  )
}

export const ListItemActionField = (props: ListFieldProps) => (
  <ListItemField
    css={{
      flexGrow: 0,
      alignItems: 'center'
    }}
    {...props}
  />
)

export const ListItemBody = (props: ListFieldProps) => (
  <ListItemField
    css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: '1 1 auto',
      minWidth: 0
    }}
    {...props}
  />
)

export const ListItemTitle = (props: TextProps) => {
  const { dense } = useListContext()

  return (
    <Text
      color='text.emphasized'
      fontWeight='bold'
      fontSize={dense ? 'mini' : 'small'}
      {...props}
    />
  )
}

export const ListItemSubtitle = (props: TextProps) => (
  <Text as='p' fontSize='mini' {...props} />
)

export const ListItemTextField = ({ children, ...other }: FlexProps) => (
  <ListItemBody {...other}>
    <ListItemTitle>{children}</ListItemTitle>
  </ListItemBody>
)

export interface ListItemProps extends PropsOf<typeof system.li>, ListOptions {}

const StyledListItem = system('li')<ListOptions>(({ dense }: ListOptions) =>
  css({
    display: 'flex',
    position: 'relative',
    mb: dense ? space(1) : space(3),
    py: dense ? 1 : 2,
    borderRadius: 'small',
    color: 'grey.dark'
  } as const)
)

export const ListItem = forwardRef<'li', ListItemProps>(
  ({ dense: denseProp, ...other }, ref) => {
    const { dense } = useListContext({
      dense: denseProp
    })

    return (
      <ListContextProvider dense={dense}>
        <StyledListItem ref={ref} {...other} />
      </ListContextProvider>
    )
  }
)

ListItem.defaultProps = {
  alignItems: 'center'
}

export const ListItemAvatar = (props: ComponentProps<typeof Avatar>) => {
  const { dense } = useListContext()

  return (
    <ListItemField mr={2}>
      <Avatar size={dense ? 'small' : 'medium'} {...props} />
    </ListItemField>
  )
}

export interface ListProps extends PropsOf<typeof system.ul>, ListOptions {}

export const List = forwardRef<typeof system.ul, ListProps>(
  ({ dense, ...other }, ref) => (
    <ListContextProvider dense={dense}>
      <BareList ref={ref} {...other} />
    </ListContextProvider>
  )
)
