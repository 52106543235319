import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type SelectTagsInputTagFragment = { __typename: 'Tag', name: string, id: string };

export type SelectTagsInputQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type SelectTagsInputQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, tags: Array<{ __typename: 'Tag', name: string, id: string }> } };

export const SelectTagsInputTagFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SelectTagsInputTagFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Tag"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]} as unknown as DocumentNode<SelectTagsInputTagFragment, unknown>;
export const SelectTagsInputQueryDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SelectTagsInputQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"tags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SelectTagsInputTagFragment"}}]}}]}}]}},...SelectTagsInputTagFragmentDoc.definitions]} as unknown as DocumentNode<SelectTagsInputQuery, SelectTagsInputQueryVariables>;