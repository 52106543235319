import { MouseEvent, forwardRef, useCallback } from 'react'

import { ListboxOption, ListboxOptionProps } from '../Listbox'

import { useSelectContext } from './useSelectState'

interface SelectOptionProps extends Omit<ListboxOptionProps, 'children'> {
  label?: string
}

export const SelectOption = forwardRef<HTMLLIElement, SelectOptionProps>(
  ({ value, label, ...other }, ref) => {
    const { selectedLabelRef, getOptionProps } = useSelectContext()
    const { onChange, ...props } = getOptionProps()

    const handleClick = useCallback(
      (e: MouseEvent) => {
        selectedLabelRef.current = label ?? value
        onChange(value)

        e.preventDefault()
      },
      [label, onChange, selectedLabelRef, value]
    )

    return (
      <ListboxOption
        ref={ref}
        value={value}
        onClick={handleClick}
        {...other}
        {...props}
      >
        {label ?? value}
      </ListboxOption>
    )
  }
)
