/* eslint-disable @typescript-eslint/no-use-before-define */
import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react'
import * as ReactQuery from '@tanstack/react-query'
import * as DE from '@nll/datum/DatumEither'
import * as E from 'fp-ts/Either'
import * as TE from 'fp-ts/TaskEither'
import { createContext } from '@woorcs/utils'
import { pipe, constNull, constVoid } from 'fp-ts/function'

import {
  ApiClient,
  createClient,
  CreateOrganizationInput,
  MePayload,
  CreateOrganizationPayload,
  CreateAPIClientOptions
} from './client'
import { FetchError } from './fetch'

export const [ApiClientContextProvider, useApiClient] =
  createContext<ApiClient>()

const defaultQueryClient = new ReactQuery.QueryClient({
  defaultOptions: {
    queries: {
      // suspense: true,
      throwOnError: false
    },
    mutations: {
      throwOnError: false
    }
  }
})

interface ApiClientProviderProps extends CreateAPIClientOptions {
  children: ReactNode
  queryClient?: ReactQuery.QueryClient
}

export const ApiClientProvider = ({
  children,
  queryClient = defaultQueryClient,
  ...options
}: ApiClientProviderProps) => {
  const client = useMemo(() => createClient(options), [options])

  return (
    <ApiClientContextProvider value={client}>
      <ReactQuery.QueryClientProvider client={queryClient}>
        {children}
      </ReactQuery.QueryClientProvider>
    </ApiClientContextProvider>
  )
}

type AccountContext = MePayload

const [AccountContextProvider, useAccount] = createContext<AccountContext>()

export { useAccount }

interface AccountProviderProps {
  children: ReactElement
  onError?(error: FetchError): void
  renderEmpty?(loading?: boolean): ReactElement | null
  renderError?(
    error: FetchError,
    retry: () => void,
    loading?: boolean
  ): ReactElement | null
}

export const AccountProvider = ({
  children,
  onError = constVoid,
  renderEmpty = constNull,
  renderError = constNull
}: AccountProviderProps) => {
  const me = useMe({ onError })
  const queryClient = ReactQuery.useQueryClient()

  const handleRetry = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['me']
    })
  }, [queryClient])

  return pipe(
    me,
    DE.squash(
      renderEmpty,
      (error, loading) => renderError(error, handleRetry, loading),
      (me) => {
        return (
          <AccountContextProvider value={me}>{children}</AccountContextProvider>
        )
      }
    )
  )
}

function queryToDatumEither<E, A>(
  query: ReactQuery.UseQueryResult<A, E>
): DE.DatumEither<E, A> {
  switch (query.status) {
    case 'success': {
      if (query.isFetching) {
        return DE.toRefresh(DE.success(query.data))
      }

      return DE.success(query.data)
    }

    case 'error': {
      if (query.isFetching) {
        return DE.toRefresh(DE.failure(query.error))
      }

      return DE.failure(query.error)
    }

    case 'pending': {
      return DE.pending
    }

    default: {
      return DE.initial
    }
  }
}

const authErrorCodes = [401, 403]

const shouldRetry = (failureCount: number, error: FetchError) => {
  switch (error._tag) {
    case 'tokenMissingError': {
      return false
    }

    case 'httpResponseStatusError': {
      if (authErrorCodes.includes(error.status)) {
        return false
      }

      return failureCount < 2
    }

    default: {
      return failureCount < 2
    }
  }
}

interface UseMeOptions {
  onError?(error: FetchError): void
}

export const useMe = ({ onError }: UseMeOptions = {}) => {
  const client = useApiClient()

  const response = ReactQuery.useQuery<MePayload, FetchError>({
    retry: shouldRetry,
    queryKey: ['me'],
    queryFn: () => {
      const getMe = client.me()

      return getMe().then(
        E.getOrElseW((e: FetchError) => {
          onError?.(e)

          // rethrow error so that ReactQuery handles it
          throw e
        })
      )
    }
  })

  return useMemo(() => queryToDatumEither(response), [response])
}

export const useCreateOrganization = () => {
  const [updating, setUpdating] = useState(false)
  const client = useApiClient()
  const queryClient = ReactQuery.useQueryClient()

  const createOrganization = (
    input: CreateOrganizationInput
  ): Promise<E.Either<FetchError, CreateOrganizationPayload>> => {
    const createOrganization = pipe(
      TE.fromIO<void, FetchError>(() => setUpdating(true)),
      TE.chainW(() => client.createOrganization(input)),
      TE.chainFirstTaskK(() => () => {
        setUpdating(false)

        return queryClient.invalidateQueries({
          queryKey: ['me']
        })
      })
    )

    return createOrganization()
  }

  return [updating, createOrganization] as const
}
