import React, { ComponentType, FC } from 'react'
import { variant } from 'styled-system'
import { rem } from '@woorcs/utils'

import { PropsOf, system } from '../../../system'

const size = variant({
  prop: 'size',
  variants: {
    mini: {
      fontSize: rem(12),
      width: rem(12),
      height: rem(12)
    },
    small: {
      fontSize: rem(16),
      width: rem(16),
      height: rem(16)
    },
    medium: {
      fontSize: rem(24),
      width: rem(24),
      height: rem(24)
    },
    large: {
      fontSize: rem(48),
      width: rem(48),
      height: rem(48)
    }
  }
})

export type IconSize = 'mini' | 'small' | 'medium' | 'large'

export interface IconProps extends PropsOf<typeof system.div> {
  size?: IconSize
}

export type IconComponentType = ComponentType<IconProps>

const Container = system('div')(
  {
    userSelect: 'none',
    display: 'inline-block',
    fill: 'currentColor',
    flexShrink: 0,
    svg: {
      display: 'block'
    }
  },
  size
)

export const Icon: FC<IconProps> = ({
  children,
  color = 'currentColor',
  size = 'medium',
  ...other
}) => (
  <Container focusable='false' color={color} size={size} {...other}>
    {children}
  </Container>
)
