import React from 'react'
import { PropsOf, system } from '@woorcs/design-system'
import TextareaAutosize from 'react-textarea-autosize'
import { palette } from '@woorcs/design-tokens'

const StyledTextareaAutosize = system(TextareaAutosize)({
  position: 'relative',
  width: '100%',
  padding: 0,
  margin: 0,
  background: 'transparent',
  border: 'none',
  fontWeight: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  color: 'inherit',
  resize: 'none',
  ':placeholder': {
    color: 'grey.50'
  },
  ':focus': {
    outline: 'none',
    boxShadow: `0 0 0 1px ${palette.primary[500]};`,
    borderRadius: '2px'
  }
})

type EditableTextProps = PropsOf<typeof StyledTextareaAutosize>

export const EditableText = ({ value, ...other }: EditableTextProps) => {
  return (
    <StyledTextareaAutosize
      value={value}
      draggable={false}
      autoCorrect='off'
      spellCheck='false'
      {...other}
    />
  )
}
