import { uuid } from '@woorcs/types/UUID'
import { TranslateableText, ResponseSet, Locale } from '@woorcs/form'
import {
  InspectionFormDefinition,
  InspectionFormDocument
} from '@woorcs/inspection-form'

const languages = {
  se: {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any

export const emptyState: InspectionFormDefinition.InspectionFormDefinition = {
  type: 'InspectionForm',
  id: uuid(),
  title: TranslateableText.translateableText(''),
  description: TranslateableText.translateableText(''),
  i18n: {
    defaultLanguage: 'en' as Locale.Locale,
    languages
  },
  children: [
    InspectionFormDocument.inspectionFormPageElement({
      key: uuid(),
      title: TranslateableText.translateableText('Page 1'),
      description: TranslateableText.translateableText(''),
      children: [],
      rule: null
    })
  ],
  responseSets: ResponseSet.createDefaultResponseSets().reduce(
    (responseSets, responseSet) => ({
      ...responseSets,
      [responseSet.id]: responseSet
    }),
    {}
  ),
  documents: {}
}
