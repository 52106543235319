import { Box, BoxProps } from '../Box'
import { system } from '../../../system'

export interface CircleProps extends BoxProps {
  radius: number
}

export const Circle = system(Box)(
  ({ radius }: CircleProps) => ({
    width: radius,
    height: radius,
    borderRadius: radius
  }),
  {
    boxSizing: 'border-box',
    minWidth: 0
  }
)
