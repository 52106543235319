import { memo, Suspense, useMemo, useState } from 'react'
import { Box, Flex, Text, LargeBody } from '@woorcs/design-system'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import { sub } from 'date-fns'
import { useQuery } from 'urql'
import { useViewer } from '@woorcs/graphql'
import { PeriodInterval } from '@woorcs/graphql/src/schema'
import { useTranslation } from 'react-i18next'

import {
  ActivityLogWidget,
  Page,
  PageContent,
  PageHeader,
  PageTitle,
  TrendWidget,
  WeeklySubmissionsWidget
} from '@app/components'

import { TodaysSubmissionsQueryDocument } from './__generated__/DashboardPage'

const variables = {
  input: {
    interval: PeriodInterval.Daily,
    startDate: sub(new Date(), {
      days: 7
    }),
    endDate: new Date()
  }
}

const TodaysSubmissionsWidget = memo(() => {
  const { t } = useTranslation('dashboard')
  const [query] = useQuery({
    query: TodaysSubmissionsQueryDocument,
    variables,
    context: useMemo(
      () => ({
        suspense: true
      }),
      []
    )
  })
  const data = useMemo(
    () =>
      pipe(
        query.data?.submissionReport ?? [],
        A.map((d) => d.completedSubmissionCount)
      ),
    [query.data?.submissionReport]
  )

  return <TrendWidget title={t('todaysSubmissionsWidget.title')} data={data} />
})

const TodaysSubmissionWidgetFallback = () => {
  const { t } = useTranslation('dashboard')

  return <TrendWidget title={t('todaysSubmissionsWidget.title')} data={[]} />
}

const SubmissionWidgets = () => {
  return (
    <Flex gridGap={5} width='100%'>
      <Box flex={0.5} height='100%'>
        <Suspense fallback={<TodaysSubmissionWidgetFallback />}>
          <TodaysSubmissionsWidget />
        </Suspense>
      </Box>
      <Box flex={0.5}>
        <WeeklySubmissionsWidget />
      </Box>
    </Flex>
  )
}

export const DashboardPage = () => {
  const viewer = useViewer()
  const { t } = useTranslation('dashboard')

  return (
    <Page>
      <PageHeader mb={8} borderBottom='base'>
        <Box>
          <PageTitle mb={2}>{t('title')}</PageTitle>
          <LargeBody>
            {t('subtitle')}{' '}
            <Text fontWeight='bold'>{`${viewer.firstName} ${viewer.lastName}`}</Text>
          </LargeBody>
        </Box>
      </PageHeader>
      <PageContent>
        <Flex maxWidth='1040px' gridRowGap={5} flexWrap='wrap' pb={8}>
          <SubmissionWidgets />
          <Flex width='100%' gridColumnGap={5}>
            <ActivityLogWidget />
          </Flex>
        </Flex>
      </PageContent>
    </Page>
  )
}
