import * as w from 'wonka'
import { Exchange, Operation, CombinedError } from 'urql'

const addOrganizationIdToOperation =
  (organizationId: string) => (operation: Operation) => {
    const fetchOptions =
      typeof operation.context.fetchOptions === 'function'
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {}

    return {
      ...operation,
      context: {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            'X-ORGANIZATION-ID': String(organizationId)
          }
        }
      }
    }
  }

const isOrganizationError = (error: CombinedError) =>
  error.graphQLErrors.some(
    (e) => e.message === 'Unauthorized organization access'
  )

export interface OrganizationExchangeProps {
  organizationId: string
  onUnauthorizedError(error: Error): void
}

export const createOrganizationExchange =
  ({
    organizationId,
    onUnauthorizedError
  }: OrganizationExchangeProps): Exchange =>
  ({ forward }) =>
  (ops$) => {
    const shared$ = w.share(ops$)
    const withHeader$ = w.pipe(
      shared$,
      w.map(addOrganizationIdToOperation(organizationId))
    )

    return w.pipe(
      forward(withHeader$),
      w.tap(({ error }) => {
        if (error && isOrganizationError(error)) {
          onUnauthorizedError(error)
        }
      })
    )
  }
