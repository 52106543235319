import React, { useCallback } from 'react'
import {
  Badge,
  H4,
  Box,
  Button,
  ConfirmAlertDialog,
  Flex,
  List,
  ListItemActionField,
  ListItemTextField,
  SelectableListItem
} from '@woorcs/design-system'
import * as R from 'fp-ts/Record'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import { Locale } from '@woorcs/form'

import { useI18nConfig } from '../../state'

import { AddLanguageMenu } from './AddLanguageMenu'

type ManageLanguagesFormRowProps = {
  locale: Locale.Locale
  isDefaultLanguage?: boolean
  onRemove?(locale: Locale.Locale): void
}

const ManageLanguagesFormRow = ({
  locale,
  isDefaultLanguage,
  onRemove
}: ManageLanguagesFormRowProps) => {
  const handleRemoveLanguage = useCallback(
    () => onRemove?.(locale),
    [locale, onRemove]
  )

  return (
    <SelectableListItem>
      <ListItemTextField>
        {Locale.getLocaleLabel(locale)}{' '}
        {isDefaultLanguage && <Badge ml={2}>default</Badge>}
      </ListItemTextField>

      {!isDefaultLanguage && (
        <ListItemActionField>
          <ConfirmAlertDialog
            title='Remove lanauge'
            body='Are you sure that you want to remove the language? All translations for the language will be permanently removed.'
            destructive
            onConfirm={handleRemoveLanguage}
          >
            <Button colorVariant='danger' size='small'>
              Delete
            </Button>
          </ConfirmAlertDialog>
        </ListItemActionField>
      )}
    </SelectableListItem>
  )
}

export const ManageLanguagesForm = () => {
  const [{ defaultLanguage, languages }, { removeLanguage }] = useI18nConfig()
  const renderLanguageList = () =>
    pipe(
      languages,
      R.collect((locale) => locale),
      A.map((locale) => (
        <ManageLanguagesFormRow
          key={locale}
          locale={locale}
          onRemove={removeLanguage}
        />
      ))
    )

  return (
    <Box>
      <Box mb={8} px={4}>
        <H4>Manage languages</H4>
      </Box>

      <Box mb={8}>
        <List>
          <ManageLanguagesFormRow locale={defaultLanguage} isDefaultLanguage />
          {renderLanguageList()}
        </List>
      </Box>

      <Flex px={4} justifyContent='flex-end'>
        <AddLanguageMenu>
          <Button>Add language</Button>
        </AddLanguageMenu>
      </Flex>
    </Box>
  )
}
