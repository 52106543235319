import React, { memo, ReactNode } from 'react'
import { animated, useSpring } from 'react-spring'
import * as O from 'fp-ts/Option'
import { useMeasure, usePrevious } from '@woorcs/hooks'
import { constant, pipe } from 'fp-ts/function'

import { Box, BoxProps } from '../layout'
import { system } from '../../system'

const AnimatedBox = animated(Box)

interface Props extends BoxProps {
  isOpen: boolean
  children: ReactNode
}

interface UpdateOpenSpringProps {
  isOpen: boolean
  height: number
  previousOpen?: boolean | null
}

const closedFrom = ({ height }: UpdateOpenSpringProps) => ({
  height,
  opacity: 1,
  transform: `translate3d(0, 0px, 0)`,
  pointerEvents: 'auto'
})

const closedTo = ({ height }: UpdateOpenSpringProps) => ({
  height: 0,
  opacity: 0,
  transform: `translate3d(0, ${-height / 2}px, 0)`,
  pointerEvents: 'none'
})

const openFrom = (props: UpdateOpenSpringProps) => {
  if (props.previousOpen || typeof props.previousOpen === 'undefined') {
    return {
      height: props.height,
      opacity: 1,
      transform: `translate3d(0, 0px, 0)`,
      pointerEvents: 'auto'
    }
  }

  return closedTo(props)
}

const openTo = ({ height }: UpdateOpenSpringProps) => ({
  height,
  opacity: 1,
  transform: `translate3d(0, 0px, 0)`,
  pointerEvents: 'auto'
})

const from = (props: UpdateOpenSpringProps) => {
  if (props.isOpen) {
    return openFrom(props)
  }

  return closedFrom(props)
}

const to = (props: UpdateOpenSpringProps) => {
  if (props.isOpen) {
    return openTo(props)
  }

  return closedTo(props)
}

export const Collapse = memo<Props>(({ isOpen, children, ...other }: Props) => {
  const previousOpen = usePrevious(isOpen)
  const [ref, dimensions] = useMeasure<HTMLDivElement>()
  const viewHeight = pipe(
    dimensions,
    O.fold(constant(0), (dimensions) => dimensions.contentBox.height)
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { height, opacity, transform, pointerEvents }: any = useSpring({
    from: from({ height: viewHeight, isOpen, previousOpen }),
    to: to({ height: viewHeight, isOpen, previousOpen })
  })

  return (
    <system.div
      as={AnimatedBox}
      __css={{
        willChange: 'transform, opacity, height'
      }}
      style={{
        opacity,
        height:
          isOpen && (previousOpen || typeof previousOpen === 'undefined')
            ? 'auto'
            : height
      }}
      overflow='hidden'
      {...other}
    >
      <AnimatedBox ref={ref} style={{ transform, pointerEvents }}>
        {children}
      </AnimatedBox>
    </system.div>
  )
})
