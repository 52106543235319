import { Either } from 'fp-ts/Either'
import { constant, constVoid, pipe } from 'fp-ts/function'
import * as RIO from 'fp-ts-contrib/ReaderIO'
import * as IOE from 'fp-ts/IOEither'
import * as IO from 'fp-ts/IO'
import * as E from 'fp-ts/Either'
import * as O from 'fp-ts/Option'
import { sequenceS } from 'fp-ts/Apply'
import { Path } from '@woorcs/types/Path'
import { UUID } from '@woorcs/types/UUID'
import * as ET from '@woorcs/types/ElementTree'

import {
  editorStore,
  EditorStore,
  CreateEditorStoreOptions,
  EditorStateIO,
  getHistoryIO,
  getStateIO,
  EditorStateSelectorIO,
  EditorStateHistoryIO
} from './state'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

interface Capabilities {
  state: EditorStateIO
}

type ElementDecoder = <T extends ET.ElementTree>(
  element: T
) => Either<unknown, T>

export interface EditorConfig extends CreateEditorStoreOptions {
  decodeElement: ElementDecoder
}

interface Environment extends EditorConfig, Capabilities {}

type Program<A> = RIO.ReaderIO<Environment, A>

interface EditorInternals {
  __store: EditorStore
}

export interface Editor
  extends EditorStateSelectorIO,
    EditorStateHistoryIO,
    EditorInternals {
  getValue<T extends ET.Element = ET.ElementTree>(): IO.IO<T>
  getElement(id: UUID): IO.IO<O.Option<ET.ElementTree>>
  getElementPath(id: UUID): IO.IO<O.Option<Path>>
  addElement<T extends ET.Element>(
    element: T,
    at: Path
  ): IO.IO<E.Either<EditorError, void>>
  updateElement<T extends ET.Element>(
    element: T
  ): IO.IO<E.Either<EditorError, void>>
  moveElement(id: UUID, to: Path): IO.IO<void>
  removeElement(id: UUID): IO.IO<void>
}

// -------------------------------------------------------------------------------------
// errors
// -------------------------------------------------------------------------------------

export type InvalidElementError = {
  _tag: 'InvalidElement'
  element: ET.ElementTree
}

export const invalidElementError = (
  element: ET.ElementTree
): InvalidElementError => ({
  _tag: 'InvalidElement',
  element
})

export type UnknownElementError = {
  _tag: 'UnknownElement'
  element: ET.ElementTree
}

export const unknownElementError = (
  element: ET.ElementTree
): UnknownElementError => ({
  _tag: 'UnknownElement',
  element
})

export type EditorError = InvalidElementError | UnknownElementError

// -------------------------------------------------------------------------------------
// program
// -------------------------------------------------------------------------------------

const getElementDecoder =
  (decode: ElementDecoder) => (element: ET.ElementTree) =>
    pipe(
      decode(element),
      IOE.fromEither,
      IOE.mapLeft(() => invalidElementError(element))
    )

const findElementPath = (id: UUID) =>
  pipe(
    RIO.ask<Environment>(),
    RIO.chainIOK(({ state }) =>
      pipe(
        state.getValue(),
        IO.map((value) =>
          pipe(
            value,
            ET.findElementPath((element) => element.id === id)
          )
        )
      )
    )
  )

const getElement = (id: UUID) =>
  pipe(
    RIO.ask<Environment>(),
    RIO.chainIOK(({ state }) =>
      pipe(
        state.getValue(),
        IO.map((value) =>
          pipe(
            value,
            ET.find((element) => element.id === id)
          )
        )
      )
    )
  )

const addElement = (
  element: ET.ElementTree,
  at: Path
): Program<E.Either<InvalidElementError, void>> =>
  pipe(
    RIO.ask<Environment>(),
    RIO.chainIOK(({ state, decodeElement }) =>
      pipe(
        element,
        getElementDecoder(decodeElement),
        IOE.chainIOK((element) => state.addElement(element, at))
      )
    )
  )

const updateElement = (
  element: ET.Element
): Program<E.Either<InvalidElementError | UnknownElementError, void>> =>
  pipe(
    RIO.ask<Environment>(),
    RIO.chainIOK(({ state, decodeElement }) =>
      pipe(
        IO.Do,
        IO.bind('value', () => state.getValue()),
        IO.bind(
          'path',
          ({ value }) =>
            () =>
              pipe(value, ET.elementPath(element))
        ),
        IO.bind(
          'element',
          // NOTE: This annotation is necessary as we can't widen sequenceS
          (): IO.IO<
            Either<InvalidElementError | UnknownElementError, ET.ElementTree>
          > => pipe(element, getElementDecoder(decodeElement))
        ),
        IO.map(({ path, element: e }) =>
          sequenceS(E.Applicative)({
            path: pipe(
              path,
              E.fromOption(() => unknownElementError(element))
            ),
            element: e
          })
        ),
        IOE.chainIOK(({ element, path }) => state.updateElement(element, path)),
        IOE.orElse((error) => {
          if (error._tag !== 'UnknownElement') {
            return IOE.left(error)
          }

          return pipe(
            state.getValue(),
            IO.map((value) =>
              value.id === element.id
                ? E.right(state.updateRoot(element)())
                : E.left(error)
            )
          )
        })
      )
    )
  )

const moveElement = (id: UUID, to: Path): Program<void> =>
  pipe(
    RIO.ask<Environment>(),
    RIO.chain(({ state }) =>
      pipe(
        findElementPath(id),
        RIO.chainIOK((from) =>
          pipe(
            from,
            O.fold(constant(IO.of(constVoid)), (from) =>
              state.moveElement(from, to)
            )
          )
        )
      )
    )
  )

export const removeElement = (id: UUID): Program<void> =>
  pipe(
    RIO.ask<Environment>(),
    RIO.chain(({ state }) =>
      pipe(
        findElementPath(id),
        RIO.chainIOK((path) =>
          pipe(
            path,
            O.fold(constant(IO.of(constVoid)), (path) =>
              state.removeElement(path)
            )
          )
        )
      )
    )
  )

export const getEditor = (
  initialValue: ET.ElementTree,
  config: EditorConfig
): Editor => {
  const store = editorStore(initialValue, config)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const history = getHistoryIO(store as any, initialValue)
  const state = getStateIO(store, history)
  const env: Environment = {
    ...config,
    state
  }

  return {
    __store: store,
    ...state,
    getElement: (id) => pipe(env, getElement(id)),
    getElementPath: (id) => pipe(env, findElementPath(id)),
    addElement: (element, at) => pipe(env, addElement(element, at)),
    updateElement: (element) => pipe(env, updateElement(element)),
    moveElement: (id, to) => pipe(env, moveElement(id, to)),
    removeElement: (id) => pipe(env, removeElement(id))
  }
}
