import { ReactElement, useCallback } from 'react'
import {
  Box,
  Button,
  Flex,
  ListItemActionField,
  ListItemField,
  Popover,
  PopoverContent,
  PopoverDisclosure,
  SelectableListItem,
  Text,
  css
} from '@woorcs/design-system'
import { space } from '@woorcs/utils'
import { UUID } from '@woorcs/types/UUID'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import { useTranslation } from 'react-i18next'

import { useValue } from '../../state'
import { AddPageModal } from '../AddPageModal'

type SelectPageMenuItemProps = {
  index: number
  page: InspectionFormDocument.InspectionFormPageElement
  isSelected: boolean
  onSelect(
    page: InspectionFormDocument.InspectionFormPageElement,
    index: number
  ): void
}

const SelectPageMenuItem = ({
  index,
  page,
  isSelected,
  onSelect
}: SelectPageMenuItemProps) => {
  const { t } = useTranslation()
  const handleClick = useCallback(() => {
    onSelect(page, index)
  }, [index, onSelect, page])

  return (
    <SelectableListItem
      css={{ cursor: 'pointer' }}
      isSelected={isSelected}
      onClick={handleClick}
    >
      <ListItemActionField mr={4}>
        <Text fontWeight='bold'>{index + 1}.</Text>
      </ListItemActionField>
      <ListItemField py={1}>
        <Box>
          <Text as='p' fontWeight='bold'>
            {page.title.text}
          </Text>
          <Text>
            {page.children.length} {t('common:questions')}
          </Text>
        </Box>
      </ListItemField>
    </SelectableListItem>
  )
}

type SelectPageMenuFooterProps = {
  onAddPage(page: InspectionFormDocument.InspectionFormPageElement): void
}

const SelectPageMenuFooter = ({ onAddPage }: SelectPageMenuFooterProps) => (
  <Flex justifyContent='center' pt={2}>
    <AddPageModal onAddPage={onAddPage}>
      <Button colorVariant='secondary' fullWidth>
        Add Page
      </Button>
    </AddPageModal>
  </Flex>
)

type SelectPageMenuListProps = {
  pages: InspectionFormDocument.InspectionFormPageElement[]
  selectedID: UUID
  onSelectPage(
    page: InspectionFormDocument.InspectionFormPageElement,
    index: number
  ): void
}

export const SelectPageMenuList = ({
  pages,
  selectedID,
  onSelectPage
}: SelectPageMenuListProps) => {
  return (
    <Box css={css({ overflowY: 'auto' })} maxHeight={space(80)}>
      {pages.map((page, index) => (
        <SelectPageMenuItem
          key={page.id}
          page={page}
          index={index}
          isSelected={page.id === selectedID}
          onSelect={onSelectPage}
        />
      ))}
    </Box>
  )
}

type SelectPageMenuProps = {
  selectedID: UUID
  onSelectPage(pageID: UUID): void
  children: ReactElement
}

export const SelectPageMenu = ({
  selectedID,
  onSelectPage,
  children
}: SelectPageMenuProps) => {
  const root = useValue()
  const pages = root.children

  return (
    <Popover placement='bottom'>
      {({ hide }) => (
        <>
          <PopoverDisclosure>{children}</PopoverDisclosure>
          <PopoverContent aria-label='Select page' gutter={8} width={480} p={5}>
            <SelectPageMenuList
              pages={pages}
              selectedID={selectedID}
              onSelectPage={(page) => {
                hide()
                onSelectPage(page.id)
              }}
            />
            <SelectPageMenuFooter
              onAddPage={(page) => {
                onSelectPage(page.id)
                hide()
              }}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
