import { ariaAttr, rem } from '@woorcs/utils'
import * as Ariakit from '@ariakit/react'
import { AnimatePresence } from 'framer-motion'

import { PopoverContainer, PopoverContainerProps } from '../Popover'

type MenuContentProps = PopoverContainerProps & Ariakit.MenuProps

const minWidth = rem(180)

export const MenuContent = ({ children, ...props }: MenuContentProps) => {
  const store = Ariakit.usePopoverContext()

  if (!store) {
    throw new Error('PopoverContainer must be used within a Popover component')
  }

  const mounted = store.useState('mounted')

  return (
    <AnimatePresence>
      {mounted && (
        <Ariakit.Menu
          aria-label={ariaAttr(props['aria-label'])}
          render={(props) => (
            <PopoverContainer minWidth={minWidth} p={2} {...props} />
          )}
          alwaysVisible
          {...props}
        >
          {children}
        </Ariakit.Menu>
      )}
    </AnimatePresence>
  )
}
