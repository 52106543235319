import { createContext, ariaAttr } from '@woorcs/utils'
import {
  UseSelectStateReturn,
  useSelectState,
  UseSelectStateProps
} from '@woorcs/hooks'
import { MouseEvent, useCallback } from 'react'

interface MenuOptionGroupContext<T> extends UseSelectStateReturn<T> {
  type: 'radio' | 'checkbox'
  name?: string
}

export const [MenuOptionGroupProvider, useMenuOptionGroupContext] =
  createContext<MenuOptionGroupContext<any>>({
    errorMessage: '`MenuOption` must be be a child of a `MenuOptionGroup`'
  })

export interface UseMenuOptionGroupProps<T>
  extends Omit<UseSelectStateProps<T>, 'multiple'> {
  type: 'radio' | 'checkbox'
  name?: string
}

export const useMenuOptionGroup = <T>({
  type,
  name,
  ...other
}: UseMenuOptionGroupProps<T>): MenuOptionGroupContext<T> => {
  const state = useSelectState({
    multiple: type === 'checkbox',
    ...other
  })

  return {
    type,
    name,
    ...state
  }
}

export interface UseMenuOptionProps {
  value: any
  onChange?(value: any): void
  onClick?(event: MouseEvent): void
}

export const useMenuOption = (props: UseMenuOptionProps) => {
  const { type, onChange, isSelected } = useMenuOptionGroupContext()
  const checked = isSelected(props.value)

  const handleClick = useCallback(
    (e: MouseEvent) => {
      props.onClick?.(e)

      if (e.defaultPrevented) {
        return
      }

      onChange(props.value)
      props.onChange?.(props.value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, props.onChange, props.onClick, props.value]
  )

  return {
    role: `menuitem${type}`,
    'aria-selected': ariaAttr(checked),
    checked,
    onClick: handleClick
  }
}
