import { ComponentProps, forwardRef } from 'react'
import { space } from '@woorcs/utils'

import { Input } from '../Input'
import { system } from '../../../system'

const TextareaInput = system('textarea')({
  height: space(20),
  my: 3,
  resize: 'none'
})

type TextareaProps = ComponentProps<typeof Input>

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => <Input ref={ref} as={TextareaInput} {...props} />
)
