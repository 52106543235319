import { Dict } from './types'
import { isArray } from './isArray'

export const isObject = (value: any): value is Dict => {
  const type = typeof value

  return (
    value != null &&
    (type === 'object' || type === 'function') &&
    !isArray(value)
  )
}
