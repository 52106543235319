import { UUID } from '@woorcs/types/UUID'
import * as R from 'fp-ts/Reader'
import { pipe } from 'fp-ts/function'

import { FormContext } from '../context'

import { useFormProgram } from './useFormProgram'

export const useDocument = (id: UUID) =>
  useFormProgram(
    pipe(
      R.ask<FormContext>(),
      R.map(({ documents }) => documents.get(id))
    )
  )
