import { ReactNode } from 'react'
import { isFunction } from '@woorcs/utils'
import * as Ariakit from '@ariakit/react'

interface MenuProps extends Ariakit.MenuStoreProps {
  children: ReactNode | ((store: Ariakit.MenuStore) => ReactNode)
}

export const Menu = ({ children, ...props }: MenuProps) => {
  const store = Ariakit.useMenuStore(props)

  return (
    <Ariakit.MenuProvider store={store}>
      {isFunction(children) ? children(store) : children}
    </Ariakit.MenuProvider>
  )
}
