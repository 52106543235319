import * as O from 'fp-ts/Option'
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Box,
  Flex,
  Button,
  system
} from '@woorcs/design-system'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import { UUID } from '@woorcs/types/UUID'

import { PageList } from '../PageList'
import { AddPageModal } from '../AddPageModal'
import { Sidebar } from '../Sidebar'

import { InspectionFormSettings } from './Settings'

const Section = system('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottomColor: 'grey.50',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  px: 5
})

interface PageListTabProps {
  selectedPageId: O.Option<UUID>
  onSelectPage: (page: InspectionFormDocument.InspectionFormPageElement) => void
}

const PageListTab = ({ selectedPageId, onSelectPage }: PageListTabProps) => (
  <>
    <AddPageModal onAddPage={onSelectPage}>
      <Button colorVariant='secondary' mb={4} fullWidth>
        Add page
      </Button>
    </AddPageModal>

    <PageList selectedPageId={selectedPageId} onPageClick={onSelectPage} />
  </>
)

interface EditorSidebarProps extends PageListTabProps {
  selectedPageId: O.Option<UUID>
  onSelectPage: (page: InspectionFormDocument.InspectionFormPageElement) => void
}

export const EditorLeftSidebar = ({
  onSelectPage,
  selectedPageId
}: EditorSidebarProps) => {
  return (
    <Sidebar>
      <Tabs>
        <Flex flexDirection='column' height='100%'>
          <Section pt={4}>
            <Flex height='100%' alignItems='flex-end' mb='-1px'>
              <TabList>
                <Tab>Pages</Tab>
                <Tab>Settings</Tab>
              </TabList>
            </Flex>
          </Section>
          <Flex flex={1} flexDirection='column' height='100%'>
            <Box flex={1} p={4} style={{ overflowY: 'auto' }}>
              <TabPanel>
                <PageListTab
                  selectedPageId={selectedPageId}
                  onSelectPage={onSelectPage}
                />
              </TabPanel>
              <TabPanel>
                <InspectionFormSettings />
              </TabPanel>
            </Box>
          </Flex>
        </Flex>
      </Tabs>
    </Sidebar>
  )
}
