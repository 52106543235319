import React from 'react'
import { whenTrue, space } from '@woorcs/utils'

import { css, system } from '../../../system'
import {
  MenuList,
  MenuListItem,
  MenuListProps,
  MenuListItemProps,
  MenuListLink,
  MenuListButton
} from '../MenuList'

const isActive = whenTrue(['active'])

export const NavbarMenu = (props: MenuListProps) => (
  <MenuList vertical={false} {...props} />
)

type NavbarMenuItemProps = MenuListItemProps

export const NavbarMenuItem = (props: NavbarMenuItemProps) => (
  <MenuListItem {...props} />
)

const actionStyle = [
  css({
    px: space(2),
    mx: 1,
    '&[aria-current="page"]': {
      color: 'primary.dark',
      backgroundColor: 'transparent'
    }
  }),
  isActive({
    background: 'transparent',
    color: 'primary.dark'
  })
]

export const NavbarMenuLink = system(MenuListLink)(...actionStyle)

export const NavbarMenuButton = system(MenuListButton)(...actionStyle)
