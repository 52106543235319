import React from 'react'
import { CardHeader, H4, Body, Card } from '@woorcs/design-system'
import { rem } from '@woorcs/utils'
import { useTranslation } from 'react-i18next'

import { SubmitButton, ViewerProfileForm } from '@app/components'

export const ProfileSettingsStep = () => {
  const { t } = useTranslation()

  return (
    <Card width={rem(540)} p={8}>
      <CardHeader mb={8}>
        <H4 mb={2}>{t('onboarding:profileSetingsStep.title')}</H4>
        <Body mb={4}>{t('onboarding:profileSetingsStep.subtitle')}</Body>
      </CardHeader>
      <ViewerProfileForm
        displayEmail={false}
        actions={<SubmitButton label={t('common:continue')} />}
      />
    </Card>
  )
}
