/* eslint-disable @typescript-eslint/no-use-before-define */
import { constant, identity, pipe } from 'fp-ts/function'
import * as RA from 'fp-ts/ReadonlyArray'
import * as R from 'fp-ts/Reader'
import * as O from 'fp-ts/Option'
import { UUID } from '@woorcs/types/UUID'

import * as Element from './FormDocument/Element'
import { getFormSubmissionStore } from './FormSubmission'
import { FormDocument } from './FormDocument'
import { parseInputElementValue } from './FormDocument/InputElement'
import { FormEnvironment } from './Form'
import { getOptionLabel } from './ResponseSetRegistry'

export interface FormSubmissionElementRenderer<A> {
  renderDocument: (document: FormDocument.FormDocument, children: A[]) => A
  renderInputElement: <T extends Element.InputElementType>(
    element: T,
    label: string,
    value: O.Option<Element.ValueOfInputElement<T>>
  ) => A
  renderLayoutElement: (element: Element.LayoutElementType, children: A[]) => A
  renderGroupInputElement: (
    element: Element.GroupInputElement,
    document: A
  ) => A
}

export interface Environment<A>
  extends FormSubmissionElementRenderer<A>,
    FormEnvironment {}

type FormSubmissionRenderer<R, A> = R.Reader<Environment<R>, A>

export const formSubmissionRendererEnvironment = <A>(
  environment: Environment<A>
) => environment

type Children = Element.FormElement[]

// -------------------------------------------------------------------------------------
// render
// -------------------------------------------------------------------------------------

export const renderChildren = <A>(element: {
  children: Children
}): FormSubmissionRenderer<A, ReadonlyArray<A>> =>
  pipe(
    element.children,
    R.traverseArray((element) => renderElement<A>(element)),
    R.map((children) => pipe(children, RA.filterMap(identity)))
  )

const renderGroup = <A>(element: Element.GroupInputElement) =>
  pipe(
    R.ask<Environment<A>>(),
    R.chain(({ documents, renderGroupInputElement }) =>
      pipe(
        documents.get(element.document),
        O.map((document) => render<A>(document)),
        O.fold(
          () => R.of(O.none),
          (document) =>
            pipe(
              document,
              R.map((document) =>
                pipe(renderGroupInputElement(element, document), O.some)
              )
            )
        )
      )
    )
  )

const getGroupEnvironment =
  <A>(element: Element.GroupInputElement) =>
  (environment: Environment<A>) =>
    formSubmissionRendererEnvironment({
      ...environment,
      submission: pipe(
        environment.submission.get(element.key),
        O.chain(parseInputElementValue(element)),
        O.getOrElseW(constant({})),
        getFormSubmissionStore
      )
    })

export const renderGroupInputElement = <A>(
  element: Element.GroupInputElement
): FormSubmissionRenderer<A, O.Option<A>> =>
  R.local(getGroupEnvironment<A>(element))(renderGroup(element))

const getFieldValue = <A>(
  element: Element.InputElementType
): FormSubmissionRenderer<A, O.Option<unknown>> =>
  pipe(
    R.ask<Environment<A>>(),
    R.map(({ submission }) =>
      pipe(
        submission.get(element.key),
        O.chain(parseInputElementValue(element))
      )
    ),
    R.chainW((value) => {
      if (!Element.isResponseSetInputElement(element)) {
        return R.of(value)
      }

      const getSelectValue =
        (
          element: Element.SelectInputElement | Element.MultiSelectInputElement
        ) =>
        (value: UUID) =>
          pipe(getOptionLabel(element.responseSet, value), R.map(O.some))

      const getMultiSelectValue =
        (
          element: Element.SelectInputElement | Element.MultiSelectInputElement
        ) =>
        (values: UUID[]) =>
          pipe(
            values,
            RA.map((value) => getOptionLabel(element.responseSet, value)),
            R.sequenceArray,
            R.map((v) => v as string[]),
            R.map(O.some)
          )

      return pipe(
        value,
        O.fold(
          () => R.of(O.none),
          (value) =>
            pipe(
              value,
              O.fromPredicate(Array.isArray),
              O.matchW(
                () => getSelectValue(element)(value as UUID),
                getMultiSelectValue(element)
              )
            )
        )
      )
    })
  )

const renderInputElement = <A>(
  element: Element.InputElementType
): FormSubmissionRenderer<A, O.Option<A>> =>
  pipe(
    Element.isElementVisible(element),
    R.chainW((isVisible) => {
      if (!isVisible) {
        return R.of(O.none)
      }

      return pipe(
        getFieldValue<A>(element),
        R.chain((value) =>
          pipe(
            R.ask<Environment<A>>(),
            R.map(({ renderInputElement, i18n, locale }) =>
              renderInputElement(
                element,
                i18n.getText(element.label, locale),
                value as any
              )
            )
          )
        ),
        R.map(O.some)
      )
    })
  )

export const renderLayoutElement = <A>(element: Element.LayoutElementType) =>
  pipe(
    Element.isElementVisible(element),
    R.chainW((isVisible) => {
      if (!isVisible) {
        return R.of(O.none)
      }

      return pipe(
        R.ask<Environment<A>>(),
        R.chain(({ renderLayoutElement }) =>
          pipe(
            renderChildren<A>(element),
            R.map((children) =>
              renderLayoutElement(element, RA.toArray(children))
            )
          )
        ),
        R.map(O.some)
      )
    })
  )

export const renderElement = <A>(
  element: Element.FormElement
): FormSubmissionRenderer<A, O.Option<A>> => {
  if (Element.GroupInputElement.is(element)) {
    return renderGroupInputElement(element)
  }

  if (Element.isLayoutElement(element)) {
    return renderLayoutElement(element)
  }

  if (Element.isInputElement(element)) {
    return renderInputElement(element)
  }

  return R.of(O.none)
}

export const render = <A>(
  document: FormDocument.FormDocument
): FormSubmissionRenderer<A, A> =>
  pipe(
    R.ask<Environment<A>>(),
    R.chain(({ renderDocument }) =>
      pipe(
        renderChildren<A>(document),
        R.map((children) =>
          pipe(RA.toArray(children), (children) =>
            renderDocument(document, children)
          )
        )
      )
    )
  )
