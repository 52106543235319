import { ReactElement, useCallback, useState } from 'react'
import {
  SecondaryButton,
  PrimaryButton,
  Tooltip,
  Flex,
  AlertDialog,
  AlertDialogDisclosure,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogBody,
  AlertDialogFooter,
  Body,
  DateRangeTuple
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import * as O from 'fp-ts/Option'
import { constant, pipe } from 'fp-ts/function'
import { useMutation } from 'urql'
import { DialogDismiss } from '@ariakit/react'

import { RequestCsvExportDocument } from './__generated__/RequestCsvExport'

interface ExportRequestedAlertProps {
  visible: boolean
  onVisiblityChange(visible: boolean): void
  children: ReactElement
}

export const ExportRequestedAlert = ({
  visible,
  onVisiblityChange,
  children
}: ExportRequestedAlertProps) => (
  <AlertDialog open={visible} setOpen={onVisiblityChange}>
    <AlertDialogDisclosure>{children}</AlertDialogDisclosure>

    <AlertDialogContent aria-label='CSV exported'>
      <AlertDialogHeader mb={6}>
        <AlertDialogTitle>Rapport skapas</AlertDialogTitle>
      </AlertDialogHeader>

      <AlertDialogBody>
        <Body>
          Rapporten kommer att skickas till din email så snart den är skapad.
        </Body>
      </AlertDialogBody>

      <AlertDialogFooter>
        <DialogDismiss render={<PrimaryButton />}>
          <PrimaryButton>Ok</PrimaryButton>
        </DialogDismiss>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
)

interface ExportSubmissionsCSVButtonProps {
  published: O.Option<DateRangeTuple.DateRangeTuple>
  formId: string
  creatorId: O.Option<string>
  tags: O.Option<Array<string>>
}

export const ExportSubmissionsCSVButton = (
  props: ExportSubmissionsCSVButtonProps
) => {
  const { t } = useTranslation('submissions')
  const [downloading, setDownloading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [, requestExport] = useMutation(RequestCsvExportDocument)

  const handleClick = useCallback(() => {
    setDownloading(true)

    requestExport({
      input: {
        from: pipe(
          props.published,
          O.chain(DateRangeTuple.start),
          O.toUndefined
        ),
        to: pipe(props.published, O.chain(DateRangeTuple.end), O.toUndefined),
        formId: props.formId,
        creatorId: O.toNullable(props.creatorId),
        tags: pipe(props.tags, O.getOrElseW(constant([])))
      }
    })
      .then(() => {
        setShowAlert(true)
      })
      .finally(() => setDownloading(false))
  }, [props, requestExport])

  const button = (
    <Flex flexDirection={'column'}>
      <SecondaryButton
        loading={downloading}
        disabled={downloading || !props.formId}
        onClick={handleClick}
      >
        {t('exportSubmissionsCSVButton.label')}
      </SecondaryButton>
    </Flex>
  )

  if (!props.formId) {
    return (
      <Tooltip disabled={false} title={t('exportSubmissionsCSVButton.noForm')}>
        {button}
      </Tooltip>
    )
  }

  return (
    <ExportRequestedAlert visible={showAlert} onVisiblityChange={setShowAlert}>
      {button}
    </ExportRequestedAlert>
  )
}
