import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type WeeklySubmissionsWidgetQueryVariables = SchemaTypes.Exact<{
  input: SchemaTypes.SubmissionReportInput;
}>;


export type WeeklySubmissionsWidgetQuery = { __typename: 'Query', submissionReport: Array<{ __typename: 'SubmissionReportData', id: string, interval: SchemaTypes.PeriodInterval, periodStart: any, createdSubmissionCount: number, completedSubmissionCount: number, uniqueUserCount: number }> };


export const WeeklySubmissionsWidgetQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"WeeklySubmissionsWidgetQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SubmissionReportInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"submissionReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"interval"}},{"kind":"Field","name":{"kind":"Name","value":"periodStart"}},{"kind":"Field","name":{"kind":"Name","value":"createdSubmissionCount"}},{"kind":"Field","name":{"kind":"Name","value":"completedSubmissionCount"}},{"kind":"Field","name":{"kind":"Name","value":"uniqueUserCount"}}]}}]}}]} as unknown as DocumentNode<WeeklySubmissionsWidgetQuery, WeeklySubmissionsWidgetQueryVariables>;