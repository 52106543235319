import * as NEA from 'fp-ts/NonEmptyArray'
import { isNonEmpty } from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import * as D from 'io-ts/Decoder'

import { interpreter } from '../interpreter'
import { Schemable2C } from '../schemable'

import { Guard } from './guard'

const nonEmptyArray = <A>(item: D.Decoder<unknown, A>) =>
  pipe(
    item,
    D.array,
    D.refine((a): a is NEA.NonEmptyArray<A> => isNonEmpty(a), 'nonEmptyArray')
  )

const schemable: Schemable2C<D.URI, unknown> = {
  ...D.Schemable,
  ...D.WithUnion,
  ...D.WithRefine,
  any: D.fromGuard(Guard.any, 'any'),
  unknown: D.fromGuard(Guard.unknown, 'unknown'),
  nonEmptyArray,
  readonlyNonEmptyArray: nonEmptyArray
}

export const Decoder = {
  ...D,
  ...schemable,
  schemable
}

export const getDecoder = interpreter(Decoder)
