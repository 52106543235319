export const white = '#fff'
export const black = '#050505'

export const grey = <const>{
  '50': '#f2f2f8',
  '100': '#d7d7db',
  '200': '#bcbcc1',
  '300': '#a1a2a6',
  '400': '#86888d',
  '500': '#6d6f75',
  '600': '#55575b',
  '700': '#3c3e41',
  '800': '#242628',
  '900': '#0c0f12'
}

const purple = <const>{
  '50': '#e5e8ff',
  '100': '#b4bdff',
  '200': '#848efd',
  '300': '#535bfa',
  '400': '#2123f7',
  '500': '#0c08de',
  '600': '#050fad',
  '700': '#03137d',
  '800': '#00124d',
  '900': '#000820'
}

const teal = <const>{
  '50': '#d8fffa',
  '100': '#adfaff',
  '200': '#7ef0fd',
  '300': '#4ee4fa',
  '400': '#21d4f7',
  '500': '#08b4de',
  '600': '#0098ad',
  '700': '#00757d',
  '800': '#004c4d',
  '900': '#001e1f'
}

const green = <const>{
  '50': '#defcf6',
  '100': '#bdefe4',
  '200': '#99e2d2',
  '300': '#74d5bf',
  '400': '#4fc9ad',
  '500': '#36b094',
  '600': '#278973',
  '700': '#186252',
  '800': '#063c30',
  '900': '#00170e'
}

const yellow = <const>{
  '50': '#fffddb',
  '100': '#fef5af',
  '200': '#fbef80',
  '300': '#fae850',
  '400': '#f8e221',
  '500': '#dec807',
  '600': '#ad9c00',
  '700': '#7c6f00',
  '800': '#4a4300',
  '900': '#1b1600'
}

const orange = <const>{
  '50': '#ffedde',
  '100': '#fcd3b4',
  '200': '#f7b988',
  '300': '#f2a359',
  '400': '#ee7c2c',
  '500': '#d45512',
  '600': '#a6370d',
  '700': '#762007',
  '800': '#480c00',
  '900': '#1e0002'
}

export const red = <const>{
  '50': '#ffe2e4',
  '100': '#ffb2b7',
  '200': '#ff8187',
  '300': '#fd4f58',
  '400': '#fb1e29',
  '500': '#e10410',
  '600': '#b0000b',
  '700': '#7e0007',
  '800': '#4e0002',
  '900': '#200000'
}

export const primary = purple
export const secondary = green
export const accent = orange

export const success = green['500']
export const info = purple['500']
export const warning = orange['500']
export const danger = red['500']

export const text = <const>{
  base: grey['800'],
  emphasized: grey['900'],
  muted: grey['400'],
  link: purple['500'],
  inverted: white
}

export const background = <const>{
  base: white,
  page: grey['50']
}

export const border = <const>{
  base: primary['200'],
  emphasized: grey['400'],
  muted: grey['300'],
  separator: primary['200']
}

export const palette = <const>{
  white,
  black,
  grey,
  purple,
  green,
  orange,
  yellow,
  red,
  teal,

  text,
  background,
  bg: background,
  border,

  primary,
  secondary,
  accent,

  success,
  info,
  warning,
  danger
}
