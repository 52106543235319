import { palette } from '@woorcs/design-tokens'
import Color from 'color'

export const focusOutlineColor = Color(palette.primary['300'])
  .alpha(0.85)
  .string()

export const focusStyle = {
  boxShadow: `0 0 0 2px ${focusOutlineColor};`,
  outline: 'none',
  transition: 'box-shadow 0.3s ease-out'
}
