import { useState, useCallback, ChangeEvent } from 'react'
import { without } from 'ramda'
import { isInputEvent, isNotNilOrEmpty } from '@woorcs/utils'

import { useControllableProp } from './useControllable'

export interface UseSelectStateProps<T = string | number | null | undefined> {
  value?: T | null
  defaultValue?: T | T[] | null
  multiple?: boolean
  allowEmptyValue?: boolean
  onChange?(value: T | T[] | null): void
}

export interface UseSelectStateReturn<T>
  extends Required<Pick<UseSelectStateProps<T>, 'multiple' | 'value'>> {
  // value: SelectStateValue<T> | null
  hasValue: boolean
  clear(): void
  isSelected(value: T): boolean
  onChange(value: T | null): void
  onChange(e: ChangeEvent<HTMLElement>): void
}

export function useSelectState<T>({
  value: propValue,
  multiple = false,
  defaultValue = multiple ? ([] as T[]) : null,
  allowEmptyValue = false,
  onChange
}: UseSelectStateProps<T>): UseSelectStateReturn<T> {
  const [stateValue, setValue] = useState(defaultValue)
  const [isControlled, currentValue] = useControllableProp(
    propValue,
    stateValue
  )

  const hasValue = multiple
    ? (currentValue as T[]).length > 0
    : isNotNilOrEmpty(currentValue)

  const isSelected = useCallback(
    (value: T) => {
      if (multiple) {
        return (currentValue as any).includes(value as T)
      }

      return value === currentValue
    },
    [currentValue, multiple]
  )

  const handleChange = useCallback(
    (eventOrValue: T | ChangeEvent<HTMLElement>) => {
      const value = isInputEvent(eventOrValue)
        ? ((eventOrValue.target as any).value as unknown as T)
        : eventOrValue

      const isCurrentlySelected = isSelected(value)

      const getNextValue = () => {
        if (multiple && Array.isArray(currentValue)) {
          return isCurrentlySelected
            ? without([value], currentValue)
            : [...currentValue, value]
        }

        if (isCurrentlySelected && allowEmptyValue) {
          return null
        }

        return value
      }

      const nextValue = getNextValue()

      if (!isControlled) {
        setValue(nextValue)
      }

      onChange?.(nextValue as any)
    },
    [
      allowEmptyValue,
      currentValue,
      isControlled,
      isSelected,
      multiple,
      onChange
    ]
  )

  const clear = useCallback(() => {
    if (multiple && !allowEmptyValue) {
      return
    }

    const emptyValue = multiple ? [] : null

    if (!isControlled) {
      setValue(emptyValue)
    }

    onChange?.(emptyValue as any)
  }, [allowEmptyValue, isControlled, multiple, onChange])

  return {
    multiple,
    value: currentValue as any,
    onChange: handleChange as any,
    clear,
    isSelected,
    hasValue
  }
}
