import * as O from 'fp-ts/Option'
import * as IO from 'fp-ts/IO'
import * as Eq from 'fp-ts/Eq'
import { pipe } from 'fp-ts/function'
import { sequenceS } from 'fp-ts/Apply'
import { Path } from '@woorcs/types/Path'
import * as ET from '@woorcs/types/ElementTree'
import { UUID } from '@woorcs/types/UUID'

import { Editor } from '../state'

import { useEditorSelector } from './useEditorSelector'

const eq = O.getEq(
  Eq.struct({
    element: Eq.eqStrict,
    path: Path
  })
)

export const getElement = (editor: Editor) => (id: UUID) =>
  pipe(
    IO.Do,
    IO.bind('tree', () => editor.getValue()),
    IO.bind('element', ({ tree }) =>
      IO.of(
        pipe(
          tree,
          ET.find((element) => element.id === id)
        )
      )
    ),
    IO.bind('path', ({ tree, element }) =>
      IO.of(
        pipe(
          element,
          O.chain((element) => pipe(tree, ET.elementPath(element)))
        )
      )
    ),
    IO.map(({ element, path }) =>
      sequenceS(O.Applicative)({
        element,
        path
      })
    )
  )

export const useElement = (id: UUID) =>
  useEditorSelector((editor) => pipe(id, getElement(editor)), eq)

export const useOptionalElement = (id: O.Option<UUID>) =>
  useEditorSelector(
    (editor) =>
      pipe(
        id,
        O.fold(
          () => () => O.none,
          (id) => pipe(id, getElement(editor))
        )
      ),
    eq
  )
