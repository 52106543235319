import { useMemo } from 'react'

export const PaginationRangeItemType = {
  PAGE: 'PAGE',
  ELLIPSIS: 'ELLIPSIS'
} as const

export type PaginationRangeItemType = 'PAGE' | 'ELLIPSIS'

export type PaginationRangeItem = ['PAGE', number] | ['ELLIPSIS']

const generatePaginationRange = (
  currentPage: number,
  totalPages: number,
  width: number
): PaginationRangeItem[] => {
  const left = currentPage - width
  const right = currentPage + width + 1
  const range = []
  const rangeWithDots: PaginationRangeItem[] = []

  let l: number

  for (let i = 1; i <= totalPages; i += 1) {
    if (i === 1 || i === totalPages || (i >= left && i <= right)) {
      range.push(i)
    } else if (i < left) {
      i = left - 1
    } else if (i > right) {
      range.push(totalPages)
      break
    }
  }

  range.forEach((i) => {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push([PaginationRangeItemType.PAGE, l + 1])
      } else if (i - l !== 1) {
        rangeWithDots.push([PaginationRangeItemType.ELLIPSIS])
      }
    }

    rangeWithDots.push([PaginationRangeItemType.PAGE, i])

    l = i
  })

  return rangeWithDots
}

export interface UsePaginationProps {
  currentPage: number
  totalPages: number
  width?: number
  boundaryCount?: number
}

export const usePagination = ({
  currentPage,
  totalPages,
  width = 2
}: UsePaginationProps): PaginationRangeItem[] => {
  const items = useMemo(
    () => generatePaginationRange(currentPage, totalPages, width),
    [currentPage, width, totalPages]
  )

  return items
}
