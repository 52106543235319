import { memo, ReactElement } from 'react'
import * as Ariakit from '@ariakit/react'

import { splitStyledProps, PropsOf, system } from '../../../system'

import { UseListboxProps, useListbox } from './useListbox'
import { ListboxProvider } from './ListboxProvider'
import { ListboxContainer } from './ListboxContainer'

interface ListboxProps
  extends UseListboxProps,
    Omit<
      PropsOf<typeof system.li>,
      'onChange' | 'onBlur' | 'onFocus' | 'value' | 'defaultValue'
    > {
  disabled?: boolean
  readOnly?: boolean
  multiple?: boolean
  children: ReactElement | ReactElement[]
}

export const Listbox = memo(({ children, ...props }: ListboxProps) => {
  const [styledProps, other] = splitStyledProps(props)
  const context = useListbox(other)

  return (
    <Ariakit.CompositeProvider store={context.compositeStore}>
      <ListboxProvider context={context}>
        <ListboxContainer {...styledProps}>{children}</ListboxContainer>
      </ListboxProvider>
    </Ariakit.CompositeProvider>
  )
})
