import React, { ChangeEvent, useCallback } from 'react'
import * as IO from 'fp-ts/IO'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import {
  Flex,
  Input,
  NativeSelect,
  NativeSelectOption
} from '@woorcs/design-system'
import { rem } from '@woorcs/utils'
import { constNull, pipe } from 'fp-ts/function'
import * as RA from 'fp-ts/ReadonlyArray'
import * as ET from '@woorcs/types/ElementTree'
import { UUID } from '@woorcs/types/UUID'
import { Condition, FormElement, LeafCondition } from '@woorcs/form'
import { InspectionFormDefinition } from '@woorcs/inspection-form'

import { useEditorSelector } from '../../state'

const supportsCondition = FormElement.FormElementType.isAnyOf([
  'SelectInput',
  'TextInput',
  'MultiSelectInput'
])

const hasResponseSet = FormElement.FormElementType.isAnyOf([
  'SelectInput',
  'MultiSelectInput'
])

type SupportedInputElements =
  | FormElement.SelectInputElement
  | FormElement.TextInputElement
  | FormElement.MultiSelectInputElement

const useSupportedInputElements = (): ReadonlyArray<SupportedInputElements> =>
  useEditorSelector(
    (editor) =>
      pipe(
        editor.getValue<FormElement.FormElement>(),
        IO.map((tree) => ET.toArray(tree)),
        IO.map(
          RA.filter((element) =>
            supportsCondition(element as SupportedInputElements)
          )
        )
      )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any

const useInput = (key: UUID): O.Option<FormElement.InputElementType> =>
  useEditorSelector((editor) =>
    pipe(
      editor.getValue<FormElement.FormElement>(),
      IO.map((value) =>
        pipe(
          value,
          ET.find((element) => {
            if (!FormElement.isInputElement(element)) {
              return false
            }

            return element.key === key
          }),
          O.chain((element) =>
            FormElement.InputElementType.is(element) ? O.some(element) : O.none
          )
        )
      )
    )
  )

const useResponseSet = (id: UUID) =>
  useEditorSelector((editor) =>
    pipe(
      editor.getValue() as IO.IO<InspectionFormDefinition.InspectionFormDefinition>,
      IO.map((root) => pipe(root.responseSets, R.lookup(id)))
    )
  )

type ConditionOperatorSelectProps = {
  value: Condition.Operator
  onChange(e: ChangeEvent<HTMLInputElement>): void
}

const ConditionOperatorSelect = ({
  value,
  onChange
}: ConditionOperatorSelectProps) => {
  return (
    <NativeSelect
      value={value}
      mr={2}
      width={rem(80)}
      flexShrink={0}
      flexGrow={0}
      onChange={onChange}
    >
      <NativeSelectOption value='eq'>=</NativeSelectOption>
      <NativeSelectOption value='ne'>!=</NativeSelectOption>
    </NativeSelect>
  )
}

type SelectQuestionProps = {
  value: string
  onChange(e: ChangeEvent<HTMLInputElement>): void
}

const SelectQuestion = ({ value, onChange }: SelectQuestionProps) => {
  const elements = useSupportedInputElements()

  return (
    <NativeSelect
      value={value ?? ''}
      mr={2}
      width={rem(200)}
      flexShrink={0}
      onChange={onChange}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <NativeSelectOption value={''}>{'Select a question'}</NativeSelectOption>
      {elements.map((element) => (
        <NativeSelectOption key={element.key} value={element.key}>
          {element.label.text}
        </NativeSelectOption>
      ))}
    </NativeSelect>
  )
}

type SelectResponseSetOptionProps = {
  responseSetID: UUID
  value: string
  onChange(e: ChangeEvent<HTMLInputElement>): void
}

const SelectResponseSetOption = ({
  responseSetID,
  value,
  onChange
}: SelectResponseSetOptionProps) => {
  return pipe(
    useResponseSet(responseSetID),
    O.fold(constNull, (responseSet) => (
      <NativeSelect value={value ?? ''} onChange={onChange}>
        <NativeSelectOption value={''} disabled>
          {'Select a question'}
        </NativeSelectOption>
        {responseSet.options.map((option) => (
          <NativeSelectOption
            key={option.id}
            label={option.label.text}
            value={option.id}
          />
        ))}
      </NativeSelect>
    ))
  )
}

type SelectResponseProps = {
  selectedKey: UUID
  value: string
  onChange(e: ChangeEvent<HTMLInputElement>): void
}

const SelectResponse = ({
  selectedKey,
  value,
  onChange
}: SelectResponseProps) => {
  return pipe(
    useInput(selectedKey),
    O.fold(constNull, (element) => {
      if (hasResponseSet(element)) {
        return (
          <SelectResponseSetOption
            value={value}
            responseSetID={element.responseSet}
            onChange={onChange}
          />
        )
      }

      return <Input value={value} mr={2} onChange={onChange} />
    })
  )
}

export type ConditionFormProps = {
  condition: Condition.LeafCondition
  onChange(condition: Condition.LeafCondition): void
}

export const ConditionForm = ({ condition, onChange }: ConditionFormProps) => {
  const handleKeyChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value as UUID

      pipe(
        condition,
        LeafCondition.keyLens.set(value),
        LeafCondition.expectedValueLens.set(null),
        onChange
      )
    },
    [condition, onChange]
  )

  const handleOperatorChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      if (!Condition.Operator.is(value)) {
        return
      }

      pipe(condition, LeafCondition.operatorLens.set(value), onChange)
    },
    [condition, onChange]
  )

  const handleExpectedValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      pipe(condition, LeafCondition.expectedValueLens.set(value), onChange)
    },
    [condition, onChange]
  )

  return (
    <Flex alignItems='center'>
      <SelectQuestion value={condition.key} onChange={handleKeyChange} />
      <ConditionOperatorSelect
        value={condition.operator}
        onChange={handleOperatorChange}
      />
      <SelectResponse
        selectedKey={condition.key as UUID}
        value={condition.expectedValue as string}
        onChange={handleExpectedValueChange}
      />
    </Flex>
  )
}
