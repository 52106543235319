import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type SelectTagsModalTagFragment = { __typename: 'Tag', id: string, name: string, color: string | null };

export type SelectTagsModalQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type SelectTagsModalQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, tags: Array<{ __typename: 'Tag', id: string, name: string, color: string | null }> } };

export const SelectTagsModalTagFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SelectTagsModalTagFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Tag"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"color"}}]}}]} as unknown as DocumentNode<SelectTagsModalTagFragment, unknown>;
export const SelectTagsModalQueryDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SelectTagsModalQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"tags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SelectTagsModalTagFragment"}}]}}]}}]}},...SelectTagsModalTagFragmentDoc.definitions]} as unknown as DocumentNode<SelectTagsModalQuery, SelectTagsModalQueryVariables>;