import { forwardRef, ElementType, cloneElement, ReactElement } from 'react'
import { Checkbox } from 'reakit/Checkbox'
import { Radio } from 'reakit/Radio'
import { forkRefs } from '@woorcs/utils'
import { useFocusable } from '@woorcs/hooks'
import { PropsOf } from '@emotion/react'

import { system, splitStyledProps } from '../../../system'

const Container = system('div')({
  flexShrink: 0,
  position: 'relative',
  cursor: 'pointer'
})

export interface ToggleBaseControlProps {
  checked?: boolean
  disabled?: boolean
  focused?: boolean
}

export interface ToggleBaseProps extends PropsOf<typeof system.div> {
  name?: string
  value?: unknown
  type?: 'checkbox' | 'radio'
  checked?: boolean
  disabled?: boolean
  defaultChecked?: boolean
  children: ReactElement
}

export const ToggleBase = forwardRef<HTMLInputElement, ToggleBaseProps>(
  ({ type = 'checkbox', children, ...props }, ref) => {
    const [styleProps, inputProps] = splitStyledProps(props)
    const Input: ElementType = (type === 'checkbox' ? Checkbox : Radio) as any
    const [focusRef, { focused }] = useFocusable({
      disabled: inputProps.disabled
    })

    const control = cloneElement(children, {
      checked: inputProps.checked,
      disabled: inputProps.disabled,
      focused
    })

    return (
      <Container {...styleProps}>
        <Input
          ref={forkRefs(ref, focusRef)}
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            opacity: 0,
            cursor: 'pointer'
          }}
          type={type}
          {...inputProps}
        />

        {control}
      </Container>
    )
  }
)
