import { ReactElement, cloneElement } from 'react'
import * as Ariakit from '@ariakit/react'

export interface DialogSidebarDisclosureProps
  extends Ariakit.DialogDisclosureProps {
  children: ReactElement
}

export const DialogSidebarDisclosure = ({
  children,
  ...props
}: DialogSidebarDisclosureProps) => {
  return (
    <Ariakit.DialogDisclosure
      render={(disclosureProps) => cloneElement(children, disclosureProps)}
      {...props}
    />
  )
}
