import React from 'react'
import { Listbox, ListboxOption } from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'

type SelectRolesInputProps = {
  value?: string | null
  onChange?(value: string | null): void
}

const roles = ['ADMIN']

export const SelectRolesInput = ({
  value,
  onChange
}: SelectRolesInputProps) => {
  const { t } = useTranslation()
  return (
    <Listbox value={value} onChange={onChange}>
      <>
        <ListboxOption key='no-role' value={null}>
          No role
        </ListboxOption>
        {roles.map((role) => (
          <ListboxOption key={role} value={role}>
            {t(`roles:${role}` as const)}
          </ListboxOption>
        ))}
      </>
    </Listbox>
  )
}
