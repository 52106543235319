import React from 'react'
import {
  ChevronDownIcon,
  SelectOption,
  Flex,
  Text,
  Select,
  SelectDisclosure,
  ToggleButton,
  ToggleButtonLabel,
  SelectPlaceholder,
  SelectValue,
  SelectMenu
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'

type PaginiationLimitSelectProps = {
  value: number
  onChange(limit: number): void
}

export const PaginiationLimitSelect = ({
  value,
  onChange
}: PaginiationLimitSelectProps) => {
  const { t } = useTranslation()

  return (
    <Flex alignItems='center'>
      <Text
        css={{
          whiteSpace: 'nowrap'
        }}
        display='block'
        fontSize='small'
        fontWeight='bold'
        mr={3}
      >
        {t('common:resultsPerPage')}:
      </Text>
      <Select value={value} onChange={onChange}>
        <SelectDisclosure>
          <ToggleButton>
            <ToggleButtonLabel>
              <SelectPlaceholder>{`${t('common:select')}:`}</SelectPlaceholder>
              <SelectValue />
              <ChevronDownIcon size='small' color='primary.500' ml={2} />
            </ToggleButtonLabel>
          </ToggleButton>
        </SelectDisclosure>

        <SelectMenu aria-label={t('common:resultsPerPage')}>
          <SelectOption value={10} />
          <SelectOption value={25} />
          <SelectOption value={50} />
          <SelectOption value={100} />
        </SelectMenu>
      </Select>
    </Flex>
  )
}
