import { createContext } from '@woorcs/utils'
import React, { ReactNode } from 'react'

type OnboardingWizardContext = readonly [
  { currentIndex: number },
  { next(): void; prev(): void }
]

const [Provider, useOnboardingWizardContext] =
  createContext<OnboardingWizardContext>()

export { useOnboardingWizardContext }

type OnboardingWizardContextProviderProps = {
  currentIndex: number
  onIndexChange(index: number): void
  children: ReactNode
}

export const OnboardingWizardContextProvider = ({
  currentIndex,
  onIndexChange,
  children
}: OnboardingWizardContextProviderProps) => {
  const context = [
    { currentIndex },
    {
      prev: () => onIndexChange(currentIndex - 1),
      next: () => onIndexChange(currentIndex + 1)
    }
  ] as const

  return <Provider value={context}>{children}</Provider>
}
