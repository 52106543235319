import { Badge, useTheme } from '@woorcs/design-system'
import { Tag } from '@woorcs/graphql/schema'
import { ComponentProps } from 'react'

import { TagAvatar } from '../TagAvatar'

interface Props extends ComponentProps<typeof Badge> {
  tag: Pick<Tag, 'color'>
}

export const TagBadge = ({ children, tag, ...other }: Props) => {
  const theme = useTheme()

  return (
    <Badge
      bg='grey.50'
      border='none'
      borderRadius='full'
      pl={2}
      pr={3}
      color='text.emphasized'
      css={{
        ':hover': {
          backgroundColor: theme.colors.grey[100]
        }
      }}
      {...other}
    >
      <TagAvatar tag={tag} mr={1} />
      {children}
    </Badge>
  )
}
