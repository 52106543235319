import { Button, Flex, system } from '@woorcs/design-system'
import { rem } from '@woorcs/utils'
import React, { MouseEvent, useCallback, useMemo, useRef } from 'react'
import { useDrop } from 'react-dnd'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as O from 'fp-ts/Option'
import { constVoid, pipe } from 'fp-ts/function'
import { UUID } from '@woorcs/types/UUID'
import * as Path from '@woorcs/types/Path'
import { InspectionFormDocument } from '@woorcs/inspection-form'

import { ElementList, ELEMENT_LIST_ITEM_DRAG_TYPE } from '../ElementList'
import { AddElementMenu } from '../AddElementMenu'

import { EditPageSidebar } from './Sidebar'
import { useSelectedElement } from './useSelectedElement'

type EmptyPageProps = {
  addElementAt: Path.Path
}

const EmptyPage = ({ addElementAt }: EmptyPageProps) => (
  <Flex
    justifyContent='center'
    alignItems='center'
    borderRadius='medium'
    bg='grey.50'
    borderColor='primary.50'
    borderWidth={1}
    borderStyle='solid'
    py={14}
  >
    <AddElementMenu at={addElementAt}>
      <Button colorVariant='secondary' size='small'>
        Add element
      </Button>
    </AddElementMenu>
  </Flex>
)

const Container = system('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  height: '100%',
  overflowY: 'auto'
})

const Content = system('div')({
  display: 'flex',
  width: rem(640),
  flexDirection: 'column'
})

type InspectionFormEditorPageProps = {
  page: InspectionFormDocument.InspectionFormPageElement
  index: number
}

export const EditPageElements = ({
  page,
  index
}: InspectionFormEditorPageProps) => {
  const [selectedElementId, selectedElement, setSelectedElementID] =
    useSelectedElement()
  const containerRef = useRef<HTMLDivElement | null>(null)

  /**
   * Setting the whole page as a drop area prevents a bug where the page preview
   * will be stuck while the invisible browser preview is animating.
   */
  const [, dropRef] = useDrop({
    accept: [ELEMENT_LIST_ITEM_DRAG_TYPE]
  })
  const firstChild = useMemo(() => Path.fromNonEmptyArray([index, 0]), [index])

  const handleSelectElement = useCallback(
    (elementID: UUID) => {
      setSelectedElementID(O.some(elementID))
    },
    [setSelectedElementID]
  )

  const handleDeselectElement = useCallback(() => {
    setSelectedElementID(O.none)
  }, [setSelectedElementID])

  const handleContainerClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (e.target !== containerRef.current) {
        return
      }

      pipe(
        selectedElement,
        O.fold(constVoid, () => setSelectedElementID(O.none))
      )
    },
    [selectedElement, setSelectedElementID]
  )

  const elements = pipe(
    page.children,
    NEA.fromArray,
    O.fold(
      () => <EmptyPage addElementAt={firstChild} />,
      (elements) => (
        <ElementList
          elements={elements}
          selectedElementId={selectedElementId}
          onEditElement={handleSelectElement}
          onElementClick={handleSelectElement}
        />
      )
    )
  )

  return (
    <Flex ref={dropRef} key={page.id} flexDirection='column' height='100%'>
      <Container ref={containerRef} py={14} onClick={handleContainerClick}>
        <Content>{elements}</Content>
      </Container>

      <EditPageSidebar
        page={page}
        selectedElement={selectedElement}
        onDeselectElement={handleDeselectElement}
      />
    </Flex>
  )
}
