import React from 'react'

import { Text, TextProps } from '../Text'

export const Subtitle = (props: TextProps) => (
  <Text
    as='p'
    fontWeight='bold'
    fontSize='subtitle'
    letterSpacing='subtitle'
    fontFamily='display'
    color='primary.500'
    {...props}
  />
)

export const Subtitle2 = (props: TextProps) => (
  <Text
    as='p'
    fontWeight='semi-bold'
    fontSize='subtitle2'
    letterSpacing='subtitle2'
    fontFamily='display'
    lineHeight={[1.43, 1.43, 1.43, 1.5]}
    color='text.emphasized'
    {...props}
  />
)
