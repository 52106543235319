import React from 'react'

import { IconButton } from '../../buttons'
import { Flex } from '../../layout'
import { ChevronLeftIcon, ChevronRightIcon } from '../../icons'

import { CalendarCell } from './CalendarCell'
import { useCalendar } from './useCalendar'

export const CalendarNavigation = () => {
  const { getBackProps, getForwardProps } = useCalendar()

  return (
    <Flex
      position='absolute'
      top={0}
      left={0}
      right={0}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      pt={2}
    >
      <CalendarCell>
        <IconButton
          size='small'
          variant='plain'
          tabIndex={-1}
          {...getBackProps()}
        >
          <ChevronLeftIcon />
        </IconButton>
      </CalendarCell>

      <CalendarCell>
        <IconButton
          size='small'
          variant='plain'
          tabIndex={-1}
          {...getForwardProps()}
        >
          <ChevronRightIcon />
        </IconButton>
      </CalendarCell>
    </Flex>
  )
}
