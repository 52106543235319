import React, { ReactNode } from 'react'

import { FullscreenPage } from '@app/components'

import { OnboardingNavbar } from '../Navbar'

type OnboardingLayoutChildren = {
  children: ReactNode
}

export const OnboardingLayout = ({ children }: OnboardingLayoutChildren) => {
  return (
    <FullscreenPage>
      <OnboardingNavbar />
      {children}
    </FullscreenPage>
  )
}
