import {
  ConfirmAlertDialog,
  Menu,
  MenuButton,
  MenuContent,
  MenuItem
} from '@woorcs/design-system'
import { Locale } from '@woorcs/form'
import { ReactElement, useState, memo, useCallback } from 'react'

import { useI18nConfig } from '../../state'

export type LanguageListItemMenuProps = {
  locale: Locale.Locale
  children: ReactElement
}

export const LanguageListItemMenu = memo(
  ({ locale, children }: LanguageListItemMenuProps) => {
    const [, { removeLanguage }] = useI18nConfig()
    const [visible, setVisible] = useState(false)

    const handleRemove = useCallback(() => {
      removeLanguage(locale)
    }, [locale, removeLanguage])

    return (
      <Menu open={visible} aria-label='Language menu' setOpen={setVisible}>
        <MenuButton>{children}</MenuButton>

        <MenuContent aria-label='Language menu'>
          <ConfirmAlertDialog
            title='Remove language'
            aria-label='Confirm remove language'
            body='Are you sure that you want to remove the language? All translations for the language will be permanently removed.'
            destructive
            onConfirm={handleRemove}
          >
            <MenuItem hideOnClick={false} destructive>
              Remove
            </MenuItem>
          </ConfirmAlertDialog>
        </MenuContent>
      </Menu>
    )
  }
)
