import { apply, identity } from 'ramda'
import { useCallback } from 'react'

type Callback = (...args: any[]) => void

export const applyAll =
  (...xs: (Callback | void)[]) =>
  (...args: any[]) =>
    xs.filter(identity).map((x) => apply(x as any, args))

export const useAllCallbacks = (
  xs: (Callback | void)[],
  dependencies: unknown[] = []
) =>
  useCallback(
    (...args: any[]) => {
      applyAll(...xs)(...args)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...xs, ...dependencies]
  )
