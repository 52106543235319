import { UpdateResolver } from '@urql/exchange-graphcache'
import { pipe, constNull } from 'fp-ts/function'
import { Endomorphism } from 'fp-ts/Endomorphism'
import * as O from 'fp-ts/Option'
import * as Optional from 'monocle-ts/Optional'
import * as Lens from 'monocle-ts/Lens'

import { SuperuserListUserFragment } from '../components/SuperuserList/__generated__/SuperuserList'
import {
  RemoveSuperuserMutation,
  RemoveSuperuserMutationVariables,
  SuperUserListQuery,
  SuperUserListQueryDocument
} from '../pages/superusers/__generated__/SuperUsersPage'

const superuserListQueryResponseLens = pipe(
  Lens.id<SuperUserListQuery>(),
  Lens.prop('superusers')
)

const superuserListQueryResponseOptional = Lens.asOptional(
  superuserListQueryResponseLens
)

const updateSuperuserListResponseData = (
  update: Endomorphism<SuperuserListUserFragment[]>
) => pipe(superuserListQueryResponseOptional, Optional.modify(update))

const removeUser = (accountId: string) =>
  pipe(
    updateSuperuserListResponseData((data) => {
      return data.filter((user) => accountId !== user.account.id)
    })
  )

const removeSuperuser: UpdateResolver<
  RemoveSuperuserMutation,
  RemoveSuperuserMutationVariables
> = (_, { input: { accountId } }, cache) => {
  cache.updateQuery({ query: SuperUserListQueryDocument }, (superuserList) =>
    pipe(
      O.fromNullable(superuserList),
      O.map(removeUser(accountId)),
      O.getOrElseW(constNull)
    )
  )
}

export const updates = {
  Mutation: {
    removeSuperuser
  },
  Subscription: {}
}
