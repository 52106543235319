import React from 'react'
import { LinePath, AreaClosed } from '@visx/shape'
import { LinearGradient } from '@visx/gradient'
import { scaleLinear } from '@visx/scale'
import { curveNatural } from '@visx/curve'
import { useTheme } from '@woorcs/design-system'
import { useId } from '@woorcs/hooks'
import { max } from 'fp-ts/Ord'
import { Ord } from 'fp-ts/number'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'

const PADDING = 4

export type Trend = 'positive' | 'negative' | 'neutral'

export type TrendData = number[]

interface TrendChartProps {
  data: TrendData
  trend: Trend
}

export const TrendChart = ({ data, trend }: TrendChartProps) => {
  const theme = useTheme()
  const height = 54
  const width = 140
  const backgroundId = useId()
  const maxData = pipe(
    data,
    A.reduce(0, (m, a) => max(Ord)(a, m))
  )

  const xScale = scaleLinear({
    domain: [7, 0],
    range: [0 + PADDING, width - PADDING]
  })

  const yScale = scaleLinear({
    domain: [0, Math.max(10, maxData)],
    range: [height - PADDING * 2, PADDING * 2]
  })

  const getBg = () => {
    switch (trend) {
      case 'positive': {
        return theme.colors.secondary[100]
      }

      case 'negative': {
        return theme.colors.orange[100]
      }

      case 'neutral': {
        return theme.colors.primary[100]
      }
    }
  }

  const getStroke = () => {
    switch (trend) {
      case 'positive': {
        return theme.colors.secondary[500]
      }

      case 'negative': {
        return theme.colors.orange[500]
      }

      case 'neutral': {
        return theme.colors.primary[500]
      }
    }
  }

  return (
    <svg height={height} width={width}>
      <LinearGradient
        id={String(backgroundId)}
        from={getBg()}
        to='rgba(255, 255, 255, 0)'
        fromOffset={0.25}
      />

      <AreaClosed
        data={data}
        x={(_, index) => xScale(index)}
        y={yScale}
        yScale={yScale}
        fill={`url('#${backgroundId}')`}
        curve={curveNatural}
        strokeWidth={3}
      />

      <LinePath
        data={data}
        x={(_, index) => xScale(index)}
        y={yScale}
        stroke={getStroke()}
        strokeWidth={3}
        curve={curveNatural}
      />
    </svg>
  )
}
