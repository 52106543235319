import React, { FC } from 'react'
import { animated, useSpring, config } from 'react-spring'

import { RadioActiveIcon } from '../../icons'

export interface RadioIconProps {
  size: 'small' | 'medium'
  checked?: boolean
}

export const RadioIcon: FC<RadioIconProps> = ({ size, checked }) => {
  const visible = checked
  const spring = useSpring({
    delay: 50,
    opacity: visible ? 1 : 0,
    transform: visible ? 'scale(1)' : 'scale(0)',
    config: config.gentle
  })

  return (
    <animated.div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        willChange: 'transform, opacity'
      }}
      style={spring}
    >
      <RadioActiveIcon size={size} />
    </animated.div>
  )
}
