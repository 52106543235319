export const elementReceivesFocusOnMouseDown = (element: EventTarget) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { userAgent } = window.navigator as unknown as any
  const is = (string: string) => userAgent.indexOf(string) !== -1
  const isLikeMac = is('Mac') || is('like Mac')
  const isSafariOrFirefox = is('Safari') || is('Firefox')

  return (
    !isLikeMac ||
    !isSafariOrFirefox ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !(element instanceof typeof HTMLButtonElement)
  )
}
