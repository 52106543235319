import { ReactElement, useMemo } from 'react'
import { createContext } from '@woorcs/utils'
import * as Optional from 'monocle-ts/Optional'
import { constNull, pipe } from 'fp-ts/function'
import * as DE from '@nll/datum/DatumEither'
import { CombinedError, useQuery } from 'urql'

import { queryToDatumEither } from '../utils'

import {
  ViewerFragment,
  ViewerQuery,
  ViewerQueryDocument
} from './__generated__/viewer'

type ViewerContext = ViewerFragment

const [Provider, useViewerContext] = createContext<ViewerContext>()

export const viewerQueryResponseOptional = pipe(
  Optional.id<ViewerQuery>(),
  Optional.prop('viewer'),
  Optional.fromNullable
)

interface ViewerProviderProps {
  onNone?(refreshing?: boolean): ReactElement | null
  onFailure?(error: CombinedError, refreshing?: boolean): ReactElement | null
  children: ReactElement
}

export const ViewerProvider = ({
  onNone = constNull,
  onFailure = constNull,
  children
}: ViewerProviderProps) =>
  pipe(
    useMemo(() => ({ query: ViewerQueryDocument }), []),
    useQuery,
    queryToDatumEither(viewerQueryResponseOptional),
    DE.squash(onNone, onFailure, (viewer) => (
      <Provider value={viewer}>{children}</Provider>
    ))
  )

export const useViewer = useViewerContext
