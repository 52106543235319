import { BarGroup, BarRounded } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { AxisBottom } from '@visx/axis'
import { Text as SvgText } from '@visx/text'
import { Flex, Text, useTheme } from '@woorcs/design-system'
import { ParentSize } from '@visx/responsive'
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend'
import { PeriodInterval } from '@woorcs/graphql/src/schema'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

const getDataLabel = (period: PeriodInterval, periodStart: string) => {
  switch (period) {
    case PeriodInterval.Daily:
      return format(new Date(periodStart), 'EEEE')
    case PeriodInterval.Weekly:
      return `v.${format(new Date(periodStart), 'w')}`
    case PeriodInterval.Monthly:
      return format(new Date(periodStart), 'MMM')
    case PeriodInterval.Yearly:
      return format(new Date(periodStart), 'yyyy')
  }
}

type BarData = {
  date: string
  started: number
  completed: number
}

export type BarsProps = {
  period: PeriodInterval
  data: BarData[]
  height: number
}

type Key = 'started' | 'completed'

const keys = ['started', 'completed'] as Key[]

const getDate = (d: BarData) => d.date

export const Bars = ({ period, data, height }: BarsProps) => {
  const { t } = useTranslation('dashboard')
  const theme = useTheme()
  const xScale = scaleBand<string>({
    round: true,
    domain: data.map(getDate),
    padding: 0.4
  })

  const statusScale = scaleBand<Key>({
    domain: keys,
    padding: 0.3
  })

  const maxValue = Math.max(
    ...data.map((d) => Math.max(...keys.map((key) => Number(d[key]))))
  )

  const yScale = scaleLinear<number>({
    round: true,
    domain: [0, maxValue === 0 ? 1 : maxValue]
  })

  const colorScale = scaleOrdinal<string, string>({
    domain: keys.map((k) => k),
    range: [theme.colors.primary[400], theme.colors.secondary[500], 'purple']
  })

  const getLegendLabel = (key: Key) => {
    switch (key) {
      case 'started':
        return t('totalSubmissionsWidget.legend.started')
      case 'completed':
        return t('totalSubmissionsWidget.legend.completed')
    }
  }

  return (
    <ParentSize ignoreDimensions={['height']}>
      {(parent) => {
        const svgHeight = height - 30
        const xMax = parent.width
        const yMax = svgHeight - 30

        xScale.rangeRound([0, xMax])
        yScale.range([yMax, 20])
        statusScale.rangeRound([0, xScale.bandwidth()])

        return (
          <>
            <svg width={parent.width} height={height - 30}>
              <Group>
                <BarGroup
                  data={data}
                  keys={keys}
                  height={yMax}
                  x0={getDate}
                  x0Scale={xScale}
                  x1Scale={statusScale}
                  yScale={yScale}
                  color={colorScale}
                >
                  {(barGroups) =>
                    barGroups.map((barGroup) => (
                      <Group
                        key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                        left={barGroup.x0}
                      >
                        {barGroup.bars.map((bar) => {
                          const barY = bar.value === 0 ? yMax - 5 : bar.y
                          const textX = bar.x + bar.width / 2
                          const textY = barY - 5
                          const d = data[barGroup.index]
                          const isInFuture = new Date(d.date) > new Date()

                          if (isInFuture) {
                            return null
                          }

                          return (
                            <Group
                              key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                            >
                              <BarRounded
                                x={bar.x}
                                y={barY}
                                width={bar.width}
                                height={Math.max(bar.height, 5)}
                                radius={6}
                                fill={bar.color}
                                top
                                bottom
                              />
                              <SvgText
                                x={textX}
                                y={textY}
                                dominantBaseline='end'
                                textAnchor='middle'
                                fontSize={10}
                                fontWeight='bold'
                                fill={bar.color}
                                fontFamily={theme.fonts.body}
                              >
                                {bar.value}
                              </SvgText>
                            </Group>
                          )
                        })}
                      </Group>
                    ))
                  }
                </BarGroup>
              </Group>
              <AxisBottom
                top={svgHeight - 25}
                scale={xScale}
                stroke='transparent'
                tickStroke={theme.colors.primary[100]}
                tickLength={8}
                tickFormat={(d) => getDataLabel(period, d)}
                tickLabelProps={() => ({
                  fill: theme.colors.grey[900],
                  fontSize: 11,
                  fontFamily: theme.fonts.body,
                  fontWeight: 'semi-bold',
                  textAnchor: 'middle'
                })}
              />
            </svg>
            <LegendOrdinal
              scale={colorScale}
              labelFormat={(label) => `${label.toUpperCase()}`}
            >
              {(labels) => (
                <Flex flexDirection='row' px={6} pt={2} gridColumnGap={3}>
                  {labels.map((label, i) => (
                    <LegendItem
                      key={`legend-quantile-${i}`}
                      alignItems='center'
                    >
                      <svg width={8} height={8}>
                        <rect fill={label.value} width={8} height={8} rx={2} />
                      </svg>
                      <LegendLabel align='left' margin='0 0 0 4px'>
                        <Text fontSize='mini'>
                          {getLegendLabel(label.datum as Key)}
                        </Text>
                      </LegendLabel>
                    </LegendItem>
                  ))}
                </Flex>
              )}
            </LegendOrdinal>
          </>
        )
      }}
    </ParentSize>
  )
}
