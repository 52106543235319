import { Button, ButtonProps, ChevronLeftIcon } from '@woorcs/design-system'
import React from 'react'
import { Link } from 'react-router-dom'

interface BackButtonProps extends Omit<ButtonProps, 'leftIcon'> {
  to: string
}

export const BackButton = (props: BackButtonProps) => {
  return (
    <Button as={Link} variant='plain' leftIcon={<ChevronLeftIcon />} {...props}>
      Back to forms
    </Button>
  )
}
