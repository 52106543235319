import * as IO from 'fp-ts/IO'
import { pipe } from 'fp-ts/function'
import { Path } from '@woorcs/types/Path'
import * as ET from '@woorcs/types/ElementTree'
import { UUID } from '@woorcs/types/UUID'
import { useMemo } from 'react'

import { Editor } from '../state'

import { useEditorSelector } from './useEditorSelector'
import { useEditor } from './useEditor'
import { useEditorContext } from './useEditorContext'

export interface ElementIO<T extends ET.ElementTree> {
  onChange(element: T): IO.IO<void>
  onRemove(): IO.IO<void>
  onMove(to: Path): IO.IO<void>
}

export const getElementIO =
  <T extends ET.ElementTree = ET.ElementTree>(editor: Editor) =>
  (element: T): IO.IO<ElementIO<T>> =>
    IO.of({
      onChange: (element: T) => editor.updateElement(element),
      onRemove: () => editor.removeElement(element.id as UUID),
      onMove: (to) => {
        return editor.moveElement(element.id as UUID, to)
      }
    })

export const useElementIO = <T extends ET.ElementTree = ET.ElementTree>(
  element: T
) => {
  const { editor } = useEditorContext()

  return useMemo(() => pipe(element, getElementIO(editor))(), [editor, element])
}

// export const useElementIO = <T extends ET.ElementTree = ET.ElementTree>(
//   element: T
// ) => useEditorSelector((editor) => pipe(element, getElementIO(editor)))
