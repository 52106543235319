import React, { ReactElement } from 'react'
import { Modal, ModalDialog, ModalDisclosure } from '@woorcs/design-system'

import { ManageLanguagesForm } from '../ManageLanguagesForm'

type ManageLanguagesModalProps = {
  children: ReactElement
}

export const ManageLanguagesModal = ({
  children
}: ManageLanguagesModalProps) => (
  <Modal>
    <ModalDisclosure>{children}</ModalDisclosure>
    <ModalDialog width={540} px={5} py={8}>
      <ManageLanguagesForm />
    </ModalDialog>
  </Modal>
)
