import {
  createOrganizationExchange,
  OrganizationExchangeProps
} from './exchanges'
import { graphqlClient, GraphqlClientProps } from './client'

export interface TenantGraphqlClientProps
  extends GraphqlClientProps,
    Omit<OrganizationExchangeProps, 'onUnauthorizedError'> {
  onUnauthorizedOrganizationError?(error: Error): void
}

export const tenantGraphqlClient = ({
  organizationId,
  exchanges = [],
  onUnauthorizedOrganizationError,
  ...other
}: TenantGraphqlClientProps) => {
  const userOrganizationExchange = createOrganizationExchange({
    organizationId,
    onUnauthorizedError: (error) => {
      //if (__DEV__) {
      //  console.log('organization error', error)
      //}

      onUnauthorizedOrganizationError?.(error)
    }
  })

  return graphqlClient({
    ...other,
    exchanges: [...exchanges, userOrganizationExchange]
  })
}
