import { Children, ReactElement, useCallback, useState } from 'react'
import {
  Box,
  Flex,
  ListItemField,
  ListItemTitle,
  PlainButton,
  Popover,
  PopoverContent,
  PopoverDisclosure,
  SelectableListItem,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Text
} from '@woorcs/design-system'
import { pipe } from 'fp-ts/function'
import { FormElement } from '@woorcs/form'
import { Path } from '@woorcs/types/Path'
import { UUID } from '@woorcs/types/UUID'
import { useResponseSets } from '@woorcs/form/react'

import {
  createElementType,
  formElementIcon,
  formElementLabel
} from '../../element'
import { useEditorContext } from '../../state'

type AddElementMenuSectionProps = {
  label?: string
  children: ReactElement | ReactElement[]
}

const AddElementMenuSection = ({
  label,
  children
}: AddElementMenuSectionProps) => (
  <Box mb={2}>
    {label && (
      <Box mb={1} px={4}>
        <Text fontWeight='bold' fontSize='small'>
          {label}
        </Text>
      </Box>
    )}

    <Flex flexWrap='wrap' px={3}>
      {Children.map(children, (child) => (
        <Box width='50%' px={1} mb={2}>
          {child}
        </Box>
      ))}
    </Flex>
  </Box>
)

type AddElementMenuItemProps = {
  type: FormElement.FormElementTypeName
  onAdd(type: FormElement.FormElementTypeName): void
}

const AddElementMenuItem = ({ type, onAdd }: AddElementMenuItemProps) => {
  const Icon = formElementIcon(type)
  const label = formElementLabel(type)

  const handleClick = useCallback(() => {
    onAdd(type)
  }, [onAdd, type])

  return (
    <SelectableListItem as={PlainButton} width={'100%'} onClick={handleClick}>
      <ListItemField ml={0}>
        <Icon color='text.base' />
      </ListItemField>
      <ListItemTitle pr={4}>{label}</ListItemTitle>
    </SelectableListItem>
  )
}

interface InputElementsProps {
  onAdd(type: FormElement.FormElementTypeName): void
}

const InputElements = ({ onAdd }: InputElementsProps) => {
  return (
    <Box>
      <AddElementMenuSection>
        <AddElementMenuItem type='TextInput' onAdd={onAdd} />
        <AddElementMenuItem type='NumberInput' onAdd={onAdd} />
        <AddElementMenuItem type='EmailInput' onAdd={onAdd} />
      </AddElementMenuSection>
      <AddElementMenuSection label='Choices'>
        <AddElementMenuItem type='SelectInput' onAdd={onAdd} />
        <AddElementMenuItem type='MultiSelectInput' onAdd={onAdd} />
      </AddElementMenuSection>
      <AddElementMenuSection label='Media'>
        <AddElementMenuItem type='ImageInput' onAdd={onAdd} />
        <AddElementMenuItem type='SignatureInput' onAdd={onAdd} />
      </AddElementMenuSection>
      <AddElementMenuSection label='Time'>
        <AddElementMenuItem type='DateInput' onAdd={onAdd} />
        <AddElementMenuItem type='DateRangeInput' onAdd={onAdd} />
        <AddElementMenuItem type='TimeInput' onAdd={onAdd} />
      </AddElementMenuSection>

      <AddElementMenuSection label='Collections'>
        <AddElementMenuItem type='GroupInput' onAdd={onAdd} />
      </AddElementMenuSection>
    </Box>
  )
}

interface StaticElementsProps {
  onAdd(type: FormElement.FormElementTypeName): void
}

const StaticElements = ({ onAdd }: StaticElementsProps) => {
  return (
    <AddElementMenuSection>
      <AddElementMenuItem type='Alert' onAdd={onAdd} />
    </AddElementMenuSection>
  )
}

type AddElementMenuProps = {
  at: Path
  children: ReactElement
}

export const AddElementMenu = ({ at, children }: AddElementMenuProps) => {
  const { editor } = useEditorContext()
  const [visible, setVisible] = useState(false)
  const responseSets = useResponseSets()

  const handleAddElement = useCallback(
    (type: FormElement.FormElementTypeName) => {
      const addElement = pipe(
        createElementType(type, Object.keys(responseSets)[0] as UUID),
        (element) => editor.addElement(element, at)
      )

      addElement()
      setVisible(false)
    },
    [at, editor, responseSets]
  )

  return (
    <Popover placement='top' open={visible} setOpen={setVisible}>
      <PopoverDisclosure>{children}</PopoverDisclosure>

      <PopoverContent aria-label='Add element'>
        <Box style={{ overflowY: 'scroll' }} width={360} height={320} pb={4}>
          <Tabs>
            <Box pt={2}>
              <TabList
                display='flex'
                flexDirection='row'
                justifyContent='center'
                borderTopLeftRadius='large'
                borderTopRightRadius='large'
                mb={4}
              >
                <Tab>Form inputs</Tab>
                <Tab mr={0}>Presentation</Tab>
              </TabList>
            </Box>
            <TabPanel>
              <InputElements onAdd={handleAddElement} />
            </TabPanel>

            <TabPanel>
              <StaticElements onAdd={handleAddElement} />
            </TabPanel>
          </Tabs>
        </Box>
      </PopoverContent>
    </Popover>
  )
}
