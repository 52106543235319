import { forwardRef, Children, cloneElement, ReactElement } from 'react'

import { system, PropsOf } from '../../../system'

interface FormControlOptions {
  row?: boolean
}

export interface FormControlGroupProps
  extends Omit<PropsOf<typeof system.div>, 'children'>,
    FormControlOptions {
  children: ReactElement[]
}

const Container = system('div')<FormControlOptions>(
  {
    display: 'flex',
    flexWrap: 'wrap'
  },
  ({ row }: FormControlOptions) =>
    ({
      flexDirection: row ? 'row' : 'column'
    }) as const
)

export const FormControlGroup = forwardRef<
  HTMLDivElement,
  FormControlGroupProps
>(({ children, row = false, ...other }, ref) => {
  const numChilds = Children.count(children)
  const isLast = (idx: number) => idx === numChilds - 1

  return (
    <Container ref={ref} row={row} {...other}>
      {/* TODO: Handle item margins in a better way, as this won't allow custom margins on individual items */}
      {Children.map(children as ReactElement[], (child, index) =>
        isLast(index)
          ? child
          : cloneElement(child, {
              mb: row ? 0 : 3,
              mr: row ? 4 : 0
            })
      )}
    </Container>
  )
})
