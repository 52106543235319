import React from 'react'
import { Badge } from '@woorcs/design-system'

import { FormRevisionBadgeFormRevisionFragment } from './__generated__/FormRevisionBadge'

interface FormRevisionBadgeProps {
  revision: FormRevisionBadgeFormRevisionFragment
}

export const FormRevisionBadge = ({ revision }: FormRevisionBadgeProps) => {
  if (revision.status === 'DRAFT') {
    return <Badge variant='warning'>Draft</Badge>
  }

  if (revision.status === 'ARCHIVED') {
    return <Badge variant='danger'>Archived</Badge>
  }

  return <Badge>Active</Badge>
}
