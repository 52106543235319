import { useCallback } from 'react'
import * as O from 'fp-ts/Option'

import { CalendarDate } from './useCalendarState'
import { useCalendarContext } from './context'
import * as DateRangeTuple from './DateRangeTuple'

export const useCalendar = () => {
  return useCalendarContext()
}

export const useCalendarDay = (day: CalendarDate) => {
  const {
    getDateProps,
    setHoveredDate,
    currentDate,
    range,
    isInRange: getIsInRange
  } = useCalendar()

  const handleMouseLeave = useCallback(() => {
    if (!range) {
      return
    }

    setHoveredDate(O.none)
  }, [range, setHoveredDate])

  const handleMouseEnter = useCallback(() => {
    if (!range) {
      return
    }

    const [start] = currentDate as DateRangeTuple.DateRangeTuple

    if (O.isNone(start)) {
      return
    }

    setHoveredDate(O.some(day.date))
  }, [currentDate, day.date, range, setHoveredDate])

  const getButtonProps = useCallback(
    () => ({
      onMouseLeave: handleMouseLeave,
      onMouseEnter: handleMouseEnter,
      ...getDateProps(day)
    }),
    [day, getDateProps, handleMouseEnter, handleMouseLeave]
  )

  const isInRange = getIsInRange(day.date)

  return {
    getButtonProps,
    isInRange
  }
}
