import { forwardRef } from 'react'
import { Box, PropsOf, system } from '@woorcs/design-system'
import * as Ariakit from '@ariakit/react'
import { AnimatePresence } from 'framer-motion'

export type FullscreenModalDialogProps = PropsOf<typeof system.div>

const Dialog = forwardRef<HTMLDivElement, FullscreenModalDialogProps>(
  (props, ref) => (
    <Box
      ref={ref}
      __css={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        perspective: '800px',
        transition: 'opacity 250ms ease-in-out, transform 250ms ease-in-out',
        opacity: 0,
        transformOrigin: 'top center',
        transform: 'translate3d(0, 2.5%, 0)',
        '&[data-enter]': {
          opacity: 1,
          transform: 'translate3d(0, 0, 0)'
        },
        zIndex: '33333',
        width: '100vw',
        height: '100vh'
      }}
      bg='white'
      {...props}
    />
  )
)

export const FullScreenModalDialog = ({
  children,
  ...other
}: FullscreenModalDialogProps) => {
  const store = Ariakit.useDialogContext()

  if (!store) {
    throw new Error(
      'No modal context found. Make sure you are using a ModalProvider.'
    )
  }

  const mounted = store.useState('mounted')

  return (
    <AnimatePresence>
      {mounted && (
        <Ariakit.Dialog render={<Dialog {...other} />}>
          {children}
        </Ariakit.Dialog>
      )}
    </AnimatePresence>
  )
}
