import { ReactElement, cloneElement } from 'react'
import * as Ariakit from '@ariakit/react'

export interface ModalDisclosureProps {
  children: ReactElement
}

export const ModalDisclosure = ({
  children,
  ...props
}: ModalDisclosureProps) => {
  return (
    <Ariakit.DialogDisclosure
      render={(disclosureProps) => cloneElement(children, disclosureProps)}
      {...props}
    />
  )
}
