import { createContext } from '@woorcs/utils'
import { useMemo, useReducer } from 'react'

import { SnackbarAction, SnackbarVariant } from '../../components'

type SnackbarOptions = {
  duration?: number
  title: string
  variant?: SnackbarVariant
  action?: SnackbarAction
}

type SnackbarInstance = SnackbarOptions & {
  key: string
}

type SnackbarProviderState = {
  current: [config: SnackbarInstance, visible: boolean] | null
  queue: SnackbarInstance[]
}

export enum ActionType {
  PUSH_SNACKBAR = 'PUSH_SNACKBAR',
  HIDE_CURRENT = 'HIDE_CURRENT',
  DID_HIDE_CURRENT = 'DID_HIDE_CURRENT'
}

type SnackbarProviderAction =
  | [type: ActionType.PUSH_SNACKBAR, snackbar: SnackbarInstance]
  | [type: ActionType.HIDE_CURRENT]
  | [type: ActionType.DID_HIDE_CURRENT]

const initialState = { current: null, queue: [] }

const reducer = (
  state: SnackbarProviderState = initialState,
  action: SnackbarProviderAction
): SnackbarProviderState => {
  switch (action[0]) {
    case ActionType.PUSH_SNACKBAR: {
      const snackbar = action[1]

      if (state.current) {
        return {
          ...state,
          queue: [...state.queue, snackbar]
        }
      }

      return {
        ...state,
        current: [snackbar, true]
      }
    }

    case ActionType.HIDE_CURRENT: {
      if (!state.current) {
        return state
      }

      return {
        ...state,
        current: [state.current[0], false]
      }
    }

    case ActionType.DID_HIDE_CURRENT: {
      const next = state.queue[0]

      if (next) {
        return {
          queue: state.queue.slice(1),
          current: [next, true]
        }
      }

      return {
        ...state,
        current: null
      }
    }
  }
}

export const useSnackbarProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const operations = useMemo(
    () => ({
      showSnackbar(options: SnackbarOptions) {
        dispatch([
          ActionType.PUSH_SNACKBAR,
          {
            ...options,
            key: Date.now().toString()
          }
        ])
      },
      hideCurrent() {
        dispatch([ActionType.HIDE_CURRENT])
      },
      didHideCurrent() {
        dispatch([ActionType.DID_HIDE_CURRENT])
      }
    }),
    []
  )

  return [state, operations] as const
}

export type SnackbarContext = ReturnType<typeof useSnackbarProvider>[1]

export const [SnackbarContextProvider, useSnackbarContext] =
  createContext<SnackbarContext>()
