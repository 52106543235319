import {
  Box,
  Button,
  CloseIcon,
  Flex,
  FormField,
  FormFieldLabel,
  IconButton,
  Input
} from '@woorcs/design-system'
import { ResponseSet } from '@woorcs/form'
import { constant, pipe } from 'fp-ts/function'
import { ChangeEvent, useCallback } from 'react'

type ResponseSetOptionFormProps = {
  option: ResponseSet.ResponseSetOption
  canRemove: boolean
  onChange(option: ResponseSet.ResponseSetOption): void
  onRemove(option: ResponseSet.ResponseSetOption): void
}

const ResponseSetOptionForm = ({
  option,
  canRemove,
  onChange,
  onRemove
}: ResponseSetOptionFormProps) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      pipe(option, ResponseSet.updateLabel(e.target.value), onChange)
    },
    [onChange, option]
  )

  const handleRemove = useCallback(() => {
    onRemove(option)
  }, [onRemove, option])

  return (
    <Flex mb={4} alignItems='center'>
      <Input value={option.label.text} mr={4} onChange={handleChange} />
      <IconButton
        size='mini'
        disabled={!canRemove}
        opacity={canRemove ? 1 : 0}
        onClick={handleRemove}
      >
        <CloseIcon />
      </IconButton>
    </Flex>
  )
}

type ResponseSetFormProps = {
  responseSet: ResponseSet.ResponseSet
  onChange(responseSet: ResponseSet.ResponseSet): void
}

export const ResponseSetForm = ({
  responseSet,
  onChange
}: ResponseSetFormProps) => {
  const handleOptionChange = useCallback(
    (option: ResponseSet.ResponseSetOption) => {
      pipe(
        responseSet,
        ResponseSet.updateOption(option.id, constant(option)),
        onChange
      )
    },
    [onChange, responseSet]
  )

  const handleAddOption = useCallback(() => {
    pipe(
      responseSet,
      ResponseSet.addOption(ResponseSet.responseSetOption('')),
      onChange
    )
  }, [onChange, responseSet])

  const handleRemoveOption = useCallback(
    (option: ResponseSet.ResponseSetOption) => {
      pipe(responseSet, ResponseSet.removeOption(option.id), onChange)
    },
    [onChange, responseSet]
  )

  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      pipe(responseSet, ResponseSet.setName(value), onChange)
    },
    [onChange, responseSet]
  )

  return (
    <Box>
      <FormField label='Name' mb={6}>
        <Input value={responseSet.name} onChange={handleNameChange} />
      </FormField>

      <FormFieldLabel mb={4}>Response options</FormFieldLabel>
      <Box mb={4}>
        {responseSet.options.map((option) => (
          <ResponseSetOptionForm
            key={option.id}
            option={option}
            canRemove={responseSet.options.length > 1}
            onChange={handleOptionChange}
            onRemove={handleRemoveOption}
          />
        ))}
      </Box>

      <Button colorVariant='secondary' size='small' onClick={handleAddOption}>
        Add response
      </Button>
    </Box>
  )
}
