import * as O from 'fp-ts/Option'
import * as IO from 'fp-ts/IO'
import { constVoid, pipe } from 'fp-ts/function'
import * as Path from '@woorcs/types/Path'
import { UUID } from '@woorcs/types/UUID'
import * as ET from '@woorcs/types/ElementTree'
import { useMemo } from 'react'

import { Editor } from '../state'

import { getElementIO, ElementIO } from './useElementIO'
import { useEditorSelector } from './useEditorSelector'
import { getElementPath } from './useElementPath'
import { useEditorContext } from './useEditorContext'

interface ParentElementIO<T extends ET.ParentElement> extends ElementIO<T> {
  // addChild: (
  //   element: ET.ElementTree,
  //   atIndex: number
  // ) => IO.IO<Either<EditorError, void>>
  addChild: <T extends ET.ElementTree>(
    element: T,
    atIndex: number
  ) => IO.IO<void>
  moveChild: <T extends ET.ElementTree>(
    element: T,
    toIndex: number
  ) => IO.IO<void>
}

export const getParentElementIO =
  <T extends ET.ParentElement>(editor: Editor) =>
  (element: T) =>
    pipe(
      element,
      getElementIO(editor),
      IO.map(
        (elementIO): ParentElementIO<T> => ({
          ...elementIO,
          addChild: (child: ET.ElementTree, atIndex: number) =>
            pipe(
              editor,
              getElementPath(element),
              IO.chain((path) =>
                pipe(
                  path,
                  O.fold(IO.of(constVoid), (path) =>
                    editor.addElement(child, pipe(path, Path.append(atIndex)))
                  )
                )
              )
            ),
          moveChild: (child: ET.ElementTree, toIndex: number) =>
            pipe(
              editor,
              getElementPath(element),
              IO.chain((path) =>
                pipe(
                  path,
                  O.fold(IO.of(constVoid), (path) =>
                    editor.moveElement(
                      child.id as UUID,
                      pipe(path, Path.append(toIndex))
                    )
                  )
                )
              )
            )
        })
      )
    )

// export const useParentElementIO = <
//   T extends ET.ParentElement = ET.ParentElement
// >(
//   element: T
// ) => useEditorSelector((editor) => pipe(element, getParentElementIO(editor)))

export const useParentElementIO = <
  T extends ET.ParentElement = ET.ParentElement
>(
  element: T
) => {
  const { editor } = useEditorContext()

  return useMemo(
    () => pipe(element, getParentElementIO(editor))(),
    [editor, element]
  )
}
