import { ReactElement, useRef, useEffect, useState, useCallback } from 'react'
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuItem,
  MenuSeparator,
  NAV_SIDEBAR_WIDTH
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import * as Ariakit from '@ariakit/react'

import { useAuth } from '@app/auth'

import { LanguageMenu } from '../LanguageMenu'

type UserMenuProps = {
  children: ReactElement
}

export const UserMenu = ({ children }: UserMenuProps) => {
  const [, { logout }] = useAuth()
  const { t } = useTranslation(['common'])
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const settingsRef = useRef<HTMLButtonElement | null>(null)

  const handleSettingsClick = useCallback(() => {
    navigate('/settings/user/profile', {
      state: {
        backgroundLocation: location
      }
    })
  }, [location, navigate])

  useEffect(() => {
    if (visible && settingsRef.current) {
      settingsRef.current.focus()
    }
  }, [visible])

  return (
    <Menu open={visible} setOpen={setVisible}>
      <MenuButton>{children}</MenuButton>

      <MenuContent width={NAV_SIDEBAR_WIDTH - 28} aria-label='User menu'>
        <MenuItem ref={settingsRef} onClick={handleSettingsClick}>
          {t('common:settings')}
        </MenuItem>

        <Ariakit.MenuItem render={<LanguageMenu />} />

        <MenuSeparator />

        <MenuItem
          destructive
          onClick={() =>
            logout({
              returnTo: window.location.origin
            })
          }
        >
          {t('common:logout')}
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}
