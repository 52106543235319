export const baseFontSize = 16
export const baseLineHeight = 24
export const baseLineHeightRatio = baseLineHeight / baseFontSize

const MAJOR_THIRD = 1.125

export const typeScale = (position: number) =>
  `${Math.pow(MAJOR_THIRD, position)}rem`

export const fontSizes = {
  body: typeScale(0),
  smallBody: typeScale(-1),
  largeBody: typeScale(1),
  subtitle: typeScale(1),
  subtitle2: typeScale(-1),
  caption: typeScale(-2),
  display: typeScale(16),
  h1: typeScale(11),
  h2: typeScale(10),
  h3: typeScale(7),
  h4: typeScale(4),
  h5: typeScale(3),
  overline: typeScale(-4),

  mini: typeScale(-2),
  small: typeScale(-1),
  base: typeScale(0),
  medium: typeScale(3),
  large: typeScale(4),
  'x-large': typeScale(7),
  huge: typeScale(10)
}

export const fonts = {
  display: `"Nunito sans", sans-serif`,
  body: `"Nunito sans", sans-serif`
}

export const fontWeights = {
  'extra-bold': 800,
  bold: 700,
  'semi-bold': 600,
  medium: 500,
  regular: 400,
  light: 300,
  hairline: 100
}

export type FontWeight = typeof fontWeights

export const letterSpacings = {
  body: 0.5,
  smallBody: 0.25,
  largeBody: 0.5,
  subtitle: 0.15,
  subtitle2: 0.1,
  caption: 0.4,
  overline: 1.5,
  h1: -0.5,
  h2: 0,
  h3: 0.25,
  h4: 0,
  h5: 0.15
}

export const lineHeights = {
  base: baseLineHeightRatio,
  solid: 1,
  loose: 1.75,

  body: baseLineHeightRatio
  // body2: 0.25,
  // subtitle: 0.15,
  // subtitle2: 0.1,
  // caption: 0.4,
  // h1: -0.5,
  // h2: 0,
  // h3: 0.25,
  // h4: 0,
  // h5: 0.15
}
