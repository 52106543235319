import { GraphqlProvider } from './graphql'
import { AdminRouter } from './router'
import { ViewerProvider } from './graphql/ViewerProvider'

interface AdminAppProps {
  graphqlUrl: string
  basename: string
}

export const AdminApp = ({ graphqlUrl, basename }: AdminAppProps) => {
  return (
    <GraphqlProvider url={graphqlUrl}>
      <ViewerProvider>
        <AdminRouter basename={basename} />
      </ViewerProvider>
    </GraphqlProvider>
  )
}
