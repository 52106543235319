import { ReactElement } from 'react'
import { InspectionFormSubmissionRenderer } from '@woorcs/inspection-form'
import { Box, Text } from '@woorcs/design-system'
import { FormDocument } from '@woorcs/form'

import {
  Header,
  Container,
  Page,
  RenderField,
  renderInputElementResponse
} from './Sections'
import { ReportModalSubmissionRevisionFragment } from './__generated__/ReportModal'

type ReportModalProps = {
  submissionRevision: ReportModalSubmissionRevisionFragment
}

export const Report = ({ submissionRevision }: ReportModalProps) => {
  const formDefinition = submissionRevision.parent.formRevision.definition

  return InspectionFormSubmissionRenderer.getInspectionFormSubmissionRenderer<ReactElement>(
    {
      locale: formDefinition.i18n.defaultLocale,
      definition: formDefinition,
      data: submissionRevision.submissionData,
      renderer: {
        renderRoot: (pages) => (
          <Container>
            <Header
              title={FormDocument.getDefaultTitle(formDefinition)}
              subtitle=''
            />
            {pages}
          </Container>
        ),
        renderDocument: (_, children) => <Box>{children}</Box>,
        renderPage: (page, title, children) => (
          <Page key={page.key} title={title}>
            {children}
          </Page>
        ),
        renderInputElement: (element, label, value) => (
          <RenderField key={element.id} label={label} isLast={false}>
            {renderInputElementResponse(element, value)}
          </RenderField>
        ),
        renderLayoutElement: (_, children) => <Box>{children}</Box>,
        renderGroupInputElement: (element, children) => (
          <Box key={element.id} p={2}>
            <Box bg='greyLighter' borderRadius='m' p={2}>
              <Text variant='subtitle2'>{element.label.text}</Text>
              <Box>{children}</Box>
            </Box>
          </Box>
        )
      }
    }
  )
}
