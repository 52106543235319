import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type UserListUserListPageFragment = { __typename: 'UserListPage', data: Array<{ __typename: 'User', id: string, name: string, firstName: string | null, lastName: string | null, status: SchemaTypes.UserStatus, role: SchemaTypes.Role | null, tags: Array<{ __typename: 'Tag', id: string, name: string, color: string | null }> }>, pageInfo: { __typename: 'PageInfo', totalCount: number, offset: number, limit: number, hasNextPage: boolean, hasPreviousPage: boolean } };

export type UserTagFragment = { __typename: 'Tag', id: string, name: string, color: string | null };

export type UserListUserFragment = { __typename: 'User', id: string, name: string, firstName: string | null, lastName: string | null, status: SchemaTypes.UserStatus, role: SchemaTypes.Role | null, tags: Array<{ __typename: 'Tag', id: string, name: string, color: string | null }> };

export const UserTagFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserTagFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Tag"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"color"}}]}}]} as unknown as DocumentNode<UserTagFragment, unknown>;
export const UserListUserFragmentDoc = {"kind":"Document", "definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserListUserFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"role"}},{"kind":"Field","name":{"kind":"Name","value":"tags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserTagFragment"}}]}}]}},...UserTagFragmentDoc.definitions]} as unknown as DocumentNode<UserListUserFragment, unknown>;
export const UserListUserListPageFragmentDoc = {"kind":"Document", "definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserListUserListPageFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserListPage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserListUserFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pageInfo"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalCount"}},{"kind":"Field","name":{"kind":"Name","value":"offset"}},{"kind":"Field","name":{"kind":"Name","value":"limit"}},{"kind":"Field","name":{"kind":"Name","value":"hasNextPage"}},{"kind":"Field","name":{"kind":"Name","value":"hasPreviousPage"}}]}}]}},...UserListUserFragmentDoc.definitions]} as unknown as DocumentNode<UserListUserListPageFragment, unknown>;