import React, { ChangeEvent, FocusEvent, forwardRef } from 'react'
import { animated, useSpring } from 'react-spring'
import { rem, whenTrue } from '@woorcs/utils'

import { useCheckbox } from '../Checkbox'
import { focusStyle } from '../../../styles'
import {
  ToggleBase,
  ToggleBaseProps,
  ToggleBaseControlProps
} from '../ToggleBase'
import { system, css } from '../../../system'

const HEIGHT = 24
const WIDTH = 48
const HANDLE_SIZE = 16
const PADDING = 4

const isChecked = whenTrue(['checked'])
const isDisabled = whenTrue(['disabled'])
const isFocused = whenTrue(['focused'])
const isCheckedAndDisabled = whenTrue(['checked', 'disabled'])

const SwitchControl = system('div')<ToggleBaseControlProps>(
  css({
    position: 'relative',
    appearance: 'none',
    width: rem(WIDTH),
    height: rem(HEIGHT),
    borderRadius: rem(24),
    borderColor: 'transparent',
    backgroundColor: 'primary.100',
    borderWidth: 0,
    borderStyle: 'solid',
    boxShadow: 'none',
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'primary.200'
    }
  }),
  isChecked({
    backgroundColor: 'primary.500',
    border: 'none',
    boxShadow: 'input',
    ':hover': {
      backgroundColor: 'primary.500'
    }
  }),
  isDisabled({
    backgroundColor: 'primary.50',
    boxShadow: 'none',
    borderColor: 'primary.50',
    cursor: 'not-allowed',
    ':hover': {
      borderColor: 'primary.50',
      backgroundColor: 'white'
    }
  }),
  isCheckedAndDisabled({
    backgroundColor: 'primary.100',
    borderColor: 'transparent',
    ':hover': {
      backgroundColor: 'primary.50'
    }
  }),
  isFocused(focusStyle)
)

const Handle = system(animated.div)({
  position: 'absolute',
  top: rem(HEIGHT / 2 - HANDLE_SIZE / 2),
  left: rem(4),
  width: rem(HANDLE_SIZE),
  height: rem(HANDLE_SIZE),
  borderRadius: '100%',
  backgroundColor: 'white',
  boxShadow: 'input',
  willChange: 'transform'
})

interface SwitchProps extends Omit<ToggleBaseProps, 'controlAs' | 'children'> {
  name?: string
  value?: string | number
  checked?: boolean
  disabled?: boolean
  readOnly?: boolean
  onChange?(event: ChangeEvent<HTMLInputElement>): void
  onBlur?(event: FocusEvent<HTMLInputElement>): void
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    { name, value, checked, disabled, readOnly, onChange, onBlur, ...other },
    ref
  ) => {
    const [state, inputProps] = useCheckbox({
      ref,
      name,
      value,
      checked,
      disabled,
      readOnly,
      onChange,
      onBlur
    })
    const handleStyle = useSpring({
      transform: state.checked
        ? `translate3d(${WIDTH - (HANDLE_SIZE + PADDING * 2)}px, 0, 0)`
        : 'translate3d(0px, 0, 0)'
    })

    return (
      <ToggleBase {...other} {...inputProps}>
        <SwitchControl>
          <Handle style={handleStyle} />
        </SwitchControl>
      </ToggleBase>
    )
  }
)
