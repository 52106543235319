import { variant } from 'styled-system'
import { space } from '@woorcs/utils'

import { system } from '../../../system'

export const sizeVariants = variant({
  prop: 'size',
  variants: {
    mini: {
      mx: space(2)
    },
    small: {
      mx: space(1)
    },
    medium: {
      mx: space(2)
    }
  }
})

export const ButtonLabel = system('span')(sizeVariants)
