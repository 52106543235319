import { useState, useCallback, ReactElement, MouseEvent } from 'react'
import {
  Modal,
  ModalDialog,
  Calendar,
  SecondaryButton,
  ModalBody,
  DateRangeTuple,
  Menu,
  MenuButton,
  MenuContent,
  MenuOptionGroup,
  MenuOption,
  ModalFooter,
  PrimaryButton,
  ModalDismiss
} from '@woorcs/design-system'
import { setHours, setYear, setMonth } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { MenuStoreProps } from '@ariakit/react'

enum DateRanges {
  NONE,
  TODAY,
  PAST_WEEK,
  PAST_MONTH,
  PAST_YEAR,
  CUSTOM
}

interface DateRangePickerModalProps {
  value: DateRangeTuple.DateRangeTuple
  visible: boolean
  onChange(value: DateRangeTuple.DateRangeTuple): void
  onVisiblityChange(visible: boolean): void
  onSubmit(): void
}

const DateRangePickerModal = ({
  value,
  visible,
  onChange,
  onVisiblityChange,
  onSubmit
}: DateRangePickerModalProps) => (
  <Modal open={visible} setOpen={onVisiblityChange}>
    <ModalDialog aria-label='Pick a date' showCloseButton={false}>
      <ModalBody>
        <Calendar value={value} monthsToDisplay={2} range onChange={onChange} />
      </ModalBody>
      <ModalFooter>
        <ModalDismiss render={<SecondaryButton mr={2} />}>Avbryt</ModalDismiss>
        <PrimaryButton onClick={onSubmit}>Filtrera</PrimaryButton>
      </ModalFooter>
    </ModalDialog>
  </Modal>
)

const dateToUTC = (date: Date) => {
  date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000)

  return date
}

const rangeTypeToDateRange = (
  rangeType: DateRanges,
  customRange: DateRangeTuple.DateRangeTuple
): DateRangeTuple.DateRangeTuple => {
  const now = dateToUTC(new Date(Date.now()))

  switch (rangeType) {
    case DateRanges.TODAY: {
      const start = dateToUTC(setHours(now, 0))

      return DateRangeTuple.fromDates(start, now)
    }

    case DateRanges.PAST_WEEK: {
      const start = dateToUTC(
        new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)
      )

      return DateRangeTuple.fromDates(start, now)
    }

    case DateRanges.PAST_MONTH: {
      const start = dateToUTC(new Date(setMonth(now, now.getMonth() - 1)))

      return DateRangeTuple.fromDates(start, now)
    }

    case DateRanges.PAST_YEAR: {
      const start = dateToUTC(new Date(setYear(now, now.getFullYear() - 1)))

      return DateRangeTuple.fromDates(start, now)
    }

    case DateRanges.CUSTOM: {
      const dates = customRange.map((o) =>
        pipe(
          o,
          O.map((date) => dateToUTC(date))
        )
      )

      return DateRangeTuple.dateRangeTuple(dates[0], dates[1])
    }

    // case DateRanges.NONE:
    default: {
      return DateRangeTuple.empty
    }
  }
}

interface DateRangeFilterMenuProps {
  value?: DateRangeTuple.DateRangeTuple
  placement?: MenuStoreProps['placement']
  onChange(value: DateRangeTuple.DateRangeTuple): void
  children: ReactElement
}

export const DateRangeFilterMenu = ({
  value = DateRangeTuple.empty,
  onChange,
  placement,
  children
}: DateRangeFilterMenuProps) => {
  const { t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [rangeType, setRangeType] = useState(
    DateRangeTuple.isEmpty(value) ? DateRanges.NONE : DateRanges.CUSTOM
  )
  const [customRange, setCustomRange] =
    useState<DateRangeTuple.DateRangeTuple>(value)

  const handleRangeTypeChange = useCallback(
    (rangeType: DateRanges) => {
      const range = rangeTypeToDateRange(rangeType, customRange)

      setRangeType(rangeType)
      onChange(range)
    },
    [customRange, onChange]
  )

  const handleCustomRangeChange = useCallback(
    (range: DateRangeTuple.DateRangeTuple) => {
      setCustomRange(range)

      if (rangeType === DateRanges.CUSTOM) {
        onChange(range)
      }
    },
    [onChange, rangeType]
  )

  const getLabel = (range: DateRanges) => {
    switch (range) {
      case DateRanges.NONE: {
        return t('dateRangeFilter.label.none')
      }

      case DateRanges.TODAY: {
        return t('dateRangeFilter.label.today')
      }

      case DateRanges.PAST_WEEK: {
        return t('dateRangeFilter.label.pastWeek')
      }

      case DateRanges.PAST_MONTH: {
        return t('dateRangeFilter.label.pastMonth')
      }

      case DateRanges.PAST_YEAR: {
        return t('dateRangeFilter.label.pastYear')
      }

      case DateRanges.CUSTOM: {
        const startLabel = DateRangeTuple.startToLocaleDateString(customRange)
        const endLabel = DateRangeTuple.endToLocaleDateString(customRange)

        return t('dateRangeFilter.label.custom', {
          start: startLabel,
          end: endLabel
        })
      }
    }
  }

  const handleCustomRangeClick = useCallback((e: MouseEvent) => {
    setShowDatePicker(true)

    e.preventDefault()
  }, [])

  const handleSetCustomRange = useCallback(() => {
    if (rangeType !== DateRanges.CUSTOM) {
      handleRangeTypeChange(DateRanges.CUSTOM)
    }

    setShowDatePicker(false)
  }, [handleRangeTypeChange, rangeType])

  return (
    <>
      <DateRangePickerModal
        value={rangeType === DateRanges.CUSTOM ? value : customRange}
        visible={showDatePicker}
        onChange={handleCustomRangeChange}
        onVisiblityChange={setShowDatePicker}
        onSubmit={handleSetCustomRange}
      />

      <Menu open={showMenu} placement={placement} setOpen={setShowMenu}>
        <MenuButton>{children}</MenuButton>
        <MenuContent>
          <MenuOptionGroup
            name='range'
            type='radio'
            value={rangeType}
            onChange={handleRangeTypeChange}
          >
            {/* <MenuOption value={DateRanges.NONE}>
              {getLabel(DateRanges.NONE)}
            </MenuOption> */}
            <MenuOption value={DateRanges.TODAY} hideOnClick>
              {getLabel(DateRanges.TODAY)}
            </MenuOption>
            <MenuOption value={DateRanges.PAST_WEEK} hideOnClick>
              {getLabel(DateRanges.PAST_WEEK)}
            </MenuOption>
            <MenuOption value={DateRanges.PAST_MONTH} hideOnClick>
              {getLabel(DateRanges.PAST_MONTH)}
            </MenuOption>
            <MenuOption value={DateRanges.PAST_YEAR} hideOnClick>
              {getLabel(DateRanges.PAST_YEAR)}
            </MenuOption>
            <MenuOption
              value={DateRanges.CUSTOM}
              hideOnClick
              onClick={handleCustomRangeClick}
            >
              {t('dateRangeFilter.selectCustomRange')}
            </MenuOption>
          </MenuOptionGroup>
        </MenuContent>
      </Menu>
    </>
  )
}
