import { forwardRef, useMemo } from 'react'
import { rem } from '@woorcs/utils'
import { motion } from 'framer-motion'

import { Surface } from '../../layout'
import { PropsOf, system, css } from '../../../system'
import { focusStyle } from '../../../styles'

const transition = {
  duration: 0.25,
  ease: [0.48, 0.15, 0.25, 0.96]
}

export type DialogSidebarContainerProps = PropsOf<typeof system.div>

export const DialogSidebarContainer = forwardRef<
  typeof system.div,
  DialogSidebarContainerProps
>((props, ref) => {
  const animations = useMemo(
    () => ({
      initial: { x: '100%' },
      animate: { x: '0%', transition },
      exit: { x: '100%', transition }
    }),
    []
  )

  return (
    <motion.div
      css={css({
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 'modal',
        willChange: 'transform',
        transformOrigin: 'left center'
      })}
      initial={animations.initial}
      animate={animations.animate}
      exit={animations.exit}
    >
      <Surface
        ref={ref}
        __css={css({
          width: rem(540),
          height: '100vh',
          ':focus': focusStyle
        })}
        elevation={3}
        borderRadius='none'
        {...props}
      />
    </motion.div>
  )
})
