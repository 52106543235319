import * as R from 'fp-ts/Reader'
import { pipe } from 'fp-ts/function'
import { FormEnvironment } from '@woorcs/form'

import { useInspectionFormContext } from '../context'

export type InspectionFormProgram<A> = R.Reader<FormEnvironment, A>

export const useInspectionFormProgram = <A>(
  program: InspectionFormProgram<A>
) => pipe(useInspectionFormContext(), program)
