import { space } from '@woorcs/utils'
import * as Ariakit from '@ariakit/react'

import {
  disabled,
  focused,
  invalid,
  invalidAndDisabled
} from '../Input/InputGroup'
import { system, PropsOf, css } from '../../../system'
import { List } from '../../data'

import { useListBoxContext } from './ListboxProvider'

interface ListboxListProps extends PropsOf<typeof List> {
  disabled?: boolean
  readOnly?: boolean
  multiple?: boolean
  focused?: boolean
  invalid?: boolean
}

export const ListboxList = system(List)<ListboxListProps>(
  css({
    overflowX: 'hidden',
    overflowY: 'scroll',
    borderRadius: 'medium',
    border: 'thin',
    borderColor: 'grey.100',
    bg: 'white',
    maxHeight: space(54),
    p: 2
  }),
  disabled,
  focused,
  invalid,
  invalidAndDisabled
)

export const ListboxContainer = ({ children, ...props }: ListboxListProps) => {
  const { disabled, focused, invalid, getListProps } = useListBoxContext()

  return (
    <Ariakit.Composite {...getListProps()}>
      <ListboxList
        // {...compositeProps}
        {...props}
        disabled={disabled}
        focused={focused}
        invalid={invalid}
      >
        {children}
      </ListboxList>
    </Ariakit.Composite>
  )
}
