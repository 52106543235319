import { pipe } from 'fp-ts/function'
import * as RR from 'fp-ts/ReadonlyRecord'
import * as S from '@woorcs/types/Schemable'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

const locales = ['en', 'sv', 'da', 'et', 'fi', 'lt', 'lv', 'no'] as const

const labels = {
  en: 'English',
  sv: 'Swedish',
  da: 'Danish',
  et: 'Estionian',
  fi: 'Finnish',
  lt: 'Lithuanian',
  lv: 'Latvian',
  no: 'Norwegian'
}

const countryCode = {
  en: 'GB',
  sv: 'SE',
  da: 'DK',
  et: 'EE',
  fi: 'FI',
  lt: 'LT',
  lv: 'LV',
  no: 'NO'
}

export type Locale = string

// -------------------------------------------------------------------------------------
// schema
// -------------------------------------------------------------------------------------

export const schema = S.make((S) =>
  pipe(
    S.string,
    S.refine((s): s is Locale => s.length > 0, 'locale')
  )
)

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export const getCountryCode = (locale: string) =>
  pipe(countryCode, RR.lookup(locale))

export const getLocaleList = (): ReadonlyArray<Locale> => locales

export const getLocaleLabel = (locale: Locale): string =>
  (labels as any)[locale]
