import { forwardRef, ReactNode, memo } from 'react'
import * as Ariakit from '@ariakit/react'

import { SelectableListItem, ListItemField } from '../../data'
import { PropsOf, system } from '../../../system'

import { useListboxOption, UseListboxOptionProps } from './useListboxOption'
import { ListboxOptionCheck } from './ListboxOptionCheck'

export interface ListboxOptionProps
  extends Omit<PropsOf<typeof system.button>, 'value' | 'as' | 'color'>,
    UseListboxOptionProps {
  children: ReactNode
}

export const ListboxOption = memo(
  forwardRef<HTMLButtonElement, ListboxOptionProps>(
    ({ children, ...other }, ref) => {
      const { selected, multiple, disabled, getOptionButtonProps } =
        useListboxOption(other)

      return (
        <Ariakit.CompositeItem
          ref={ref}
          render={
            <SelectableListItem
              isSelected={selected}
              {...getOptionButtonProps(other)}
            />
          }
          {...other}
        >
          <ListboxOptionCheck
            checked={selected}
            multiple={multiple}
            disabled={disabled}
          />
          <ListItemField>
            <system.span __css={{ fontSize: 'small', fontWeight: 'medium' }}>
              {children}
            </system.span>
          </ListItemField>
        </Ariakit.CompositeItem>
      )
    }
  )
)
