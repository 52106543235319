import { createContext } from '@woorcs/utils'
import { useId, useBoolean } from '@woorcs/hooks'
import { ReactNode, useMemo } from 'react'

export interface FormFieldMessages {
  errorMessage?: ReactNode
  infoMessage?: ReactNode
  warningMessage?: ReactNode
}

export interface FormFieldInputProps {
  id?: string
  labelId?: string
  messageId?: string
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  invalid?: boolean
  focused?: boolean
  onFocus?(): void
  onBlur?(): void
}

export interface FormFieldContext
  extends FormFieldMessages,
    FormFieldInputProps {
  labelId?: string
}

export const [FormFieldProvider, useFormFieldContext] =
  createContext<FormFieldContext>({
    strict: false
  })

export type UseFormFieldProviderProps = Omit<
  FormFieldContext,
  'labelId' | 'messageId' | 'onFocus' | 'onBlur'
>

export const useFormFieldProvider = ({
  id: propId,
  ...other
}: UseFormFieldProviderProps): FormFieldContext => {
  const uuid = useId()
  const id = propId ?? uuid
  const labelId = `${id}-label`
  const messageId = `${id}-message`

  const [focused, setFocused] = useBoolean()

  return useMemo(
    () => ({
      id,
      labelId,
      messageId,
      focused,
      onFocus: setFocused.on,
      onBlur: setFocused.off,
      ...other
    }),
    [focused, id, labelId, messageId, other, setFocused.off, setFocused.on]
  )
}
