import { useCallback } from 'react'
import {
  ModalBody,
  ModalFooter,
  SecondaryButton,
  Stack,
  Input,
  useSnackbar,
  ModalDismiss
} from '@woorcs/design-system'
import { Form, Formik, FormikHelpers } from 'formik'
import * as TE from 'fp-ts/TaskEither'
import * as E from 'fp-ts/Either'
import * as O from 'fp-ts/Option'
import * as t from 'io-ts'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import {
  InspectionFormDefinition,
  InspectionFormDocument
} from '@woorcs/inspection-form'
import { I18nConfig, Locale, TranslateableText } from '@woorcs/form'
import { SelectLanguageInput } from '@woorcs/inspection-form-editor'
import { useMutation } from 'urql'
import { getFormikValidator, NonEmptyTrimmedString } from '@woorcs/utils/formik'

import { FormField, SubmitButton } from '../Formik'

import {
  CreateFormFormFragment,
  CreateFormFormMutationDocument
} from './__generated__/CreateFormForm'

const InspectionFormForm = t.type({
  title: NonEmptyTrimmedString,
  defaultLanguage: NonEmptyTrimmedString
})

type InspectionFormForm = t.TypeOf<typeof InspectionFormForm>
type InspectionFormValue = t.OutputOfProps<(typeof InspectionFormForm)['props']>

const validate = getFormikValidator(InspectionFormForm)

const initialValues = {
  title: '',
  defaultLanguage: ''
}

type CreateFormFormProps = {
  onCreateForm(form: CreateFormFormFragment): void
}

export const CreateFormForm = ({ onCreateForm }: CreateFormFormProps) => {
  const { t } = useTranslation()
  const [, createFormMutation] = useMutation(CreateFormFormMutationDocument)
  const { showSnackbar } = useSnackbar()
  const handleSubmit = useCallback(
    (
      values: InspectionFormValue,
      helpers: FormikHelpers<InspectionFormValue>
    ) => {
      const definition = InspectionFormDefinition.inspectionFormDefinition({
        title: TranslateableText.translateableText(values.title),
        i18n: I18nConfig.i18nConfig(values.defaultLanguage as Locale.Locale),
        children: [
          InspectionFormDocument.inspectionFormPageElement({
            title: TranslateableText.translateableText('Page 1'),
            description: TranslateableText.translateableText('Page 1'),
            children: []
          })
        ]
      })

      const createForm = pipe(
        TE.tryCatch(
          () =>
            createFormMutation({
              input: {
                title: values.title,
                definition,
                editable: true
              }
            }),
          (e) => e
        ),
        TE.chainEitherKW((response) =>
          pipe(
            response.data?.createForm,
            E.fromNullable(() => ({
              _tag: 'invalidResponse'
            }))
          )
        ),
        TE.bimap(
          () =>
            showSnackbar({
              title: 'Something went wrong',
              variant: 'danger'
            }),
          ({ form }) => {
            helpers.resetForm({})
            onCreateForm(form)
            showSnackbar({
              title: 'Form created',
              variant: 'success'
            })
          }
        )
      )

      createForm()
    },
    [createFormMutation, onCreateForm, showSnackbar]
  )

  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <ModalBody>
            <Stack spacing={6}>
              <FormField name='title' label={t('common:name')} required>
                <Input />
              </FormField>
              <FormField
                name='defaultLanguage'
                label={t('common:language')}
                required
              >
                {({ value, onChange }) => (
                  <SelectLanguageInput
                    value={O.fromNullable(value as Locale.Locale | null)}
                    onChange={onChange}
                  />
                )}
              </FormField>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ModalDismiss render={<SecondaryButton mr={2} />}>
              {t('common:cancel')}
            </ModalDismiss>
            <SubmitButton label={t('common:save')} />
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}
