import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as IO from 'fp-ts/IO'
import { Path } from '@woorcs/types/Path'
import * as ET from '@woorcs/types/ElementTree'

import { Editor } from '../state'

import { useEditorSelector } from './useEditorSelector'

export const getElementPath =
  <T extends ET.ElementTree = ET.ElementTree>(element: T) =>
  (editor: Editor) =>
    pipe(editor.getValue(), IO.map(ET.elementPath(element)))

export const useElementPath = <T extends ET.ElementTree = ET.ElementTree>(
  element: T
) => useEditorSelector(getElementPath(element), O.getEq(Path))
