import { PrismTheme } from 'prism-react-renderer'
import { palette } from '@woorcs/design-tokens'

export const prismTheme: PrismTheme = {
  plain: {
    color: palette.primary['500'],
    backgroundColor: palette.accent['50']
  },
  styles: [
    {
      types: ['changed'],
      style: {
        color: 'rgb(162, 191, 252)',
        fontStyle: 'italic'
      }
    },
    {
      types: ['deleted'],
      style: {
        color: palette.red['500'],
        fontStyle: 'italic'
      }
    },
    {
      types: ['inserted', 'attr-name'],
      style: {
        color: palette.red['500'],
        fontStyle: 'italic'
      }
    },
    {
      types: ['attr-value'],
      style: {
        color: palette.primary['500']
      }
    },
    {
      types: ['comment'],
      style: {
        color: palette.grey['300'],
        fontStyle: 'italic'
      }
    },
    {
      types: ['string', 'url'],
      style: {
        color: palette.secondary['700']
      }
    },
    {
      types: ['variable'],
      style: {
        color: 'rgb(214, 222, 235)'
      }
    },
    {
      types: ['number'],
      style: {
        color: palette.accent['500']
      }
    },
    {
      types: ['builtin', 'char', 'constant', 'function'],
      style: {
        color: 'rgb(130, 170, 255)'
      }
    },
    {
      types: ['punctuation'],
      style: {
        color: palette.primary['300']
      }
    },
    {
      types: ['selector', 'doctype'],
      style: {
        color: 'rgb(199, 146, 234)',
        fontStyle: 'italic'
      }
    },
    {
      types: ['function'],
      style: {
        color: palette.red['500']
      }
    },
    {
      types: ['class-name'],
      style: {
        color: palette.accent['500']
      }
    },
    {
      types: ['tag'],
      style: {
        color: palette.accent['300']
      }
    },
    {
      types: ['operator'],
      style: {
        color: palette.accent['500']
      }
    },
    {
      types: ['keyword'],
      style: {
        color: palette.primary['500']
      }
    },
    {
      types: ['boolean'],
      style: {
        color: palette.red['500']
      }
    },
    {
      types: ['property'],
      style: {
        color: palette.red['500']
      }
    },
    {
      types: ['namespace'],
      style: {
        color: palette.red['500']
      }
    }
  ]
}
