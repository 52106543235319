import { pipe, tap } from 'wonka'
import { Exchange, CombinedError } from 'urql'

export const createErrorExchange =
  (onError: (error: CombinedError) => void): Exchange =>
  ({ forward }) =>
  (ops$) =>
    pipe(
      forward(ops$),
      tap(({ error }) => {
        if (error) {
          onError(error)
        }
      })
    )
