import { absurd } from 'fp-ts/function'
import * as S from '@woorcs/types/Schemable'

import { ConditionGroup, Condition } from '../types/Condition'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export const ElementRuleEffect = S.type((S) => S.literal('hide', 'show'))

export type ElementRuleEffect = S.TypeOf<typeof ElementRuleEffect>

export const ElementRule = S.type((S) =>
  S.struct({
    effect: ElementRuleEffect.schema(S),
    condition: Condition.schema(S)
  })
)

export type ElementRule = S.TypeOf<typeof ElementRule>

interface HideRule extends ElementRule {
  effect: 'hide'
}

interface ShowRule extends ElementRule {
  effect: 'show'
}

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const elementRule = (
  effect: ElementRuleEffect,
  condition: ConditionGroup
): ElementRule => ({
  effect,
  condition
})

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export const matchRule =
  <T>(patterns: { hide: (rule: HideRule) => T; show: (rule: ShowRule) => T }) =>
  (rule: ElementRule): T => {
    switch (rule.effect) {
      case 'hide':
        return patterns.hide(rule as HideRule)
      case 'show':
        return patterns.show(rule as ShowRule)
      default:
        return absurd<T>(rule.effect)
    }
  }

export const isVisibilityRule = (rule: ElementRule) =>
  rule.effect === 'hide' || rule.effect === 'show'
