import * as IO from 'fp-ts/IO'
import * as Eq from 'fp-ts/Eq'
import * as b from 'fp-ts/boolean'
import { sequenceS } from 'fp-ts/Apply'

import { useEditorSelector } from './useEditorSelector'

const eq = Eq.struct({
  canUndo: b.Eq,
  canRedo: b.Eq
})

export const useEditorHistory = () =>
  useEditorSelector(
    (editor) =>
      sequenceS(IO.Applicative)({
        redo: editor.redo,
        undo: editor.undo,
        canUndo: editor.canUndo(),
        canRedo: editor.canRedo()
      }),
    eq
  )
