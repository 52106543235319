import { memo, ReactElement, useCallback } from 'react'
import * as O from 'fp-ts/Option'
import { css, Flex, system } from '@woorcs/design-system'
import * as Ariakit from '@ariakit/react'
import { pipe, constNull } from 'fp-ts/function'
import { AnimatePresence, motion } from 'framer-motion'
import { FormElement } from '@woorcs/form'
import { Path } from '@woorcs/types/Path'
import { UUID } from '@woorcs/types/UUID'
import { InspectionFormDocument } from '@woorcs/inspection-form'

import { useParentElement } from '../../state'

import { ElementActionMenuActions } from './Actions'

const transition = {
  duration: 0.25,
  ease: [0.48, 0.15, 0.25, 0.96]
}

const animations = {
  initial: { opacity: 0, scale: 0.8, y: 15 },
  animate: { opacity: 1, scale: 1, y: 0 },
  exit: { opacity: 0, scale: 0.8, y: 15 }
}

interface ElementActionMenuProps
  extends Pick<Ariakit.PopoverStoreProps, 'placement'> {
  path: Path
  element: FormElement.FormElementType
  children: ReactElement
  disabled?: boolean
  onEdit(elementID: UUID): void
}

export const ElementActionMenu = memo(
  ({
    element,
    path,
    placement,
    disabled,
    children,
    onEdit
  }: ElementActionMenuProps) => {
    const parent = useParentElement(element)
    const store = Ariakit.useHovercardStore({
      placement,
      timeout: 100
    })
    const mounted = store.useState('mounted')

    const handleEdit = useCallback(() => {
      onEdit(element.id)
    }, [element.id, onEdit])

    return (
      <Ariakit.HovercardProvider store={store}>
        <Ariakit.HovercardAnchor
          aria-label='Show element actions'
          disabled={disabled}
        >
          {children}
        </Ariakit.HovercardAnchor>

        <AnimatePresence>
          {!disabled && mounted && (
            <Ariakit.Hovercard
              gutter={8}
              aria-label='Element actions'
              render={
                <motion.div
                  initial={animations.initial}
                  animate={animations.animate}
                  transition={transition}
                  exit={animations.exit}
                />
              }
              alwaysVisible
            >
              <system.div
                css={css({
                  zIndex: 'popover'
                })}
                onClick={store.hide}
              >
                <Flex
                  css={{
                    boxShadow:
                      '0 4px 16px rgb(0 0 0 / 12%), 0 0 0 1px rgb(0 0 0 / 2%)'
                  }}
                  alignItems='center'
                  borderRadius='medium'
                  bg='grey.900'
                  border='base'
                  py={2}
                  pl={3}
                  pr={2}
                >
                  {pipe(
                    parent,
                    O.fold(constNull, (parent) => (
                      <ElementActionMenuActions
                        element={element}
                        parent={
                          parent as InspectionFormDocument.InspectionFormPageElement
                        }
                        path={path}
                        onEdit={handleEdit}
                      />
                    ))
                  )}
                </Flex>
              </system.div>
            </Ariakit.Hovercard>
          )}
        </AnimatePresence>
      </Ariakit.HovercardProvider>
    )
  }
)
