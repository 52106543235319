import { ReactNode } from 'react'
import { isFunction } from '@woorcs/utils'
import * as Ariakit from '@ariakit/react'

import {
  useDialogSidebar,
  UseDialogSidebarProps,
  DialogSidebarContext,
  DialogSidebarProvider
} from './useDialogSidebar'

export interface DialogSidebarProps
  extends UseDialogSidebarProps,
    Ariakit.DialogStoreProps {
  children: ReactNode | ((context: DialogSidebarContext) => ReactNode)
}

export const DialogSidebar = ({
  children,
  placement,
  ...other
}: DialogSidebarProps) => {
  const context = useDialogSidebar({ placement })
  const store = Ariakit.useDialogStore(other)

  return (
    <Ariakit.DialogProvider store={store}>
      <DialogSidebarProvider value={context}>
        {isFunction(children) ? children(context) : children}
      </DialogSidebarProvider>
    </Ariakit.DialogProvider>
  )
}
