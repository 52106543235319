import * as S from '@woorcs/types/Schemable'
import * as Lens from 'monocle-ts/Lens'
import { uuid, UUID } from '@woorcs/types/UUID'
import { ParentElement } from '@woorcs/types/ElementTree'
import { constant, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'

import * as TranslateableText from '../i18n/TranslateableText'

import { FormElement } from './Element'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export interface FormDocument extends ParentElement {
  id: UUID
  type: string
  title: TranslateableText.TranslateableText
  children: FormElement[]
}

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const formDocument = <T extends FormDocument>(
  document: Omit<T, 'id'>
) => ({
  id: uuid(),
  ...document
})

// -------------------------------------------------------------------------------------
// instances
// -------------------------------------------------------------------------------------

export const schema: S.Schema<FormDocument> = S.make((S) =>
  S.struct({
    id: UUID.schema(S),
    type: S.string,
    title: TranslateableText.TranslateableText.schema(S),
    children: S.array(FormElement.schema(S))
  })
)

// -------------------------------------------------------------------------------------
// lenses
// -------------------------------------------------------------------------------------

export const formDocumentLens = Lens.id<FormDocument>()

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export const getDefaultTitle = (document: FormDocument) =>
  pipe(
    O.fromNullable(document.title),
    O.map(TranslateableText.getText),
    O.getOrElse(constant(''))
  )
