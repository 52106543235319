import * as Ariakit from '@ariakit/react'

export type ModalDismissProps = Ariakit.DialogDismissProps

export const ModalDismiss = (props: ModalDismissProps) => {
  return (
    <Ariakit.DialogDismiss
      // render={(disclosureProps) => cloneElement(children, disclosureProps)}
      {...props}
    />
  )
}
