import { createContext } from '@woorcs/utils'
import { useControllableState, useBoolean } from '@woorcs/hooks'

interface NavSidebarContext {
  collapsed: boolean
  setCollapsed(collapsed: boolean): void
}

export const [NavSidebarProvider, useNavSidebarContext] =
  createContext<NavSidebarContext>()

export interface UseNavSidebarProps {
  collapsed?: boolean
  onCollapsedChange?(collapsed: boolean): void
}

export const useNavSidebar = ({
  collapsed: propCollapsed,
  onCollapsedChange
}: UseNavSidebarProps): NavSidebarContext => {
  const [stateCollapsed, { set }] = useBoolean()
  const collapsed = useControllableState(
    propCollapsed,
    stateCollapsed,
    set,
    onCollapsedChange
  )

  return {
    collapsed,
    setCollapsed: set
  }
}
