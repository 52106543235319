import React, { ReactElement, cloneElement } from 'react'
import { space } from '@woorcs/utils'
import { variant } from 'styled-system'

import { system, PropsOf } from '../../../system'
import { CloseButton, CloseButtonProps } from '../../buttons'
import { Avatar, AvatarProps } from '../Avatar'

type ChipThumbnailProps = PropsOf<typeof system.div>

export const ChipThumbnail = (props: ChipThumbnailProps) => (
  <system.div
    __css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: space(4.5),
      height: space(4.5),
      // bg: 'primary.200',
      borderRadius: 'full',
      overflow: 'hidden'
    }}
    {...props}
  />
)

interface ChipIconProps extends ChipThumbnailProps {
  children: ReactElement
}

export const ChipIcon = ({ children, ...props }: ChipIconProps) => (
  <ChipThumbnail {...props}>
    {cloneElement(children, {
      size: 'small'
    })}
  </ChipThumbnail>
)

type ChipAvatarProps = Omit<AvatarProps, 'size'>

export const ChipAvatar = (props: ChipAvatarProps) => (
  <ChipThumbnail>
    <Avatar size='mini' {...props} />
  </ChipThumbnail>
)

type ChipTextProps = PropsOf<typeof system.span>

export const ChipText = (props: ChipTextProps) => (
  <system.span
    __css={{ color: 'grey.900' }}
    mx={2}
    fontSize='mini'
    fontWeight='semi-bold'
    {...props}
  />
)

type ChipDeleteButtonProps = Omit<CloseButtonProps, 'size'>

export const ChipDeleteButton = (props: ChipDeleteButtonProps) => {
  return <CloseButton size='mini' {...props} />
}

type ChipVariant = 'normal'
type ChipSize = 'small' | 'medium'

const size = variant({
  prop: 'size',
  variants: {
    small: {
      height: space(6),
      px: 1
    },
    medium: {
      height: space(8),
      px: 2,
      fontWeight: 'medium',
      fontSize: 'small'
    }
  }
})

const StyledChip = system('div')(
  {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 'full',
    bg: 'primary.50'
  },
  size
)

interface ChipProps extends PropsOf<typeof system.div> {
  variant?: ChipVariant
  size?: ChipSize
}

export const Chip = ({ variant, size = 'medium', ...props }: ChipProps) => {
  return <StyledChip size={size} {...props} />
}
