import {
  FormSubmissionData,
  Locale,
  Fields,
  FormDocument,
  RuleEngine,
  Translator,
  DocumentsRegistry,
  ResponseSetRegistry
} from '@woorcs/form'
import { useMemo } from 'react'
import { useSubmissionState } from '@woorcs/form/react'

import { InspectionFormDefinition } from '../../InspectionFormDefinition'

export const useInspectionFormEnvironment = (
  definition: InspectionFormDefinition,
  initialData: FormSubmissionData,
  locale: Locale.Locale
  // eslint-disable-next-line react-hooks/exhaustive-deps
) => {
  const i18n = Translator.getTranslator(definition.i18n)
  const responseSets = ResponseSetRegistry.getResponseSetRegistry(
    definition.responseSets
  )
  const documents = DocumentsRegistry.getDocumentsRegistry(definition.documents)
  const fields = Fields.fromDocument(
    definition as unknown as FormDocument.FormDocument
  )({
    i18n,
    responseSets,
    locale
  })
  const [data, submission] = useSubmissionState(fields, initialData)
  const ruleEngine = RuleEngine.getRuleEngine(submission.get)

  const environment = useMemo(
    () => ({
      submission,
      i18n,
      ruleEngine,
      documents,
      responseSets,
      locale,
      pages: []
    }),
    [submission, i18n, ruleEngine, documents, responseSets, locale]
  )

  return [data, environment] as const
}
