import { MutableRefObject } from 'react'
import { InspectionFormDocument } from '@woorcs/inspection-form'

export const PAGE_LIST_ITEM_DRAG_TYPE = 'page-list-item'

export interface PageListItemDragItem {
  type: typeof PAGE_LIST_ITEM_DRAG_TYPE
  page: InspectionFormDocument.InspectionFormPageElement
  originalIndex: number
  currentIndexRef: MutableRefObject<number>
  rect: {
    width: number
    height: number
  }
}
