import React, { ReactNode } from 'react'
import { Surface, SurfaceProps } from '@woorcs/design-system'

interface SidebarProps extends SurfaceProps {
  children: ReactNode
}

export const Sidebar = ({ children, ...other }: SidebarProps) => (
  <Surface
    position='fixed'
    left={0}
    top={0}
    bottom={0}
    width={420}
    elevation={1}
    pt={18}
    {...other}
  >
    {children}
  </Surface>
)
