import React, { memo } from 'react'
import { constNull, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { useTranslation } from 'react-i18next'
import { Box, FormFieldMessage } from '@woorcs/design-system'

type FormFieldErrorMessageProps = {
  error?: string
}

export const FormFieldErrorMessage = memo(
  ({ error }: FormFieldErrorMessageProps) => {
    const { t } = useTranslation()

    return pipe(
      O.fromNullable(error),
      O.map((error) => t(`forms:validation.${error}` as any)),
      O.fold(constNull, (message) => (
        <Box pt={1}>
          <FormFieldMessage status='error'>{message}</FormFieldMessage>
        </Box>
      ))
    )
  }
)
