import { createContext } from '@woorcs/utils'

import { useToggleButtonGroup } from './useToggleButtonGroup'

export type ToggleButtonGroupContext = ReturnType<typeof useToggleButtonGroup>

export const [ToggleButtonGroupProvider, useToggleButtonGroupContext] =
  createContext<ToggleButtonGroupContext>({
    strict: false
  })
