import React from 'react'
import { space } from '@woorcs/utils'

import { Flex, FlexProps } from '../../layout'

export const CELL_WIDTH = space(11)

type CalendarCellProps = FlexProps

export const CalendarCell = ({ children, ...other }: CalendarCellProps) => (
  <Flex
    justifyContent='center'
    alignItems='center'
    width={CELL_WIDTH}
    height={CELL_WIDTH}
    {...other}
  >
    {children}
  </Flex>
)
