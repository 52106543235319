/* eslint-disable complexity */
import { ADTType, makeADT, ofType } from '@morphic-ts/adt'
import { constTrue, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import * as D from 'io-ts/Decoder'

import * as V from './validation'
import * as FieldType from './FieldType'
import { FieldDescriptor } from './FieldDescriptor'

// -------------------------------------------------------------------------------------
// adts
// -------------------------------------------------------------------------------------

export const Field = makeADT('_tag')({
  Text: ofType<FieldType.TextFieldDescriptor>(),
  Number: ofType<FieldType.NumberFieldDescriptor>(),
  Location: ofType<FieldType.LocationFieldDescriptor>(),
  Image: ofType<FieldType.ImageFieldDescriptor>(),
  Signature: ofType<FieldType.SignatureFieldDescriptor>(),
  Email: ofType<FieldType.EmailFieldDescriptor>(),
  Date: ofType<FieldType.DateFieldDescriptor>(),
  Time: ofType<FieldType.TimeFieldDescriptor>(),
  DateRange: ofType<FieldType.DateRangeFieldDescriptor>(),
  Select: ofType<FieldType.SelectFieldDescriptor>(),
  MultiSelect: ofType<FieldType.MultiSelectFieldDescriptor>(),
  Group: ofType<FieldType.GroupFieldDescriptor>()
})

export type Field = ADTType<typeof Field>

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const text = Field.as.Text

export const number = Field.as.Number

export const email = Field.as.Email

export const image = Field.as.Image

export const signature = Field.as.Signature

export const location = Field.as.Location

export const date = Field.as.Date

export const dateRange = Field.as.DateRange

export const time = Field.as.Time

export const group = Field.as.Group

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export type AnyField = FieldDescriptor<any, any, any>

export const getFieldType = (f: Field) => FieldType.getFieldType(f._tag)

export const empty = (field: Field) => getFieldType(field).empty

export const defaultValue = (field: AnyField): O.Option<unknown> => {
  // if (fieldType._tag === 'Optional') {
  //   return defaultValue(fieldType.field)
  // }

  return pipe(
    field.defaultValue,
    O.altW(() => pipe(empty(field), O.some))
  )
}

export interface TypeError extends V.ValidationError {
  _tag: 'TypeError'
  error: D.DecodeError
}

export const typeError = (
  actual: unknown,
  error: D.DecodeError
): TypeError => ({
  _tag: 'TypeError',
  actual,
  error
})

export const getFieldTypeValidator = (
  f: Field
): V.Validator<FieldType.FieldTypeErrors, unknown> => {
  return getFieldType(f).validate(f.options as any) as any
  // switch (f._tag) {
  //   case 'Text': {
  //     return textValidator(f.options)
  //   }

  //   case 'Number': {
  //     return numberValidator(f.options)
  //   }

  //   case 'Optional': {
  //     return V.optional(getFieldTypeValidator(f.field))
  //   }

  //   default: {
  //     return V.identity()
  //   }
  // }
}

export const getDecoder = (field: Field) => getFieldType(field)

export const getValidator = (field: Field) => (value: unknown) => {
  const decoder = getDecoder(field)
  const validate = getFieldTypeValidator(field)

  return pipe(
    decoder.decode(value),
    // E.mapLeft(
    //   (error): V.ValidationErrors<TypeError> => RNEA.of(typeError(value, error))
    // ),
    E.chainW(validate)
  )
}

const isEmpty = (field: AnyField, value: O.Option<unknown>) =>
  pipe(
    value,
    O.map((value) => value === empty(field)),
    O.getOrElse(constTrue)
  )

export const isValid = (field: AnyField, value: O.Option<unknown>): boolean => {
  // const validator = getValidator(field)

  if (isEmpty(field, value)) {
    return field.optional
  }

  return true

  // return pipe(
  //   value,
  //   O.chain((value) => pipe(validator(value), O.fromEither)),
  //   O.fold(constFalse, constTrue)
  // )
}
