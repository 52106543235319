import { createContext } from '@woorcs/utils'

export type AlertVariant = 'success' | 'info' | 'warning' | 'danger' | 'neutral'

export interface AlertStyleContext {
  variant: AlertVariant
}

export const [AlertStyleProvider, useAlertStyle] =
  createContext<AlertStyleContext>()
