import React, { ReactNode } from 'react'

import { FullscreenPage } from '@app/components'

import { LobbyNavbar } from '../Navbar'

type LobbyLayoutProps = {
  children: ReactNode
}

export const LobbyLayout = ({ children }: LobbyLayoutProps) => (
  <FullscreenPage>
    <LobbyNavbar />
    {children}
  </FullscreenPage>
)
