import { FormElement } from '@woorcs/form'
import { Path } from '@woorcs/types/Path'
import { MutableRefObject } from 'react'

export const ELEMENT_LIST_ITEM_DRAG_TYPE = 'element-list-item'

export interface ElementListItemDragItem {
  type: typeof ELEMENT_LIST_ITEM_DRAG_TYPE
  element: FormElement.FormElementType
  originalPath: Path
  currentPathRef: MutableRefObject<Path>
  rect: {
    width: number
    height: number
  }
}
