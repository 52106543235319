import {
  InspectionFormDefinition,
  InspectionFormDocument
} from '@woorcs/inspection-form'
import { TranslateableText, FormElement, Locale } from '@woorcs/form'
import { uuid } from '@woorcs/types/UUID'

import { emptyState } from './emptyState'

export const exampleForm: InspectionFormDefinition.InspectionFormDefinition = {
  ...emptyState,
  title: TranslateableText.translateableText('Example form'),
  i18n: {
    defaultLanguage: 'sv' as Locale.Locale,
    languages: {
      'en-GB': {},
      'fi-FI': {},
      'nb-NO': {}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any
  },
  children: [
    InspectionFormDocument.inspectionFormPageElement({
      key: uuid(),
      title: TranslateableText.translateableText('Page 1'),
      description: TranslateableText.translateableText(''),
      children: [
        FormElement.alertElement({
          status: 'danger',
          children: [
            {
              id: uuid(),
              type: 'Text',
              rule: null,
              text: TranslateableText.translateableText('Something is wrong')
            }
          ]
        }),
        FormElement.dateInputElement({
          key: uuid(),
          label: TranslateableText.translateableText('Select a date'),
          placeholder: TranslateableText.translateableText(''),
          informativeText: TranslateableText.translateableText(''),
          options: {
            withTime: false
          }
        }),
        FormElement.textInputElement({
          key: uuid(),
          label: TranslateableText.translateableText('Describe the problem'),
          placeholder: TranslateableText.translateableText(''),
          informativeText: TranslateableText.translateableText('')
        }),
        FormElement.signatureInputElement({
          key: uuid(),
          label: TranslateableText.translateableText('Please sign below'),
          informativeText: TranslateableText.translateableText(''),
          options: {
            allowUpload: false
          }
        }),
        FormElement.locationInputElement({
          key: uuid(),
          label: TranslateableText.translateableText(
            'Please add your exact position.'
          ),
          informativeText: TranslateableText.translateableText('')
        })
      ],
      rule: null
    }),
    InspectionFormDocument.inspectionFormPageElement({
      key: uuid(),
      title: TranslateableText.translateableText('Page 2'),
      description: TranslateableText.translateableText(''),
      children: [],
      rule: null
    }),
    InspectionFormDocument.inspectionFormPageElement({
      key: uuid(),
      title: TranslateableText.translateableText('Page 3'),
      description: TranslateableText.translateableText(''),
      children: [],
      rule: null
    })
  ]
}
