import { forwardRef } from 'react'
import { motion } from 'framer-motion'

import { Surface } from '../../layout'
import { PropsOf, system, css } from '../../../system'
import { focusStyle } from '../../../styles'

export type ModalContainerProps = PropsOf<typeof system.div>

export const ModalContainer = forwardRef<HTMLDivElement, ModalContainerProps>(
  (props, ref) => (
    <motion.div
      ref={ref}
      css={css({
        width: props.width,
        zIndex: 'modal'
      })}
      initial={{ opacity: 0, z: -100 }}
      animate={{ opacity: 1, z: 0 }}
      exit={{ opacity: 0, z: -100 }}
      transition={{
        type: 'spring',
        damping: 20,
        stiffness: 200
      }}
    >
      <Surface
        css={css({
          zIndex: 'modal',
          position: 'relative',
          ':focus': focusStyle
        })}
        elevation={3}
        borderRadius='large'
        {...props}
      />
    </motion.div>
  )
)
