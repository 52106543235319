import React, { useCallback } from 'react'
import {
  Flex,
  List,
  Text,
  ListItemBody,
  SelectableListItem
} from '@woorcs/design-system'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import { ResponseSet } from '@woorcs/form'

type ResponseSetOptionLabelProps = {
  option: ResponseSet.ResponseSetOption
}

export const ResponseSetOptionLabel = ({
  option
}: ResponseSetOptionLabelProps) => {
  return (
    <Flex justifyContent='space-between' width='100%' flexShrink={1}>
      <Text fontWeight='semi-bold' fontSize='small'>
        {option.label.text}
      </Text>
    </Flex>
  )
}

type ResponseSetOptionListItemProps = {
  option: ResponseSet.ResponseSetOption
  onClick(responseSet: ResponseSet.ResponseSetOption): void
}

const ResponseSetOptionListItem = ({
  option,
  onClick
}: ResponseSetOptionListItemProps) => {
  const handleClick = useCallback(() => {
    onClick(option)
  }, [onClick, option])

  return (
    <SelectableListItem css={{ cursor: 'pointer' }} onClick={handleClick}>
      <ListItemBody ml={0}>
        <Flex alignItems='center'>
          <ResponseSetOptionLabel option={option} />
        </Flex>
      </ListItemBody>
    </SelectableListItem>
  )
}

type ResponseSetOptionListProps = {
  responseSet: ResponseSet.ResponseSet
  onOptionClick(option: ResponseSet.ResponseSetOption): void
}

export const ResponseSetOptionList = ({
  responseSet,
  onOptionClick
}: ResponseSetOptionListProps) =>
  pipe(
    responseSet.options,
    A.map((option) => (
      <ResponseSetOptionListItem
        key={option.id}
        option={option}
        onClick={onOptionClick}
      />
    )),
    (items) => <List>{items}</List>
  )
