import { useState, ReactElement, useCallback } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  Input,
  Modal,
  ModalFooter,
  ModalDialog,
  ModalDisclosure,
  SecondaryButton,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalDismiss
} from '@woorcs/design-system/src'
import * as t from 'io-ts'
import { NonEmptyTrimmedString, getFormikValidator } from '@woorcs/utils/formik'

import { FormField, SubmitButton } from '@app/components'

const OrganizationForm = t.type({
  name: NonEmptyTrimmedString
})

export type OrganizationFormOrganization = t.TypeOf<typeof OrganizationForm>

const validateForm = getFormikValidator(OrganizationForm)

const defaultInitialValues = {
  name: ''
}

interface Props {
  title: string
  initialValues?: Record<keyof OrganizationFormOrganization, unknown>
  children: ReactElement
  onSubmit(
    organization: OrganizationFormOrganization,
    actions: FormikHelpers<OrganizationFormOrganization>
  ): void
}

export const AddOrganizationModal = ({
  title,
  initialValues = defaultInitialValues,
  children,
  onSubmit
}: Props) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  const handleSubmit = useCallback(
    (
      organization: OrganizationFormOrganization,
      actions: FormikHelpers<OrganizationFormOrganization>
    ) => {
      onSubmit(organization, actions)
      setVisible(false)
    },
    [onSubmit]
  )

  return (
    <Modal open={visible} setOpen={setVisible}>
      <ModalDisclosure>{children}</ModalDisclosure>
      <ModalDialog aria-label={title} role='dialog'>
        <ModalHeader>
          <ModalTitle mb={6}>{title}</ModalTitle>
        </ModalHeader>
        <Formik
          validate={validateForm}
          initialValues={initialValues as any}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <ModalBody>
                <FormField name='name' label={t('common:name')} required>
                  <Input />
                </FormField>
              </ModalBody>

              <ModalFooter>
                <ModalDismiss render={<SecondaryButton mr={2} />}>
                  {t('common:cancel')}
                </ModalDismiss>
                <SubmitButton label={t('common:save')} />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalDialog>
    </Modal>
  )
}
