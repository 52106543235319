import * as E from 'fp-ts/Either'
import * as O from 'fp-ts/Option'
import { constant, flow, pipe } from 'fp-ts/function'
import * as RNEA from 'fp-ts/ReadonlyNonEmptyArray'
import { Predicate } from 'fp-ts/Predicate'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export type ValidationError = {
  readonly _tag: string
  readonly actual: unknown
}

export type ValidationErrors<E> = RNEA.ReadonlyNonEmptyArray<E>

export type ValidationResult<E, A> = E.Either<ValidationErrors<E>, A>

export type Validator<E, A> = (value: A) => ValidationResult<E, A>

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const validator = <E, A>(v: Validator<E, A>) => v

export const identity = <A>() => validator<never, A>((v) => E.right(v))

export const validatorFromPredicate: <E, A>(
  predicate: Predicate<A>,
  onLeft: (value: A) => E
) => Validator<E, A> = (p, onLeft) =>
  flow(E.fromPredicate(p, onLeft), E.mapLeft(RNEA.of))

// -------------------------------------------------------------------------------------
// combinators
// -------------------------------------------------------------------------------------

export function combine<A, E1, E2>(
  ...validators: [Validator<E1, A>, Validator<E2, A>]
): Validator<E1 | E2, A>
export function combine<A, E1, E2, E3>(
  ...validators: [Validator<E1, A>, Validator<E2, A>, Validator<E3, A>]
): Validator<E1 | E2 | E3, A>
export function combine<A, E1, E2, E3, E4>(
  ...validators: [
    Validator<E1, A>,
    Validator<E2, A>,
    Validator<E3, A>,
    Validator<E4, A>
  ]
): Validator<E1 | E2 | E3 | E4, A>
export function combine<A, E>(
  ...validators: [Validator<E, A>]
): Validator<E, A> {
  return (a: A) =>
    pipe(
      validators,
      RNEA.traverse(E.getApplicativeValidation(RNEA.getSemigroup<E>()))((v) =>
        v(a)
      ),
      E.map(() => a)
    )
}

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export type InputOf<V> = V extends Validator<any, infer I> ? I : never

export type ErrorOf<V> = V extends Validator<infer E, any> ? E : never

export const optional = <E, A>(v: Validator<E, A>): Validator<E, A> =>
  validator((value) => {
    if (!value) {
      return E.right(value)
    }

    return v(value)
  })

/**
 * returns the first error of the validation result
 */
export const firstError = <E extends ValidationError, T>(
  vr: ValidationResult<E, T>
) =>
  pipe(
    vr,
    E.match((e) => pipe(RNEA.head(e), O.some), constant(O.none))
  )
