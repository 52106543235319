import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type ShareSubmissionPdfMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ShareSubmissionPdfInput;
}>;


export type ShareSubmissionPdfMutation = { __typename: 'Mutation', shareSubmissionPdf: { __typename: 'ShareSubmissionPdfSuccess', success: boolean } };


export const ShareSubmissionPdfMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ShareSubmissionPdfMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ShareSubmissionPdfInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"shareSubmissionPdf"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShareSubmissionPdfSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]}}]} as unknown as DocumentNode<ShareSubmissionPdfMutation, ShareSubmissionPdfMutationVariables>;