import { makeOperation, CombinedError } from '@urql/core'
import { authExchange } from '@urql/exchange-auth'
import * as T from 'fp-ts/Task'
import * as TO from 'fp-ts/TaskOption'
import { Exchange } from 'urql'
import { pipe } from 'fp-ts/function'

export const isAuthError = (error: CombinedError) =>
  error.response?.status === 401

export interface AuthExchangeProps {
  getAccessToken(): TO.TaskOption<string>
  logout(): void
}

export const createAuthExchange = ({
  getAccessToken
}: AuthExchangeProps): Exchange =>
  authExchange<string>({
    getAuth: async () => {
      const getToken = pipe(
        getAccessToken(),
        TO.getOrElseW(() =>
          T.fromIO(() => {
            //logout()

            return null
          })
        )
      )

      return getToken()
    },
    willAuthError: ({ authState }) => {
      if (!authState) {
        return true
      }
      return false
    },
    didAuthError: ({ error }) => {
      return isAuthError(error)
    },
    addAuthToOperation: ({ authState, operation }) => {
      if (!authState) {
        return operation
      }

      const fetchOptions =
        typeof operation.context.fetchOptions === 'function'
          ? operation.context.fetchOptions()
          : operation.context.fetchOptions || {}

      const makeOperationsWithHeaders = (headers: { [key: string]: string }) =>
        makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              ...headers
            }
          }
        })

      return makeOperationsWithHeaders({
        Authorization: `Bearer ${authState}`
      })
    }
  })
