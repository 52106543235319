import React from 'react'
import {
  CloseButton,
  DangerButton,
  Flex,
  TrashIcon,
  Text,
  ConfirmAlertDialog
} from '@woorcs/design-system'
import { animated, useSpring } from 'react-spring'
import { useTranslation } from 'react-i18next'

const AnimatedFlex = animated(Flex)

interface UserListBulkActionsProps {
  onRemove(): void
  onCancel(): void
  selectedCount: number
}

export const UserListBulkActions = ({
  onRemove,
  selectedCount,
  onCancel
}: UserListBulkActionsProps) => {
  const { t } = useTranslation()
  const animatedStyles = useSpring({
    transform:
      selectedCount > 0 ? 'translate3d(0, 0%, 0)' : 'translate3d(0, 100%, 0)'
  })

  return (
    <AnimatedFlex
      style={animatedStyles}
      css={{
        willChange: 'transform'
      }}
      bg='primary.500'
      py={4}
      px={12}
      justifyContent='space-between'
      alignItems='center'
      position='fixed'
      bottom={0}
      left={260} // TODO: Should probably be positioned absolute inside the parent element.
      right={0}
      zIndex={900}
    >
      <Text color='white'>
        {t('users:userList.bulkActions.selectedLabel', {
          count: selectedCount
        })}
      </Text>

      <Flex alignItems='center'>
        <ConfirmAlertDialog
          title={t('users:userList.bulkActions.confirmDeleteDialog.title')}
          body={t('users:userList.bulkActions.confirmDeleteDialog.body')}
          confirmLabel={t('common:remove')}
          onConfirm={onRemove}
        >
          <DangerButton
            mr={4}
            leftIcon={<TrashIcon size='small' />}
            alignSelf='flex-end'
          >
            {t('users:userList.bulkActions.deleteButton.label')}
          </DangerButton>
        </ConfirmAlertDialog>
        <CloseButton color='white' onClick={onCancel} />
      </Flex>
    </AnimatedFlex>
  )
}
