/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo } from 'react'
import { id } from 'io-ts/Decoder'
import { FormElement } from '@woorcs/form'
import { ElementTree } from '@woorcs/types/ElementTree'

import { EditorConfig, getEditor } from '../state'

export type UseEditorOptions = EditorConfig

export const useEditor = <T extends ElementTree>(
  initialValue: T,
  onChange: (value: T) => void,
  debug = true
) => {
  const editor = useMemo(
    () =>
      getEditor(initialValue, {
        debug,
        // decodeElement: FormElement.FormElementType.decode as any,
        decodeElement: id<FormElement.FormElement>().decode as any
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debug]
  )

  useEffect(() => {
    const store = editor.__store

    return store.subscribe(() => onChange?.(editor.getValue()() as T))
  }, [editor, onChange])

  return editor
}
