import { ComponentProps } from 'react'
import { Circle } from '@woorcs/design-system'
import { Tag } from '@woorcs/graphql/schema'

export interface TagAvatarProps
  extends Omit<ComponentProps<typeof Circle>, 'radius'>,
    Partial<Pick<ComponentProps<typeof Circle>, 'radius'>> {
  tag: Pick<Tag, 'color'>
}

export const TagAvatar = ({ tag, ...other }: TagAvatarProps) => (
  <Circle backgroundColor={tag.color ?? 'primary.500'} radius={8} {...other} />
)
