import { useCallback, useEffect, useRef } from 'react'

type UseAutoHideProps = {
  duration: number
  visible: boolean
  onHide(): void
}

export const useAutoHide = ({
  duration,
  visible,
  onHide
}: UseAutoHideProps) => {
  const autoHideTimeoutRef = useRef<number | undefined>()
  const setAutoHideTimer = useCallback(
    (duration: number) => {
      if (!onHide || duration === null) {
        return
      }

      clearTimeout(autoHideTimeoutRef.current)

      autoHideTimeoutRef.current = window.setTimeout(() => {
        onHide()
      }, duration)
    },
    [onHide]
  )

  useEffect(() => {
    if (visible) {
      setAutoHideTimer(duration)
    }

    return () => {
      clearTimeout(autoHideTimeoutRef.current)
    }
  }, [setAutoHideTimer, duration, visible])

  const pause = () => {
    clearTimeout(autoHideTimeoutRef.current)
  }

  const resume = useCallback(() => {
    if (duration !== null) {
      setAutoHideTimer(duration * 0.5)
    }
  }, [duration, setAutoHideTimer])

  return {
    onMouseEnter: pause,
    onMouseLeave: resume
  }
}
