import React from 'react'
import { useTranslation } from 'react-i18next'
import { pipe, constNull } from 'fp-ts/function'
import * as O from 'fp-ts/Option'

import {
  ListPage,
  ListPageHeader,
  ListPageHeaderGroup,
  PageTitle,
  SubmissionList,
  ExportSubmissionsCSVButton,
  SubmissionListFilters,
  useSubmissionFilters
} from '@app/components'
import { FiltersProvider } from '@app/components/filters'

export const SubmissionsPage = () => {
  const { t } = useTranslation('submissions')

  const filtersContext = useSubmissionFilters()

  return (
    <FiltersProvider value={filtersContext}>
      <ListPage>
        <ListPageHeaderGroup>
          <ListPageHeader>
            <PageTitle>{t('submissionsPage.title')}</PageTitle>
            {pipe(
              filtersContext.filters.formId,
              O.fold(constNull, (formId) => (
                <ExportSubmissionsCSVButton
                  formId={formId}
                  creatorId={filtersContext.filters.creatorId}
                  published={filtersContext.filters.published}
                  tags={filtersContext.filters.tags}
                />
              ))
            )}
          </ListPageHeader>

          <SubmissionListFilters />
        </ListPageHeaderGroup>

        <SubmissionList />
      </ListPage>
    </FiltersProvider>
  )
}
