import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuContent,
  MenuOption,
  MenuOptionGroup,
  Spinner,
  Text
} from '@woorcs/design-system'
import { Suspense, useCallback, useMemo, useState } from 'react'
import { endOfWeek, endOfYear, startOfWeek, startOfYear, sub } from 'date-fns'
import { useQuery } from 'urql'
import { PeriodInterval } from '@woorcs/graphql/src/schema'
import { useTranslation } from 'react-i18next'

import { Bars } from './Bars'
import { WeeklySubmissionsWidgetQueryDocument } from './__generated__/WeeklySubmissionsWidget'

interface WeeklySubmissionsWidgetChartProps {
  period: PeriodInterval
  startDate: Date
  endDate: Date
}

const WeeklySubmissionsWidgetChart = ({
  period,
  startDate,
  endDate
}: WeeklySubmissionsWidgetChartProps) => {
  const [query] = useQuery({
    requestPolicy: 'network-only',
    query: WeeklySubmissionsWidgetQueryDocument,
    variables: useMemo(
      () => ({
        input: {
          interval: period,
          startDate,
          endDate
        }
      }),
      [endDate, period, startDate]
    )
  })

  const barData = useMemo(
    () =>
      (query.data?.submissionReport ?? []).map((d) => ({
        date: d.periodStart,
        started: d.createdSubmissionCount,
        completed: d.completedSubmissionCount
      })),
    [query.data?.submissionReport]
  )

  return <Bars period={period} height={290} data={barData} />
}

export const WeeklySubmissionsWidget = () => {
  const { t } = useTranslation('dashboard')
  const [period, setPeriod] = useState<PeriodInterval>(PeriodInterval.Daily)
  const { startDate, endDate } = useMemo(() => {
    switch (period) {
      case PeriodInterval.Daily:
        return {
          startDate: startOfWeek(new Date(), {
            weekStartsOn: 1
          }),
          endDate: endOfWeek(new Date(), {
            weekStartsOn: 1
          })
        }
      case PeriodInterval.Weekly:
        return {
          startDate: sub(new Date(), {
            months: 2
          }),
          endDate: endOfWeek(new Date(), {
            weekStartsOn: 1
          })
        }
      case PeriodInterval.Monthly:
        return {
          startDate: startOfYear(new Date()),
          endDate: endOfYear(new Date())
        }
      case PeriodInterval.Yearly:
        return {
          startDate: sub(new Date(), {
            years: 5
          }),
          endDate: new Date()
        }
    }
  }, [period])

  const handlePeriodChange = useCallback((period: PeriodInterval) => {
    setPeriod(period)
    // startTransition(() => {
    //   setPeriod(period)
    // })
  }, [])

  const getPeriodOptionLabel = (period: PeriodInterval) => {
    switch (period) {
      case PeriodInterval.Daily:
        return t('totalSubmissionsWidget.options.thisWeek')
      case PeriodInterval.Weekly:
        return t('totalSubmissionsWidget.options.weekly')
      case PeriodInterval.Monthly:
        return t('totalSubmissionsWidget.options.monthly')
      case PeriodInterval.Yearly:
        return t('totalSubmissionsWidget.options.lastFiveYears')
    }
  }

  return (
    <Box py={6} borderRadius='large' bg='primary.50'>
      <Flex
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        px={6}
        mb={6}
      >
        <Text as='h3' fontSize='medium' fontWeight='regular'>
          {t('totalSubmissionsWidget.title')}
        </Text>

        <Menu placement='bottom-end'>
          <MenuButton>
            <Button size='small' colorVariant='primary'>
              {getPeriodOptionLabel(period)}
            </Button>
          </MenuButton>
          <MenuContent gutter={4}>
            <MenuOptionGroup
              type='radio'
              value={period}
              onChange={handlePeriodChange}
            >
              <MenuOption value={PeriodInterval.Daily}>
                {getPeriodOptionLabel(PeriodInterval.Daily)}
              </MenuOption>
              <MenuOption value={PeriodInterval.Weekly}>
                {getPeriodOptionLabel(PeriodInterval.Weekly)}
              </MenuOption>
              <MenuOption value={PeriodInterval.Monthly}>
                {getPeriodOptionLabel(PeriodInterval.Monthly)}
              </MenuOption>
              <MenuOption value={PeriodInterval.Yearly}>
                {getPeriodOptionLabel(PeriodInterval.Yearly)}
              </MenuOption>
            </MenuOptionGroup>
          </MenuContent>
        </Menu>
      </Flex>
      <Box height={290}>
        <Suspense
          fallback={
            <Flex
              flex={1}
              height='100%'
              justifyContent='center'
              alignItems='center'
            >
              <Spinner size='small' />
            </Flex>
          }
        >
          <WeeklySubmissionsWidgetChart
            period={period}
            startDate={startDate}
            endDate={endDate}
          />
        </Suspense>
      </Box>
    </Box>
  )
}
