import { compose, curry, divide } from 'ramda'
import { baseFontSize } from '@woorcs/design-tokens'

export const stripUnit = parseFloat

export const addUnit = (unit: string) => (value: number | string) =>
  `${value}${unit}`

export const pxToRelative = curry((base: number, value: number): number =>
  divide(value, base)
)

const baseFontSizeRatio = pxToRelative(baseFontSize)

export const rem = compose(addUnit('rem'), baseFontSizeRatio)
