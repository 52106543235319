import React, { useMemo } from 'react'
import { Flex, Separator, SecondaryButton } from '@woorcs/design-system'
import * as Path from '@woorcs/types/Path'
import { pipe } from 'fp-ts/function'

import { AddElementMenu } from '../AddElementMenu'

interface ElementListSeparatorProps {
  path: Path.Path
}

export const ElementListSeparator = ({ path }: ElementListSeparatorProps) => {
  const at = useMemo(() => pipe(path, Path.movePath(1)), [path])

  return (
    <Flex
      css={{
        opacity: 0,
        transition: 'opacity 0.3s ease-out',
        ':hover': {
          opacity: 1
        }
      }}
      py={4}
      width='100%'
      alignItems='center'
    >
      <Separator mr={4} bg='primary.50' />
      <AddElementMenu at={at}>
        <SecondaryButton size='small'>Add element</SecondaryButton>
      </AddElementMenu>
      <Separator ml={4} bg='primary.50' />
    </Flex>
  )
}
