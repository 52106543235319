import React from 'react'

import { Text, TextProps } from '../Text'

export const H1 = (props: TextProps) => (
  <Text
    as='h1'
    fontWeight='bold'
    fontFamily='display'
    color='text.emphasized'
    fontSize='h1'
    letterSpacing='h1'
    lineHeight={1.1}
    {...props}
  />
)

export const H2 = (props: TextProps) => (
  <Text
    as='h2'
    fontWeight='bold'
    fontFamily='display'
    color='text.emphasized'
    fontSize='h2'
    letterSpacing='h2'
    lineHeight={1.25}
    {...props}
  />
)

export const H3 = (props: TextProps) => (
  <Text
    as='h3'
    fontWeight='bold'
    fontFamily='display'
    color='text.emphasized'
    fontSize='h3'
    letterSpacing='h3'
    lineHeight={1.1}
    {...props}
  />
)

export const H4 = (props: TextProps) => (
  <Text
    as='h4'
    fontWeight='bold'
    fontFamily='display'
    color='text.emphasized'
    fontSize='h4'
    letterSpacing='h4'
    lineHeight={1.33}
    {...props}
  />
)

export const H5 = (props: TextProps) => (
  <Text
    as='h5'
    fontWeight='bold'
    fontFamily='display'
    color='text.emphasized'
    fontSize='h5'
    letterSpacing='h5'
    lineHeight={1.4}
    {...props}
  />
)
