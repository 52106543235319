import { Flex, Spinner } from '@woorcs/design-system'

export const FullscreenLoader = () => {
  return (
    <Flex
      justifyContent='center'
      alignItems='center'
      width='100%'
      height='100vh'
    >
      <Spinner />
    </Flex>
  )
}
