import { Box, css, Text } from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import React, { useCallback } from 'react'
import { constFalse, pipe } from 'fp-ts/function'
import { UUID } from '@woorcs/types/UUID'

import * as ResponseSet from '../../types/ResponseSet'

type OptionProps = {
  option: ResponseSet.ResponseSetOption
  isSelected: boolean
  onSelect(value: UUID): void
}

const Option = ({ isSelected, option, onSelect }: OptionProps) => {
  const handleClick = useCallback(() => {
    onSelect(option.id)
  }, [onSelect, option.id])

  return (
    <Box
      css={css({
        cursor: 'pointer',
        color: isSelected ? 'white' : 'text.emphasized',
        bg: isSelected ? 'primary.500' : 'grey.100',
        ':hover': {
          color: 'white',
          bg: 'primary.500'
        }
      })}
      borderRadius='small'
      py={2}
      px={4}
      mb={2}
      onClick={handleClick}
    >
      <Text fontSize='small' fontWeight='semi-bold'>
        {option.label.text}
      </Text>
    </Box>
  )
}

type ResponseSetInputProps = {
  responseSet: ResponseSet.ResponseSet
  value: O.Option<UUID>
  onChange(value: string): void
}

export const ResponseSetInput = ({
  responseSet,
  value,
  onChange
}: ResponseSetInputProps) => {
  const isSelected = (id: UUID) =>
    pipe(
      value,
      O.map((value) => value === id),
      O.getOrElse(constFalse)
    )

  return (
    <Box>
      {responseSet.options.map((option) => (
        <Option
          key={option.id}
          isSelected={isSelected(option.id)}
          option={option}
          onSelect={onChange}
        />
      ))}
    </Box>
  )
}
