import React, { ReactNode, forwardRef } from 'react'

import { Surface, SurfaceProps } from '../Surface'
import { system } from '../../../system'

export const CardHeader = system('div')({
  borderTopLeftRadius: 'large',
  borderTopRightRadius: 'large',
  m: '-1px'
})

export const CardFooter = system('div')({
  borderBottomLeftRadius: 'large',
  borderBottomRightRadius: 'large',
  m: '-1px'
})

export interface CardProps extends SurfaceProps {
  children?: ReactNode
}

export const Card = forwardRef<typeof system.div, CardProps>(
  ({ children, ...other }, ref) => (
    <Surface
      ref={ref}
      __css={{
        borderTopLeftRadius: 'large',
        borderTopRightRadius: 'large',
        borderBottomLeftRadius: 'large',
        borderBottomRightRadius: 'large'
        // border: 'thin',
        // borderColor: 'grey.50'
      }}
      elevation={1}
      {...other}
    >
      {children}
    </Surface>
  )
)
