import { useCallback, useState, ReactElement } from 'react'
import { FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import { useSnackbar } from '@woorcs/design-system'

import { Role } from '@app/schema/schema.generated'

import { UserFormModal, UserFormUser } from '../UserForm'

import { AddUserToOrganizationDocument } from './__generated__/AddUserModal'

type AddUserModalProps = {
  children: ReactElement
}

export const AddUserModal = ({ children }: AddUserModalProps) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const { showSnackbar } = useSnackbar()
  const [, addUser] = useMutation(AddUserToOrganizationDocument)

  const handleSubmit = useCallback(
    (user: UserFormUser, actions: FormikHelpers<UserFormUser>) => {
      addUser({
        input: { ...user, role: (user.role as Role) || null }
      })
        .then(() => {
          showSnackbar({
            title: t('users:addUserModal.addUserSuccessSnackbar.title'),
            variant: 'success'
          })
          setVisible(false)
        })
        .catch(() => {
          showSnackbar({
            title: 'Failed to save user',
            variant: 'danger'
          })
        })
        .finally(() => {
          actions.setSubmitting(false)
        })
    },
    [addUser, showSnackbar, t]
  )

  return (
    <UserFormModal
      title={t('users:addUserModal.title')}
      visible={visible}
      onVisiblityChange={setVisible}
      onSubmit={handleSubmit}
    >
      {children}
    </UserFormModal>
  )
}
