import { UUID } from '@woorcs/types/UUID'
import * as O from 'fp-ts/Option'
import { constNull, pipe } from 'fp-ts/function'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import { Path } from '@woorcs/types/Path'

import { EditPageElements } from '../EditPage/EditPageElements'
import { useElement } from '../../state'

interface EditPageProps {
  pageId: UUID
}

export const EditPage = ({ pageId }: EditPageProps) => {
  const page = useElement(pageId) as O.Option<{
    element: InspectionFormDocument.InspectionFormPageElement
    path: Path
  }>

  return pipe(
    page,
    O.fold(constNull, (page) => (
      <EditPageElements page={page.element} index={page.path[0]} />
    ))
  )
}
