import React from 'react'
import { space } from '@woorcs/utils'

import { Surface, SurfaceProps } from '../../layout'

export const NAVBAR_HEIGHT = space(18)

export const Navbar = (props: Omit<SurfaceProps, 'elevation' | 'color'>) => (
  <Surface
    __css={
      {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'no-wrap',
        alignItems: 'center',
        height: NAVBAR_HEIGHT,
        border: 'none',
        px: space(5),
        overflow: 'visible'
        // zIndex: 'navbar'
      } as any
    }
    elevation={0}
    zIndex={'navbar' as any}
    bg='grey.100'
    {...props}
  />
)
