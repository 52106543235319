import React from 'react'
import { useParams } from 'react-router-dom'
import { constNull, pipe } from 'fp-ts/function'
import * as Optional from 'monocle-ts/Optional'
import * as DE from '@nll/datum/DatumEither'
import { useQuery } from 'urql'
import { queryToDatumEither } from '@woorcs/graphql'

import { EditForm, BackButton, ResourceNotFound } from '@app/components'

import {
  FormRevisionEditorPageQuery,
  FormRevisionEditorPageQueryDocument
} from './__generated__/FormRevisionEditor'

const FormNotFound = () => {
  return (
    <ResourceNotFound
      title='Form not found'
      description="We could not find the form you're looking for."
    >
      <BackButton to='/forms' variant='filled'>
        Back to forms
      </BackButton>
    </ResourceNotFound>
  )
}

const responseOptional = pipe(
  Optional.id<FormRevisionEditorPageQuery>(),
  Optional.prop('form'),
  Optional.fromNullable
)

export const FormRevisionEditorPage = () => {
  const { formId } = useParams()

  return pipe(
    useQuery({
      query: FormRevisionEditorPageQueryDocument,
      variables: { id: formId as string }
    }),
    queryToDatumEither(responseOptional),
    DE.squash(
      constNull,
      () => <FormNotFound />,
      (form) => {
        return <EditForm form={form} />
      }
    )
  )
}
