import { clamp, ordNumber } from 'fp-ts/Ord'
import React from 'react'

import { Box, BoxProps } from '../../layout'

const clampProgressWidth = clamp(ordNumber)(0, 100)

type ProgressBarProps = {
  value: number
  min?: number
  max?: number
} & BoxProps

export const ProgressBar = ({
  value = 0,
  min = 0,
  max = 1,
  ...other
}: ProgressBarProps) => {
  const progress = ((value - min) * 100) / (max - min)

  return (
    <Box
      width='100%'
      bg='primary.50'
      borderRadius='large'
      height={4}
      {...other}
    >
      <Box
        width={`${clampProgressWidth(progress)}%`}
        bg='primary.500'
        borderRadius='large'
        height={4}
      />
    </Box>
  )
}
