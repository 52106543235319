import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, H4, useSnackbar } from '@woorcs/design-system'

import { ViewerProfileForm } from '@app/components'

export const ProfileSettingsPage = () => {
  const { t } = useTranslation()
  const { showSnackbar } = useSnackbar()

  const handleSubmitSuccess = useCallback(() => {
    showSnackbar({
      title: t('viewer:settings.updateSettingsSuccessSnackbar.title'),
      variant: 'success'
    })
  }, [showSnackbar, t])

  return (
    <Box>
      <Box
        justifyContent='space-between'
        alignItems='center'
        mb={8}
        pb={4}
        borderBottom='base'
      >
        <H4>Profile</H4>
      </Box>
      <ViewerProfileForm onSubmitSuccess={handleSubmitSuccess} />
    </Box>
  )
}
