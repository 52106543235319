/* eslint-disable complexity */
import {
  ImageIcon,
  FormatTextIcon,
  CalendarIcon,
  ClockIcon,
  LocationIcon,
  EditIcon,
  EmailIcon,
  ListIcon,
  HashtagIcon,
  DocumentIcon,
  DangerIcon
} from '@woorcs/design-system'
import { absurd } from 'fp-ts/function'
import { UUID, uuid } from '@woorcs/types/UUID'
import { FormElement, TranslateableText } from '@woorcs/form'

type FormElementType = FormElement.FormElementType['type']

const matchFormElementTypeName =
  <R>(cases: Record<FormElementType, (type: FormElementType) => R>) =>
  (type: FormElementType) =>
    cases[type](type)

export const formElementLabel = matchFormElementTypeName({
  TextInput: () => 'Text',
  NumberInput: () => 'Number',
  EmailInput: () => 'Email',
  TimeInput: () => 'Time',
  SelectInput: () => 'Single Select',
  MultiSelectInput: () => 'Multi Select',
  DateInput: () => 'Date',
  DateRangeInput: () => 'Date range',
  ImageInput: () => 'Image',
  LocationInput: () => 'Location',
  SignatureInput: () => 'Signature',
  GroupInput: () => 'Document',
  Fieldset: () => 'Fieldset',
  Text: () => 'Text',
  Alert: () => 'Alert'
})

export const formElementIcon = matchFormElementTypeName({
  TextInput: () => FormatTextIcon,
  NumberInput: () => HashtagIcon,
  EmailInput: () => EmailIcon,
  TimeInput: () => ClockIcon,
  SelectInput: () => ListIcon,
  MultiSelectInput: () => ListIcon,
  DateInput: () => CalendarIcon,
  DateRangeInput: () => CalendarIcon,
  ImageInput: () => ImageIcon,
  LocationInput: () => LocationIcon,
  SignatureInput: () => EditIcon,
  GroupInput: () => DocumentIcon,
  Fieldset: () => FormatTextIcon,
  Text: () => FormatTextIcon,
  Alert: () => DangerIcon
})

type SubsetOfU<T, K> = T extends { type: K } ? T : never

export const createElementType = (
  type: FormElementType,
  defaultResponseSetId: UUID
): FormElement.FormElement => {
  const key = uuid()

  const match = (): SubsetOfU<FormElement.FormElementType, FormElementType> => {
    switch (type) {
      case 'TextInput': {
        return FormElement.textInputElement({
          key,
          label: TranslateableText.translateableText('Text'),
          placeholder: TranslateableText.translateableText(''),
          informativeText: TranslateableText.translateableText('')
        })
      }

      case 'NumberInput': {
        return FormElement.numberInputElement({
          key,
          label: TranslateableText.translateableText('Number'),
          placeholder: TranslateableText.translateableText(''),
          options: {
            max: null,
            min: null
          },
          informativeText: TranslateableText.translateableText('')
        })
      }

      case 'EmailInput': {
        return FormElement.emailInputElement({
          key,
          label: TranslateableText.translateableText('Email'),
          placeholder: TranslateableText.translateableText(''),
          informativeText: TranslateableText.translateableText('')
        })
      }

      case 'TimeInput': {
        return FormElement.timeInputElement({
          key,
          label: TranslateableText.translateableText('Time'),
          placeholder: TranslateableText.translateableText(''),
          informativeText: TranslateableText.translateableText('')
        })
      }

      case 'SelectInput': {
        return FormElement.selectInputElement({
          key,
          label: TranslateableText.translateableText('Select'),
          informativeText: TranslateableText.translateableText(''),
          responseSet: defaultResponseSetId
        })
      }

      case 'MultiSelectInput': {
        return FormElement.multiSelectInputElement({
          key,
          label: TranslateableText.translateableText('Select'),
          informativeText: TranslateableText.translateableText(''),
          responseSet: defaultResponseSetId
        })
      }

      case 'DateInput': {
        return FormElement.dateInputElement({
          key,
          label: TranslateableText.translateableText('Date'),
          placeholder: TranslateableText.translateableText(''),
          informativeText: TranslateableText.translateableText(''),
          options: {
            withTime: false
          }
        })
      }

      case 'DateRangeInput': {
        return FormElement.dateRangeInputElement({
          key,
          label: TranslateableText.translateableText('Select a date range'),
          informativeText: TranslateableText.translateableText(''),
          options: {
            withTime: false,
            allowWeekends: false
          }
        })
      }

      case 'ImageInput': {
        return FormElement.imageInputElement({
          key,
          label: TranslateableText.translateableText('Image'),
          informativeText: TranslateableText.translateableText(''),
          options: {
            multiple: false
          }
        })
      }

      case 'LocationInput': {
        return FormElement.locationInputElement({
          key,
          label: TranslateableText.translateableText('Select location'),
          informativeText: TranslateableText.translateableText('')
        })
      }

      case 'SignatureInput': {
        return FormElement.signatureInputElement({
          key,
          label: TranslateableText.translateableText('Sign below'),
          informativeText: TranslateableText.translateableText(''),
          options: {
            allowUpload: false
          }
        })
      }

      case 'GroupInput': {
        return FormElement.groupInputElement({
          key,
          label: TranslateableText.translateableText('Group'),
          informativeText: TranslateableText.translateableText(''),
          document: uuid()
        })
      }

      case 'Fieldset': {
        return FormElement.fieldsetElement({
          children: []
        })
      }

      case 'Text': {
        return FormElement.textElement({
          text: TranslateableText.translateableText('')
        })
      }

      case 'Alert': {
        return FormElement.alertElement({
          children: [
            FormElement.textElement({
              text: TranslateableText.translateableText('Alert text')
            })
          ],
          status: 'danger'
        })
      }

      default: {
        return absurd(type)
      }
    }
  }

  return match()
}
