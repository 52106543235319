import { ReactElement, forwardRef, Ref } from 'react'

import { MenuGroup, MenuGroupOptions } from './MenuGroup'
import {
  useMenuOptionGroup,
  UseMenuOptionGroupProps,
  MenuOptionGroupProvider
} from './useMenu'

interface MenuOptionGroupProps<T>
  extends UseMenuOptionGroupProps<T>,
    MenuGroupOptions {
  children: ReactElement | ReactElement[]
}

export const MenuOptionGroup = forwardRef(function <T>(
  { children, label, ...other }: MenuOptionGroupProps<T>,
  ref: Ref<HTMLDivElement>
) {
  const context = useMenuOptionGroup(other)

  return (
    <MenuOptionGroupProvider value={context}>
      <MenuGroup ref={ref} label={label}>
        {children}
      </MenuGroup>
    </MenuOptionGroupProvider>
  )
})
