import React, { useCallback } from 'react'
import { TableCell, TableRow, Button, Text } from '@woorcs/design-system'

import { OrganizationListOrganizationFragment } from '../__generated__/OrganizationList'

interface OrganizationRowProps {
  organization: OrganizationListOrganizationFragment
  onManageClicked: (id: string) => void
}

export const OrganizationRow = ({
  organization,
  onManageClicked
}: OrganizationRowProps) => {
  const changeOrganization = useCallback(() => {
    onManageClicked(organization.id)
  }, [onManageClicked, organization.id])

  return (
    <TableRow>
      <TableCell>
        <Text fontSize='largeBody'>{organization.name}</Text>
      </TableCell>

      <TableCell textAlign='right'>
        <Button variant='outlined' onClick={changeOrganization}>
          Manage
        </Button>
      </TableCell>
    </TableRow>
  )
}
