import * as IOEq from 'io-ts/Eq'
import * as Eq from 'fp-ts/Eq'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as RNEA from 'fp-ts/ReadonlyNonEmptyArray'

import { Schemable1 } from '../schemable'
import { interpreter } from '../interpreter'

const schemable: Schemable1<Eq.URI> = {
  ...IOEq.Schemable,
  ...IOEq.WithRefine,
  any: Eq.eqStrict,
  unknown: Eq.eqStrict,
  nonEmptyArray: NEA.getEq,
  readonlyNonEmptyArray: RNEA.getEq
}

export const Equals = {
  ...schemable
}

export const getEq = interpreter(Equals)
