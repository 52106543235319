import React from 'react'
import { H4, Box, Button, Flex } from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'

import { AddTagModal, TagList } from '@app/components'

export const OrganizationTagsSettingsPage = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        mb={4}
        pb={4}
        borderBottom='base'
      >
        <H4>{t('common:tags')}</H4>
        <AddTagModal>
          <Button size='small' colorVariant='secondary'>
            {t('tags:tagsPage.addTagButton.label')}
          </Button>
        </AddTagModal>
      </Flex>

      <Box mx='-8px'>
        <TagList />
      </Box>
    </Box>
  )
}
