import { ChangeEvent, ReactElement, useCallback, useMemo } from 'react'
import {
  Box,
  Flex,
  List,
  Modal,
  ModalDialog,
  ModalDisclosure,
  system,
  Text
} from '@woorcs/design-system'
import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as Ariakit from '@ariakit/react'

type RenderItem<T> = (item: T) => ReactElement

interface SelectListProps<T> {
  items: T[]
  renderItem: RenderItem<T>
  // onSelect(item: T): void
}

const SelectList = <T,>({ items, renderItem }: SelectListProps<T>) => {
  const modalStore = Ariakit.useDialogContext()

  return (
    <Box height='100%' overflowY='auto' p={3}>
      <List>
        {items.map((item, index) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            key={index}
            onClick={modalStore?.hide}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                modalStore?.hide()
              }
            }}
          >
            {renderItem(item)}
          </div>
        ))}
      </List>
    </Box>
  )
}

const SearchInput = system('input')({
  width: '100%',
  py: 6,
  px: 6,
  bg: 'transparent',
  fontSize: 'medium',
  border: 'none',
  borderBottom: 'base',
  color: 'grey.900',
  ':focus': {
    outline: 'none'
  }
})

interface SearchModalContentProps<T> {
  searchPlaceholder: string
  query: string
  items: T[]
  onQueryChange: (query: any) => any
  filterItem?(item: T, query: string): boolean
  renderItem: RenderItem<T>
}

const SearchModalContent = <T,>({
  searchPlaceholder,
  query,
  items,
  onQueryChange,
  filterItem,
  renderItem
}: SearchModalContentProps<T>) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onQueryChange(e.target.value)
    },
    [onQueryChange]
  )

  const filteredItems = useMemo(
    () =>
      filterItem
        ? pipe(
            items,
            A.filter((item) => filterItem(item, query))
          )
        : items,
    [filterItem, items, query]
  )

  return (
    <Flex flexDirection='column' maxHeight={420}>
      <SearchInput
        value={query}
        placeholder={searchPlaceholder}
        onChange={handleChange}
      />

      {pipe(
        filteredItems,
        NEA.fromArray,
        O.fold(
          () => (
            <Box px={6} py={6}>
              <Text color='text.muted'>No items found</Text>
            </Box>
          ),
          (items) => (
            <SelectList
              items={items}
              renderItem={renderItem}
              // onSelect={handleSelect}
            />
          )
        )
      )}
    </Flex>
  )
}

interface SearchModalProps<T> {
  searchPlaceholder?: string
  query: string
  items: T[]
  onQueryChange: (query: any) => any
  filterItem?(item: T, query: string): boolean
  renderItem: RenderItem<T>
  children: ReactElement
}

export const SearchModal = <T,>({
  searchPlaceholder = 'Search',
  query,
  items,
  onQueryChange,
  filterItem,
  renderItem,
  children
}: SearchModalProps<T>) => (
  <Modal>
    <ModalDisclosure>{children}</ModalDisclosure>

    <ModalDialog aria-label='Search' centerContent={false}>
      <SearchModalContent
        items={items}
        searchPlaceholder={searchPlaceholder}
        filterItem={filterItem}
        renderItem={renderItem}
        query={query}
        onQueryChange={onQueryChange}
      />
    </ModalDialog>
  </Modal>
)
