import React, { memo } from 'react'

import { Checkbox } from '../Checkbox'
import { ListItemActionField } from '../../data'

interface ListboxOptionCheckProps {
  multiple?: boolean
  checked: boolean
  disabled?: boolean
}

export const ListboxOptionCheck = memo(
  ({ checked, multiple, disabled }: ListboxOptionCheckProps) => {
    if (multiple) {
      return (
        <ListItemActionField __css={{ pointerEvents: 'none' }}>
          <Checkbox size='small' checked={checked} disabled={disabled} />
        </ListItemActionField>
      )
    }

    return null
  }
)
