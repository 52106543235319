import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type PreviewModalFragment = { __typename: 'FormRevision', id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus, definition: any };

export type PreviewModalQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


export type PreviewModalQuery = { __typename: 'Query', formRevision: { __typename: 'FormRevision', id: string, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus, definition: any } };

export const PreviewModalFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PreviewModalFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"publishedAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"definition"}}]}}]} as unknown as DocumentNode<PreviewModalFragment, unknown>;
export const PreviewModalQueryDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PreviewModalQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"formRevision"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PreviewModalFragment"}}]}}]}},...PreviewModalFragmentDoc.definitions]} as unknown as DocumentNode<PreviewModalQuery, PreviewModalQueryVariables>;