import { ChangeEvent, FocusEvent, forwardRef } from 'react'
import { rem, whenTrue } from '@woorcs/utils'
import { variant } from 'styled-system'

import {
  ToggleBase,
  ToggleBaseProps,
  ToggleBaseControlProps
} from '../ToggleBase'
import { focusStyle } from '../../../styles'
import { system, css } from '../../../system'

import { RadioIcon } from './RadioIcon'
import { useRadio } from './useRadio'

const isChecked = whenTrue(['checked'])
const isDisabled = whenTrue(['disabled'])
const isFocused = whenTrue(['focused'])

const size = variant({
  prop: 'size',
  variants: {
    small: {
      width: rem(18),
      height: rem(18)
    },
    medium: {
      width: rem(24),
      height: rem(24)
    }
  }
})

type RadioSize = 'small' | 'medium'

interface RadioProps extends ToggleBaseControlProps {
  size: RadioSize
}

const Radio = system('div')<RadioProps>(
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    apperance: 'none',
    borderRadius: '100%',
    borderColor: 'primary.500',
    borderWidth: 2,
    borderStyle: 'solid',
    boxShadow: 'none',
    color: 'white',
    cursor: 'pointer',
    transition: 'background 0.15s ease-in',
    ':hover': {
      borderColor: 'primary.dark'
    }
  } as any),
  isChecked({
    backgroundColor: 'primary.500',
    border: 'none',
    boxShadow: 'input',
    ':hover': {
      backgroundColor: 'primary.400'
    }
  }),
  isDisabled({
    backgroundColor: 'primary.100',
    cursor: 'not-allowed',
    color: 'primary.medium',
    boxShadow: 'none',
    border: 'none'
  }),
  isFocused(focusStyle),
  size
)

interface RadioButtonProps
  extends Omit<ToggleBaseProps, 'checked' | 'controlAs' | 'children' | 'ref'> {
  name?: string
  value: string
  checked?: boolean
  disabled?: boolean
  indeterminate?: boolean
  size?: RadioSize
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
}

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      name,
      value,
      checked,
      disabled,
      size = 'medium',
      onChange,
      onBlur,
      ...other
    },
    ref
  ) => {
    const [state, inputProps] = useRadio({
      ref,
      name,
      value,
      checked,
      disabled,
      onChange,
      onBlur
    })

    return (
      <ToggleBase type='radio' {...inputProps} {...other}>
        <Radio size={size}>
          <RadioIcon size={size} checked={state.checked} />
        </Radio>
      </ToggleBase>
    )
  }
)
