import { whenTrue } from '@woorcs/utils'
import * as Ariakit from '@ariakit/react'

import { system, SystemComponentProps } from '../../../system'

const whenIsDisabled = whenTrue(['disabled'])

export interface PlainButtonProps
  extends Omit<Ariakit.ButtonProps, 'color' | 'css'>,
    SystemComponentProps {}

export const plainButtonStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  padding: 0,
  background: 'none',
  border: 'none',
  outline: 'none',
  color: 'inherit',
  lineHeight: 1,
  appearance: 'none',
  cursor: 'pointer'
} as const

export const PlainButton = system(Ariakit.Button)<PlainButtonProps>(
  {
    display: 'inline-flex',
    alignItems: 'center',
    padding: 0,
    background: 'none',
    border: 'none',
    outline: 'none',
    color: 'inherit',
    lineHeight: 1,
    appearance: 'none',
    cursor: 'pointer'
    // ':focus': focusStyle
  },
  whenIsDisabled({
    cursor: 'not-allowed'
  })
)

PlainButton.defaultProps = {
  type: 'button'
}
