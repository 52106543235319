import React from 'react'

import { Text, TextProps } from '../Text'

export const Body = (props: TextProps) => (
  <Text
    as='p'
    fontWeight='regular'
    fontSize='body'
    lineHeight='body'
    {...props}
  />
)

export const SmallBody = (props: TextProps) => (
  <Text
    as='p'
    fontWeight='regular'
    fontSize='smallBody'
    lineHeight='smallBody'
    {...props}
  />
)

export const LargeBody = (props: TextProps) => (
  <Text
    as='p'
    fontWeight='regular'
    fontSize='medium'
    lineHeight='largeBody'
    {...props}
  />
)
