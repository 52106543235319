/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useRef, useEffect, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useMeasure } from '@woorcs/hooks'
import { BoxModel, forkRefs } from '@woorcs/utils'
import { getEmptyImage } from 'react-dnd-html5-backend'
import * as O from 'fp-ts/Option'
import { constant } from 'fp-ts/function'
import { FormElement } from '@woorcs/form'
import * as Path from '@woorcs/types/Path'

import { useEditorContext } from '../../../state'

import {
  ElementListItemDragItem,
  ELEMENT_LIST_ITEM_DRAG_TYPE
} from './DragItem'

interface UsedragElementFieldProps {
  element: FormElement.FormElementType
  path: Path.Path
  isAnimating: boolean
}

const getRect = O.fold(
  constant({
    width: 0,
    height: 0
  }),
  (box: BoxModel) => ({
    width: box.paddingBox.width,
    height: box.paddingBox.height
  })
)

interface UseDropCollectedProps {
  isOver: boolean
  isDraggingOther: boolean
  isDraggingOtherId: O.Option<string>
}

export const useSortableElementListItem = ({
  element,
  path,
  isAnimating
}: UsedragElementFieldProps) => {
  const { editor } = useEditorContext()
  const [ref, box] = useMeasure<HTMLDivElement>()
  const [disabled, setDisabled] = useState(false)
  const currentPathRef = useRef(path)

  const [{ isDragging }, dragRef, dragPreview] = useDrag({
    type: ELEMENT_LIST_ITEM_DRAG_TYPE,
    item: () => {
      currentPathRef.current = path

      return {
        type: ELEMENT_LIST_ITEM_DRAG_TYPE,
        element,
        rect: getRect(box),
        originalPath: path,
        currentPathRef
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const [{ isOver, isDraggingOther, isDraggingOtherId }, dropRef] = useDrop<
    ElementListItemDragItem,
    UseDropCollectedProps,
    UseDropCollectedProps
  >({
    accept: [ELEMENT_LIST_ITEM_DRAG_TYPE],
    collect: (monitor) => {
      const item = monitor.getItem<ElementListItemDragItem>()

      if (!item || item.type !== ELEMENT_LIST_ITEM_DRAG_TYPE) {
        return {
          isOver: false,
          isDraggingOther: false,
          isDraggingOtherId: O.none
        }
      }

      const isDraggingOther = Boolean(item)
      const isDraggingOtherId = isDraggingOther
        ? O.some(item.element.id)
        : O.none

      return {
        isOver: monitor.canDrop() && monitor.isOver(),
        isDraggingOther,
        isDraggingOtherId
      }
    },
    hover(item, monitor) {
      const type = monitor.getItemType()

      if (type !== ELEMENT_LIST_ITEM_DRAG_TYPE) {
        return
      }

      const { element: dragElement, currentPathRef: dragPathRef } = item
      const dragPath = dragPathRef.current
      const [, dragIndex] = dragPath
      const [, hoverIndex] = path

      if (!ref.current) {
        return
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      if (!clientOffset) {
        return
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY / 2) {
        return
      }

      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY + hoverMiddleY / 2
      ) {
        return
      }

      if (dragElement.id !== element.id && !Path.Path.equals(dragPath, path)) {
        const moveElement = editor.moveElement(dragElement.id, path)

        if (isAnimating) {
          return
        }

        if (disabled) {
          return
        }

        moveElement()

        setDisabled(true)

        dragPathRef.current = path
      }
    }
  })

  useEffect(() => {
    if (disabled) {
      window.setTimeout(() => setDisabled(false), 500)
    }
  }, [disabled])

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [dragPreview])

  return [
    forkRefs(ref, dropRef as any, dragRef as any),
    { isOver, isDragging, isDraggingOther, isDraggingOtherId }
  ] as const
}
