import { forwardRef, useMemo, MouseEvent, ReactNode } from 'react'
import { space } from '@woorcs/utils'

import { system, PropsOf } from '../../../system'
import { ChevronDownIcon } from '../../icons'
import { PlainButton, IconButton } from '../../buttons'

import {
  TableStyleContext,
  TableStyleProvider,
  useTableStyle
} from './useTableStyle'

interface TableProps
  extends PropsOf<typeof system.table>,
    Partial<TableStyleContext> {}

const defaultPadding = (dense: boolean) => (dense ? 3 : 6)

export const Table = ({
  dense = false,
  outerPadding = space(defaultPadding(dense)),
  ...props
}: TableProps) => {
  const context = useMemo(
    () => ({
      dense,
      outerPadding
    }),
    [dense, outerPadding]
  )

  return (
    <TableStyleProvider value={context}>
      <system.table
        __css={{
          borderCollapse: 'collapse',
          borderSpacing: 0
        }}
        textAlign='left'
        width='100%'
        {...props}
      />
    </TableStyleProvider>
  )
}

export const TableBody = (props: PropsOf<typeof system.tbody>) => (
  <system.tbody {...props} />
)

export const TableRow = (props: PropsOf<typeof system.tr>) => {
  return (
    <system.tr
      __css={{
        cursor: props.onClick ? 'pointer' : 'default',
        ':hover': {
          backgroundColor: props.onClick ? 'grey.50' : 'transparent'
        }
      }}
      verticalAlign='middle'
      borderBottom='thin'
      borderColor='grey.50'
      {...props}
    />
  )
}

export const TableHead = ({
  children,
  ...props
}: PropsOf<typeof system.thead>) => (
  <system.thead {...props}>
    <TableRow __css={{}}>{children}</TableRow>
  </system.thead>
)

type TableCellProps = PropsOf<typeof system.td>

export const TableCell = forwardRef<'td', TableCellProps>((props, ref) => {
  const { outerPadding, dense } = useTableStyle()
  const horizontalPadding = defaultPadding(dense)

  return (
    <system.td
      ref={ref}
      css={{
        ':first-of-type': {
          paddingLeft: outerPadding
        },
        ':last-of-type': {
          paddingRight: outerPadding
        }
      }}
      pr={horizontalPadding}
      pl={horizontalPadding}
      py={dense ? 3 : 5}
      borderTopWidth='1px'
      borderColor='inherit'
      fontSize='small'
      {...props}
    />
  )
})

export const TableCheckboxCell = forwardRef<'td', TableCellProps>(
  (props: TableCellProps, ref) => (
    <TableCell ref={ref} width='24px' pr={0} {...props} />
  )
)

export const TableHeadCell = (props: PropsOf<typeof system.th>) => (
  <TableCell as='th' fontWeight='bold' {...props} />
)

interface TableSortLabelProps {
  active: boolean
  direction: 'asc' | 'desc'
  onClick(e: MouseEvent<HTMLButtonElement>): void
  children: ReactNode
}

export const TableSortLabel = ({
  active,
  direction,
  onClick,
  children
}: TableSortLabelProps) => {
  return (
    <PlainButton
      __css={{
        color: 'inherit',
        fontWeight: 'inherit',
        '> div': {
          opacity: active ? 1 : 0
        },
        ':hover div': {
          opacity: 1
        }
      }}
      onClick={onClick}
    >
      {children}
      <IconButton
        as='div'
        size='mini'
        ml={1}
        css={{
          transform: direction === 'asc' ? 'rotate(-180deg)' : 'rotate(0)',
          transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
          willChange: 'transform, opacity'
        }}
      >
        <ChevronDownIcon />
      </IconButton>
    </PlainButton>
  )
}
