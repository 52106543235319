import { ReactElement } from 'react'
import { Modal, ModalDialog, ModalDisclosure } from '@woorcs/design-system'

import { ManageDocuments } from './ManageDocuments'

type ManageDocumentsModalProps = {
  children: ReactElement
}

export const ManageDocumentsModal = ({
  children
}: ManageDocumentsModalProps) => (
  <Modal>
    <ModalDisclosure>{children}</ModalDisclosure>
    <ModalDialog width={540} px={5} py={8}>
      <ManageDocuments />
    </ModalDialog>
  </Modal>
)
