import { forwardRef, ComponentProps } from 'react'
import { Box, Flex, FormIcon, system } from '@woorcs/design-system'
import { FormElement } from '@woorcs/form'
import { RenderFormElement } from '@woorcs/form/react-dom'
import { motion } from 'framer-motion'

const SystemMotion = system(motion.div)({})

export interface ElementListItemProps
  extends ComponentProps<typeof SystemMotion> {
  element: FormElement.FormElementType
}

export const ElementListItem = forwardRef<HTMLDivElement, ElementListItemProps>(
  ({ element, ...other }, ref) => (
    <Box ref={ref} as={motion.div} borderRadius='medium' {...other}>
      <Box style={{ pointerEvents: 'none' }} position='relative'>
        <RenderFormElement element={element} />
        {element.rule && (
          <Box position='absolute' top={2} right={2}>
            <Flex bg='orange.50' p={1} borderRadius='small' flexGrow={0}>
              <FormIcon size='small' color='orange.500' />
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  )
)
