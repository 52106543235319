import * as IO from 'fp-ts/IO'
import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as RA from 'fp-ts/ReadonlyArray'
import { eqStrict } from 'fp-ts/Eq'
import { UUID } from '@woorcs/types/UUID'

import { useEditorSelector } from './useEditorSelector'

const eq = A.getEq(eqStrict)

export const usePageIds = () =>
  useEditorSelector(
    (editor) =>
      pipe(
        editor.getValue(),
        IO.map((root) =>
          pipe(
            root.children ?? [],
            RA.map((child) => child.id as UUID),
            RA.toArray
          )
        )
      ),
    eq
  )
