import { FormContextProvider } from '@woorcs/form/react'
import React, { ReactNode } from 'react'

import { InspectionFormContextProvider, InspectionFormContext } from './context'

interface InspectionFormProviderProps extends InspectionFormContext {
  children: ReactNode
}

export const InspectionFormProvider = ({
  children,
  ...environment
}: InspectionFormProviderProps) => (
  <InspectionFormContextProvider value={environment}>
    <FormContextProvider value={environment}>{children}</FormContextProvider>
  </InspectionFormContextProvider>
)
