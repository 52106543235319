import { variant } from 'styled-system'
import { whenPropEq, rem, space } from '@woorcs/utils'

export type ButtonVariant = 'filled' | 'outlined' | 'plain'

export type ButtonColorVariant =
  | 'primary'
  | 'secondary'
  | 'neutral'
  | 'success'
  | 'danger'
  | 'warning'

export type ButtonSizeVariant = 'mini' | 'small' | 'medium' | 'large'

export const outlined = whenPropEq('variant', 'outlined')
export const filled = whenPropEq('variant', 'filled')
export const plain = whenPropEq('variant', 'plain')

interface CreateColorVariantProps {
  variant?: ButtonVariant
  name: string
  color: string
}

const createOutlineVariant = (color: string) =>
  outlined({
    color: `${color}.500`,
    ':hover, &[hover]': {
      color: `${color}.600`,
      backgroundColor: `${color}.50`
    },
    ':active, &[active]': {
      color: `${color}.500`,
      backgroundColor: `${color}.100`
    }
  })

const createPlainVariant = (color: string) =>
  plain({
    color: `${color}.500`,
    ':hover': {
      backgroundColor: `${color}.50`
    }
  })

interface ColorVariantProps {
  variant?: ButtonVariant
  colorVariant?: string
  filled?: boolean
}

const createInvertedColorVariant = ({ name, color }: CreateColorVariantProps) =>
  whenPropEq<'colorVariant', string, ColorVariantProps>('colorVariant', name)(
    filled({
      backgroundColor: `${color}.50`,
      color: `${color}.500`,
      ':hover, &[hover]': {
        backgroundColor: `${color}.100`
      },
      ':active, &[active]': {
        backgroundColor: `${color}.100`
      }
    }),
    createOutlineVariant(color),
    createPlainVariant(color)
  )

const createColorVariant = ({ name, color }: CreateColorVariantProps) =>
  whenPropEq<'colorVariant', string, ColorVariantProps>('colorVariant', name)(
    filled({
      backgroundColor: `${color}.500`,
      color: `${color}.50`,
      ':hover, &[hover]': {
        backgroundColor: `${color}.600`
      },
      ':active, &[active]': {
        backgroundColor: `${color}.700`
      }
    }),
    createOutlineVariant(color),
    createPlainVariant(color)
  )

const primary = createColorVariant({
  name: 'primary',
  color: 'primary'
})

const secondary = createInvertedColorVariant({
  name: 'secondary',
  color: 'primary'
})

const neutral = createInvertedColorVariant({
  name: 'neutral',
  color: 'grey'
})

const success = createInvertedColorVariant({
  name: 'success',
  color: 'green'
})

const danger = createInvertedColorVariant({
  name: 'danger',
  color: 'red'
})

const warning = createInvertedColorVariant({
  name: 'warning',
  color: 'orange'
})

export const colorVariants = [
  primary,
  secondary,
  neutral,
  success,
  danger,
  warning
]

export const BUTTON_HEIGHT = {
  mini: rem(18),
  small: rem(28),
  medium: rem(42),
  large: rem(52)
}

export const sizeVariants = variant({
  prop: 'size',
  variants: {
    mini: {
      height: BUTTON_HEIGHT.mini,
      px: space(1),
      borderRadius: BUTTON_HEIGHT.mini,
      fontSize: rem(10)
    },
    small: {
      height: BUTTON_HEIGHT.small,
      px: space(3),
      borderRadius: BUTTON_HEIGHT.small,
      fontSize: 'mini'
    },
    medium: {
      height: BUTTON_HEIGHT.medium,
      px: space(3),
      borderRadius: 6,
      fontSize: 'small'
    },
    large: {
      height: BUTTON_HEIGHT.large,
      px: space(6),
      borderRadius: 6,
      fontSize: 'base'
    }
  }
})
