import { space } from '@woorcs/utils'
import * as Ariakit from '@ariakit/react'
import { AnimatePresence } from 'framer-motion'
import { ReactNode } from 'react'

import { CloseButton } from '../../buttons'

import {
  DialogSidebarContainer,
  DialogSidebarContainerProps
} from './DialogSidebarContainer'

interface DialogSidebarContentProps extends DialogSidebarContainerProps {
  showCloseButton?: boolean
  children?: ReactNode
}

export const DialogSidebarContent = ({
  showCloseButton = true,
  children,
  ...other
}: DialogSidebarContentProps) => {
  const store = Ariakit.useDialogContext()

  if (!store) {
    throw new Error('DialogSidebarContent must be wrapped in DialogSidebar')
  }

  const mounted = store.useState('mounted')

  return (
    <AnimatePresence>
      {mounted && (
        <Ariakit.Dialog
          render={(props) => <DialogSidebarContainer {...other} {...props} />}
          // backdrop={<DialogBackdrop centerContent={false} />}
          backdrop={false}
          modal
          alwaysVisible
          hideOnInteractOutside
          onClose={store.hide}
        >
          {children}

          {showCloseButton && (
            <Ariakit.DialogDismiss
              render={
                <CloseButton
                  position='absolute'
                  top={space(2)}
                  right={space(2)}
                />
              }
            />
          )}
        </Ariakit.Dialog>
      )}
    </AnimatePresence>
  )
}
