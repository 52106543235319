import { ADT, makeADT } from '@morphic-ts/adt'
import * as A from 'fp-ts/Array'
import * as R from 'fp-ts/Record'
import { pipe } from 'fp-ts/function'

import { Type, type } from './type'
import { Schema } from './schemable'

export declare type ElemType<A> = A extends Array<infer E> ? E : never

export declare type ExtractUnion<
  A,
  Tag extends keyof A,
  Tags extends A[Tag]
> = Extract<A, Record<Tag, Tags>>

export declare type ExcludeUnion<
  A,
  Tag extends keyof A,
  Tags extends A[Tag]
> = Exclude<A, Record<Tag, Tags>>

export interface Sum<A, T extends keyof A & string>
  extends Type<A>,
    Omit<ADT<A, T>, 'is' | 'select' | 'exclude'> {
  select: <Keys extends A[T][]>(
    keys: Keys
  ) => Sum<ExtractUnion<A, T, ElemType<Keys>>, T>
  exclude: <Keys extends A[T][]>(
    keys: Keys
  ) => Sum<ExcludeUnion<A, T, ElemType<Keys>>, T>
}

type TypeOfSchema<X> = X extends Schema<infer A> ? A : never

type MakeSum = <Tag extends string>(
  tag: Tag
) => <R extends { [x in keyof R]: Schema<{ [t in Tag]: x }> }>(
  _keys: R
) => Sum<TypeOfSchema<R[keyof R]>, Tag>

export const sum: MakeSum = (tag) => (members) => {
  const keys = Object.keys(members)
  const adt = pipe(
    keys,
    A.reduce({}, (members, name) => pipe(members, R.upsertAt(name, null))),
    makeADT(tag)
  ) as any

  return {
    ...adt,
    ...type((S) =>
      S.sum(tag)(
        pipe(
          members,
          R.map((member: any) => member(S))
        )
      )
    ),
    select: (keys: string[]) =>
      pipe(
        keys,
        A.reduce({}, (r, k) => pipe(r, R.upsertAt(k, (members as any)[k]))),
        sum(tag)
      ),
    exclude: (exclude: string[]) =>
      pipe(
        keys,
        A.filter((key: any) => exclude.includes(key)),
        A.reduce({}, (r, k) => pipe(r, R.upsertAt(k, (members as any)[k]))),
        sum(tag)
      )
  }
}
