import { ChangeEvent, useCallback, useRef } from 'react'
import { useId } from '@woorcs/hooks'
import { palette } from '@woorcs/design-tokens'
import * as Ariakit from '@ariakit/react'

import { Flex } from '../../layout'
import { Input, InputProps } from '../Input'
import { PopoverContent, Popover, PopoverDisclosure } from '../../overlays'
import { Swatch } from '../../data'

const defaultColorPalette = [
  palette.red['500'],
  palette.red['300'],
  palette.yellow['300'],
  palette.yellow['500'],
  palette.secondary['300'],
  palette.secondary['500'],
  palette.primary['300'],
  palette.primary['500']
]

interface ColorOptionProps {
  color: string
  onClick?(color: string): void
}

const ColorOption = ({ color, onClick }: ColorOptionProps) => {
  const handleClick = useCallback(() => {
    onClick?.(color)
  }, [color, onClick])

  return (
    <Ariakit.CompositeItem
      key={color}
      render={(props) => (
        <Swatch
          color={color}
          {...props}
          css={{
            cursor: 'pointer'
          }}
          display='block'
          mr={1}
          onClick={handleClick}
        />
      )}
    />
  )
}

type ColorInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  defaultColors?: string[]
  value?: string
  onChange?(value: string): void
}

export const ColorInput = ({
  value,
  defaultColors = defaultColorPalette,
  onChange,
  ...other
}: ColorInputProps) => {
  const inputId = useId()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const popoverStore = Ariakit.usePopoverStore({
    placement: 'bottom-start'
  })

  const handleFocus = useCallback(() => {
    popoverStore.show()
  }, [popoverStore])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value)
    },
    [onChange]
  )

  const handleColorOptionClick = useCallback(
    (color: string) => {
      popoverStore.hide()
      onChange?.(color)
    },
    [onChange, popoverStore]
  )

  return (
    <Popover store={popoverStore}>
      <Ariakit.CompositeProvider>
        <PopoverDisclosure>
          <Input
            ref={inputRef}
            value={value}
            id={inputId}
            autoComplete='off'
            leftAddon={<Swatch color={value} ml={-1} />}
            onChange={handleChange}
            onFocus={handleFocus}
            {...other}
          />
        </PopoverDisclosure>

        <PopoverContent
          gutter={8}
          p={2}
          tabIndex={-1}
          hideOnInteractOutside={false}
        >
          <Ariakit.Composite>
            <Ariakit.CompositeGroup
              role='grid'
              aria-label='Colors'
              render={<Flex flexDirection='row' />}
            >
              {defaultColors.map((color) => (
                <ColorOption
                  key={color}
                  color={color}
                  onClick={handleColorOptionClick}
                />
              ))}
            </Ariakit.CompositeGroup>
          </Ariakit.Composite>
        </PopoverContent>
      </Ariakit.CompositeProvider>
    </Popover>
  )
}
