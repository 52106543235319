import React from 'react'
import { Input, Box, Badge } from '@woorcs/design-system'
import { ResponseSet } from '@woorcs/form'

import { SelectResponseSetMenu } from './Menu'

type SelectResponseSetProps = {
  value: ResponseSet.ResponseSet
  onSelect(responseSet: ResponseSet.ResponseSet): void
}

type ResponseSetOptionsBadgeProps = {
  responseSet: ResponseSet.ResponseSet
}

const ResponseSetOptionsBadge = ({
  responseSet
}: ResponseSetOptionsBadgeProps) => (
  <Badge flexShrink={0}>{`${responseSet.options.length} options`}</Badge>
)

export const SelectResponseSet = ({
  value,
  onSelect
}: SelectResponseSetProps) => (
  <SelectResponseSetMenu onSelect={onSelect}>
    <Box>
      <Input
        value={value.name}
        rightAddon={<ResponseSetOptionsBadge responseSet={value} />}
        readOnly
      />
    </Box>
  </SelectResponseSetMenu>
)
