import { propOr } from 'ramda'
import { applyAll, useControllableProp, useAllCallbacks } from '@woorcs/hooks'
import {
  useState,
  useLayoutEffect,
  ChangeEvent,
  FocusEvent,
  useCallback,
  useRef,
  Ref
} from 'react'
import { isArray, forkRefs } from '@woorcs/utils'

import { useCheckboxGroupContext } from './CheckboxGroup'

interface UseCheckboxState {
  readOnly?: boolean
  disabled: boolean
  checked: boolean
  indeterminate?: boolean
  defaultChecked?: boolean
}

interface UseCheckboxProps {
  ref?: Ref<HTMLInputElement>
  name?: string
  value?: string | number
  readOnly?: boolean
  disabled?: boolean
  checked?: boolean
  indeterminate?: boolean
  defaultChecked?: boolean
  onChange?(e: ChangeEvent<HTMLInputElement>): void
  onBlur?(e: FocusEvent<HTMLInputElement>): void
}

interface UseCheckboxInputProps {
  ref: Ref<HTMLInputElement>
  name?: string
  value?: string | number
  checked: boolean
  disabled: boolean
  onChange(e: ChangeEvent<HTMLInputElement>): void
  onBlur(e: FocusEvent<HTMLInputElement>): void
}

type UseCheckboxReturn = [UseCheckboxState, UseCheckboxInputProps]

export const useCheckbox = ({
  ref,
  name,
  value,
  readOnly,
  disabled,
  checked: checkedProp,
  indeterminate,
  defaultChecked,
  onChange,
  onBlur
}: UseCheckboxProps): UseCheckboxReturn => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const group = useCheckboxGroupContext()
  const handleBlur = useAllCallbacks([onBlur, group?.onBlur])
  const [checkedState, setCheckedState] = useState(!!defaultChecked)
  const [isControlled, checked] = useControllableProp(checkedProp, checkedState)
  const isDiabled =
    disabled ?? propOr<boolean, unknown, boolean>(false, 'disabled', group)

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (readOnly || disabled) {
        event.preventDefault()
        return
      }

      if (!isControlled) {
        if (checked) {
          setCheckedState(event.target.checked)
        } else {
          setCheckedState(indeterminate ? true : event.target.checked)
        }
      }

      applyAll(onChange, group?.onChange)(event)
    },
    [readOnly, disabled, isControlled, onChange, group, checked, indeterminate]
  )

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = Boolean(indeterminate)
    }
  }, [indeterminate])

  const resolveChecked = () => {
    if (group?.value) {
      return group.isCheckboxChecked(value) ?? false
    }

    return checked
  }

  return [
    {
      checked: resolveChecked(),
      indeterminate,
      disabled: isDiabled,
      readOnly
    },
    {
      ref: forkRefs(ref, inputRef),
      name: group && isArray(group.value) ? group.name : name,
      value,
      checked: resolveChecked(),
      disabled: isDiabled,
      onChange: handleChange,
      onBlur: handleBlur
    }
  ]
}
