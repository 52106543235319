import React from 'react'
import { Card, Box, Flex, Subtitle } from '@woorcs/design-system'
import { Link } from 'react-router-dom'

import { FormVersionBadge } from '../FormVersionBadge'

import { FormListItemFormFragment } from './__generated__/FormListItem'

type FormListItemProps = {
  form: FormListItemFormFragment
}

export const FormListItem = ({ form }: FormListItemProps) => {
  return (
    <Link key={form.id} to={`/forms/${form.id}`}>
      <Card p={4} mb={4} border='base'>
        <Flex justifyContent='space-between'>
          <Box>
            <Subtitle color='text.emphasized' mb={3}>
              {form.title}
            </Subtitle>

            <FormVersionBadge form={form} />
          </Box>
        </Flex>
      </Card>
    </Link>
  )
}
