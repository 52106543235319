import React, { ReactElement, useCallback } from 'react'
import { pipe } from 'fp-ts/function'
import { ResponseSet } from '@woorcs/form'
import { InspectionFormDefinition } from '@woorcs/inspection-form'

import { ResponseSetFormModal } from '../ResponseSetFormModal'
import { useEditorContext, useValue } from '../../state'

interface AddResponseSetModalProps {
  initialValue?: ResponseSet.ResponseSet
  children: ReactElement
}

export const AddResponseSetModal = ({
  initialValue,
  children
}: AddResponseSetModalProps) => {
  const { editor } = useEditorContext()
  const root = useValue()

  const handleSubmit = useCallback(
    (responseSet: ResponseSet.ResponseSet) => {
      const updateQuestion = pipe(
        root,
        InspectionFormDefinition.addResponseSet(responseSet),
        (element) => editor.updateElement(element)
      )

      updateQuestion()
    },
    [editor, root]
  )

  return (
    <ResponseSetFormModal initialValue={initialValue} onSubmit={handleSubmit}>
      {children}
    </ResponseSetFormModal>
  )
}
