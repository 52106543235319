import * as G from 'io-ts/Guard'
import { pipe } from 'fp-ts/function'
import { isNonEmpty } from 'fp-ts/Array'
import { NonEmptyArray } from 'fp-ts/NonEmptyArray'

import { Schemable1 } from '../schemable'
import { interpreter } from '../interpreter'

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const any = { is: (_: unknown): _ is any => true }

const unknown = { is: (_: unknown): _ is unknown => true }

const nonEmptyArray = <A>(
  item: G.Guard<unknown, A>
): G.Guard<unknown, NonEmptyArray<A>> =>
  pipe(
    G.array(item),
    G.refine((a): a is NonEmptyArray<A> => isNonEmpty(a))
  )

const schemable: Schemable1<G.URI> = {
  ...G.Schemable,
  ...G.WithUnion,
  ...G.WithRefine,
  any,
  unknown,
  nonEmptyArray,
  readonlyNonEmptyArray: nonEmptyArray
}

export const Guard = {
  ...schemable,
  schemable
}

export const getGuard = interpreter(Guard)
