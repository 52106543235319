import React from 'react'
import styled from '@emotion/styled'
import { ThemeProvider as Provider } from '@emotion/react'
import { color } from 'styled-system'

import { darkTheme, lightTheme } from './theme'

interface Props {
  theme?: 'light' | 'dark'
  children: React.ReactNode
}

const ColorProvider = styled('div')(
  {
    height: '100%',
    position: 'relative'
  },
  color
)

export const ThemeProvider = ({
  children,
  theme = 'light',
  ...other
}: Props) => (
  <Provider
    theme={(theme === 'light' ? lightTheme : darkTheme) as any}
    {...other}
  >
    <ColorProvider color='text.base'>{children}</ColorProvider>
  </Provider>
)
