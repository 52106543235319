import React, { useCallback } from 'react'
import {
  SelectableListItem,
  ListItemBody,
  ListItemTitle,
  ListItemActionField,
  Avatar,
  Box
} from '@woorcs/design-system'
import { Organization } from '@woorcs/api-client'
import * as O from 'fp-ts/Option'

interface UserItemProps {
  organization: Organization
  onSelect(user: Organization): void
}

const OrganizationItem = ({ organization, onSelect }: UserItemProps) => {
  const handleSelect = useCallback(() => {
    onSelect(organization)
  }, [onSelect, organization])

  return (
    <SelectableListItem style={{ cursor: 'pointer' }} onClick={handleSelect}>
      <ListItemActionField>
        <Avatar
          size='medium'
          bg='secondary.300'
          color='green.700'
          src={O.fromNullable(organization.logo)}
          name={organization.name}
          circle={false}
        />
      </ListItemActionField>
      <ListItemBody>
        <ListItemTitle>{organization.name}</ListItemTitle>
      </ListItemBody>
    </SelectableListItem>
  )
}

interface SelectOrganizationProps {
  organizations: Organization[]
  onSelect(user: Organization): void
}

export const SelectOrganization = ({
  organizations,
  onSelect
}: SelectOrganizationProps) => {
  return (
    <Box>
      {organizations.map((organization) => (
        <OrganizationItem
          key={organization.id}
          organization={organization}
          onSelect={onSelect}
        />
      ))}
    </Box>
  )
}
