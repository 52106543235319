import React, { FC } from 'react'
import { animated, useSpring, config } from 'react-spring'

import { CheckIcon, IndeterminateIcon } from '../../icons'

export interface CheckboxIconProps {
  size: 'small' | 'medium'
  checked?: boolean
  indeterminate?: boolean
}

export const CheckboxIcon: FC<CheckboxIconProps> = ({
  size,
  checked,
  indeterminate
}) => {
  const visible = checked || indeterminate

  const spring = useSpring({
    from: {
      opacity: visible ? 1 : 0,
      transform: visible ? 'scale(1)' : 'scale(0)'
    },
    delay: 50,
    opacity: visible ? 1 : 0,
    transform: visible ? 'scale(1)' : 'scale(0)',
    config: config.gentle
  })

  return (
    <animated.div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...spring
      }}
    >
      {indeterminate ? (
        <IndeterminateIcon size={size} />
      ) : (
        <CheckIcon size={size} />
      )}
    </animated.div>
  )
}
