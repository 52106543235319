import { Auth0ContextInterface, IdToken } from '@auth0/auth0-react'
import { useMemo } from 'react'

const token = import.meta.env.VITE_REACT_APP_AUTH_LOCAL_TOKEN

type LocalAuthState = Auth0ContextInterface

export const useLocalAuthState = (): LocalAuthState => {
  if (!token || token.length === 0) {
    throw new Error(
      'Token must be set via VITE_REACT_APP_AUTH_LOCAL_TOKEN when using local auth type.'
    )
  }

  const context = useMemo(
    (): LocalAuthState => ({
      getAccessTokenSilently: () => Promise.resolve(token as any),
      getAccessTokenWithPopup: () => Promise.resolve(token),
      getIdTokenClaims: () => Promise.resolve({} as IdToken),
      loginWithRedirect: () => Promise.resolve(),
      loginWithPopup: () => Promise.resolve(),
      logout: () => Promise.resolve(),
      buildAuthorizeUrl: () => Promise.resolve(''),
      buildLogoutUrl: () => '',
      handleRedirectCallback: () => Promise.resolve({}),
      user: {
        sub: ''
      },
      isAuthenticated: true,
      isLoading: false
    }),
    []
  )

  return context
}
