import { ReactNode } from 'react'
import { Box, Flex, NAV_SIDEBAR_WIDTH } from '@woorcs/design-system'
import { Outlet } from 'react-router'

import { AdminSidebar } from '../Sidebar'

const ContentContainer = (props: { children: ReactNode }) => (
  <Flex
    width='100%'
    minHeight='100%'
    flexDirection='column'
    flexGrow={1}
    alignItems='center'
    {...props}
  />
)

export const AdminLayout = () => (
  <Box>
    <AdminSidebar />

    <Box pl={NAV_SIDEBAR_WIDTH}>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </Box>
  </Box>
)
