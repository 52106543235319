import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FormListItemFormFragmentDoc } from './FormListItem';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type FormListQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type FormListQuery = { __typename: 'Query', forms: Array<{ __typename: 'Form', id: string, title: string, latestRevision: { __typename: 'FormRevision', id: string, status: SchemaTypes.FormRevisionStatus }, active: { __typename: 'FormRevision', id: string } | null, revisions: Array<{ __typename: 'FormRevision', id: string }> }> };


export const FormListDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FormList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormListItemFormFragment"}}]}}]}},...FormListItemFormFragmentDoc.definitions]} as unknown as DocumentNode<FormListQuery, FormListQueryVariables>;