import { useRef, useEffect } from 'react'

export const useControllableProp = <T>(prop: T | undefined, state: T) => {
  const { current: isControlled } = useRef(prop !== undefined)
  const value = isControlled && typeof prop !== 'undefined' ? prop : state

  return [isControlled, value] as const
}

export const useControllableState = <T>(
  prop: T | undefined,
  state: T,
  setState: (x: T) => void,
  onChange?: (x: T) => void
  // eslint-disable-next-line max-params
) => {
  const [isControlled, value] = useControllableProp(prop, state)

  useEffect(() => {
    if (!isControlled) {
      return
    }

    if (prop !== state) {
      setState(prop as T)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop])

  useEffect(() => {
    if (!isControlled) {
      return
    }

    if (prop !== state) {
      onChange?.(state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    if (isControlled) {
      return
    }

    onChange?.(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return value
}
