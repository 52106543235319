import { ComponentProps, forwardRef } from 'react'
import TextareaAutosize, {
  TextareaAutosizeProps
} from 'react-textarea-autosize'

import { Input } from '../Input'

const TextareaInput = forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>(
  (props, ref) => (
    <TextareaAutosize
      ref={ref}
      css={{
        resize: 'none'
      }}
      {...props}
    />
  )
)

interface Props extends ComponentProps<typeof Input> {
  minRows?: number
  maxRows?: number
}

export const AutosizeTextarea = forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => <Input ref={ref} as={TextareaInput} {...props} />
)
