import { forwardRef } from 'react'
import { motion } from 'framer-motion'

import { system, PropsOf } from '../../../system'

const StyledMotion = system(motion.div)({
  bg: 'background.base',
  boxShadow: 'large',
  position: 'relative',
  zIndex: 'menu',
  borderRadius: 'large',
  ':focus': {
    outline: 'none'
  }
})

const transition = {
  duration: 0.25,
  ease: [0.48, 0.15, 0.25, 0.96]
}

const animations = {
  initial: { opacity: 0, scale: 0.8, transition },
  animate: { opacity: 1, scale: 1, transition },
  exit: { opacity: 0, scale: 0.8 }
}

export type PopoverContainerProps = PropsOf<typeof system.div>

export const PopoverContainer = forwardRef<
  HTMLDivElement,
  PopoverContainerProps
>(({ children, ...props }, ref) => {
  return (
    <motion.div
      initial={animations.initial}
      animate={animations.animate}
      exit={animations.exit}
    >
      <StyledMotion ref={ref} {...props}>
        {children}
      </StyledMotion>
    </motion.div>
  )
})
