export const reset = `
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  dl,
  dd,
  ol,
  ul,
  form,
  fieldset,
  legend,
  table,
  th,
  td,
  caption,
  hr {
    margin: 0;
    padding: 0;
  }

  fieldset {
    border: none;
  }

  a {
    text-decoration: none;
  }

  ul,
  ol {
    list-style: none;
  }
`
