import * as TE from 'fp-ts/TaskEither'
import * as TD from 'io-ts/TaskDecoder'

import { createFetch, CreateFetchOptions, FetchError } from './fetch'

export const Account = TD.struct({
  id: TD.string,
  email: TD.string,
  superuser: TD.boolean
})

export type Account = TD.TypeOf<typeof Account>

export const Organization = TD.struct({
  id: TD.string,
  name: TD.string,
  logo: TD.nullable(TD.string)
})

export type Organization = TD.TypeOf<typeof Organization>

export const MePayload = TD.struct({
  account: Account,
  organizations: TD.array(Organization)
})

export type MePayload = TD.TypeOf<typeof MePayload>

export const CreateOrganizationInput = TD.struct({
  organizationName: TD.string
})

export type CreateOrganizationInput = TD.TypeOf<typeof CreateOrganizationInput>

export const CreateOrganizationPayload = TD.struct({
  id: TD.string,
  name: TD.string
})

export type CreateOrganizationPayload = TD.TypeOf<
  typeof CreateOrganizationPayload
>

export interface ApiClient {
  me: () => TE.TaskEither<FetchError, MePayload>
  createOrganization: (
    input: CreateOrganizationInput
  ) => TE.TaskEither<FetchError, CreateOrganizationPayload>
}

export type CreateAPIClientOptions = CreateFetchOptions

export const createClient = (options: CreateAPIClientOptions): ApiClient => {
  const fetch = createFetch(options)

  return {
    me: () =>
      fetch({
        method: 'GET',
        path: '/me',
        responseDecoder: MePayload
      }),
    createOrganization: (payload) =>
      fetch({
        method: 'POST',
        path: '/createOrganization',
        body: payload,
        responseDecoder: CreateOrganizationPayload
      })
  }
}
