import React, { ReactNode } from 'react'
import { DangerIcon } from '@woorcs/design-system'

import { EmptyState } from '../EmptyState'

interface ErrorViewProps {
  title?: string
  body: string
  children?: ReactNode
}

export const ErrorView = ({
  title = 'Oops, something went wrong.',
  body,
  children
}: ErrorViewProps) => (
  <EmptyState
    title={title}
    description={body}
    Icon={DangerIcon}
    status='danger'
  >
    {children}
  </EmptyState>
)
