// eslint-disable-next-line import/no-unresolved
import { Kind, Kind2, URIS, URIS2, HKT } from 'fp-ts/HKT'
import { ReadonlyNonEmptyArray } from 'fp-ts/ReadonlyNonEmptyArray'
import { NonEmptyArray } from 'fp-ts/NonEmptyArray'
import * as S from 'io-ts/Schemable'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export interface Schemable<S extends URIS>
  extends S.Schemable<S>,
    S.WithRefine<S> {
  readonly any: HKT<S, any>
  readonly unknown: HKT<S, unknown>
  readonly nonEmptyArray: <A>(item: HKT<S, A>) => HKT<S, NonEmptyArray<A>>
  readonly readonlyNonEmptyArray: <A>(
    item: HKT<S, A>
  ) => HKT<S, ReadonlyNonEmptyArray<A>>
}

export interface Schemable1<S extends URIS>
  extends S.Schemable1<S>,
    S.WithRefine1<S> {
  readonly any: Kind<S, any>
  readonly unknown: Kind<S, unknown>
  readonly nonEmptyArray: <A>(item: Kind<S, A>) => Kind<S, NonEmptyArray<A>>
  readonly readonlyNonEmptyArray: <A>(
    item: Kind<S, A>
  ) => Kind<S, ReadonlyNonEmptyArray<A>>
}

export interface Schemable2C<S extends URIS2, E>
  extends S.Schemable2C<S, E>,
    S.WithRefine2C<S, E> {
  readonly any: Kind2<S, E, any>
  readonly unknown: Kind2<S, E, unknown>
  readonly nonEmptyArray: <A>(
    item: Kind2<S, E, A>
  ) => Kind2<S, E, NonEmptyArray<A>>
  readonly readonlyNonEmptyArray: <A>(
    item: Kind2<S, E, A>
  ) => Kind2<S, E, ReadonlyNonEmptyArray<A>>
}

export interface Schema<A> {
  <S extends URIS>(S: Schemable<S>): HKT<S, A>
}

export type TypeOf<S> = S extends Schema<infer A> ? A : never

export function make<A>(f: Schema<A>): Schema<A> {
  return S.memoize(f)
}
