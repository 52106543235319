import { whenPropEq } from '@woorcs/utils'

export type InputVariant = 'underlined' | 'outlined' | 'filled' | 'inline'
export type InputSize = 'small' | 'medium'

export const isUnderlined = whenPropEq('variant', 'underlined')
export const isOutlined = whenPropEq('variant', 'outlined')
export const isFilled = whenPropEq('variant', 'filled')
export const isInline = whenPropEq('variant', 'inline')

export const isSmall = whenPropEq('size', 'small')
export const isMedium = whenPropEq('size', 'medium')
