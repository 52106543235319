import React, { ReactElement } from 'react'
import * as t from 'io-ts'
import { Formik, FormikHelpers, Form } from 'formik'
import {
  Input,
  Stack,
  Modal,
  ModalFooter,
  ModalDialog,
  ModalDisclosure,
  SecondaryButton,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalDismiss
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import {
  Email,
  getFormikValidator,
  NonEmptyTrimmedString
} from '@woorcs/utils/formik'

import { SelectRolesInput } from '../SelectRolesInput'
import { SelectTagsInput } from '../SelectTagsInput'
import { FormField, SubmitButton } from '../Formik'

const UserForm = t.type({
  email: Email,
  firstName: NonEmptyTrimmedString,
  lastName: NonEmptyTrimmedString,
  role: t.string,
  tags: t.array(t.string)
})

export type UserFormUser = t.TypeOf<typeof UserForm>

const validateForm = getFormikValidator(UserForm)

const defaultInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  tags: []
}

interface Props {
  title: string
  visible?: boolean
  initialValues?: Record<keyof UserFormUser, unknown>
  children: ReactElement
  onVisiblityChange?(visible: boolean): void
  onSubmit(user: UserFormUser, actions: FormikHelpers<UserFormUser>): void
}

export const UserFormModal = ({
  title,
  visible,
  onSubmit,
  children,
  onVisiblityChange,
  initialValues = defaultInitialValues
}: Props) => {
  const { t } = useTranslation()

  return (
    <Modal open={visible} setOpen={onVisiblityChange}>
      <ModalDisclosure>{children}</ModalDisclosure>
      <ModalDialog aria-label={title} role='dialog'>
        <ModalHeader>
          <ModalTitle mb={6}>{title}</ModalTitle>
        </ModalHeader>
        <Formik
          validate={validateForm}
          initialValues={initialValues as any}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <ModalBody>
                <Stack spacing={6}>
                  <FormField
                    name='firstName'
                    label={t('common:firstName')}
                    required
                  >
                    <Input />
                  </FormField>
                  <FormField
                    name='lastName'
                    label={t('common:lastName')}
                    required
                  >
                    <Input />
                  </FormField>
                  <FormField name='email' label={t('common:email')} required>
                    <Input type='email' />
                  </FormField>
                  <FormField name='role' label={t('common:roles')}>
                    <SelectRolesInput />
                  </FormField>
                  <FormField name='tags' label={t('common:tags')}>
                    <SelectTagsInput />
                  </FormField>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <ModalDismiss render={<SecondaryButton mr={2} />}>
                  {t('common:cancel')}
                </ModalDismiss>
                <SubmitButton label={t('common:save')} />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalDialog>
    </Modal>
  )
}
