import {
  useRef,
  useState,
  useEffect,
  useCallback,
  MutableRefObject
} from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import * as O from 'fp-ts/Option'
import { getBox, BoxModel } from '@woorcs/utils'

export function useMeasure<T extends HTMLElement>(): [
  MutableRefObject<T | null>,
  O.Option<BoxModel>
] {
  const ref = useRef<T | null>(null)
  const [dimensions, setDimensions] = useState<O.Option<BoxModel>>(O.none)
  const measure = useCallback(() => {
    if (!ref.current) {
      return
    }

    const boxModel = getBox(ref.current)

    setDimensions(O.some(boxModel))
  }, [])

  const [ro] = useState(() => new ResizeObserver(() => measure()))

  useEffect(() => {
    measure()

    if (ref.current) {
      ro.observe(ref.current)
    }

    return () => ro.disconnect()
  }, [measure, ro])

  return [ref, dimensions]
}
