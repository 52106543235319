import React, { ReactNode, ReactElement } from 'react'
import { useToggle } from '@woorcs/hooks'
import { space } from '@woorcs/utils'

import { Box, BoxProps, Flex } from '../../layout'
import { Collapse } from '../../Collapse'
import { PlainButton, IconButton } from '../../buttons'
import { MenuList } from '../MenuList'
import { Subtitle } from '../../typography'
import { ChevronDownIcon } from '../../icons'

const TOGGLE_HEIGHT = space(8)

interface Props extends BoxProps {
  title: string
  icon?: ReactElement
  isOpen?: boolean
  children: ReactNode
}

export const CollapseMenu = ({
  title,
  icon,
  isOpen: propIsOpen = true,
  children,
  ...other
}: Props) => {
  const [isOpen, toggleOpen] = useToggle({ defaultVisible: propIsOpen })

  return (
    <Flex {...other}>
      {icon && (
        <Flex mr={4} alignItems='center' flexGrow={0} height={TOGGLE_HEIGHT}>
          {icon}
        </Flex>
      )}

      <Box flex={1}>
        <PlainButton
          css={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: TOGGLE_HEIGHT,
            justifyContent: 'space-between'
          }}
          onClick={toggleOpen}
        >
          <Subtitle fontSize='base'>{title}</Subtitle>
          <IconButton
            as='div'
            css={{
              transform: isOpen ? 'rotate(0)' : 'rotate(-90deg)',
              transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
              willChange: 'transform, opacity'
            }}
            size='mini'
          >
            <ChevronDownIcon />
          </IconButton>
        </PlainButton>

        <Collapse isOpen={isOpen} mx={-2} overflow='visible'>
          <MenuList pt={2} px={2} vertical>
            {children}
          </MenuList>
        </Collapse>
      </Box>
    </Flex>
  )
}
