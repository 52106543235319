import React, { useCallback, useState, ReactElement } from 'react'
import { FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import { UpdateTagInput } from '@woorcs/graphql/schema'
import { useSnackbar } from '@woorcs/design-system'

import { TagFormModal, TagFormTag } from '../TagFormModal'
import { TagListTagFragment } from '../TagsList/__generated__/TagsList'

import { UpdateTagDocument } from './__generated__/EditTagModal'

type EditTagModalProps = {
  tag: TagListTagFragment
  children: ReactElement
}

const tagToInput = (tag: TagFormTag & { id: string }): UpdateTagInput => ({
  ...tag,
  color: tag.color === '' ? null : tag.color,
  description: tag.description === '' ? null : tag.description
})

export const EditTagModal = ({ tag, children }: EditTagModalProps) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const { showSnackbar } = useSnackbar()
  const [, updateTag] = useMutation(UpdateTagDocument)

  const handleSubmit = useCallback(
    ({ description, name, color }: TagFormTag, actions: FormikHelpers<any>) => {
      updateTag({
        input: tagToInput({
          description,
          name,
          color,
          id: tag.id
        })
      })
        .then(() => {
          actions.resetForm({})
          setVisible(false)
          showSnackbar({
            title: t('tags:editTagModal.editTagSuccessSnackbar.title'),
            variant: 'success'
          })
        })
        .finally(() => actions.setSubmitting(false))
    },
    [updateTag, tag.id, showSnackbar, t]
  )

  return (
    <TagFormModal
      title={t('tags:editTagModal.title')}
      visible={visible}
      initialValues={{
        description: tag.description ?? '',
        color: tag.color ?? '',
        name: tag.name
      }}
      onVisiblityChange={setVisible}
      onSubmit={handleSubmit}
    >
      {children}
    </TagFormModal>
  )
}
