import { isHTMLElement } from './isHTMLElement'
import { isElementHidden } from './isElementHidden'
import { isContentEditable } from './isContentEditable'
import { hasTabIndex } from './tabIndex'

export function isElementFocusable(element: Element) {
  if (!isHTMLElement(element)) {
    return false
  }
  if (isElementHidden(element)) {
    return false
  }
  if (isElementHidden(element)) {
    return false
  }

  const { localName } = element
  const focusableTags = ['input', 'select', 'textarea', 'button']

  if (focusableTags.indexOf(localName) >= 0) {
    return true
  }

  const others: Record<string, () => void> = {
    a: () => element.hasAttribute('href'),
    audio: () => element.hasAttribute('controls'),
    video: () => element.hasAttribute('controls')
  }

  if (localName in others) {
    return others[localName]?.()
  }

  if (isContentEditable(element)) {
    return true
  }

  return hasTabIndex(element)
}
