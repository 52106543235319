/* eslint-disable react/jsx-key */
import React, { ReactNode } from 'react'
import { take, join, compose, split, map, head } from 'ramda'
import { variant } from 'styled-system'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'

import { system, PropsOf, css } from '../../../system'
import { Flex } from '../../layout'

const SIZE = {
  mini: 18,
  small: 28,
  medium: 36,
  large: 42,
  xlarge: 64
} as const

type Size = keyof typeof SIZE

interface AvatarSizeProps {
  size?: Size | null
  circle?: boolean
}

const sizeStyle = ({ size }: AvatarSizeProps) => {
  const value = SIZE[size as Size]

  if (!value) {
    return null
  }

  return {
    width: value,
    height: value
  }
}

const Wrapper = system('div')<AvatarSizeProps>(
  ({ circle = true }) =>
    css({
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: circle ? '100%' : 'medium',
      overflow: 'hidden'
    }),
  sizeStyle
)

const ImageAvatar = system('img')(sizeStyle)

const textSize = variant({
  prop: 'size',
  variants: {
    mini: { fontSize: 10 },
    small: { fontSize: 12 },
    medium: { fontSize: 12 },
    large: { fontSize: 14 },
    xlarge: { fontSize: 18 }
  }
})

const AvatarFallbackText = system(Flex)<AvatarSizeProps>(
  {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  textSize,
  sizeStyle
)

const getInitials = compose(join(''), map(head), take(2), split(' '))

interface AvatarFallbackProps extends AvatarSizeProps {
  name: string
  getFallbackText?(name: string): string
}

const AvatarFallback = ({
  name,
  size,
  getFallbackText = getInitials
}: AvatarFallbackProps) => {
  const initials = getFallbackText(name)

  return <AvatarFallbackText size={size}>{initials}</AvatarFallbackText>
}

export interface AvatarProps
  extends AvatarSizeProps,
    Omit<PropsOf<typeof system.div>, 'size'> {
  src?: O.Option<string>
  srcSet?: O.Option<string>
  name?: string
  children?: ReactNode
  getFallbackText?(name: string): string
}

export const Avatar = ({
  size = 'medium',
  name,
  src = O.none,
  children,
  getFallbackText,
  ...other
}: AvatarProps) => {
  const innerComponent = pipe(
    src,
    O.map((image) => <ImageAvatar src={image} size={size} alt={name} />),
    O.alt(() =>
      pipe(
        O.fromNullable(name),
        O.map((n) => (
          <AvatarFallback
            size={size}
            name={n}
            getFallbackText={getFallbackText}
          />
        ))
      )
    ),
    O.toNullable
  )

  return (
    <Wrapper size={size} {...other}>
      {innerComponent ?? children}
    </Wrapper>
  )
}
