import { forwardRef } from 'react'
import * as Ariakit from '@ariakit/react'

import { Box, BoxProps } from '../../layout'
import { Subtitle2 } from '../../typography'

export interface MenuGroupOptions {
  label?: string
}

export interface MenuGroupProps
  extends Omit<BoxProps, 'as' | 'color'>,
    MenuGroupOptions {}

export const MenuGroup = forwardRef<HTMLDivElement, MenuGroupProps>(
  ({ label, children, ...props }, ref) => (
    <Ariakit.MenuGroup ref={ref} render={<Box />} {...props}>
      {label && (
        <Box px={1} py={1}>
          <Subtitle2>{label}</Subtitle2>{' '}
        </Box>
      )}
      {children}
    </Ariakit.MenuGroup>
  )
)
