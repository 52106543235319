import React from 'react'
import {
  unstable_GridCell as GridCell,
  unstable_GridCellProps as GridCellProps,
  unstable_GridCellHTMLProps as GridCellHTMLProps
} from 'reakit/Grid'

import { Button, ButtonProps, BUTTON_HEIGHT } from '../../buttons'

import { CalendarCell } from './CalendarCell'
import { CalendarDate } from './useCalendarState'
import { useCalendarDay } from './useCalendar'

interface CalendarCellProps
  extends ButtonProps,
    Omit<GridCellProps, keyof GridCellHTMLProps> {
  day: CalendarDate
}

export const CalendarDayCell = ({ day, ...other }: CalendarCellProps) => {
  const { date } = day
  const isNotCurrentMonth = day.prevMonth || day.nextMonth

  const { getButtonProps, isInRange } = useCalendarDay(day)

  return (
    <CalendarCell
      bg={isInRange && !isNotCurrentMonth ? 'primary.50' : 'transparent'}
    >
      <GridCell
        as={Button}
        css={{
          width: BUTTON_HEIGHT.small
        }}
        size='small'
        type='button'
        variant={day.selected ? 'filled' : 'plain'}
        {...getButtonProps()}
        {...other}
        disabled={!day.selectable || isNotCurrentMonth}
      >
        {date.getDate()}
      </GridCell>
    </CalendarCell>
  )
}

export const CalendarTodayCell = ({ day, ...other }: CalendarCellProps) => (
  <CalendarDayCell
    day={day}
    colorVariant={day.selected ? 'primary' : 'secondary'}
    variant={day.selected ? 'filled' : 'outlined'}
    {...other}
  />
)
