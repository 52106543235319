import { mapObjIndexed } from 'ramda'

export const addAliases = (
  arr: unknown[],
  aliases: Record<string, unknown>
) => {
  mapObjIndexed(
    (value, key) =>
      Object.defineProperty(arr, key, {
        enumerable: false,
        get() {
          return value
        }
      }),
    aliases
  )

  return arr
}
