import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  Spinner,
  Box
} from '@woorcs/design-system'
import { pipe } from 'fp-ts/function'
import * as Lens from 'monocle-ts/Lens'

import { SuperUserListQuery } from '../../pages/superusers/__generated__/SuperUsersPage'

import { SuperuserRow } from './SuperuserRow'
import { SuperuserListUserFragment } from './__generated__/SuperuserList'

interface ListProps {
  superusers: SuperuserListUserFragment[]
  onRemove(userId: string): void
  loading: boolean
}

export const superuserListQueryResponseLens = pipe(
  Lens.id<SuperUserListQuery>(),
  Lens.prop('superusers')
)

export const superuserListQueryResponseOptional = Lens.asOptional(
  superuserListQueryResponseLens
)

export const SuperuserList = ({ superusers, onRemove, loading }: ListProps) => {
  if (loading && !superusers) {
    return (
      <Box display='flex' py={10} justifyContent='center' alignItems='center'>
        <Spinner />
      </Box>
    )
  }

  return (
    <Table mb={5}>
      <TableHead>
        <TableHeadCell>Namn</TableHeadCell>
        <TableHeadCell>Email</TableHeadCell>
        <TableHeadCell />
      </TableHead>
      <TableBody>
        {superusers.map((superuser, index) => (
          <SuperuserRow key={index} superuser={superuser} onRemove={onRemove} />
        ))}
      </TableBody>
    </Table>
  )
}
