import { focusOutlineColor } from '@woorcs/design-system/src/styles'
import { palette } from '@woorcs/design-tokens'
import React, { memo } from 'react'

import { PageListItem, PageListItemProps } from './Page'

interface PreviewPageListItemProps extends PageListItemProps {
  rect: {
    width: number
    height: number
  }
}

export const PreviewPageListItem = memo(
  ({ rect, ...props }: PreviewPageListItemProps) => {
    const { width, height } = rect

    return (
      <PageListItem
        css={{
          width,
          height,
          boxShadow: `0 0 2px 3px ${focusOutlineColor}, 0 0 0 1px ${palette.primary[500]};`
        }}
        bg='white'
        {...props}
      />
    )
  }
)
