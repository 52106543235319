import * as SchemaTypes from '../../../../schema/schema.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OrganizationListOrganizationFragmentDoc } from '../../../components/OrganizationList/__generated__/OrganizationList';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type OrganizationListQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type OrganizationListQuery = { __typename: 'Query', organizations: Array<{ __typename: 'Organization', id: string, name: string }> };

export type CreateOrganizationMutationVariables = SchemaTypes.Exact<{
  name: SchemaTypes.Scalars['String'];
}>;


export type CreateOrganizationMutation = { __typename: 'Mutation', createOrganization: { __typename: 'CreateOrganizationSuccess', organization: { __typename: 'Organization', id: string, name: string } } | null };


export const OrganizationListQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OrganizationListQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationListOrganization"}}]}}]}},...OrganizationListOrganizationFragmentDoc.definitions]} as unknown as DocumentNode<OrganizationListQuery, OrganizationListQueryVariables>;
export const CreateOrganizationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateOrganization"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createOrganization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CreateOrganizationSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CreateOrganizationMutation, CreateOrganizationMutationVariables>;