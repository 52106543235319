import React from 'react'
import { Box, Listbox, ListboxOption, Spinner } from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { useQuery } from 'urql'

import { SelectTagsInputQueryDocument } from './__generated__/SelectTagsInput'

type SelectTagsInputProps = {
  value?: string[]
  onChange?(value: string[]): void
}

export const SelectTagsInput = ({ value, onChange }: SelectTagsInputProps) => {
  const [query] = useQuery({ query: SelectTagsInputQueryDocument })

  if (query.fetching || !query.data) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  return pipe(
    O.fromNullable(query.data),
    O.map((data) => data.organization.tags),
    O.fold(
      () => null,
      (tags) => (
        <Listbox
          value={value as any} // TODO: Fix Listbox value type to support arrays
          multiple
          onChange={onChange}
        >
          {tags.map(({ id, name }) => (
            <ListboxOption key={id} value={id}>
              {name}
            </ListboxOption>
          ))}
        </Listbox>
      )
    )
  )
}
