import React, { forwardRef } from 'react'
import { variant } from 'styled-system'
import { isValidHex } from '@woorcs/utils'
import { palette } from '@woorcs/design-tokens'

import { system } from '../../../system'
import { BoxProps } from '../../layout'

type SwatchProps = Omit<BoxProps, 'color'> & {
  color?: string
  size?: 'small' | 'medium'
}

const StyledSwatch = system('div')(
  variant({
    prop: 'size',
    variants: {
      small: {
        width: 18,
        height: 18,
        borderRadius: 'small'
      },
      medium: {
        width: 24,
        height: 24,
        borderRadius: 'medium'
      }
    }
  })
)

export const Swatch = forwardRef<HTMLDivElement, SwatchProps>(
  ({ color, size = 'medium', ...other }, ref) => {
    const valueIsColor = isValidHex(color ?? '')
    const colorOrDefault = valueIsColor ? color : palette.grey['100']

    return (
      <StyledSwatch
        ref={ref}
        __css={{ bg: colorOrDefault }}
        size={size}
        {...other}
      />
    )
  }
)
