import React from 'react'
import { Route, Routes } from 'react-router'

import {
  OrganizationGeneralSettingsPage,
  OrganizationTagsSettingsPage,
  ProfileSettingsPage
} from './pages'

export const OrganizationSettingsRoutes = () => (
  <Routes>
    <Route path='/' element={<OrganizationGeneralSettingsPage />} />
    <Route path='/tags' element={<OrganizationTagsSettingsPage />} />
  </Routes>
)

export const UserSettingsRoutes = () => (
  <Routes>
    <Route path='/profile' element={<ProfileSettingsPage />} />
  </Routes>
)

export const SettingsRoutes = () => (
  <Routes>
    <Route path='/organization/*' element={<OrganizationSettingsRoutes />} />
    <Route path='/user/*' element={<UserSettingsRoutes />} />
  </Routes>
)
