import { forwardRef } from 'react'
import * as Ariakit from '@ariakit/react'

import { MenuItemButton, MenuItemButtonProps } from './MenuItemButton'

export interface MenuItemProps
  extends Ariakit.MenuItemOptions<'button'>,
    Omit<MenuItemButtonProps, 'ref' | 'color'> {
  destructive?: boolean
}

export const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(
  (
    {
      destructive = false,
      hideOnClick = true,
      preventScrollOnKeyDown = true,
      focusOnHover,
      blurOnHoverEnd,
      ...props
    },
    ref
  ) => {
    return (
      <Ariakit.MenuItem
        render={
          <MenuItemButton ref={ref} destructive={destructive} {...props} />
        }
        preventScrollOnKeyDown={preventScrollOnKeyDown}
        focusOnHover={focusOnHover}
        blurOnHoverEnd={blurOnHoverEnd}
        hideOnClick={hideOnClick}
      />
    )
  }
)
