import React from 'react'
import { whenPropEq } from '@woorcs/utils'
import { PropsOf } from '@emotion/react'

import { system, css } from '../../../system'

const vertical = whenPropEq('orientation', 'vertical')

interface SeparatorProps extends PropsOf<typeof system.hr> {
  orientation?: 'vertical' | 'horizontal'
}

const StyledSeparator = system('hr')<SeparatorProps>(
  css({
    backgroundColor: 'grey.50',
    width: '100%',
    height: 1,
    border: 'none'
  }),
  vertical({
    height: '100%',
    width: 1
  })
)

export const Separator = ({
  orientation = 'horizontal',
  ...other
}: SeparatorProps) => (
  <StyledSeparator
    role='separator'
    orientation={orientation}
    aria-orientation={orientation}
    {...other}
  />
)
