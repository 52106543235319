import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ReportModalSubmissionRevisionFragmentDoc } from '../../ReportModal/__generated__/ReportModal';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type SubmissionRevisionListItemSubmissionRevisionFragment = { __typename: 'SubmissionRevision', id: string, createdAt: any, publishedAt: any | null, version: number, submissionData: any, parent: { __typename: 'Submission', id: string, language: string | null, formRevision: { __typename: 'FormRevision', id: string, definition: any }, user: { __typename: 'User', id: string, name: string, firstName: string | null, lastName: string | null } } };

export const SubmissionRevisionListItemSubmissionRevisionFragmentDoc = {"kind":"Document", "definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SubmissionRevisionListItemSubmissionRevisionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SubmissionRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"publishedAt"}},{"kind":"Field","name":{"kind":"Name","value":"version"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ReportModalSubmissionRevisionFragment"}}]}},...ReportModalSubmissionRevisionFragmentDoc.definitions]} as unknown as DocumentNode<SubmissionRevisionListItemSubmissionRevisionFragment, unknown>;