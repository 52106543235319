import { createContext } from '@woorcs/utils'

import { FormEnvironment } from '../../Form'

export type FormContext = FormEnvironment

export const [FormContextProvider, useFormContext] = createContext<FormContext>(
  {
    strict: false
  }
)
