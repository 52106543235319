/* eslint-disable complexity */

import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'

import * as FieldType from '../ValidationSchema/FieldType'

import { InputElementType, ValueOfInputElement } from './Element'

type MatchProp<
  T extends string,
  R extends { [K in T]: string },
  K
> = R extends unknown ? (R[T] extends K ? R : never) : never

type MatchInputElementTypeName<T> = MatchProp<'type', InputElementType, T>

type InputElementTypeName = InputElementType['type']

type InputFieldType = {
  [T in InputElementTypeName]: FieldType.FieldType<
    any,
    any,
    ValueOfInputElement<MatchInputElementTypeName<T>>,
    any
  >
}

export const inputFieldType: InputFieldType = {
  TextInput: FieldType.textField,
  NumberInput: FieldType.numberField,
  EmailInput: FieldType.emailField,
  LocationInput: FieldType.locationField,
  SignatureInput: FieldType.signatureField,
  ImageInput: FieldType.imageField,
  DateInput: FieldType.dateField,
  DateRangeInput: FieldType.dateRangeField,
  TimeInput: FieldType.timeField,
  SelectInput: FieldType.selectField,
  MultiSelectInput: FieldType.multiSelectField,
  GroupInput: FieldType.groupField
}

export const getFieldType = <T extends InputElementTypeName>(type: T) =>
  inputFieldType[type] as FieldType.AnyFieldType

export type InputElementTypeFieldDescriptor<F extends InputElementType> =
  FieldType.Descriptor<InputFieldType[F['type']]>

export const parseInputElementValue =
  <T extends InputElementType>(element: T) =>
  (value: unknown): O.Option<ValueOfInputElement<T>> =>
    pipe(getFieldType(element.type).decode(value), O.fromEither)

// interface InputElementResponse<T extends InputElementType> {
//   element: T
//   value: ValueOfInputElement<T>
// }

// const selectOptionsFromResponseSet = (responseSet: ResponseSet) =>
//   pipe(
//     responseSet.options,
//     R.traverseArray((option) =>
//       pipe(
//         getOptionLabel(responseSet.id, option.id),
//         R.map((label) => ({
//           key: option.id,
//           label
//         }))
//       )
//     )
//   )

// const getSelectOptions = (id: UUID) =>
//   pipe(
//     responseSets.get(id),
//     O.getOrElse(() => [] as any),
//     selectOptionsFromResponseSet
//   )

// export const toField = (getOptions) =>
//   pipe(
//     element,
//     InputElementType.match({
//       TextInput: (element) =>
//         FieldType.textField.make(label, element.options, defaultValue),
//       NumberInput: (element) =>
//         FieldType.numberField.make(label, element.options, defaultValue),
//       EmailInput: () => FieldType.emailField.make(label, {}, defaultValue),
//       LocationInput: () =>
//         FieldType.locationField.make(label, {}, defaultValue),
//       ImageInput: () => FieldType.imageField.make(label, {}, defaultValue),
//       SignatureInput: () => FieldType.imageField.make(label, {}, defaultValue),
//       DateInput: () => FieldType.dateField.make(label, {}, defaultValue),
//       DateRangeInput: () =>
//         FieldType.dateRangeField.make(label, {}, defaultValue),
//       TimeInput: () => FieldType.timeField.make(label, {}, defaultValue),
//       SelectInput: (element) =>
//         pipe(
//           getOptions(element.responseSet),
//           R.map((options) =>
//             FieldType.selectField.make(
//               label,
//               { options },
//               defaultValue
//               // optional: element.optional ?? false,
//               // when: O.none
//             )
//           )
//         ),
//       MultiSelectInput: (element) =>
//         pipe(
//           getOptions(element.responseSet),
//           R.map((options) =>
//             FieldType.multiSelectField.make(
//               label,
//               { options },
//               defaultValue
//               // optional: element.optional ?? false,
//               // when: O.none
//             )
//           )
//         ),
//       GroupInput: () => R.of(FieldType.timeField.make(label, {}, defaultValue))
//     })
//   )
