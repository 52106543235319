import * as O from 'fp-ts/Option'
import * as IO from 'fp-ts/IO'
import { sequenceS } from 'fp-ts/Apply'
import { UUID } from '@woorcs/types/UUID'
import { pipe } from 'fp-ts/function'
import { InspectionFormDocument } from '@woorcs/inspection-form'

import { useEditorSelector } from './useEditorSelector'

const ado = sequenceS(IO.Applicative)

export const usePage = (pageId: UUID) => {
  return useEditorSelector((editor) =>
    pipe(
      ado({
        page: editor.getElement(pageId),
        path: editor.getElementPath(pageId),
        totalPages: pipe(
          editor.getValue(),
          IO.map((root) => root.children?.length ?? 0)
        )
      }),
      IO.map(({ page, path, totalPages }) =>
        pipe(
          sequenceS(O.Applicative)({ page, path }),
          O.map(({ page, path }) => ({
            element: page as InspectionFormDocument.InspectionFormPageElement,
            path,
            index: path[0],
            totalPages,
            isFirst: path[0] === 0,
            isLast: path[0] === totalPages - 1
          }))
        )
      )
    )
  )
}
