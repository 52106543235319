import { EmptyState } from '@woorcs/design-system'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { AdminLayout } from './components'
import { useViewer } from './graphql/ViewerProvider'
import { SuperUsersPage, OrganizationsPage } from './pages'

interface AdminRouterProps {
  basename: string
}

export const AdminRouter = ({ basename }: AdminRouterProps) => {
  const viewer = useViewer()

  if (!viewer.account.superuser) {
    return (
      <EmptyState
        status='danger'
        title="You don't have access to this page"
        description='If you think this is wrong contact an administrator.'
      />
    )
  }

  return (
    <BrowserRouter basename={basename}>
      <Routes>
        <Route path='*' element={<AdminLayout />}>
          <Route element={<OrganizationsPage />} index />
          <Route path='superusers' element={<SuperUsersPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
