import facepaint from 'facepaint'
import { map } from 'ramda'
import { breakpoints } from '@woorcs/design-tokens'

const createMediaQuery = (breakpoint: string): string =>
  `@media screen and (min-width: ${breakpoint})`

const mediaQueries = map(createMediaQuery, Object.values(breakpoints))

export const mq = facepaint(mediaQueries)
