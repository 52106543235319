import { useCallback } from 'react'
import { Card, Body, Box, H3 } from '@woorcs/design-system'
import { rem } from '@woorcs/utils'
import { useMe, Organization } from '@woorcs/api-client'
import * as DE from '@nll/datum/DatumEither'
import { pipe, constNull } from 'fp-ts/function'

import { navigateToOrganization } from '@app/utils'
import { ErrorView, SlideIn } from '@app/components'

import { SelectOrganization, LobbyLayout } from '../../components'
import { CreateOrganizationPage } from '../CreateOrganization'

interface PageProps {
  organizations: Organization[]
}

const Page = ({ organizations }: PageProps) => {
  const handleSelect = useCallback(({ id }: Organization) => {
    navigateToOrganization(id)
  }, [])

  // useOnce(() => {
  //   if (users.length === 1) {
  //     navigateToOrganization(users[0].organizationId)
  //   }
  // })

  if (organizations.length === 0) {
    return <CreateOrganizationPage />
  }

  return (
    <LobbyLayout>
      <SlideIn in>
        <Box mb={12} textAlign='center'>
          <H3 mb={2}>Select organization</H3>
          <Body mb={4}>Which organization do you want to work on?</Body>
        </Box>
        <Card p={3} width={rem(540)} maxHeight={rem(420)} overflowY='auto'>
          <SelectOrganization
            organizations={organizations}
            onSelect={handleSelect}
          />
        </Card>
      </SlideIn>
    </LobbyLayout>
  )
}

export const SelectOrganizationPage = () =>
  pipe(
    useMe(),
    DE.squash(
      constNull,
      (e) => <ErrorView body={e._tag} />,
      ({ organizations }) => <Page organizations={organizations} />
    )
  )
