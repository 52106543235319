import { TableCell, TableRow } from '@woorcs/design-system'
import { format } from 'date-fns'

import { FormRevisionBadge } from '../FormRevisionBadge'

import { FormRevisionListRevisionFragment } from './__generated__/FormRevisionList'
import { PreviewModal } from './PreviewModal'

interface FormRevisionListItemProps {
  revision: FormRevisionListRevisionFragment
  version: number
}

export const FormRevisionListItem = ({
  revision,
  version
}: FormRevisionListItemProps) => {
  return (
    <TableRow>
      <TableCell>{version}</TableCell>
      <TableCell>
        <FormRevisionBadge revision={revision} />
      </TableCell>

      <TableCell>
        {format(new Date(revision.createdAt), 'yyyy-MM-dd, HH:mm')}
      </TableCell>
      <TableCell textAlign='right'>
        <PreviewModal revisionId={revision.id} />
      </TableCell>
    </TableRow>
  )
}
