import { getOrElse } from 'fp-ts/Option'
import * as R from 'fp-ts/Reader'
import { constant, pipe } from 'fp-ts/function'

import { I18nConfig, getTranslation } from './I18nConfig'
import { TranslateableText } from './TranslateableText'
import { Locale } from './Locale'

export interface TranslatorEnv {
  config: I18nConfig
  locale: Locale
}

export interface Translator {
  /**
   * Resolves to the translation of the provided text if found, otherwise falls
   * back to the default value.
   */
  getText(text: TranslateableText): string
  getText(text: TranslateableText, locale: Locale): string
}

export const getTranslator = (config: I18nConfig) => {
  const getText = (
    text: TranslateableText,
    locale: Locale = config.defaultLanguage
  ) => {
    if (locale === config.defaultLanguage) {
      return text.text
    }

    return pipe(
      config,
      getTranslation(locale, text.id),
      getOrElse(constant(text.text))
    )
  }

  return {
    getText
  }
}

// -------------------------------------------------------------------------------------
// instance
// -------------------------------------------------------------------------------------

export interface TranslatorEnvironment {
  i18n: Translator
  locale: Locale
}

export const getText = (
  text: TranslateableText
): R.Reader<TranslatorEnvironment, string> =>
  pipe(
    R.ask<TranslatorEnvironment>(),
    R.map(({ locale, i18n }) => i18n.getText(text, locale))
  )
