import * as S from '@woorcs/types/Schemable'
import { uuid, UUID } from '@woorcs/types/UUID'
import * as R from 'fp-ts/Reader'
import * as O from 'fp-ts/Option'
import * as boolean from 'fp-ts/boolean'
import { TranslateableText, FormElement, ElementRule } from '@woorcs/form'
import { pipe } from 'fp-ts/function'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export interface InspectionFormPageElement extends FormElement.FormElement {
  readonly type: 'InspectionFormPage'
  readonly key: UUID
  readonly title: TranslateableText.TranslateableText
  readonly description: TranslateableText.TranslateableText
  readonly children: FormElement.FormElementType[]
}

export const InspectionFormPageElement: S.Type<InspectionFormPageElement> =
  S.type((S) =>
    S.struct({
      type: S.literal('InspectionFormPage'),
      key: UUID.schema(S),
      id: UUID.schema(S),
      title: TranslateableText.TranslateableText.schema(S),
      description: TranslateableText.TranslateableText.schema(S),
      children: S.array(FormElement.FormElementType.schema(S)),
      rule: S.nullable(ElementRule.ElementRule.schema(S))
    })
  )

export interface InspectionFormDocument {
  readonly type: 'InspectionForm'
  readonly id: UUID
  readonly title: TranslateableText.TranslateableText
  readonly children: InspectionFormPageElement[]
}

export const isInpsectionFormPage = (
  e: FormElement.FormElement
): e is InspectionFormPageElement => InspectionFormPageElement.is(e)

export const InspectionFormDocument: S.Type<InspectionFormDocument> = S.type(
  (S) =>
    S.struct({
      type: S.literal('InspectionForm'),
      id: UUID.schema(S),
      title: TranslateableText.TranslateableText.schema(S),
      children: S.array(InspectionFormPageElement.schema(S))
    })
)

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const inspectionFormDocument = (
  title: string
): InspectionFormDocument => ({
  type: 'InspectionForm',
  id: uuid(),
  title: TranslateableText.translateableText(title),
  children: []
})

export const inspectionFormPageElement = (
  page: Omit<InspectionFormPageElement, 'type' | 'id' | 'rule' | 'key'> &
    Partial<Pick<InspectionFormPageElement, 'rule' | 'key'>>
): InspectionFormPageElement => ({
  type: 'InspectionFormPage',
  id: uuid(),
  key: uuid(),
  rule: null,
  ...page
})

// -------------------------------------------------------------------------------------
// instance
// -------------------------------------------------------------------------------------

type PageEnvironment = FormElement.ElementEnvironment

export const isPageVisible: (
  page: InspectionFormPageElement
) => R.Reader<PageEnvironment, boolean> = FormElement.isElementVisible as any

export const renderPage =
  <A>(render: (page: InspectionFormPageElement) => A) =>
  (page: InspectionFormPageElement) =>
    pipe(
      isPageVisible(page),
      R.map(
        boolean.match(
          () => O.none,
          () => O.some(render(page))
        )
      )
    )
