import React from 'react'

import { Flex, Box } from '../../layout'

import { useCalendarState, UseCalendarStateProps } from './useCalendarState'
import { CalendarNavigation } from './CalendarNavigation'
import { CalendarMonth } from './CalendarMonth'
import { CalendarContextProvider } from './context'

export const Calendar = (props: UseCalendarStateProps) => {
  const calendarState = useCalendarState(props)

  return (
    <CalendarContextProvider value={calendarState}>
      <Box position='relative'>
        <CalendarNavigation />

        <Flex>
          {calendarState.calendars.map((calendar) => (
            <CalendarMonth
              key={`calendar-month-${calendar.month}`}
              calendar={calendar}
            />
          ))}
        </Flex>
      </Box>
    </CalendarContextProvider>
  )
}
