import React from 'react'
import { rem } from '@woorcs/utils'

import { PropsOf, system, splitStyledProps } from '../../../system'

import {
  UseNavSidebarProps,
  useNavSidebar,
  NavSidebarProvider
} from './useNavSidebar'

export const NAV_SIDEBAR_WIDTH = 260
export const NAV_SIDEBAR_COLLAPSED_WIDTH = 54

interface NavSidebarProps
  extends PropsOf<typeof system.div>,
    UseNavSidebarProps {}

export const NavSidebar = ({ children, ...other }: NavSidebarProps) => {
  const [styledProps, stateProps] = splitStyledProps(other)
  const context = useNavSidebar(stateProps)

  return (
    <NavSidebarProvider value={context}>
      <system.div
        display='flex'
        justifyContent='space-between'
        flexDirection='column'
        position='fixed'
        overflow='hidden'
        top={0}
        left={0}
        bottom={0}
        width={rem(NAV_SIDEBAR_WIDTH)}
        py={6}
        bg='grey.50'
        borderRight='thin'
        borderRightColor='grey.50'
        {...styledProps}
      >
        {children}
      </system.div>
    </NavSidebarProvider>
  )
}
