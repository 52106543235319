import { Box, BoxProps } from '@woorcs/design-system'
import React, { ReactNode } from 'react'
import { animated, useSpring } from 'react-spring'

const AnimatedBox = animated(Box)

interface SlideInProps extends BoxProps {
  in: boolean
  children: ReactNode
}

export const SlideIn = ({ in: slideIn, ...other }: SlideInProps) => {
  const transition = useSpring({
    from: {
      opacity: slideIn ? 0 : 1,
      transform: slideIn ? 'translate3d(0, 20px, 0)' : 'translate3d(0, 0px, 0)'
    },
    opacity: slideIn ? 1 : 0,
    transform: slideIn ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 20px, 0)',
    delay: 200
  })

  return <AnimatedBox style={transition} {...other} />
}
