import React, { useCallback } from 'react'
import { Badge, Body, Box, Button, Flex, Text } from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { ElementRule, FormElement } from '@woorcs/form'
import { InspectionFormDocument } from '@woorcs/inspection-form'

import { EditPageRuleModal } from '../EditPageRuleModal'
import { useEditorContext } from '../../state'
import { AddPageRuleModal } from '../AddPageRuleModal'

type PageRulesListItemProps = {
  page: InspectionFormDocument.InspectionFormPageElement
  rule: ElementRule.ElementRule
}

const PageRulesListItem = ({ page, rule }: PageRulesListItemProps) => {
  const { editor } = useEditorContext()
  const handleRemove = useCallback(() => {
    const updateQuestion = editor.updateElement(FormElement.removeRule(page))

    updateQuestion()
  }, [editor, page])

  return (
    <EditPageRuleModal page={page}>
      <Box
        __css={{ cursor: 'pointer', ':focus': { outline: 'none' } }}
        bg='grey.50'
        borderRadius='small'
        p={3}
        mb={2}
      >
        <Box mb={4}>
          <Text fontSize='small'>
            <Badge>{rule.effect}</Badge> when conditions are fulfilled.
          </Text>
        </Box>

        <Flex justifyContent='flex-end'>
          <Button size='small' colorVariant='danger' onClick={handleRemove}>
            Remove
          </Button>
        </Flex>
      </Box>
    </EditPageRuleModal>
  )
}

type PageRulesListProps = {
  page: InspectionFormDocument.InspectionFormPageElement
}

export const PageRulesList = ({ page }: PageRulesListProps) =>
  pipe(
    page.rule,
    O.fromNullable,
    O.fold(
      () => (
        <Box>
          <Box mb={4}>
            <Body>
              {`Create conditional rules to dynamically change the form's behaviour.`}
            </Body>
          </Box>
          <AddPageRuleModal page={page} initialValue={O.none}>
            <Button colorVariant='secondary' size='small'>
              Add rule
            </Button>
          </AddPageRuleModal>
        </Box>
      ),
      (rule) => (
        <Box>
          <Box mb={4}>
            <PageRulesListItem rule={rule} page={page} />
          </Box>
        </Box>
      )
    )
  )
