import React, { forwardRef, ChangeEvent, FocusEvent } from 'react'
import { rem, whenTrue, whenIsEither } from '@woorcs/utils'
import { variant } from 'styled-system'
import { useTheme } from '@emotion/react'

import { focusStyle } from '../../../styles'
import {
  ToggleBase,
  ToggleBaseProps,
  ToggleBaseControlProps
} from '../ToggleBase'
import { system, css } from '../../../system'

import { useCheckbox } from './useCheckbox'
import { CheckboxIcon } from './CheckboxIcon'

const SIZE = 24

const isCheckedOrIndeterminate = whenIsEither(['checked', 'indeterminate'])
const isDisabled = whenTrue(['disabled'])
const isFocused = whenTrue(['focused'])

const size = variant({
  prop: 'size',
  variants: {
    small: {
      width: rem(18),
      height: rem(18),
      borderRadius: 'medium'
    },
    medium: {
      width: rem(24),
      height: rem(24),
      borderRadius: 'large'
    }
  }
})

type CheckboxSize = 'small' | 'medium'

interface CheckboxControlProps extends ToggleBaseControlProps {
  size: CheckboxSize
}

const CheckboxControl = system('div')<CheckboxControlProps>(
  css({
    display: 'flex',
    flexGrow: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: rem(SIZE),
    height: rem(SIZE),
    borderColor: 'primary.500',
    borderWidth: 2,
    borderStyle: 'solid',
    boxShadow: 'none',
    color: 'white',
    cursor: 'pointer',
    transition: 'background 0.15s ease-in',
    ':hover': {
      borderColor: 'primary.dark'
    }
  }),
  isCheckedOrIndeterminate({
    backgroundColor: 'primary.500',
    borderColor: 'transparent',
    boxShadow: 'input',
    ':hover': {
      backgroundColor: 'primary.400'
    }
  }),
  isDisabled({
    backgroundColor: 'primary.100',
    cursor: 'not-allowed',
    color: 'primary.200',
    boxShadow: 'none',
    borderColor: 'transparent'
  }),
  isFocused(focusStyle),
  size
)

export interface CheckboxProps
  extends Omit<ToggleBaseProps, 'checked' | 'controlAs' | 'type' | 'children'> {
  name?: string
  value?: string | number
  checked?: boolean
  disabled?: boolean
  indeterminate?: boolean
  readOnly?: boolean
  size?: CheckboxSize
  onChange?(e: ChangeEvent<HTMLInputElement>): void
  onBlur?(e: FocusEvent<HTMLInputElement>): void
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      name,
      value,
      checked,
      disabled,
      indeterminate,
      readOnly,
      size: sizeProp = 'medium',
      onChange,
      onBlur,
      ...other
    },
    ref
  ) => {
    const [state, inputProps] = useCheckbox({
      ref,
      name,
      value,
      checked,
      disabled,
      indeterminate,
      readOnly,
      onChange,
      onBlur
    })
    const theme = useTheme()
    const sizeStyle = size({
      size: sizeProp,
      theme
    })

    return (
      <ToggleBase css={sizeStyle} {...other} {...inputProps}>
        <CheckboxControl size={sizeProp}>
          <CheckboxIcon
            size={sizeProp}
            checked={state.checked}
            indeterminate={state.indeterminate}
          />
        </CheckboxControl>
      </ToggleBase>
    )
  }
)
