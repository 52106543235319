import * as L from 'monocle-ts/Lens'
import { pipe } from 'fp-ts/function'
import * as RR from 'fp-ts/ReadonlyRecord'
import { uuid, UUID } from '@woorcs/types/UUID'
import * as S from '@woorcs/types/Schemable'
import {
  I18nConfig,
  FormDocument,
  TranslateableText,
  ResponseSet
} from '@woorcs/form'

import {
  InspectionFormDocument,
  InspectionFormPageElement
} from './InspectionFormDocument'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

type Documents = Record<string, FormDocument.FormDocument>

type ResponseSets = Record<string, ResponseSet.ResponseSet>

export interface InspectionFormDefinition extends InspectionFormDocument {
  readonly description: TranslateableText.TranslateableText
  readonly i18n: I18nConfig.I18nConfig
  readonly documents: Documents
  readonly responseSets: ResponseSets
}

export const ResponseSets: S.Type<ResponseSets> = S.type((S) =>
  S.record(ResponseSet.ResponseSet.schema(S))
)

export const Documents: S.Type<Documents> = S.type((S) =>
  S.record(FormDocument.schema(S))
)

export const InspectionFormDefinition: S.Type<InspectionFormDefinition> =
  S.type((S) =>
    S.struct({
      type: S.literal('InspectionForm'),
      id: UUID.schema(S),
      title: TranslateableText.TranslateableText.schema(S),
      description: TranslateableText.TranslateableText.schema(S),
      documents: Documents.schema(S),
      responseSets: ResponseSets.schema(S),
      i18n: I18nConfig.schema(S),
      children: S.array(InspectionFormPageElement.schema(S))
    })
  )

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const inspectionFormDefinition = (
  definition: Pick<InspectionFormDefinition, 'title' | 'i18n'> &
    Partial<Omit<InspectionFormDefinition, 'title' | 'i18n'>>
): InspectionFormDefinition => ({
  type: 'InspectionForm',
  id: uuid(),
  children: [],
  description: TranslateableText.translateableText(''),
  documents: {},
  responseSets: ResponseSet.createDefaultResponseSets().reduce(
    (responseSets, responseSet) => ({
      ...responseSets,
      [responseSet.id]: responseSet
    }),
    {}
  ),
  ...definition
})

// -------------------------------------------------------------------------------------
// lenses
// -------------------------------------------------------------------------------------

export const lens = L.id<InspectionFormDefinition>()

export const documentsLens = pipe(lens, L.prop('documents'))

export const responseSetsLens = pipe(lens, L.prop('responseSets'))

// -------------------------------------------------------------------------------------
// documents
// -------------------------------------------------------------------------------------

export const getFormDocument = (id: UUID) =>
  pipe(documentsLens, L.key(id)).getOption

export const addFormDocument = (document: FormDocument.FormDocument) =>
  pipe(documentsLens, L.modify(RR.upsertAt(document.id, document)))

export const removeFormDocument = (document: FormDocument.FormDocument) =>
  pipe(documentsLens, L.modify(RR.deleteAt(document.id)))

export const updateFormDocument = addFormDocument

// -------------------------------------------------------------------------------------
// response sets
// -------------------------------------------------------------------------------------

export const getResponseSet = (id: UUID) =>
  pipe(responseSetsLens, L.key(id)).getOption

export const addResponseSet = (responseSet: ResponseSet.ResponseSet) =>
  pipe(responseSetsLens, L.modify(RR.upsertAt(responseSet.id, responseSet)))

export const removeResponseSet = (responseSet: ResponseSet.ResponseSet) =>
  pipe(responseSetsLens, L.modify(RR.deleteAt(responseSet.id)))

export const updateResponseSet = addResponseSet
