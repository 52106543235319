import React, { ReactElement } from 'react'
import { createContext } from '@woorcs/utils'
import * as Optional from 'monocle-ts/Optional'
import { constNull, pipe } from 'fp-ts/function'
import * as DE from '@nll/datum/DatumEither'
import { CombinedError, useQuery } from 'urql'

import { queryToDatumEither } from '../utils'

import {
  OrganizationFragment,
  OrganizationQuery,
  OrganizationQueryDocument
} from './__generated__/organization'

type OrganizationContext = OrganizationFragment

const [Provider, useOrganizationContext] = createContext<OrganizationContext>()

export const organizationQueryResponseOptional = pipe(
  Optional.id<OrganizationQuery>(),
  Optional.prop('organization'),
  Optional.fromNullable
)

interface OrganizationProviderProps {
  onNone?(refreshing?: boolean): ReactElement | null
  onFailure?(error: CombinedError, refreshing?: boolean): ReactElement | null
  children: ReactElement
}

export const OrganizationProvider = ({
  onNone = constNull,
  onFailure = constNull,
  children
}: OrganizationProviderProps) =>
  pipe(
    useQuery({ query: OrganizationQueryDocument }),
    queryToDatumEither(organizationQueryResponseOptional),
    DE.squash(onNone, onFailure, (viewer) => (
      <Provider value={viewer}>{children}</Provider>
    ))
  )

export const useOrganization = useOrganizationContext
