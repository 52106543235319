import {
  Button,
  ChevronDownIcon,
  ChevronLeftIcon,
  Flex,
  ModalDismiss,
  Navbar,
  Spinner,
  Text
} from '@woorcs/design-system'
import { Locale } from '@woorcs/form'

import { CountryFlag } from '../CountryFlag'
import { SwitchLanguageMenu } from '../SwitchLanguageForm'

type TranslateLanguagePageNavbarProps = {
  selectedLanguage: Locale.Locale
  isSaving: boolean
  onSelectLanguage(locale: Locale.Locale): void
}

export const TranslateLanguageModalNavbar = ({
  selectedLanguage,
  isSaving,
  onSelectLanguage
}: TranslateLanguagePageNavbarProps) => {
  return (
    <Navbar bg='white' flexShrink={0} position='relative' borderBottom='base'>
      {isSaving && (
        <Button variant='plain' leftIcon={<Spinner />} disabled>
          Back
        </Button>
      )}
      {!isSaving && (
        <ModalDismiss
          render={<Button variant='plain' leftIcon={<ChevronLeftIcon />} />}
        >
          Back
        </ModalDismiss>
      )}

      <Flex
        css={{ transform: 'translate(-50%, 0)' }}
        position='absolute'
        left='50%'
        top={0}
        bottom={0}
        alignItems='center'
      >
        <SwitchLanguageMenu
          selectedLanguage={selectedLanguage}
          onSelectLanguage={onSelectLanguage}
        >
          <Button variant='plain'>
            <Flex alignItems='center'>
              <CountryFlag locale={selectedLanguage} mr={2} />
              <Text fontWeight='bold' color='text.base' fontSize='base'>
                {Locale.getLocaleLabel(selectedLanguage)}
              </Text>
              <ChevronDownIcon ml={2} color='text.base' />
            </Flex>
          </Button>
        </SwitchLanguageMenu>
      </Flex>
    </Navbar>
  )
}
