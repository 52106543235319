import { identity, pipe } from 'fp-ts/function'
import * as Monoid from 'fp-ts/Monoid'
import * as boolean from 'fp-ts/boolean'
import * as R from 'fp-ts/Reader'
import * as O from 'fp-ts/Option'
import * as ET from '@woorcs/types/ElementTree'
import * as RA from 'fp-ts/ReadonlyArray'

import { FormSubmissionStore } from './FormSubmission'
import { Translator } from './i18n/Translator'
import { ResponseSetRegistry } from './ResponseSetRegistry'
import { DocumentsRegistry } from './DocumentsRegistry'
import { ElementRuleEngine } from './FormDocument/RuleEngine'
import { Locale } from './i18n/Locale'
import * as Fields from './Fields'
import * as Field from './ValidationSchema/Field'
import { FormDocument } from './FormDocument/FormDocument'
import * as FormElement from './FormDocument/Element'

interface Config {
  locale: Locale
}

interface Capabilities {
  i18n: Translator
  submission: FormSubmissionStore
  responseSets: ResponseSetRegistry
  documents: DocumentsRegistry
  ruleEngine: ElementRuleEngine
}

export interface FormEnvironment extends Config, Capabilities {}

export const formEnvironment = (env: FormEnvironment) => env

// -------------------------------------------------------------------------------------
// validation
// -------------------------------------------------------------------------------------

const foldAll = Monoid.concatAll(boolean.MonoidAll)

export const activeInputs = (document: FormDocument) =>
  pipe(
    document as any,
    ET.toArray,
    RA.filter(FormElement.isInputElement),
    R.traverseArray(FormElement.renderElement(identity)),
    R.map((elements) =>
      pipe(
        elements,
        RA.filter(O.isSome),
        RA.map((x) => x.value)
      )
    )
  )

export const getFieldResponses = (document: FormDocument) =>
  pipe(
    activeInputs(document),
    R.chain(
      R.traverseArray((element) =>
        pipe(
          Fields.fromElement(element),
          R.chain((field) =>
            pipe(
              R.ask<FormEnvironment>(),
              R.map(({ submission }) =>
                Field.isValid(field, submission.get(element.key))
              )
            )
          )
        )
      )
    )
  )

export const progress = (document: FormDocument) =>
  pipe(
    getFieldResponses(document),
    R.map((responses) =>
      pipe(
        responses,
        RA.filter(identity),
        (valid) => valid.length / responses.length
      )
    )
  )

export const isValidSubmission = (document: FormDocument) =>
  pipe(getFieldResponses(document), R.map(foldAll))
