import React, { ReactElement, useCallback, useState } from 'react'
import {
  Checkbox,
  Flex,
  ListItemActionField,
  ListItemTitle,
  SelectableListItem,
  Text
} from '@woorcs/design-system'
import * as Optional from 'monocle-ts/Optional'
import { pipe } from 'fp-ts/function'
import * as DE from '@nll/datum/DatumEither'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as string from 'fp-ts/string'
import { useQuery } from 'urql'
import { queryToDatumEither } from '@woorcs/graphql'

import { TagAvatar } from '../TagAvatar'
import { SearchModal } from '../SearchModal'

import {
  SelectTagsModalQuery,
  SelectTagsModalQueryDocument,
  SelectTagsModalTagFragment
} from './__generated__/SelectTagsModal'

interface TagListItemProps {
  isSelected: boolean
  tag: SelectTagsModalTagFragment
  onSelect(tag: SelectTagsModalTagFragment): void
}

const TagListItem = ({ isSelected, tag, onSelect }: TagListItemProps) => {
  const handleClick = useCallback(() => {
    onSelect(tag)
  }, [onSelect, tag])

  return (
    <SelectableListItem
      alignItems='center'
      isSelected={isSelected}
      dense
      onClick={handleClick}
    >
      <ListItemActionField>
        <Checkbox size='small' checked={isSelected} />
      </ListItemActionField>
      <ListItemTitle>
        <Flex alignItems='center' pl={2}>
          <TagAvatar tag={tag} mr={2} />
          <Text>{tag.name}</Text>
        </Flex>
      </ListItemTitle>
    </SelectableListItem>
  )
}

const responseOptional = pipe(
  Optional.id<SelectTagsModalQuery>(),
  Optional.prop('organization'),
  Optional.prop('tags')
)

const filterTag = (tag: SelectTagsModalTagFragment, query: string) =>
  tag.name.toLocaleLowerCase().includes(query)

interface SelectTagsModalProps {
  searchPlaceholder?: string
  selectedIds?: string[]
  onSelect(ids: string[]): void
  children: ReactElement
}

export const SelectTagsModal = ({
  searchPlaceholder = 'Search tags',
  onSelect,
  selectedIds = [],
  children
}: SelectTagsModalProps) => {
  const [query, setQuery] = useState<string>('')
  const isSelected = (tag: SelectTagsModalTagFragment) =>
    pipe(
      selectedIds,
      A.findFirst((id) => tag.id === id),
      O.isSome
    )

  const handleSelect = useCallback(
    (tag: SelectTagsModalTagFragment) => {
      const ids = pipe(
        selectedIds,
        A.findFirst((id) => tag.id === id),
        O.fold(
          () => pipe(selectedIds, A.append(tag.id), A.uniq(string.Eq)),
          () =>
            pipe(
              selectedIds,
              A.filter((id) => tag.id !== id)
            )
        )
      )

      onSelect(ids)
    },
    [onSelect, selectedIds]
  )

  return pipe(
    useQuery({
      query: SelectTagsModalQueryDocument
    }),
    queryToDatumEither(responseOptional),
    DE.squash(
      () => null,
      () => null,
      (tags) => (
        <SearchModal
          items={tags}
          query={query}
          searchPlaceholder={searchPlaceholder}
          filterItem={filterTag}
          renderItem={(tag) => (
            <TagListItem
              key={tag.id}
              tag={tag}
              isSelected={isSelected(tag)}
              onSelect={handleSelect}
            />
          )}
          onQueryChange={setQuery}
        >
          {children}
        </SearchModal>
      )
    )
  )
}
