import { ReactElement } from 'react'
import { RadioGroup as BaseRadioGroup } from 'reakit/Radio'
import { createContext } from '@woorcs/utils'

import { FormControlGroup, FormControlGroupProps } from '../FormControlGroup'

import {
  useRadioGroup,
  UseRadioGroupReturn,
  UseRadioGroupProps
} from './useRadioGroup'

interface Props
  extends UseRadioGroupProps,
    Omit<FormControlGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'as'> {
  children: ReactElement[]
}

export type RadioGroupContext = UseRadioGroupReturn

export const [RadioGroupProvider, useRadioGroupContext] =
  createContext<RadioGroupContext>({
    name: 'RadioGroupContext',
    strict: false
  })

export const RadioGroup = ({
  name,
  value,
  defaultValue,
  disabled,
  onChange,
  onBlur,
  ...other
}: Props) => {
  const group = useRadioGroup({
    name,
    value,
    defaultValue,
    disabled,
    onChange,
    onBlur
  })

  return (
    <RadioGroupProvider value={group}>
      <FormControlGroup as={BaseRadioGroup} {...other} />
    </RadioGroupProvider>
  )
}
