import { ChangeEvent, useCallback } from 'react'
import { useFormikContext, useField as useFormikField } from 'formik'
import { isNotNilOrEmpty } from '@woorcs/utils'

const getSelectedValues = (options: any[]) => {
  const result = []

  if (options) {
    for (let index = 0; index < options.length; index++) {
      const option = options[index]

      if (option.selected) {
        result.push(option.value)
      }
    }
  }

  return result
}

const isEvent = (x: any): x is ChangeEvent =>
  !!(x && x.stopPropagation && x.preventDefault)

// eslint-disable-next-line complexity
export const getValue = (eventOrValue: unknown) => {
  if (!isEvent(eventOrValue)) {
    return eventOrValue
  }

  const event = eventOrValue
  const nativeEvent = eventOrValue.nativeEvent as any

  if (nativeEvent && nativeEvent.text !== undefined) {
    return nativeEvent.text
  }

  const {
    target: { type, value, checked, files, options },
    dataTransfer
  } = event as any

  if (type === 'number' || type === 'range') {
    const parsed = parseFloat(value)

    // eslint-disable-next-line no-undef
    return isNaN(parsed) ? '' : parsed
  }

  if (type === 'checkbox') {
    return !!checked
  }

  if (type === 'file') {
    return files || (dataTransfer && dataTransfer.files)
  }

  if (type === 'select-multiple') {
    return getSelectedValues(options)
  }

  return value
}

export const useField = <V>(name: string) => {
  const [{ value }, { error, touched, initialValue }] = useFormikField<V>(name)
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const invalid = isNotNilOrEmpty(error) && touched
  const checked = typeof value === 'boolean' ? value : undefined

  // NOTE: formik onChange callback will create a new ref everytime any value in the form changes
  //       which will cause all form components to rerender, so instead we handle this by creating
  //       our own onChange handler. It also supports passing the value instead of a change event.
  const handleChange = useCallback(
    (eventOrValue: unknown) => {
      setFieldValue(name, getValue(eventOrValue))
    },
    [name, setFieldValue]
  )

  const handleBlur = useCallback(() => {
    setFieldTouched(name, true)
  }, [name, setFieldTouched])

  return [
    {
      name,
      value,
      checked,
      onChange: handleChange,
      onBlur: handleBlur
    },
    {
      error,
      touched,
      invalid,
      initialValue
    }
  ] as const
}

export type UseFieldReturn = ReturnType<typeof useField>

export type UseFieldFieldProps = UseFieldReturn[0]
