import { focusOutlineColor } from '@woorcs/design-system/src/styles'
import { palette } from '@woorcs/design-tokens'
import React, { memo } from 'react'

import { ElementListItem, ElementListItemProps } from './ListItem'

interface PreviewElementListItemProps extends ElementListItemProps {
  rect: {
    width: number
    height: number
  }
}

export const PreviewElementListItem = memo(
  ({ rect, ...props }: PreviewElementListItemProps) => {
    const { width, height } = rect

    return (
      <ElementListItem
        css={{
          width,
          height,
          boxShadow: `0 0 2px 3px ${focusOutlineColor}, 0 0 0 1px ${palette.primary[500]};`
        }}
        bg='primary.500'
        {...props}
      />
    )
  }
)
