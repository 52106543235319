import * as R from 'fp-ts/Reader'
import * as RR from 'fp-ts/ReadonlyRecord'
import * as O from 'fp-ts/Option'
import { UUID } from '@woorcs/types/UUID'
import { identity, pipe } from 'fp-ts/function'

import * as Field from './ValidationSchema/Field'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export type FormSubmissionData = Record<UUID, unknown>

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const fromFields = (
  fields: RR.ReadonlyRecord<UUID, Field.Field>,
  defaults: FormSubmissionData
): FormSubmissionData =>
  pipe(
    fields,
    RR.mapWithIndex((key, field) =>
      pipe(
        defaults,
        RR.lookup(key),
        O.chain(O.fromNullable),
        O.alt(() => Field.defaultValue(field)),
        O.toNullable
      )
    )
  )

// -------------------------------------------------------------------------------------
// store
// -------------------------------------------------------------------------------------

export interface FormSubmissionStore {
  get(id: UUID): O.Option<unknown>
  set(id: UUID, value: unknown): void
}

type Environment = FormSubmissionData

export const getResponseData = (): R.Reader<Environment, FormSubmissionData> =>
  pipe(R.ask<Environment>(), R.map(identity))

export const getResponse = (
  id: UUID
): R.Reader<Environment, O.Option<unknown>> =>
  pipe(getResponseData(), R.map(RR.lookup(id)), R.map(O.chain(O.fromNullable)))

export const getFormSubmissionStore = (
  data: FormSubmissionData
): FormSubmissionStore => {
  return {
    get: (id) => pipe(data, getResponse(id)),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    set: () => {}
  }
}
