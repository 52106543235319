import * as IO from 'fp-ts/IO'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { eqStrict } from 'fp-ts/Eq'
import * as ET from '@woorcs/types/ElementTree'

import { useEditorSelector } from './useEditorSelector'

const eq = O.getEq(eqStrict)

export const useParentElement = (element: ET.ElementTree) =>
  useEditorSelector(
    (editor) =>
      pipe(
        editor.getValue(),
        IO.map((value) => pipe(value, ET.findParent(element)))
      ),
    eq
  )
