import Color from 'color'

import { palette } from './colors'

export const shadows = {
  overlay: '0 6px 12px 0 rgba(73, 28, 185, 0.08)',

  input: `0 1px 4px 0 ${Color(palette.primary['500'])
    .alpha(0.2)
    .string()}, 0 1px 4px 0 ${Color(palette.black).alpha(0.05).string()}`,

  small: '0px 1px 10px rgba(0, 0, 0, 0.05)',
  medium:
    '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);',
  large: `0 2px 12px 0 ${Color(palette.grey['900']).fade(0.92).string()}`
}
