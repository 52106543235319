import React, { useCallback } from 'react'
import { Badge, Body, Box, Button, Flex, Text } from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { ElementRule, FormElement } from '@woorcs/form'

import { EditElementRuleModal } from '../EditElementRuleModal'
import { useEditorContext } from '../../state'
import { AddElementRuleModal } from '../AddElementRuleModal'

type ElementRulesListItemProps = {
  element: FormElement.FormElement
  rule: ElementRule.ElementRule
}

const ElementRulesListItem = ({ element, rule }: ElementRulesListItemProps) => {
  const { editor } = useEditorContext()
  const handleRemove = useCallback(() => {
    const updateQuestion = editor.updateElement(FormElement.removeRule(element))

    updateQuestion()
  }, [editor, element])

  return (
    <EditElementRuleModal element={element} rule={rule}>
      <Box
        __css={{ cursor: 'pointer', ':focus': { outline: 'none' } }}
        bg='grey.50'
        borderRadius='small'
        p={3}
        mb={2}
      >
        <Box mb={4}>
          <Text fontSize='small'>
            <Badge>{rule.effect}</Badge> when conditions are fulfilled.
          </Text>
        </Box>

        <Flex justifyContent='flex-end'>
          <Button size='small' colorVariant='danger' onClick={handleRemove}>
            Remove
          </Button>
        </Flex>
      </Box>
    </EditElementRuleModal>
  )
}

type ElementRulesListProps = {
  element: FormElement.FormElement
}

export const ElementRulesList = ({ element }: ElementRulesListProps) =>
  pipe(
    element.rule,
    O.fromNullable,
    O.fold(
      () => (
        <Box>
          <Box mb={4}>
            <Body>
              {`Create conditional rules to dynamically change the form's behaviour.`}
            </Body>
          </Box>
          <AddElementRuleModal element={element}>
            <Button colorVariant='secondary' size='small'>
              Add rule
            </Button>
          </AddElementRuleModal>
        </Box>
      ),
      (rule) => (
        <Box>
          <Box mb={4}>
            <ElementRulesListItem rule={rule} element={element} />
          </Box>
        </Box>
      )
    )
  )
