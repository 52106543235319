import React from 'react'
import { Button } from '@woorcs/design-system'

import { ErrorView } from '@app/components'

import { useAuth } from './useAuth'

interface FetchUserErrorMessageProps {
  onLogout: () => void
}

const FetchUserErrorMessage = ({ onLogout }: FetchUserErrorMessageProps) => (
  <ErrorView
    title='Failed to fetch user'
    body='Something went wrong fetching your user. Logout and try again.'
  >
    <Button onClick={onLogout}>Logout</Button>
  </ErrorView>
)

export const AuthError = () => {
  const [, { logout }] = useAuth()

  return <FetchUserErrorMessage onLogout={() => logout()} />
}
