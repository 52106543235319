import { IO } from 'fp-ts/IO'
import { Eq } from 'fp-ts/Eq'
import { EqualityFn, shallowEqual, useSelector } from 'react-redux'

import { Editor, EditorStateWithHistory } from '../state'

import { useEditorContext } from './useEditorContext'

const shallowEq: Eq<unknown> = {
  equals: shallowEqual
}

/**
 * Wrapper hook ontop of redux that provides the passed selector with the
 * editor instead of the current state.
 *
 * @param selector
 * @param eq Custom eq instance
 */
export const useEditorSelector = <T>(
  selector: (editor: Editor) => IO<T>,
  eq: Eq<T> = shallowEq
) => {
  const { editor } = useEditorContext()

  return useSelector<EditorStateWithHistory, T>(
    () => selector(editor)(),
    eq.equals as EqualityFn<T>
  )
}
