import { ReactNode, ReactElement } from 'react'
import {
  NavSidebar,
  NavSidebarMenuItem,
  NavSidebarMenuItemProps,
  DocumentIcon,
  NavSidebarMenu,
  UserIcon
} from '@woorcs/design-system'
import { NavLink } from 'react-router-dom'

interface MenuItemProps extends NavSidebarMenuItemProps {
  to: string
  icon: ReactElement
  children: ReactNode
}

const MenuItem = ({ to, icon, children, ...other }: MenuItemProps) => (
  <NavSidebarMenuItem as={NavLink} icon={icon} {...({ to } as any)} {...other}>
    {children}
  </NavSidebarMenuItem>
)

export const AdminSidebar = () => {
  return (
    <NavSidebar>
      <NavSidebarMenu>
        <MenuItem to='/' icon={<DocumentIcon />} aria-label='organizations'>
          Organizations
        </MenuItem>
        <MenuItem to='superusers' icon={<UserIcon />} aria-label='superusers'>
          Superusers
        </MenuItem>
      </NavSidebarMenu>
    </NavSidebar>
  )
}
