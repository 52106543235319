import { pipe, constant, flow } from 'fp-ts/function'
import * as IO from 'fp-ts/IO'
import * as A from 'fp-ts/Array'
import * as RA from 'fp-ts/ReadonlyArray'
import * as O from 'fp-ts/Option'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import { UUID } from '@woorcs/types/UUID'

import { Editor } from '../state'

import { useEditorContext } from './useEditorContext'

export const getPagesIO = (editor: Editor) => {
  const getPageIds = flow(
    editor.getValue,
    IO.map((root) =>
      pipe(
        O.fromNullable(root.children),
        O.map(RA.toArray),
        O.map(A.filterMap((page) => O.fromNullable(page.id))),
        O.getOrElse(constant([] as UUID[]))
      )
    )
  )

  const getPages = flow(
    getPageIds,
    IO.chain(IO.traverseArray(editor.getElement))
  )

  const getPageId = (index: number) =>
    pipe(getPageIds(), IO.map(A.lookup(index)))

  const getPage = flow(
    getPageId,
    IO.chain((id) =>
      pipe(
        id,
        O.map((id) => editor.getElement(id)),
        O.getOrElseW(() => IO.of(O.none))
      )
    )
  )

  const addPage = (
    page: InspectionFormDocument.InspectionFormPageElement,
    atIndex = 0
  ) => editor.addElement(page, [atIndex])

  const removePage = (pageID: UUID) => editor.removeElement(pageID)

  const movePage = (pageID: UUID, toIndex: number) =>
    editor.moveElement(pageID, [toIndex])

  return {
    ...editor,
    getPageId,
    getPages,
    getPage,
    addPage,
    removePage,
    movePage
  }
}

export const usePagesIO = () =>
  pipe(useEditorContext(), ({ editor }) => editor, getPagesIO)
