import React from 'react'
import {
  Table,
  TableHead,
  TableHeadCell,
  TableBody
} from '@woorcs/design-system'
import * as Ord from 'fp-ts/Ord'
import * as date from 'fp-ts/Date'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import { space } from '@woorcs/utils'

import { FormRevisionListRevisionFragment } from './__generated__/FormRevisionList'
import { FormRevisionListItem } from './Item'

interface FormRevisionListProps {
  revisions: FormRevisionListRevisionFragment[]
}

const ordCreatedAt = pipe(
  Ord.contramap(
    (revision: FormRevisionListRevisionFragment) => revision.createdAt
  )(date.Ord),
  Ord.reverse
)

export const FormRevisionList = ({ revisions }: FormRevisionListProps) => {
  const sorted = pipe(revisions, A.sort(ordCreatedAt))

  return (
    <Table outerPadding={space(14)}>
      <TableHead>
        <TableHeadCell>Version</TableHeadCell>
        <TableHeadCell>Status</TableHeadCell>
        {/* <TableHeadCell>Submissions</TableHeadCell> */}
        <TableHeadCell>Created</TableHeadCell>

        <TableHeadCell />
      </TableHead>
      <TableBody>
        {sorted.map((revision, index) => (
          <FormRevisionListItem
            key={revision.id}
            revision={revision}
            version={revisions.length - index}
          />
        ))}
      </TableBody>
    </Table>
  )
}
