import { useMemo, ReactNode, Suspense } from 'react'
import {
  Box,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  Pagination,
  InboxIcon,
  DateRangeTuple,
  Spinner
} from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import { useTranslation } from 'react-i18next'
import { constant, pipe } from 'fp-ts/function'
import { space } from '@woorcs/utils'
import { useQuery } from 'urql'

import { ErrorView } from '../ErrorView'
import { useSubmissionListFiltersContext } from '../SubmissionListFilters/filters'
import { isEmptyFilters } from '../filters'
import { EmptyState } from '../EmptyState'

import { SubmissionListQueryDocument } from './__generated__/SubmissionList'
import { SubmissionListRow } from './Row'

const EmptyList = () => {
  const { filters } = useSubmissionListFiltersContext()

  if (isEmptyFilters(filters)) {
    return (
      <EmptyState
        Icon={InboxIcon}
        title={`It\'s empty here`}
        description={`No forms has been submitted yet.`}
      />
    )
  }

  return (
    <EmptyState
      Icon={InboxIcon}
      title={`No submissions found`}
      description='No submissions found for the current filters'
    />
  )
}

const SubmissionListHeader = () => {
  const { t } = useTranslation('submissions')

  return (
    <TableHead>
      <TableHeadCell>{t('submissionList.columns.form.label')}</TableHeadCell>
      <TableHeadCell>
        {t('submissionList.columns.submittedBy.label')}
      </TableHeadCell>
      <TableHeadCell>{t('submissionList.columns.tags.label')}</TableHeadCell>
      <TableHeadCell>
        {t('submissionList.columns.language.label')}
      </TableHeadCell>
      <TableHeadCell>{t('submissionList.columns.updated.label')}</TableHeadCell>
      <TableHeadCell />
    </TableHead>
  )
}

interface SubmissionListTableProps {
  children: ReactNode
}

const SubmissionListTable = ({ children }: SubmissionListTableProps) => {
  return (
    <Table outerPadding={space(12)}>
      <SubmissionListHeader />
      {children}
    </Table>
  )
}

const SubmissionListErrorFallback = () => (
  <ErrorView body='Something went wrong while fetching the submissions' />
)

const SubmissionListRows = () => {
  const { filters, limit, offset, setOffset } =
    useSubmissionListFiltersContext()

  const variables = useMemo(
    () => ({
      from: pipe(
        filters.published,
        O.chain(DateRangeTuple.start),
        O.toUndefined
      ),
      to: pipe(filters.published, O.chain(DateRangeTuple.end), O.toUndefined),
      formId: O.toNullable(filters.formId),
      creatorId: O.toNullable(filters.creatorId),
      tags: pipe(filters.tags, O.getOrElseW(constant([]))),
      offset,
      limit
    }),
    [filters, limit, offset]
  )

  const [query] = useQuery({
    query: SubmissionListQueryDocument,
    variables,
    requestPolicy: 'network-only'
  })

  if (query.error) {
    return <SubmissionListErrorFallback />
  }

  const response = query.data?.submissions
  const data = response?.data

  if (!data || data.length === 0) {
    return <EmptyList />
  }

  const pageInfo = response.pageInfo
  const totalPages = Math.max(
    Math.ceil(pageInfo.totalCount / pageInfo.limit),
    1
  )

  const handlePageChange = (page: number) => {
    const offset = pageInfo.limit * (page - 1)

    setOffset(offset)
  }

  return (
    <SubmissionListTable>
      <TableBody>
        {data.map((submission) => (
          <SubmissionListRow key={submission.id} submission={submission} />
        ))}
      </TableBody>
      <Box py={6} px={12}>
        <Pagination
          currentPage={pageInfo.offset / pageInfo.limit + 1}
          totalPages={totalPages}
          onChange={handlePageChange}
        />
      </Box>
    </SubmissionListTable>
  )
}

export const SubmissionList = () => {
  return (
    <Suspense
      fallback={
        <Box
          width='100%'
          display='flex'
          py={10}
          justifyContent='center'
          alignItems='center'
        >
          <Spinner />
        </Box>
      }
    >
      <SubmissionListRows />
    </Suspense>
  )
}
