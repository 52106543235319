import React, { ReactChild } from 'react'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import history from 'history/browser'

interface Props {
  children: ReactChild
}

const config = {
  domain: import.meta.env.VITE_REACT_APP_AUTH_DOMAIN?.toString() || '',
  clientId: import.meta.env.VITE_REACT_APP_AUTH_CLIENT_ID?.toString() || '',
  audience: import.meta.env.VITE_REACT_APP_AUTH_AUDIENCE?.toString()
}

export const AuthProvider = ({ children }: Props) => {
  const onRedirectCallback = (appState: AppState | undefined) => {
    history.push(appState?.returnTo ?? window.location.pathname)
  }

  if (import.meta.env.VITE_REACT_APP_AUTH_TYPE === 'local') {
    return children
  }

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      audience={config.audience}
      redirectUri={window.location.origin}
      cacheLocation='localstorage'
      useRefreshTokens
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
