import { ReactElement } from 'react'

import {
  ToggleButton,
  ToggleButtonLabel,
  ToggleButtonSize
} from '../../buttons'
import { ChevronDownIcon } from '../../icons'
import { splitSystemProps, SystemProps } from '../../../system'

import { UseSelectProps } from './useSelectState'
import { SelectMenu } from './SelectMenu'
import { SelectDisclosure } from './SelectDisclosure'
import { SelectPlaceholder } from './SelectPlaceholder'
import { SelectValue } from './SelectValue'
import { Select } from './Select'

interface SelectProps<T> extends UseSelectProps<T>, SystemProps {
  name?: string
  placeholder?: string
  children: ReactElement | ReactElement[]
  size?: ToggleButtonSize
}

export const SelectButton = <T,>({
  placeholder = '',
  children,
  size,
  ...other
}: SelectProps<T>) => {
  const [systemProps, stateProps] = splitSystemProps(other)

  return (
    <Select {...stateProps}>
      <SelectDisclosure>
        <ToggleButton size={size} {...systemProps}>
          <ToggleButtonLabel>
            <SelectValue />
            <SelectPlaceholder>{placeholder}</SelectPlaceholder>
            <ChevronDownIcon size='small' ml={2} />
          </ToggleButtonLabel>
        </ToggleButton>
      </SelectDisclosure>

      <SelectMenu>{children}</SelectMenu>
    </Select>
  )
}
