import { Suspense } from 'react'
import {
  EmptyState,
  Flex,
  SmileNeutralIcon,
  Spinner
} from '@woorcs/design-system'
import { BrowserRouter as Router, BrowserRouter } from 'react-router-dom'
import { OrganizationProvider, ViewerProvider } from '@woorcs/graphql'

import { AdminApp } from '@app/admin'
import { useAuth } from '@app/auth'
import { initI18n } from '@app/i18n'

import { Onboarding } from './onboarding'
import { AuthenticatedRoutes, PublicRoutes } from './routes'
import { GraphqlProvider } from './graphql'
import { Lobby } from './lobby/lobby'
import { ResetRouterContext, useRouter } from './utils/router'
import '@woorcs/design-system/src/assets/fonts/fonts.css'
import { AuthError } from './auth/AuthError'

const graphqlUrl =
  import.meta.env.VITE_REACT_APP_ADMIN_GRAPHQL_ENDPOINT?.toString() || ''

initI18n()

const Fallback = () => (
  <Flex width='100%' height='100vh' justifyContent='center' alignItems='center'>
    <Spinner />
  </Flex>
)

interface TenantAppProps {
  organizationId: string
}

export const TenantApp = ({ organizationId }: TenantAppProps) => (
  <GraphqlProvider organizationId={organizationId}>
    <BrowserRouter basename={organizationId}>
      <ViewerProvider onFailure={() => <AuthError />}>
        <OrganizationProvider>
          <Onboarding>
            <AuthenticatedRoutes />
          </Onboarding>
        </OrganizationProvider>
      </ViewerProvider>
    </BrowserRouter>
  </GraphqlProvider>
)

const AuthenticatedRouter = () =>
  useRouter([
    {
      path: 'admin/*',
      element: (
        <ResetRouterContext>
          <AdminApp graphqlUrl={graphqlUrl} basename='admin' />
        </ResetRouterContext>
      )
    },
    {
      path: '*',
      element: (
        <Lobby>
          {({ organizationId }) => (
            <TenantApp organizationId={organizationId} />
          )}
        </Lobby>
      )
    }
  ])

const PublicRouter = () => (
  <Router>
    <PublicRoutes />
  </Router>
)

const NoAccessPage = () => (
  <EmptyState
    Icon={SmileNeutralIcon}
    status='danger'
    title="You don't have access to this organization"
    description='If you think this is wrong contact an administrator.'
  />
)

export const App = () => {
  const [{ isAuthenticated, isLoading, error }] = useAuth()

  if (isLoading) {
    return <Fallback />
  }

  if (error) {
    return <NoAccessPage />
  }

  return (
    <Flex flexDirection='column' minHeight='100vh' bg='white'>
      <Suspense fallback={<Fallback />}>
        {!isAuthenticated && <PublicRouter />}
        {isAuthenticated && <AuthenticatedRouter />}
      </Suspense>
    </Flex>
  )
}
