import { useSelectState, UseSelectStateProps } from '@woorcs/hooks'
import { useCallback, MouseEvent, RefObject } from 'react'
import * as Ariakit from '@ariakit/react'
import { ariaAttr } from '@woorcs/utils'

import { useFormField, UseFormFieldProps } from '../FormField'

export interface UseListboxProps
  extends UseSelectStateProps,
    UseFormFieldProps<HTMLUListElement> {
  dense?: boolean
}

export interface ListboxItem {
  id: string | null
  ref: RefObject<HTMLElement>
  groupId?: string
  disabled?: boolean
  label?: string
}

export const useListbox = ({ dense = true, ...props }: UseListboxProps) => {
  const { disabled, readOnly, required, invalid, focused, getInputProps } =
    useFormField(props)
  const compositeStore = Ariakit.useCompositeStore({
    virtualFocus: true,
    orientation: 'vertical'
  })
  const state = useSelectState(props)

  const getListProps = () => ({
    role: 'listbox',
    'aria-labelledby': ariaAttr((props as any)['aria-labelledby']),
    'aria-label': ariaAttr((props as any)['aria-label']),
    'aria-orientation': 'vertical' as const,
    'aria-multiselectable': props.multiple ?? undefined,
    dense,
    ...getInputProps()
  })

  const getOptionButtonProps = useCallback(
    ({
      value,
      onClick
    }: {
      value: any
      onClick?: (e: MouseEvent<any>) => void
    }) => {
      const selected = state.isSelected(value)

      return {
        role: 'option',
        'aria-selected': props.disabled ? undefined : selected,
        disabled,
        onClick: (e: MouseEvent<any>) => {
          onClick?.(e)

          if (e.defaultPrevented) {
            return
          }

          state.onChange(value)
        }
        // id: value
      }
    },
    [disabled, props.disabled, state]
  )

  return {
    compositeStore,
    disabled,
    readOnly,
    required,
    invalid,
    focused,
    dense,
    getListProps,
    getOptionButtonProps,
    ...state
  }
}

export type UseListboxReturn = ReturnType<typeof useListbox>
