import { Box, Flex, Text } from '@woorcs/design-system'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import { ActitivityLogList } from '../ActivityLogList'

const ActitivityLogWidgetList = () => {
  return (
    <Flex width='100%' flexDirection='column'>
      <ActitivityLogList limit={8} />
      {/* <Box>
        <Button rightIcon={<ChevronRightIcon />}>View all</Button>
      </Box> */}
    </Flex>
  )
}

export const ActivityLogWidget = () => {
  const { t } = useTranslation('dashboard')

  return (
    <Flex
      flex={1}
      flexDirection='column'
      p={6}
      borderRadius='large'
      bg='grey.50'
    >
      <Box mb={4}>
        <Text as='h3' fontSize='medium' fontWeight='regular'>
          {t('activityLogWidget.title')}
        </Text>
      </Box>

      <Flex justifyContent='space-between' alignItems='flex-end'>
        <Suspense>
          <ActitivityLogWidgetList />
        </Suspense>
      </Flex>
    </Flex>
  )
}
