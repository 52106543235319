import { InspectionFormDefinition } from '@woorcs/inspection-form'
import { eqStrict } from 'fp-ts/Eq'
import { IO } from 'fp-ts/IO'

import { useEditorSelector } from './useEditorSelector'

export const useValue = () =>
  useEditorSelector(
    (editor) =>
      editor.getValue() as IO<InspectionFormDefinition.InspectionFormDefinition>,
    eqStrict
  )
