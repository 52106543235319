import React, { useCallback, MouseEvent } from 'react'
import { Input, Box, CloseIcon, IconButton } from '@woorcs/design-system'
import { FormDocument } from '@woorcs/form'
import * as O from 'fp-ts/Option'
import { constant, constNull, pipe } from 'fp-ts/function'

import { SelectDocumentMenu } from './Menu'

type SelectDocumentProps = {
  value: O.Option<FormDocument.FormDocument>
  placeholder: string
  onChange(document: O.Option<FormDocument.FormDocument>): void
}

export const SelectDocument = ({
  value,
  placeholder,
  onChange
}: SelectDocumentProps) => {
  const handleClear = useCallback(
    (e: MouseEvent) => {
      onChange(O.none)

      e.preventDefault()
    },
    [onChange]
  )

  const handleSelect = useCallback(
    (document: FormDocument.FormDocument) => {
      onChange(O.some(document))
    },
    [onChange]
  )

  return (
    <SelectDocumentMenu onSelect={handleSelect}>
      <Box>
        <Input
          value={pipe(
            value,
            O.map(FormDocument.getDefaultTitle),
            O.getOrElse(constant(''))
          )}
          placeholder={placeholder}
          rightAddon={pipe(
            value,
            O.fold(constNull, () => (
              <IconButton size='mini' onClick={handleClear}>
                <CloseIcon />
              </IconButton>
            ))
          )}
          readOnly
        />
      </Box>
    </SelectDocumentMenu>
  )
}
