import { ReactNode } from 'react'
import { isFunction } from '@woorcs/utils'
import * as Ariakit from '@ariakit/react'

export interface ModalProps extends Ariakit.DialogStoreProps {
  children: ReactNode | ((context: Ariakit.DialogStore) => ReactNode)
}

export const Modal = ({ children, ...props }: ModalProps) => {
  const store = Ariakit.useDialogStore(props)

  return (
    <Ariakit.DialogProvider store={store}>
      {isFunction(children) ? children(store) : children}
    </Ariakit.DialogProvider>
  )
}
