import React, { ReactElement } from 'react'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import { fromNullable } from 'fp-ts/Option'

import { AddPageRuleModal } from '../AddPageRuleModal'

interface EditPageRuleModalProps {
  page: InspectionFormDocument.InspectionFormPageElement
  children: ReactElement
}

export const EditPageRuleModal = ({
  page,
  children
}: EditPageRuleModalProps) => (
  <AddPageRuleModal page={page} initialValue={fromNullable(page.rule)}>
    {children}
  </AddPageRuleModal>
)
