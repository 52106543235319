import { ReactElement } from 'react'
import * as Ariakit from '@ariakit/react'
import { motion, AnimatePresence } from 'framer-motion'

import { system } from '../../../system'

const TooltipContainer = system('div')({
  px: 2,
  py: 1,
  borderRadius: 'small',
  backgroundColor: 'grey.700',
  color: 'white',
  fontSize: 'mini',
  fontWeight: 'bold'
})

interface TooltipProps extends Omit<Ariakit.TooltipStoreProps, 'color'> {
  title: string
  children: ReactElement
  disabled?: boolean
}

export const Tooltip = ({ children, title, ...props }: TooltipProps) => {
  const tooltip = Ariakit.useTooltipStore(props)
  const mounted = tooltip.useState('mounted')

  return (
    <Ariakit.TooltipProvider
      store={tooltip}
      showTimeout={100}
      hideTimeout={250}
    >
      <Ariakit.TooltipAnchor>{children}</Ariakit.TooltipAnchor>
      {!props.disabled && (
        <AnimatePresence>
          {mounted && (
            <Ariakit.Tooltip
              render={
                <motion.div
                  style={{
                    zIndex: 100000
                  }}
                  initial={{ opacity: 0, y: 5 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 5 }}
                />
              }
              portal
              alwaysVisible
            >
              <Ariakit.TooltipArrow />
              <TooltipContainer>{title}</TooltipContainer>
            </Ariakit.Tooltip>
          )}
        </AnimatePresence>
      )}
    </Ariakit.TooltipProvider>
  )
}
