/**
 * type used in place of a regular string to identify that it's translatable
 */

import * as L from 'monocle-ts/Lens'
import { constant, pipe } from 'fp-ts/function'
import { uuid, UUID } from '@woorcs/types/UUID'
import * as S from '@woorcs/types/Schemable'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export type TranslateableText = {
  readonly id: UUID

  /**
   * default / fallback text value
   */
  readonly text: string
}

// -------------------------------------------------------------------------------------
// instances
// -------------------------------------------------------------------------------------

export const TranslateableText: S.Type<TranslateableText> = S.type((S) =>
  S.struct({
    id: UUID.schema(S),
    text: S.string
  })
)

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const translateableText = (text: string): TranslateableText => ({
  id: uuid(),
  text
})

// -------------------------------------------------------------------------------------
// lenses
// -------------------------------------------------------------------------------------

export const textLens = pipe(L.id<TranslateableText>(), L.prop('text'))

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export const setText = (text: string) =>
  pipe(textLens, L.modify(constant(text)))

export const getText = textLens.get
