import {
  useRef,
  useState,
  useEffect,
  useCallback,
  MouseEvent,
  KeyboardEvent,
  MutableRefObject
} from 'react'
import {
  isNativeTabbable,
  isInputElement,
  isElementFocusable,
  hasFocusWithin,
  elementReceivesFocusOnMouseDown
} from '@woorcs/utils'

export type UseTabbableProps = {
  disabled?: boolean
  tabIndex?: number
  clickOnEnter?: boolean
  clickOnSpace?: boolean
  onClick?: any
  onKeyDown?: any
  onMouseDown?: any
}

export interface UseTabbableReturnProps {
  disabled?: boolean
  tabIndex?: number
  'aria-disabled'?: boolean
  onClick(e: MouseEvent): void
  onMouseDown(e: MouseEvent): void
  onKeyDown(e: KeyboardEvent): void
}

export type UseTabbableReturn<T> = [
  MutableRefObject<T | null>,
  UseTabbableReturnProps
]

export const useTabbable = <T extends HTMLElement>({
  disabled,
  tabIndex: propTabIndex,
  clickOnEnter = true,
  clickOnSpace = true,
  onClick,
  onKeyDown,
  onMouseDown
}: UseTabbableProps): UseTabbableReturn<T> => {
  const ref = useRef<T | null>(null)
  // const trulyDisabled = disabled && !focusable
  const [nativeTabbable, setNativeTabbable] = useState(true)
  const tabIndex = nativeTabbable ? propTabIndex : propTabIndex || 0

  useEffect(() => {
    if (ref.current && !isNativeTabbable(ref.current)) {
      setNativeTabbable(false)
    }
  }, [])

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (disabled) {
        event.stopPropagation()
        event.preventDefault()
      } else if (onClick) {
        onClick(event)
      }
    },
    [disabled, onClick]
  )

  const handleMouseDown = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (disabled) {
        event.stopPropagation()
        event.preventDefault()

        return
      }

      const self = event.currentTarget as HTMLElement
      const target = event.target as HTMLElement

      if (
        self.contains(target) &&
        !isInputElement(target) &&
        !elementReceivesFocusOnMouseDown(self)
      ) {
        event.preventDefault()

        const isFocusControl =
          isElementFocusable(target) || target instanceof HTMLLabelElement

        if (!hasFocusWithin(self) || self === target || !isFocusControl) {
          self.focus()
        }
      }

      onMouseDown?.(event)
    },
    [disabled, onMouseDown]
  )

  const handleKeyDown = useCallback(
    // eslint-disable-next-line complexity
    (event: KeyboardEvent<HTMLElement>) => {
      onKeyDown?.(event)

      if (disabled) {
        return
      }

      if (!isNativeTabbable(event.currentTarget)) {
        // Per the spec, space only triggers button click on key up.
        // On key down, it triggers the :active state.
        // Since we can't mimic this behavior, we trigger click on key down.
        if (
          (clickOnEnter && event.key === 'Enter') ||
          (clickOnSpace && event.key === ' ')
        ) {
          event.preventDefault()
          event.target.dispatchEvent(
            new MouseEvent('click', {
              view: window,
              bubbles: false,
              cancelable: false
            })
          )
        }

        return
      }

      if (
        (!clickOnEnter && event.key === 'Enter') ||
        (!clickOnSpace && event.key === ' ')
      ) {
        event.preventDefault()
      }
    },
    [disabled, clickOnEnter, clickOnSpace, onKeyDown]
  )

  return [
    ref,
    {
      disabled,
      tabIndex: disabled ? undefined : tabIndex,
      'aria-disabled': disabled,
      onClick: handleClick,
      onMouseDown: handleMouseDown,
      onKeyDown: handleKeyDown
    }
  ]
}
