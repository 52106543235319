import {
  Box,
  Flex,
  NativeSelect,
  NativeSelectOption
} from '@woorcs/design-system'
import { Condition, ConditionGroup, ElementRule } from '@woorcs/form'
import React, { ChangeEvent, useCallback } from 'react'

import {
  ConditionGroupForm,
  ConditionGroupConditionLabel
} from '../ConditionGroupForm'

type PageRuleEffectSelectProps = {
  value: ElementRule.ElementRuleEffect
  onChange(value: ElementRule.ElementRuleEffect): void
}

const PageRuleEffectSelect = ({
  value,
  onChange
}: PageRuleEffectSelectProps) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      if (!ElementRule.ElementRuleEffect.is(value)) {
        return
      }

      onChange(value)
    },
    [onChange]
  )

  return (
    <NativeSelect
      value={value}
      placeholder='Select action'
      onChange={handleChange}
    >
      <NativeSelectOption value='hide'>Hide page</NativeSelectOption>
      <NativeSelectOption value='show'>Show page</NativeSelectOption>
    </NativeSelect>
  )
}

type PageRuleFormProps = {
  rule: ElementRule.ElementRule
  onChange(rule: ElementRule.ElementRule): void
}

export const PageRuleForm = ({ rule, onChange }: PageRuleFormProps) => {
  const handleEffectChange = useCallback(
    (effect: ElementRule.ElementRuleEffect) => {
      onChange({
        ...rule,
        effect
      })
    },
    [onChange, rule]
  )

  const handleConditionChange = useCallback(
    (condition: Condition.Condition) => {
      onChange({
        ...rule,
        condition
      })
    },
    [onChange, rule]
  )

  return (
    <Box>
      <Box
        borderBottomColor='grey.100'
        borderBottomWidth={1}
        borderBottomStyle='solid'
        mb={4}
        pb={4}
      >
        <ConditionGroupForm
          group={rule.condition as ConditionGroup.ConditionGroup}
          onChange={handleConditionChange}
        />
      </Box>
      <Flex>
        <ConditionGroupConditionLabel>then</ConditionGroupConditionLabel>

        <PageRuleEffectSelect
          value={rule.effect}
          onChange={handleEffectChange}
        />
      </Flex>
    </Box>
  )
}
