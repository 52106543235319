import React from 'react'

import { Text, TextProps } from '../Text'

export const Overline = (props: TextProps) => (
  <Text
    as='small'
    display='block'
    color='text.muted'
    fontWeight='bold'
    fontSize='overline'
    letterSpacing='overline'
    {...props}
  />
)
