import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as Sh from 'fp-ts/Show'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as A from 'fp-ts/Array'
import * as number from 'fp-ts/number'

import * as S from './Schemable'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export type PathEntry = number

export const Path = S.type((S) => S.nonEmptyArray(S.number))

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Path extends S.TypeOf<typeof Path> {}

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const path = (path: (string | number)[]) => Path.decode(path)

export const fromNonEmptyArray = (path: NEA.NonEmptyArray<PathEntry>) =>
  path as Path

// -------------------------------------------------------------------------------------
// instances
// -------------------------------------------------------------------------------------

export const Show: Sh.Show<Path> = NEA.getShow(number.Show)

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export const splitLast = (p: Path): [path: O.Option<Path>, index: number] => {
  const last = NEA.last(p)
  const head = pipe(NEA.init(p), path)

  return [O.fromEither(head), last]
}

export const movePath =
  (delta: number) =>
  (path: Path): Path =>
    pipe(NEA.last(path) + delta, (index) => NEA.snoc(NEA.init(path), index))

export const append =
  (entry: PathEntry) =>
  (path: Path): Path =>
    pipe(path, A.append(entry))
