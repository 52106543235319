import * as O from 'fp-ts/Option'
import * as IOOption from 'fp-ts-contrib/IOOption'
import * as Eq from 'fp-ts/Eq'
import { useStableEffect, useStableO } from 'fp-ts-react-stable-hooks'
import { pipe } from 'fp-ts/function'
import { UUID } from '@woorcs/types/UUID'
import { FormElement } from '@woorcs/form'

import { useEditorSelector } from '../../state'

export const useSelectedElement = () => {
  const [selectedElementId, setSelectedElementId] = useStableO<UUID>(O.none)
  const element = useEditorSelector((editor) =>
    pipe(
      IOOption.fromOption(selectedElementId),
      IOOption.chainOptionK((elementId) =>
        pipe(
          elementId,
          editor.getElement(elementId),
          O.map((element) => element as FormElement.FormElementType)
        )
      )
    )
  )

  useStableEffect(
    () => {
      /**
       * Deselect page if doesn't exist anymore
       */
      if (O.isSome(selectedElementId) && O.isNone(element)) {
        setSelectedElementId(O.none)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedElementId, element],
    Eq.getTupleEq(O.getEq(Eq.eqString), O.getEq(Eq.eqStrict))
  )

  return [selectedElementId, element, setSelectedElementId] as const
}
