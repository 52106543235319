import {
  ValueOfFiltersConfig,
  filters,
  useFiltersContext,
  useFilters,
  queryFilter
} from '../../filters'

export const userFilters = filters({
  query: queryFilter('Search')
})

export const useUserFilters = (initialValues: Partial<UserListFilters> = {}) =>
  useFilters(userFilters, initialValues)

export type UserListFiltersConfig = typeof userFilters

type UserListFilters = ValueOfFiltersConfig<typeof userFilters>

export const useUserListFiltersContext = () =>
  useFiltersContext<UserListFiltersConfig>()
