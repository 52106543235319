import { pipe } from 'fp-ts/function'
import * as RNEA from 'fp-ts/ReadonlyNonEmptyArray'
import * as RA from 'fp-ts/ReadonlyArray'
import * as O from 'fp-ts/Option'
import * as L from 'monocle-ts/Lens'
import * as Optional from 'monocle-ts/Optional'

import { Condition, ConditionGroup } from './Condition'

export type { ConditionGroup }

const tagLens = pipe(L.id<ConditionGroup>(), L.prop('_tag'))
const conditionsLens = pipe(L.id<ConditionGroup>(), L.prop('conditions'))

export const setType = tagLens.set

export const addCondition = (condition: Condition) =>
  pipe(
    conditionsLens,
    L.modify((conditions) => pipe(conditions, RA.append(condition)))
  )

const conditionLens = (index: number) =>
  pipe(L.id<ConditionGroup>(), L.prop('conditions'), L.indexNonEmpty(index))

export const updateCondition = (index: number, condition: Condition) =>
  pipe(
    conditionLens(index),
    Optional.modify(() => condition)
  )

export const removeCondition = (index: number) =>
  pipe(
    conditionsLens,
    L.modify((conditions) =>
      pipe(
        conditions,
        RNEA.filterWithIndex((y) => index !== y),
        O.getOrElse(() => conditions)
      )
    )
  )
