import { variant } from 'styled-system'
import { space } from '@woorcs/utils'

import { system } from '../../../system'

const sizeVariants = variant({
  prop: 'size',
  variants: {
    mini: {
      mx: space(0.5)
    },
    small: {
      mx: space(0.5)
    },
    medium: {
      mx: space(2)
    }
  }
})

export const ToggleButtonLabel = system('span')(
  {
    display: 'flex',
    alignItems: 'center'
  },
  sizeVariants
)

ToggleButtonLabel.defaultProps = {
  size: 'medium'
}
