import React, { ReactElement } from 'react'
import {
  H3,
  Modal,
  ModalDialog,
  ModalDisclosure,
  ModalHeader
} from '@woorcs/design-system'

import { CreateFormForm } from '../CreateFormForm'
import { CreateFormFormFragment } from '../CreateFormForm/__generated__/CreateFormForm'

type CreateFormModalProps = {
  children: ReactElement
  onCreateForm(form: CreateFormFormFragment): void
}

export const CreateFormModal = ({
  children,
  onCreateForm
}: CreateFormModalProps) => {
  return (
    <Modal>
      <ModalDisclosure>{children}</ModalDisclosure>
      <ModalDialog>
        {({ hide }) => (
          <>
            <ModalHeader>
              <H3>Create form</H3>
            </ModalHeader>

            <CreateFormForm
              onCreateForm={(form) => {
                hide()
                onCreateForm(form)
              }}
            />
          </>
        )}
      </ModalDialog>
    </Modal>
  )
}
