import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Reader'
import { pipe } from 'fp-ts/function'
import { useCallback } from 'react'

import * as FormElement from '../../FormDocument/Element'
import { ResponseSet } from '../../types/ResponseSet'
import { FormDocument } from '../../FormDocument/FormDocument'
import { FormEnvironment } from '../../Form'

import { useResponseSet } from './useResponseSet'
import { useGetText } from './useGetText'
import { useDocument } from './useDocument'
import { useFormProgram } from './useFormProgram'

export interface UseInputElementReturn<
  T extends FormElement.FormElementType,
  R = FormElement.ValueOfInputElement<T>
> {
  element: T
  value: O.Option<R>
  label: string
  onChange(value: unknown): void
}

export type UseFormInputElement<
  T extends FormElement.FormElementType,
  A extends UseInputElementReturn<T> = UseInputElementReturn<T>
> = (props: T) => A

const useField = <T extends FormElement.InputElementType>(
  element: T
): [
  O.Option<FormElement.ValueOfInputElement<T>>,
  (value: FormElement.ValueOfInputElement<T>) => void
] => {
  const value = useFormProgram(
    pipe(
      R.ask<FormEnvironment>(),
      R.map(({ submission }) => submission.get(element.key)),
      R.map(O.chain(O.fromNullable))
      // R.map((value) =>
      //   pipe(
      //     value,
      //     InputElement.getFieldType(element.type).decode,
      //     O.fromEither
      //   )
      // )
    )
  )
  const set = useFormProgram(
    pipe(
      R.ask<FormEnvironment>(),
      R.map(({ submission }) => submission.set)
    )
  )

  const handleChange = useCallback(
    (value: FormElement.ValueOfInputElement<T>) => {
      set(element.key, value)
    },
    [element.key, set]
  )

  return [value as any, handleChange]
}

const useInputElement = <T extends FormElement.InputElementType>(
  element: T
) => {
  const [value, onChange] = useField(element)
  const label = useGetText(element.label)

  return {
    value,
    onChange,
    element,
    label
  }
}

export interface UsePlaceholderInputElementReturn<
  T extends FormElement.PlaceholderInputElement
> extends UseInputElementReturn<T> {
  placeholder: string
}

export type UsePlaceholderInputElement<
  T extends FormElement.PlaceholderInputElement,
  A extends
    UsePlaceholderInputElementReturn<T> = UsePlaceholderInputElementReturn<T>
> = (props: T) => A

const usePlaceholderInputElement = <
  T extends FormElement.PlaceholderInputElement
>(
  element: T
) => {
  const props = useInputElement(element)
  const placeholder = useGetText(element.placeholder)

  return {
    ...props,
    placeholder
  }
}

// -------------------------------------------------------------------------------------
// static elements
// -------------------------------------------------------------------------------------

export const useAlertElement = (element: FormElement.AlertElement) => {
  return element
}

export const useTextElement = (element: FormElement.TextElement) => {
  const text = useGetText(element.text)

  return {
    element,
    text
  }
}

// -------------------------------------------------------------------------------------
// input elements
// -------------------------------------------------------------------------------------

export const useTextInputElement: UsePlaceholderInputElement<FormElement.TextInputElement> =
  usePlaceholderInputElement

export const useNumberInputElement: UsePlaceholderInputElement<FormElement.NumberInputElement> =
  usePlaceholderInputElement

export const useEmailInputElement: UsePlaceholderInputElement<FormElement.EmailInputElement> =
  usePlaceholderInputElement

export const useLocationInputElement: UseFormInputElement<FormElement.LocationInputElement> =
  useInputElement

export const useImageInputElement: UseFormInputElement<FormElement.ImageInputElement> =
  useInputElement

export const useSignatureInputElement: UseFormInputElement<FormElement.SignatureInputElement> =
  useInputElement

export const useDateInputElement: UsePlaceholderInputElement<FormElement.DateInputElement> =
  usePlaceholderInputElement

export const useDateRangeInputElement: UseFormInputElement<FormElement.DateRangeInputElement> =
  useInputElement

export const useTimeInputElement: UsePlaceholderInputElement<FormElement.TimeInputElement> =
  usePlaceholderInputElement

interface UseGroupInputElementReturn
  extends UseInputElementReturn<FormElement.GroupInputElement> {
  document: O.Option<FormDocument>
}

export const useGroupInputElement: UseFormInputElement<
  FormElement.GroupInputElement,
  UseGroupInputElementReturn
> = (element) => {
  const props = useInputElement(element)
  const document = useDocument(element.document)

  return {
    ...props,
    document
  }
}
interface UseSelectInputElementReturn<
  T extends FormElement.SelectInputElement | FormElement.MultiSelectInputElement
> extends UseInputElementReturn<T> {
  responseSet: O.Option<ResponseSet>
}

type UseSelectInputElement<
  T extends FormElement.SelectInputElement | FormElement.MultiSelectInputElement
> = UseFormInputElement<T, UseSelectInputElementReturn<T>>

export const useSelectInputElement: UseSelectInputElement<
  FormElement.SelectInputElement
> = (element) => {
  const responseSet = useResponseSet(element.responseSet)
  const props = useInputElement(element)

  return {
    responseSet,
    ...props
  }
}

export const useMultiSelectInputElement: UseSelectInputElement<
  FormElement.MultiSelectInputElement
> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (element) => useSelectInputElement(element as any) as any
