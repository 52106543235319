import React, { useCallback } from 'react'
import {
  H4,
  Box,
  Button,
  ConfirmAlertDialog,
  List,
  ListItemActionField,
  ListItemTextField,
  SelectableListItem,
  Flex
} from '@woorcs/design-system'
import * as R from 'fp-ts/Record'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import { FormDocument } from '@woorcs/form'
import { InspectionFormDefinition } from '@woorcs/inspection-form'

import { useValue, useEditorContext } from '../../state'
import { AddDocumentModal } from '../AddDocumentModal'
import { EditDocumentModal } from '../EditDocumentModal'

type ManageDocumentsRowProps = {
  document: FormDocument.FormDocument
  onRemove(document: FormDocument.FormDocument): void
}

const ManageDocumentsRow = ({
  document,
  onRemove
}: ManageDocumentsRowProps) => {
  const handleRemove = useCallback(
    () => onRemove(document),
    [onRemove, document]
  )

  return (
    <SelectableListItem>
      <EditDocumentModal document={document}>
        <ListItemTextField css={{ cursor: 'pointer ' }}>
          {FormDocument.getDefaultTitle(document)}
        </ListItemTextField>
      </EditDocumentModal>
      <ListItemActionField>
        <ConfirmAlertDialog
          title='Remove document'
          body='Are you sure that you want to remove the document?'
          confirmLabel='Remove'
          destructive
          onConfirm={handleRemove}
        >
          <Button colorVariant='danger' size='small'>
            Delete
          </Button>
        </ConfirmAlertDialog>
      </ListItemActionField>
    </SelectableListItem>
  )
}

export const ManageDocuments = () => {
  const root = useValue()
  const { editor } = useEditorContext()

  const handleRemove = useCallback(
    (document: FormDocument.FormDocument) => {
      const removeDocument = pipe(
        root,
        InspectionFormDefinition.removeFormDocument(document),
        editor.updateElement
      )

      removeDocument()
    },
    [editor.updateElement, root]
  )

  const renderDocumentList = () =>
    pipe(
      root.documents,
      R.collect((_, document) => document),
      A.map((document) => (
        <ManageDocumentsRow
          key={document.id}
          document={document}
          onRemove={handleRemove}
        />
      ))
    )

  return (
    <Box>
      <Box mb={8} px={4}>
        <H4>Manage documents</H4>
      </Box>

      <Box mb={8}>
        <List>{renderDocumentList()}</List>
      </Box>

      <Flex px={4} justifyContent='flex-end'>
        <AddDocumentModal>
          <Button>Add document</Button>
        </AddDocumentModal>
      </Flex>
    </Box>
  )
}
