import { memoizeWith, identity } from 'ramda'
import isPropValid from '@emotion/is-prop-valid'

import { systemPropNames } from './props'

/**
 * List of props for emotion to omit from DOM.
 */
const allPropNames = [
  ...systemPropNames,
  'htmlWidth',
  'htmlHeight',
  'htmlSize',
  'as',
  'css',
  'size',
  'colorVariant',
  '__css'
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createShouldForwardProp(props: any[]) {
  const regex = new RegExp(`^(${props.join('|')})$`)

  return memoizeWith(
    identity,
    (prop: string) => isPropValid(prop) && !regex.test(prop)
  )
}

export const shouldForwardProp = createShouldForwardProp(allPropNames)
