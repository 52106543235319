import { useCallback } from 'react'
import { Flex, Box } from '@woorcs/design-system'
import { DndProvider, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  InspectionFormDefinition,
  InspectionFormDocument
} from '@woorcs/inspection-form'
import { UUID } from '@woorcs/types/UUID'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as string from 'fp-ts/string'
import * as number from 'fp-ts/number'
import * as Eq from 'fp-ts/Eq'
import { useStable, useStableEffect } from 'fp-ts-react-stable-hooks'
import { constant, constNull, pipe } from 'fp-ts/function'
import { usePreviousStrict } from '@woorcs/hooks'

import { EditorDragLayer } from '../DragLayer'
import {
  useEditor,
  emptyState,
  EditorProvider,
  usePageIds,
  useOptionalElement
} from '../../state'
import { PAGE_LIST_ITEM_DRAG_TYPE } from '../PageList/Page/DragItem'

import { EditorHeader } from './Header'
import { EditorLeftSidebar } from './LeftSidebar'
import { EditPage } from './Page'

interface EditorProps {
  version?: number
  updatedAt: Date
  isSaving: boolean
  isPublishing: boolean
  published: boolean
  onPublish(): void
  onQuit(): void
}

export const Editor = ({
  version,
  updatedAt,
  isSaving,
  isPublishing,
  published,
  onPublish,
  onQuit
}: EditorProps) => {
  const pageIds = usePageIds()
  const initialPageId = A.head(pageIds)

  const [selectedPageId, setPageId] = useStable<O.Option<UUID>>(
    initialPageId,
    O.getEq(string.Eq)
  )
  const selectedPageIndex = pipe(
    selectedPageId,
    O.chain((selectedPageId) =>
      pipe(
        pageIds,
        A.findIndex((id) => id === selectedPageId)
      )
    )
  )
  const prevIndex = usePreviousStrict(selectedPageIndex)
  const handlePageChange = useCallback(
    (page: InspectionFormDocument.InspectionFormPageElement) => {
      setPageId(O.some(page.id))
    },
    [setPageId]
  )

  const page = useOptionalElement(selectedPageId) as O.Option<{
    element: InspectionFormDocument.InspectionFormPageElement
  }>

  useStableEffect(
    () => {
      if (O.isSome(page)) {
        return
      }

      pipe(
        O.flatten(prevIndex),
        O.getOrElse(constant(0)),
        (index) =>
          pipe(
            pageIds,
            A.lookup(index - 1),
            O.alt(() => A.head(pageIds))
          ),
        setPageId
      )
    },
    [selectedPageId, page, prevIndex],
    Eq.tuple(
      O.getEq(string.Eq),
      O.getEq(Eq.eqStrict),
      O.getEq(O.getEq(number.Eq))
    )
  )

  /**
   * Setting the whole page as a drop area prevents a bug where the page preview
   * will be stuck while the invisible browser preview is animating.
   */
  const [, ref] = useDrop({
    accept: [PAGE_LIST_ITEM_DRAG_TYPE]
  })

  return (
    <Flex ref={ref} minHeight='100vh' flexDirection='column' pt={18}>
      {pipe(
        selectedPageId,
        O.fold(constNull, (pageId) => (
          <EditorHeader
            selectedPageId={pageId}
            version={version}
            updatedAt={updatedAt}
            isSaving={isSaving}
            isPublishing={isPublishing}
            published={published}
            onPublish={onPublish}
            onQuit={onQuit}
            onPageChange={setPageId}
          />
        ))
      )}

      <Box height='100%'>
        {pipe(
          selectedPageId,
          O.fold(constNull, (pageId) => <EditPage pageId={pageId} />)
        )}

        <EditorLeftSidebar
          selectedPageId={selectedPageId}
          onSelectPage={handlePageChange}
        />
      </Box>
    </Flex>
  )
}

interface InspectionFormEditorProps {
  initialValue?: InspectionFormDefinition.InspectionFormDefinition
  version?: number
  updatedAt?: Date
  isSaving: boolean
  isPublishing: boolean
  published: boolean
  onChange(definition: InspectionFormDefinition.InspectionFormDefinition): void
  onSave(definition: InspectionFormDefinition.InspectionFormDefinition): void
  onPublish(): void
  onQuit(): void
}

export const InspectionFormEditor = ({
  initialValue = emptyState,
  version,
  updatedAt = new Date(),
  onPublish,
  isSaving,
  isPublishing,
  published,
  onChange,
  onQuit
}: InspectionFormEditorProps) => {
  const editor = useEditor(initialValue, onChange, false)

  return (
    <EditorProvider editor={editor}>
      <DndProvider backend={HTML5Backend}>
        <Box bg='#F8F8FB'>
          <EditorDragLayer />
          <Editor
            version={version}
            updatedAt={updatedAt}
            isSaving={isSaving}
            isPublishing={isPublishing}
            published={published}
            onPublish={onPublish}
            onQuit={onQuit}
          />
        </Box>
      </DndProvider>
    </EditorProvider>
  )
}
