import { ReactNode, ReactElement } from 'react'
import {
  NavSidebar,
  NavSidebarMenu,
  NavSidebarMenuItem,
  DocumentIcon,
  Box,
  Flex,
  FormIcon,
  UserListIcon,
  NavSidebarMenuItemProps,
  DashboardIcon,
  Avatar,
  Text
} from '@woorcs/design-system'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useViewer } from '@woorcs/graphql'

import { UserMenu } from '../UserMenu'

import { SidebarOrganizationMenu } from './OrganizationMenu'

interface MenuItemProps extends NavSidebarMenuItemProps {
  to: string
  icon: ReactElement
  children: ReactNode
}

const MenuItem = ({ to, icon, children, ...other }: MenuItemProps) => {
  return (
    <NavSidebarMenuItem
      as={NavLink}
      icon={icon}
      {...({ to } as any)}
      {...other}
    >
      {children}
    </NavSidebarMenuItem>
  )
}

export const AppSidebar = () => {
  const { t } = useTranslation()
  const viewer = useViewer()

  return (
    <NavSidebar>
      <Box>
        <Box mb={8} px={4}>
          <SidebarOrganizationMenu />
        </Box>

        <NavSidebarMenu>
          <MenuItem
            to=''
            icon={<DashboardIcon />}
            aria-label={t('sidebar.navigation.dashboard')}
          >
            {t('sidebar.navigation.dashboard')}
          </MenuItem>
          <MenuItem
            to='forms'
            icon={<FormIcon />}
            aria-label={t('sidebar.navigation.forms')}
          >
            {t('sidebar.navigation.forms')}
          </MenuItem>

          <MenuItem
            to='submissions'
            icon={<DocumentIcon />}
            aria-label={t('sidebar.navigation.submissions')}
          >
            {t('sidebar.navigation.submissions')}
          </MenuItem>

          <MenuItem
            to='users'
            icon={<UserListIcon />}
            aria-label={'sidebar.navigation.users'}
          >
            {t('sidebar.navigation.users')}
          </MenuItem>
        </NavSidebarMenu>
      </Box>
      <Box px={2}>
        <UserMenu>
          <Box
            __css={{
              cursor: 'pointer',
              ':hover': {
                bg: 'primary.50'
              }
            }}
            borderRadius='large'
            display='flex'
            alignItems='center'
            p={2}
          >
            <Flex alignItems='center'>
              <Avatar
                name={viewer.account.email}
                bg='accent.100'
                color='accent.500'
                size='small'
                mr={2}
              />

              <Text display='block' fontSize='mini' fontWeight='bold'>
                {viewer.account.email}
              </Text>
            </Flex>
          </Box>
        </UserMenu>
      </Box>
    </NavSidebar>
  )
}
