import { forwardRef } from 'react'
import { animated, useSpring } from 'react-spring'

import { CheckIcon } from '../../icons'

import { MenuItem, MenuItemProps } from './MenuItem'
import { MenuItemIcon } from './MenuItemIcon'
import { UseMenuOptionProps, useMenuOption } from './useMenu'

interface MenuOptionProps
  extends UseMenuOptionProps,
    Omit<MenuItemProps, 'value' | 'onChange' | 'onClick'> {}

export const MenuOption = forwardRef<HTMLButtonElement, MenuOptionProps>(
  ({ children, value, onClick, onChange, ...other }, ref) => {
    const props = useMenuOption({ value, onClick, onChange })
    const spring = useSpring({
      opacity: props.checked ? 1 : 0,
      transform: props.checked ? 'scale(1)' : 'scale(0.75)'
    })

    return (
      <MenuItem ref={ref} {...other} {...props}>
        <MenuItemIcon>
          <animated.div style={spring}>
            <CheckIcon />
          </animated.div>
        </MenuItemIcon>

        {children}
      </MenuItem>
    )
  }
)
