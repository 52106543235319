import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react'
import { isRight } from 'fp-ts/Either'
import { absurd, pipe } from 'fp-ts/function'
import { useViewer } from '@woorcs/graphql'

import { ViewerProfile } from '@app/components'

import { OnboardingLayout, OnboardingWizard } from './components'
import { ProfileSettingsStep } from './steps'
import { onboardingStep } from './model'

const profileSettingsStep = onboardingStep('profile-settings', {
  title: 'Profile settings',
  isCompleted: (viewer) => pipe(ViewerProfile.decode(viewer), isRight)
})

const onboardingSteps = [profileSettingsStep]

type OnboardingStep = typeof profileSettingsStep

type OnboardingProps = {
  children: ReactElement
}

export const Onboarding = ({ children }: OnboardingProps) => {
  const viewer = useViewer()
  const activeSteps = useMemo(
    () => onboardingSteps.filter((step) => !step.isCompleted(viewer)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const isCompleted = activeSteps.every((step) => step.isCompleted(viewer))

  const handleIndexChange = useCallback(
    (index: number) => {
      if (index > activeSteps.length - 1) {
        return
      }

      setCurrentStepIndex(index)
    },
    [activeSteps.length]
  )

  const renderStep = useCallback(({ key }: OnboardingStep): ReactNode => {
    switch (key) {
      case 'profile-settings': {
        return <ProfileSettingsStep />
      }

      default:
        return absurd(key)
    }
  }, [])

  if (isCompleted) {
    return children
  }

  return (
    <OnboardingLayout>
      <OnboardingWizard
        currentIndex={currentStepIndex}
        renderStep={renderStep}
        steps={activeSteps}
        onIndexChange={handleIndexChange}
      />
    </OnboardingLayout>
  )
}
