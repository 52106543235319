import React, { ReactNode } from 'react'
import { css, Global } from '@emotion/react'
import emotionNormalize from 'emotion-normalize'
import {
  baseFontSize,
  baseLineHeightRatio,
  fonts,
  fontWeights,
  palette
} from '@woorcs/design-tokens'
import { pxToRelative } from '@woorcs/utils'

import { ThemeProvider } from '../theme'

import { reset } from './reset'

// import '../assets/fonts/fonts.css'

const DEFAULT_BROWSER_FONT_SIZE = 16

export const establishBaseline = () => `
  html {
    font-size: ${pxToRelative(DEFAULT_BROWSER_FONT_SIZE, baseFontSize) * 100}%;
    line-height: ${baseLineHeightRatio};
  }
`

interface Props {
  children: ReactNode
}

export const DesignSystemProvider = ({ children }: Props) => (
  <ThemeProvider>
    <Global
      styles={css`
        ${emotionNormalize}
        ${reset}
        ${establishBaseline()}

        html {
          font-family: ${fonts.body};
          font-weight: ${fontWeights.regular};
          font-feature-settings: 'kern';
        }

        body {
          padding: 0 !important;
        }

        *,
        *:before,
        *:after {
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
        }

        ::selection {
          color: rgba(255, 255, 255, 0.85);
          background: ${palette.primary['200']};
        }
      `}
    />
    {children}
  </ThemeProvider>
)
