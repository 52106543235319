import { ReactElement, useCallback, useState } from 'react'
import {
  PrimaryButton,
  SecondaryButton,
  DialogSidebar,
  DialogSidebarDisclosure,
  DialogSidebarContent,
  Box,
  H4,
  Body
} from '@woorcs/design-system'
import { DialogDismiss } from '@ariakit/react'
import { ResponseSet } from '@woorcs/form'

import { ResponseSetForm } from '../ResponseSetForm'

type ResponseSetModalFormProps = {
  initialValue?: ResponseSet.ResponseSet
  onSubmit(value: ResponseSet.ResponseSet): void
}

const ResponseSetModalForm = ({
  initialValue = ResponseSet.of(''),
  onSubmit
}: ResponseSetModalFormProps) => {
  const [value, setValue] = useState(initialValue)
  const valid = ResponseSet.ResponseSet.is(value)
  const handleSubmit = useCallback(() => {
    onSubmit(value)
  }, [onSubmit, value])

  return (
    <Box>
      <Box mb={8}>
        <ResponseSetForm responseSet={value} onChange={setValue} />
      </Box>

      <Box>
        <DialogDismiss render={<SecondaryButton mr={2} />}>
          Cancel
        </DialogDismiss>
        <PrimaryButton disabled={!valid} onClick={handleSubmit}>
          Save response set
        </PrimaryButton>
      </Box>
    </Box>
  )
}

interface ResponseSetFormModalProps {
  children: ReactElement
  initialValue?: ResponseSet.ResponseSet
  onSubmit(value: ResponseSet.ResponseSet): void
}

export const ResponseSetFormModal = ({
  children,
  initialValue,
  onSubmit
}: ResponseSetFormModalProps) => {
  const [visible, setVisible] = useState(false)
  const handleSubmit = useCallback(
    (responseSet: ResponseSet.ResponseSet) => {
      onSubmit(responseSet)
      setVisible(false)
    },
    [onSubmit]
  )

  return (
    <DialogSidebar open={visible} placement='right' setOpen={setVisible}>
      <DialogSidebarDisclosure>{children}</DialogSidebarDisclosure>
      <DialogSidebarContent>
        <Box px={5} pt={8} pb={5}>
          <Box mb={8}>
            <H4 mb={2}>Response set</H4>
            <Body>{`e.g. Yes, No, N/A`}</Body>
          </Box>

          <ResponseSetModalForm
            initialValue={initialValue}
            onSubmit={handleSubmit}
          />
        </Box>
      </DialogSidebarContent>
    </DialogSidebar>
  )
}
