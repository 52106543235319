import { ComponentProps, forwardRef } from 'react'

import { useSelectContext } from './useSelectState'

export const SelectValue = forwardRef<HTMLDivElement, ComponentProps<'div'>>(
  ({ children, ...props }, ref) => {
    const { hasValue, multiple, selectedLabelRef, value } = useSelectContext()

    if (!hasValue) {
      return null
    }

    function render() {
      if (children) {
        return children
      }

      if (multiple) {
        return (value as string[]).join(', ')
      }

      if (selectedLabelRef.current) {
        return selectedLabelRef.current
      }

      return value as string
    }

    return (
      <div
        ref={ref}
        style={{
          flex: '1 1 auto',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
        {...props}
      >
        {render()}
      </div>
    )
  }
)
