import React, {
  createContext,
  useContext,
  Children,
  cloneElement,
  ReactElement
} from 'react'

interface ListContextState {
  dense: boolean
}

const ListContext = createContext<ListContextState>({
  dense: false
})

interface UseListContextProps {
  dense?: boolean
}

export const useListContext = ({ dense }: UseListContextProps = {}) => {
  const context = useContext(ListContext) || {}

  return {
    dense: dense ?? context.dense
  }
}

type ListContextProviderProps = {
  dense?: boolean
  children: ReactElement<any>[] | ReactElement<any>
}

export const ListContextProvider = ({
  children,
  ...other
}: ListContextProviderProps) => {
  const context = useListContext(other)

  return (
    <ListContext.Provider value={context}>
      {Children.map(children, (child) =>
        cloneElement(child, {
          dense: context.dense
        })
      )}
    </ListContext.Provider>
  )
}
