import * as SchemaTypes from '../../../../schema/schema.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { SuperuserListUserFragmentDoc } from '../../../components/SuperuserList/__generated__/SuperuserList';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type RemoveSuperuserMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.RemoveSuperuserInput;
}>;


export type RemoveSuperuserMutation = { __typename: 'Mutation', removeSuperuser: { __typename: 'NoSuperuserError' } | { __typename: 'RemoveSuperuserSuccess', success: boolean } | null };

export type AddSuperuserMutationVariables = SchemaTypes.Exact<{
  email: SchemaTypes.Scalars['String'];
}>;


export type AddSuperuserMutation = { __typename: 'Mutation', addSuperuser: { __typename: 'AddSuperuserSuccess', user: { __typename: 'User', id: string, name: string, account: { __typename: 'Account', id: string, email: string } } } | { __typename: 'AlreadySuperuserError' } | null };

export type SuperUserListQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type SuperUserListQuery = { __typename: 'Query', superusers: Array<{ __typename: 'User', id: string, name: string, account: { __typename: 'Account', id: string, email: string } }> };


export const RemoveSuperuserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveSuperuser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RemoveSuperuserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeSuperuser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RemoveSuperuserSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]}}]} as unknown as DocumentNode<RemoveSuperuserMutation, RemoveSuperuserMutationVariables>;
export const AddSuperuserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddSuperuser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addSuperuser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AddSuperuserSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SuperuserListUserFragment"}}]}}]}}]}}]}},...SuperuserListUserFragmentDoc.definitions]} as unknown as DocumentNode<AddSuperuserMutation, AddSuperuserMutationVariables>;
export const SuperUserListQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SuperUserListQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"superusers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SuperuserListUserFragment"}}]}}]}},...SuperuserListUserFragmentDoc.definitions]} as unknown as DocumentNode<SuperUserListQuery, SuperUserListQueryVariables>;