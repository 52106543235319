import { UUID } from '@woorcs/types/UUID'
import { isArray } from '@woorcs/utils'
import { Option } from 'fp-ts/Option'

import * as Condition from '../types/Condition'

import * as ElementRule from './ElementRule'

export interface ElementRuleEngine {
  isFulfilled(rule: ElementRule.ElementRule): boolean
}

export const getRuleEngine = (
  getValue: (key: UUID) => Option<unknown>
): ElementRuleEngine => {
  const evaluator = Condition.getEvaluator({
    getValue,
    getCompare: () => (a, b) => {
      switch (typeof a) {
        case 'string':
          return a === b
        case 'object':
          return isArray(a) ? a.includes(b) : false
        default:
          return false
      }
    }
  })

  return {
    isFulfilled: (rule) => evaluator.evaluate(rule.condition)
  }
}
