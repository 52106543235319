import { createContext } from '@woorcs/utils'

import { Editor } from './state'

type EditorContext = {
  editor: Editor
}

export const [EditorContextProvider, useContextHook] =
  createContext<EditorContext>()

export const useContext = () => useContextHook() as EditorContext
