import * as O from 'fp-ts/Option'
import { Surface } from '@woorcs/design-system'
import React from 'react'
import { pipe } from 'fp-ts/function'
import { FormElement } from '@woorcs/form'
import { InspectionFormDocument } from '@woorcs/inspection-form'

import { EditElementMenu } from '../EditElementMenu'
import { EditPageSidebar as BaseEditPageSidebar } from '../EditPageSidebar'

type EditPageSidebarProps = {
  page: InspectionFormDocument.InspectionFormPageElement
  selectedElement: O.Option<FormElement.FormElementType>
  onDeselectElement(): void
}

export const EditPageSidebar = ({
  page,
  selectedElement,
  onDeselectElement
}: EditPageSidebarProps) => {
  return pipe(
    selectedElement,
    O.fold(
      () => <BaseEditPageSidebar page={page} />,
      (element) => (
        <Surface
          display='flex'
          position='fixed'
          right={0}
          top={0}
          bottom={0}
          width={420}
          elevation={1}
          pt={18}
        >
          <EditElementMenu
            key={element.id}
            element={element}
            onClose={onDeselectElement}
          />
        </Surface>
      )
    )
  )
}
