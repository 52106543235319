import React, { memo, useCallback } from 'react'
import {
  focusOutlineColor,
  css,
  AbsoluteFill,
  Box
} from '@woorcs/design-system'
import { palette } from '@woorcs/design-tokens'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import { useMeasure } from '@woorcs/hooks'
import { forkRefs } from '@woorcs/utils'
import { constFalse, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'

import { PageListItem, PageListItemProps } from './Page'
import { useSortablePageListItem } from './useSortablePage'

export interface SortablePageListItemProps
  extends Pick<PageListItemProps, 'page'> {
  index: number
  isSelected?: boolean
  onClick(page: InspectionFormDocument.InspectionFormPageElement): void
}

export const SortablePageListItem = memo(
  ({ page, index, isSelected, onClick }: SortablePageListItemProps) => {
    const [dragRef, { isOver, isDragging, draggingOtherItem }] =
      useSortablePageListItem({
        page,
        index
      })

    const [measureRef] = useMeasure()

    const handleClick = useCallback(() => {
      onClick(page)
    }, [onClick, page])

    const isDraggingPage = pipe(
      draggingOtherItem,
      O.fold(constFalse, (item) => item.type === 'page-list-item')
    )

    const isDraggingElement = pipe(
      draggingOtherItem,
      O.fold(constFalse, (item) => item.type === 'element-list-item')
    )

    const hoverStyle = () => {
      if (isDraggingPage) {
        return {}
      }

      if (isDraggingElement && isOver) {
        return {
          background: palette.orange['100'],
          transition: 'transform 0.3s ease-out',
          ':hover': {
            background: palette.orange['100']
          }
        }
      }

      return {
        transition: 'transform 0.3s ease-out',
        ':hover': {
          boxShadow: `0 0 2px 3px ${focusOutlineColor}, 0 0 0 1px ${palette.primary[500]};`
        }
      }
    }

    return (
      <Box ref={forkRefs(dragRef, measureRef)} position='relative'>
        <PageListItem
          key={page.id}
          css={css({
            width: '100%',
            // eslint-disable-next-line no-nested-ternary
            bg: isSelected ? 'primary.50' : isDragging ? 'primary.50' : 'white',
            borderColor: isDragging ? 'transparent' : 'grey.50',
            '> *': {
              opacity: isDragging ? 0 : 1
            },
            ...hoverStyle()
          })}
          page={page}
          onClick={handleClick}
        />
        {isDraggingElement && isOver && (
          <AbsoluteFill bg='orange.100'>Move here</AbsoluteFill>
        )}
      </Box>
    )
  }
)
