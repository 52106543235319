import React from 'react'
import { Badge } from '@woorcs/design-system'
import { ResponseSet } from '@woorcs/form'

type ResponseSetOptionsBadgeProps = {
  responseSet: ResponseSet.ResponseSet
}

export const ResponseSetOptionsBadge = ({
  responseSet
}: ResponseSetOptionsBadgeProps) => {
  return <Badge flexShrink={0}>{`${responseSet.options.length} options`}</Badge>
}
