import React from 'react'
import {
  IconButton,
  Box,
  Tooltip,
  UndoIcon,
  RedoIcon,
  BoxProps
} from '@woorcs/design-system'
import { useHotkeys } from 'react-hotkeys-hook'

import { useEditorHistory } from '../../state'

type HistoryActionsProps = BoxProps

export const HistoryActions = (props: HistoryActionsProps) => {
  const { canRedo, canUndo, undo, redo } = useEditorHistory()

  // FIXME: Should live somewhere further up in the component tree
  useHotkeys('⌘+z,ctrl+z', undo)
  useHotkeys('⌘+⇧+z,ctrl+⇧+z', redo)

  return (
    <Box display='flex' alignItems='center' {...props}>
      <Tooltip title='Undo'>
        <IconButton
          size='small'
          variant='plain'
          colorVariant='neutral'
          disabled={!canUndo}
          mr={2}
          onClick={undo}
        >
          <UndoIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Redo'>
        <IconButton
          size='small'
          variant='plain'
          colorVariant='neutral'
          disabled={!canRedo}
          onClick={redo}
        >
          <RedoIcon />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
