import { FormDocument } from '@woorcs/form'
import React, { useState, useCallback } from 'react'
import { Box, Button, Flex } from '@woorcs/design-system'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { UUID } from '@woorcs/types/UUID'
import { InspectionFormDefinition } from '@woorcs/inspection-form'

import { ElementList } from '../ElementList'
import { AddElementMenu } from '../AddElementMenu'
import {
  EditorProvider,
  useEditor,
  useEditorContext,
  useValue
} from '../../state'

interface EditDocumentProps {
  document: FormDocument.FormDocument
}

const Empty = () => (
  <Flex
    justifyContent='center'
    alignItems='center'
    borderRadius='medium'
    bg='grey.50'
    borderColor='primary.50'
    borderWidth={1}
    borderStyle='solid'
    py={14}
  >
    <AddElementMenu at={[0]}>
      <Button colorVariant='secondary' size='small'>
        Add element
      </Button>
    </AddElementMenu>
  </Flex>
)

export const EditDocument = ({ document }: EditDocumentProps) => {
  const root = useValue()
  const { editor } = useEditorContext()
  const handleChange = useCallback(
    (value: FormDocument.FormDocument) => {
      const update = pipe(
        root,
        InspectionFormDefinition.updateFormDocument(value),
        editor.updateElement
      )

      update()
    },
    [editor.updateElement, root]
  )

  const documentEditor = useEditor(document, handleChange, false)
  const [selectedElementId, setSelectedElementId] = useState<O.Option<UUID>>(
    O.none
  )

  const handleSelectElement = useCallback(
    (elementID: UUID) => {
      setSelectedElementId(O.some(elementID))
    },
    [setSelectedElementId]
  )

  const elements = pipe(
    document.children,
    NEA.fromArray,
    O.fold(
      () => <Empty />,
      (elements) => (
        <ElementList
          elements={elements}
          selectedElementId={selectedElementId}
          onEditElement={handleSelectElement}
          onElementClick={handleSelectElement}
        />
      )
    )
  )

  return (
    <EditorProvider editor={documentEditor}>
      <Box>{elements}</Box>
    </EditorProvider>
  )
}
