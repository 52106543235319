import * as L from 'monocle-ts/Lens'
import { pipe } from 'fp-ts/function'

import { LeafCondition, leaf } from './Condition'

export { LeafCondition, leaf }

// -------------------------------------------------------------------------------------
// lenses
// -------------------------------------------------------------------------------------

export const keyLens = pipe(L.id<LeafCondition>(), L.prop('key'))

export const operatorLens = pipe(L.id<LeafCondition>(), L.prop('operator'))

export const expectedValueLens = pipe(
  L.id<LeafCondition>(),
  L.prop('expectedValue')
)
