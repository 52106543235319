import { ReactElement, useState } from 'react'
import {
  Box,
  Button,
  Popover,
  PopoverContent,
  PopoverDisclosure
} from '@woorcs/design-system'
import { ResponseSet } from '@woorcs/form'
import * as RR from 'fp-ts/ReadonlyRecord'
import * as O from 'fp-ts/Option'
import * as RNEA from 'fp-ts/ReadonlyNonEmptyArray'
import * as string from 'fp-ts/string'
import { pipe } from 'fp-ts/function'

import { AddResponseSetModal } from '../AddResponseSetModal'
import { useValue } from '../../state'
import { ManageResponseSetsModal } from '../ManageResponseSets'

import { ResponseSetList } from './List'

type SelectResponseSetMenuProps = {
  children: ReactElement
  onSelect(responseSet: ResponseSet.ResponseSet): void
}

export const SelectResponseSetMenu = ({
  children,
  onSelect
}: SelectResponseSetMenuProps) => {
  const [visible, setVisible] = useState(false)
  const root = useValue()
  const responseSets = pipe(
    root.responseSets,
    RR.collect(string.Ord)((_, responseSet) => responseSet),
    RNEA.fromReadonlyArray
  )

  return (
    <Popover placement='bottom-start' open={visible} setOpen={setVisible}>
      <PopoverDisclosure>{children}</PopoverDisclosure>
      <PopoverContent p={4} width={320}>
        <Box>
          {pipe(
            responseSets,
            O.fold(
              () => (
                <AddResponseSetModal>
                  <Button colorVariant='secondary' fullWidth>
                    Add a response set
                  </Button>
                </AddResponseSetModal>
              ),
              () => (
                <Box>
                  <Box mb={4}>
                    <ResponseSetList
                      onResponseSetClick={(responseSet) => {
                        setVisible(false)
                        onSelect(responseSet)
                      }}
                    />
                  </Box>

                  <Box>
                    <ManageResponseSetsModal>
                      <Button colorVariant='secondary' fullWidth>
                        Manage response sets
                      </Button>
                    </ManageResponseSetsModal>
                  </Box>
                </Box>
              )
            )
          )}
        </Box>
      </PopoverContent>
    </Popover>
  )
}
