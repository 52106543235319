import React from 'react'

import { Flex } from '../../layout'
import { Overline } from '../../typography'

import { CELL_WIDTH } from './CalendarCell'

export const weekdayNamesShort = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
]

export const CalendarWeekdays = () => {
  return (
    <Flex>
      {weekdayNamesShort.map((day) => (
        <Flex
          key={`calendar-weekday-${day}`}
          justifyContent='center'
          py={4}
          width={CELL_WIDTH}
        >
          <Overline>{day}</Overline>
        </Flex>
      ))}
    </Flex>
  )
}
