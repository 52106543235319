import React, {
  createContext,
  useContext,
  Children,
  cloneElement,
  ComponentType,
  ReactNode,
  ReactElement
} from 'react'
import { rem } from '@woorcs/utils'

import { Box, Flex, BoxProps } from '../../layout'
import { system, css } from '../../../system'

type ListContextState = {
  depth: number
  // TODO: Handle this in the OrderedList component...
  start?: number
}

const ListContext = createContext<ListContextState>({
  depth: 1
})

const BareList = system('div')({
  listStyle: 'none'
})

BareList.defaultProps = {
  as: 'ul'
}

interface DecoratorProps {
  index?: number
  depth: number
}

const Bullet = system(Box)<DecoratorProps>(
  css({
    flexShrink: 0,
    width: rem(4),
    height: rem(4),
    borderRadius: '100%',
    borderColor: 'primary.500',
    borderWidth: 1,
    borderStyle: 'solid'
  }),
  ({ depth }: DecoratorProps) =>
    css({
      backgroundColor: depth > 1 ? 'primary.500' : 'transparent',
      borderColor: depth > 1 ? 'transparent' : 'primary.500'
    })
)

type BullettListItemProps = {
  index?: number
  Decorator?: ComponentType<DecoratorProps>
  children: ReactNode
}

export const BulletListItem = ({
  index,
  children,
  Decorator = Bullet,
  ...other
}: BullettListItemProps) => {
  const { depth } = useContext(ListContext)

  return (
    <Flex as='li' {...other}>
      <Box
        css={{
          height: '1.5em',
          display: 'flex',
          alignItems: 'center'
        }}
        mr={3}
        ml={2}
      >
        <Decorator index={index} depth={depth} />
      </Box>
      <Box
        css={{
          '& > *:last-child': {
            marginBottom: 0
          }
        }}
      >
        {children}
      </Box>
    </Flex>
  )
}

type Props = {
  start?: number
  children: ReactElement | ReactElement[]
} & BoxProps

export const BulletList = ({ start = 1, children, ...other }: Props) => {
  const { depth: parentDepth = 0 } = useContext(ListContext) || {}

  return (
    <ListContext.Provider value={{ start, depth: parentDepth + 1 }}>
      <BareList
        css={css({
          li: {
            mb: 2,
            ':last-child': {
              mb: 0
            }
          }
        })}
        {...other}
      >
        {Children.map(children, (child, index) =>
          cloneElement(child as any, {
            index: index + start
          })
        )}
      </BareList>
    </ListContext.Provider>
  )
}
