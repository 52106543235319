export type Status = 'success' | 'danger' | 'warning' | 'info'

export const matchStatus =
  <A>(cases: {
    success: () => A
    danger: () => A
    warning: () => A
    info: () => A
  }) =>
  (status: Status): A =>
    cases[status]()
