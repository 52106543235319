/* eslint-disable @typescript-eslint/no-use-before-define */
import * as RA from 'fp-ts/ReadonlyArray'
import * as O from 'fp-ts/Option'
import * as T from 'fp-ts/Tree'
import * as NEA from 'fp-ts/NonEmptyArray'
import { constant, constFalse, constTrue, pipe } from 'fp-ts/function'
import * as Lens from 'monocle-ts/Lens'
import * as Prism from 'monocle-ts/Prism'
import * as Optional from 'monocle-ts/Optional'
import * as Ix from 'monocle-ts/Ix'
import * as Traversal from 'monocle-ts/Traversal'
import { isObject } from '@woorcs/utils'
import { Predicate } from 'fp-ts/Predicate'
import { Path, splitLast, fromNonEmptyArray } from '@woorcs/types/Path'
import { UUID } from '@woorcs/types/UUID'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export interface Element {
  readonly type: string
  readonly id?: UUID
  readonly children?: ReadonlyArray<ElementTree>
}

export interface ParentElement extends Element {
  readonly children: ReadonlyArray<ElementTree>
}

export type ElementTree = Element | ParentElement

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export const isParent = (a: unknown): a is ParentElement =>
  isObject(a) && Array.isArray(a.children)

export const match =
  <A>(onElement: (e: Element) => A, onParent: (p: ParentElement) => A) =>
  (d: ElementTree) =>
    isParent(d) ? onParent(d) : onElement(d)

export const reduce =
  <B>(ab: (b: B, a: ElementTree) => B, init: B) =>
  (a: ElementTree): B => {
    const value = ab(init, a)

    if (!Array.isArray(a.children)) {
      return value
    }

    return pipe(
      a.children,
      RA.reduce(value, (b, a) => pipe(a, reduce(ab, b)))
    )
  }

export const find =
  <T extends ElementTree>(f: (element: T) => boolean) =>
  (a: T) =>
    pipe(
      a,
      reduce<O.Option<Element>>((found, node) => {
        if (f(node as any)) {
          return O.some(node)
        }

        return found
      }, O.none)
    )

export const toTree: (s: ElementTree) => T.Tree<ElementTree> = match(
  T.of,
  (d) => pipe(d.children, RA.map(toTree), (c) => T.make(d, c as any))
)

export const toArray = <T extends ElementTree>(t: T): ReadonlyArray<T> =>
  pipe(
    t,
    reduce(
      (elements: ReadonlyArray<ElementTree>, element) =>
        pipe(elements, RA.append(element)),
      RA.empty
    )
  ) as any

export const findChild = (f: (element: ElementTree) => boolean) =>
  pipe(
    Optional.id<ElementTree>(),
    Optional.composePrism(parentPrism),
    Optional.composeLens(childrenLens),
    Optional.findFirst(f)
  ).getOption

export const getElementAt = (path: Path) => getElementTraversal(path).getOption

export const replaceElementAt =
  (element: ElementTree, path: Path) => (tree: ElementTree) =>
    pipe(tree, getElementTraversal(path).set(element))

export const updateElementAt = (
  update: (element: Element) => Element,
  path: Path
) =>
  pipe(
    getElementTraversal(path),
    Optional.modify((f) => update(f))
  )

export const childrenAt = (path: Path) =>
  pipe(getChildrenAtTraversal(path)).getOption

export const firstChildAt = (path: Path) =>
  pipe(getChildrenAtTraversal(path), Optional.compose(getChildAtOptional(0)))
    .getOption

export const appendChildAt = (path: Path) => (element: ElementTree) =>
  pipe(
    getChildrenAtTraversal(path),
    Optional.modify((children) => pipe(children, RA.append(element)))
  )

export const prependChildAt = (path: Path) => (element: ElementTree) =>
  pipe(
    getChildrenAtTraversal(path),
    Optional.modify((children) => pipe(children, RA.append(element)))
  )

export const insertElementAt =
  (element: ElementTree, path: Path) => (tree: ElementTree) =>
    pipe(splitLast(path), ([path, index]) =>
      pipe(
        path,
        O.foldW(
          () =>
            pipe(
              Optional.id<ElementTree>(),
              Optional.composePrism(parentPrism),
              Optional.composeLens(childrenLens),
              Optional.modify((children) =>
                pipe(
                  children,
                  RA.insertAt(index, element),
                  O.getOrElse(constant(children))
                )
              )
            )(tree),
          (path) =>
            pipe(
              getChildrenAtTraversal(path),
              Optional.modify((children) =>
                pipe(
                  children,
                  RA.insertAt(index, element),
                  O.getOrElse(constant(children))
                )
              )
            )(tree)
        )
      )
    )

export const removeElementAt = (path: Path) => (tree: ElementTree) =>
  pipe(splitLast(path), ([path, index]) =>
    pipe(
      path,
      O.foldW(
        () =>
          pipe(
            Optional.id<ElementTree>(),
            Optional.composePrism(parentPrism),
            Optional.composeLens(childrenLens),
            Optional.modify((children) => RA.unsafeDeleteAt(index, children))
          )(tree),
        (path) =>
          pipe(
            getChildrenAtTraversal(path),
            Optional.modify((children) => RA.unsafeDeleteAt(index, children))
          )(tree)
      )
    )
  )

export const moveElement = (from: Path, to: Path) => (tree: ElementTree) =>
  pipe(
    tree,
    getElementAt(from),
    O.fold(
      () => tree,
      (element) =>
        pipe(tree, removeElementAt(from), insertElementAt(element, to))
    )
  )

export function findElementPath(f: (element: ElementTree) => boolean) {
  return (tree: ElementTree) => {
    const traverse = (element: ElementTree, path: number[]): O.Option<Path> => {
      if (!isParent(element)) {
        return O.none
      }

      return pipe(
        element.children,
        RA.findIndex(f),
        O.map((index) => fromNonEmptyArray([...path, index] as any)),
        O.alt(() =>
          pipe(
            element.children,
            RA.mapWithIndex((index, element) => [index, element] as const),
            RA.findFirstMap(([index, element]) =>
              traverse(element, [...path, index])
            )
          )
        )
      )
    }

    return traverse(tree, [])
  }
}

export const findParent = (a: ElementTree) => (tree: ElementTree) =>
  pipe(
    tree,
    find((element) =>
      pipe(
        element,
        findChild((b) => a.id === b.id),
        O.fold(constFalse, constTrue)
      )
    )
  )

export const elementPath = (a: ElementTree) =>
  findElementPath((b) => a.id === b.id)

// -------------------------------------------------------------------------------------
// lenses
// -------------------------------------------------------------------------------------

export const parentPrism = Prism.fromPredicate(isParent) as Prism.Prism<
  ElementTree,
  ParentElement
>

export const getChildPrism = (
  f: Predicate<ElementTree>
): Prism.Prism<ElementTree, ElementTree> => Prism.fromPredicate(f)

export const elementLens = Lens.id<ElementTree>()
export const parentLens = Lens.id<ParentElement>()

export const childTraversal = Traversal.fromTraversable(
  RA.Traversable
)<ElementTree>()

export const getChildAtOptional = (index: number) =>
  Ix.indexReadonlyArray<ElementTree>().index(index)

const getChildrenAtTraversal = (path: Path) =>
  pipe(
    getElementTraversal(path),
    Optional.composePrism(parentPrism),
    Optional.composeLens(childrenLens)
  )

export const childrenLens = pipe(parentLens, Lens.prop('children'))

export const getElementTraversal = (path: Path) =>
  pipe(
    path,
    NEA.reduce(Optional.id<ElementTree>(), (element, pathIndex) =>
      pipe(
        element,
        Optional.composePrism(parentPrism),
        Optional.composeLens(childrenLens),
        Optional.compose(getChildAtOptional(pathIndex))
      )
    )
  )
