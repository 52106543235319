/* eslint-disable @typescript-eslint/no-use-before-define */
import * as O from 'fp-ts/Option'
import * as G from 'io-ts/Guard'
import * as RT from 'fp-ts/ReadonlyTuple'
import * as D from 'io-ts/Decoder'
import * as E from 'fp-ts/Either'
import * as Equals from 'fp-ts/Eq'
import * as date from 'fp-ts/Date'
import { Endomorphism } from 'fp-ts/Endomorphism'
import { pipe, constant, constTrue, constFalse } from 'fp-ts/function'
import { sequenceT } from 'fp-ts/Apply'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export type DateRangeTuple = readonly [O.Option<Date>, O.Option<Date>]

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------
export const dateRangeTuple = (
  start: O.Option<Date>,
  end: O.Option<Date>
): DateRangeTuple => [start, end]

export const empty: DateRangeTuple = [O.none, O.none]

export const fromDates = (start: Date, end: Date): DateRangeTuple =>
  pipe(
    sequenceT(E.Apply)(DateDecoder.decode(start), DateDecoder.decode(end)),
    E.map(([start, end]) => dateRangeTuple(O.some(start), O.some(end))),
    E.getOrElse(constant(empty))
  )

// -------------------------------------------------------------------------------------
// destructors
// -------------------------------------------------------------------------------------

export const startToLocaleDateString = ([start]: DateRangeTuple) =>
  pipe(
    start,
    O.map((start) => start.toLocaleDateString()),
    O.getOrElse(constant(''))
  )

export const endToLocaleDateString = ([, end]: DateRangeTuple) =>
  pipe(
    end,
    O.map((end) => end.toLocaleDateString()),
    O.getOrElse(constant(''))
  )

export const toLocaleDateString = (range: DateRangeTuple) => {
  const start = startToLocaleDateString(range)
  const end = endToLocaleDateString(range)

  return `${start} - ${end}`
}

export const start: (r: DateRangeTuple) => O.Option<Date> = RT.fst

export const end: (r: DateRangeTuple) => O.Option<Date> = RT.snd

// -------------------------------------------------------------------------------------
// instances
// -------------------------------------------------------------------------------------

// TODO: move
export function option<A>(
  value: G.Guard<unknown, A>
): G.Guard<unknown, O.Option<A>> {
  return G.sum('_tag')({
    None: G.struct({
      _tag: G.literal('None')
    }),
    Some: G.struct({
      _tag: G.literal('Some'),
      value
    })
  })
}

export const DateGuard: G.Guard<unknown, Date> = {
  is: (u: unknown): u is Date => u instanceof Date
}

export const DateDecoder = D.fromGuard(DateGuard, 'date')

export const Guard: G.Guard<unknown, DateRangeTuple> = G.tuple(
  option(DateGuard),
  option(DateGuard)
)

export const Decoder: D.Decoder<unknown, DateRangeTuple> = D.fromGuard(
  Guard,
  'dateRangeTuple'
)

export const Eq: Equals.Eq<DateRangeTuple> = Equals.tuple(
  O.getEq(date.Eq),
  O.getEq(date.Eq)
)

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export const reverse: Endomorphism<DateRangeTuple> = RT.swap

export const isEmpty = (range: DateRangeTuple) =>
  pipe(sequenceT(O.Apply)(...range), O.fold(constTrue, constFalse))
