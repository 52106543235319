import {
  SecondaryButton,
  ListItem,
  ListItemActionField,
  ListItemBody,
  ListItemTitle,
  ListItemSubtitle
} from '@woorcs/design-system'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useOrganization } from '@woorcs/graphql'

import { pdfUrl } from '@app/utils/router'

import { ReportModal } from '../ReportModal'

import { SubmissionRevisionListItemSubmissionRevisionFragment } from './__generated__/SubmissionRevisionListFragment'

interface SubmissionRevisionListItemProps {
  revision: SubmissionRevisionListItemSubmissionRevisionFragment
  submissionId: string
}

export const SubmissionRevisionListItem = ({
  revision
}: SubmissionRevisionListItemProps) => {
  const { t } = useTranslation()
  const organization = useOrganization()

  return (
    <ReportModal submissionRevision={revision}>
      <ListItem
        px={2}
        __css={{
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'grey.50'
          }
        }}
      >
        <ListItemBody mx={0}>
          <ListItemTitle>
            {t('common:version')} #{revision.version}
          </ListItemTitle>
          <ListItemSubtitle fontSize='small'>
            {format(new Date(revision.createdAt), 'yyyy-MM-dd')}
          </ListItemSubtitle>
        </ListItemBody>
        <ListItemActionField mx={0} ml={2}>
          <SecondaryButton
            as='a'
            size='small'
            {...({
              href: pdfUrl(organization.id, revision.id),
              target: '_blank'
            } as any)}
          >
            {t('common:download')}
          </SecondaryButton>
        </ListItemActionField>
      </ListItem>
    </ReportModal>
  )
}
