import React, { ReactNode } from 'react'
import { whenTrue } from '@woorcs/utils'

import { List, ListProps } from '../../data'
import { system, css } from '../../../system'

const isVertical = whenTrue(['vertical'])

interface MenuListElementProps {
  vertical: boolean
}

const MenuListElement = system(List)<MenuListElementProps>(
  css({
    display: 'inline-flex',
    flexDirection: 'row',
    li: {
      mr: 1,
      ':last-child': {
        mr: 0
      }
    }
  }),
  isVertical({
    display: 'flex',
    flexDirection: 'column',
    mx: -3,
    li: {
      width: '100%',
      mr: 0,
      mb: 1,
      ':last-child': {
        mb: 0
      }
    },
    'a, button': {
      display: 'flex',
      width: '100%'
    }
  })
)

export interface MenuListProps extends ListProps {
  vertical?: boolean
  children: ReactNode
}

export const MenuList = ({
  children,
  vertical = false,
  ...other
}: MenuListProps) => (
  <system.nav {...other}>
    <MenuListElement
      vertical={vertical}
      aria-orientation={vertical ? 'vertical' : 'horizontal'}
    >
      {children}
    </MenuListElement>
  </system.nav>
)
