import {
  ChangeEvent,
  FormEvent,
  ReactElement,
  useCallback,
  useState
} from 'react'
import {
  Modal,
  FormField,
  PrimaryButton,
  SecondaryButton,
  Input,
  Box,
  ModalTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalDialog,
  ModalDisclosure,
  ModalDismiss
} from '@woorcs/design-system'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import { uuid } from '@woorcs/types/UUID'
import { TranslateableText } from '@woorcs/form'

import { usePageIds, usePagesIO } from '../../state'

type AddPageModalFormProps = {
  onSubmit(title: string): void
}

const AddPageModalForm = ({ onSubmit }: AddPageModalFormProps) => {
  const [title, setTitle] = useState('')
  const handleTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setTitle(value)
  }, [])

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLElement>) => {
      e.preventDefault()

      onSubmit(title)
    },
    [onSubmit, title]
  )

  return (
    <form onSubmit={handleSubmit}>
      <ModalBody>
        <Box>
          <FormField label='Page title'>
            <Input name='title' onChange={handleTitleChange} />
          </FormField>
        </Box>
      </ModalBody>

      <ModalFooter>
        <ModalDismiss render={<SecondaryButton mr={2} />}>Cancel</ModalDismiss>
        <PrimaryButton onClick={handleSubmit}>Add page</PrimaryButton>
      </ModalFooter>
    </form>
  )
}

interface AddPageModalProps {
  atIndex?: number
  onAddPage?(page: InspectionFormDocument.InspectionFormPageElement): void
  children: ReactElement
}

export const AddPageModal = ({
  atIndex,
  onAddPage,
  children
}: AddPageModalProps) => {
  const pages = usePageIds()
  const pagesIO = usePagesIO()
  const lastPageIndex = pages.length
  const [visible, setVisible] = useState(false)

  const handleSubmit = useCallback(
    (title: string) => {
      const index = atIndex ?? lastPageIndex
      const page = InspectionFormDocument.inspectionFormPageElement({
        key: uuid(),
        title: TranslateableText.translateableText(title),
        description: TranslateableText.translateableText(''),
        children: []
      })

      const addPage = pagesIO.addPage(page, index)

      setVisible(false)
      onAddPage?.(page)

      addPage()
    },
    [atIndex, lastPageIndex, onAddPage, pagesIO]
  )

  return (
    <Modal open={visible} setOpen={setVisible}>
      <ModalDisclosure>{children}</ModalDisclosure>
      <ModalDialog aria-label='Add page'>
        <ModalHeader width='420px'>
          <ModalTitle>Add page</ModalTitle>
        </ModalHeader>

        <AddPageModalForm onSubmit={handleSubmit} />
      </ModalDialog>
    </Modal>
  )
}
