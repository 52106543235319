import { Flex, Text } from '@woorcs/design-system'
import { CountryFlag } from '@woorcs/inspection-form-editor'
import { useTranslation } from 'react-i18next'

interface LanguageLabelProps {
  locale: string
}

export const LanguageLabel = ({ locale }: LanguageLabelProps) => {
  const { t } = useTranslation('common')

  return (
    <Flex alignItems='center'>
      <CountryFlag locale={locale} size={14} mr={1} />
      <Text as='span' fontWeight='semi-bold'>
        {t(`languages.${locale}`) ?? locale}
      </Text>
    </Flex>
  )
}
