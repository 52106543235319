import { ReactNode, useMemo } from 'react'
import * as Ariakit from '@ariakit/react'
import { createContext, space } from '@woorcs/utils'

import { css, system, SystemComponentProps, SystemProps } from '../../../system'

type TabListSize = 'medium' | 'large'

type TabsListStyleContext = {
  size: TabListSize
}

const [TabListStyleContextProvider, useTabsListStyleContext] =
  createContext<TabsListStyleContext>({
    errorMessage: 'You must wrap Tab components with TabList'
  })

interface TabsProps {
  defaultSelectedId?: string
  children: ReactNode
}

export const Tabs = ({ children, ...initialState }: TabsProps) => {
  const store = Ariakit.useTabStore(initialState)

  return <Ariakit.TabProvider store={store}>{children}</Ariakit.TabProvider>
}

interface TabProps extends SystemProps, Omit<Ariakit.TabProps, 'color'> {}

export const Tab = ({
  borderBottomColor = 'grey.50',
  color,
  ...props
}: TabProps) => {
  const { size } = useTabsListStyleContext()

  return (
    <Ariakit.Tab
      render={(props) => (
        <system.button
          textAlign='center'
          fontSize={size === 'medium' ? 'small' : 'base'}
          fontWeight='semi-bold'
          mb='-1px'
          bg='transparent'
          color={color}
          __css={css({
            appearance: 'none',
            border: 'none',
            cursor: 'pointer',
            borderBottom: 'thin',
            borderBottomColor,
            mr: size === 'medium' ? space(4) : space(8),
            py: size === 'medium' ? space(3) : space(4),
            px: 0,
            ':hover': {
              borderBottomColor: 'grey.100'
            },
            '&[aria-selected=true]': {
              borderBottomColor: 'primary.500',
              color: 'primary.500'
            },
            ':last-of-type': {
              mr: 0
            },
            ':focus': {
              outline: 'none'
            }
          } as any)}
          {...props}
        />
      )}
      {...props}
    />
  )
}

interface TabListProps
  extends SystemComponentProps,
    Omit<Ariakit.TabListProps, 'color' | 'css'> {
  size?: TabListSize
}

export const TabList = ({ size = 'medium', color, ...props }: TabListProps) => {
  const ctx = useMemo(() => ({ size }), [size])

  return (
    <TabListStyleContextProvider value={ctx}>
      <Ariakit.TabList
        render={(props) => (
          <system.div
            alignItems='center'
            color={color}
            borderBottomColor='grey.50'
            borderBottomWidth='1px'
            borderBottomStyle='solid'
            {...props}
          />
        )}
        {...props}
      />
    </TabListStyleContextProvider>
  )
}

interface TabPanelProps
  extends SystemComponentProps,
    Omit<Ariakit.TabPanelProps, 'color' | 'css'> {
  children: ReactNode
}

export const TabPanel = ({ color, ...props }: TabPanelProps) => {
  const store = Ariakit.useTabContext()

  if (!store) {
    throw new Error('You must wrap TabPanel components with Tab')
  }

  const currentId = store.useState('activeId')
  const isCurrent = (props.id && props.id === currentId) || !props.id

  return (
    <Ariakit.TabPanel
      render={(props) => (
        <system.div
          css={{ ':focus': { outline: 'none' } }}
          color={color}
          {...props}
        />
      )}
      {...props}
    >
      {isCurrent && props.children}
    </Ariakit.TabPanel>
  )
}
