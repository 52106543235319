import { Suspense, useCallback } from 'react'
import * as A from 'fp-ts/Array'
import { Box, Spinner, Flex, SecondaryButton } from '@woorcs/design-system'
import { pipe } from 'fp-ts/function'
import { useNavigate } from 'react-router'
import { useQuery } from 'urql'

import { EmptyState } from '../EmptyState'
import { CreateFormModal } from '../CreateFormModal'
import { CreateFormFormFragment } from '../CreateFormForm/__generated__/CreateFormForm'

import { FormListDocument } from './__generated__/FormList'
import { FormListItemFormFragment } from './__generated__/FormListItem'
import { FormListItem } from './FormListItem'

const FormListEmptyState = () => {
  const navigate = useNavigate()
  const handleCreateForm = useCallback(
    (form: CreateFormFormFragment) => {
      navigate(`/forms/${form.id}/edit`)
    },
    [navigate]
  )
  return (
    <EmptyState
      title='No forms'
      description="You haven't created any forms yet"
    >
      <CreateFormModal onCreateForm={handleCreateForm}>
        <SecondaryButton>Create your first form</SecondaryButton>
      </CreateFormModal>
    </EmptyState>
  )
}

const renderItems = (items: FormListItemFormFragment[]) =>
  pipe(
    items,
    A.map((form) => <FormListItem key={form.id} form={form} />),
    (items) => <Box>{items}</Box>
  )

export const FormListContent = () => {
  const [query] = useQuery({ query: FormListDocument })
  const data = query.data?.forms ?? []

  if (data.length === 0) {
    return <FormListEmptyState />
  }

  return <>{renderItems(data)}</>
}

export const FormList = () => {
  return (
    <Suspense
      fallback={
        <Flex
          width='100%'
          height='100%'
          justifyContent='center'
          alignItems='center'
          pt={20}
        >
          <Spinner />
        </Flex>
      }
    >
      <FormListContent />
    </Suspense>
  )
}
