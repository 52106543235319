import { Dict } from './types'

export function splitObject<T extends Dict, K extends keyof T>(
  object: T,
  keys: K[]
) {
  const picked: Dict = {}
  const omitted: Dict = {}

  for (const key in object) {
    if (keys.includes(key as T[K])) {
      picked[key] = object[key]
    } else {
      omitted[key] = object[key]
    }
  }

  return [picked, omitted] as [{ [P in K]: T[P] }, Omit<T, K>]
}
