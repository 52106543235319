import { DateRangeTuple } from '@woorcs/design-system'
import * as Eq from 'fp-ts/Eq'
import * as A from 'fp-ts/Array'
import * as RR from 'fp-ts/ReadonlyRecord'
import * as O from 'fp-ts/Option'
import * as s from 'fp-ts/struct'
import * as string from 'fp-ts/string'
import { pipe } from 'fp-ts/function'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export interface FilterType<T extends string, Value> {
  readonly _tag: T
  readonly Eq: Eq.Eq<Value>
}

export type UserFilter = FilterType<'user', string>

export const UserFilter: UserFilter = {
  _tag: 'user',
  Eq: string.Eq
}

export type FormFilter = FilterType<'form', string>

export const FormFilter: FormFilter = {
  _tag: 'form',
  Eq: string.Eq
}

export type DateRangeFilter = FilterType<
  'date-range',
  DateRangeTuple.DateRangeTuple
>

export const DateRangeFilter: DateRangeFilter = {
  _tag: 'date-range',
  Eq: DateRangeTuple.Eq
}

export type TagFilter = FilterType<'tag', string[]>

export const TagFilter: TagFilter = {
  _tag: 'tag',
  Eq: A.getEq(string.Eq)
}

export type QueryFilter = FilterType<'query', string>

export const QueryFilter: QueryFilter = {
  _tag: 'query',
  Eq: string.Eq
}

export type Filter =
  | UserFilter
  | FormFilter
  | DateRangeFilter
  | TagFilter
  | QueryFilter

export type FilterTag = Filter['_tag']

export interface FilterConfig<T extends FilterTag = FilterTag> {
  readonly _tag: T
  readonly label: string
}

export type Filters = Record<string, FilterConfig>

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const userFilter = (label: string): FilterConfig<'user'> => ({
  _tag: 'user',
  label
})

export const formFilter = (label: string): FilterConfig<'form'> => ({
  _tag: 'form',
  label
})

export const tagFilter = (label: string): FilterConfig<'tag'> => ({
  _tag: 'tag',
  label
})

export const dateRangeFilter = (label: string): FilterConfig<'date-range'> => ({
  _tag: 'date-range',
  label
})

export const queryFilter = (label: string): FilterConfig<'query'> => ({
  _tag: 'query',
  label
})

export const filters = <T extends Filters>(filters: T): T => filters

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export type FilterValue<T> = T extends FilterType<string, infer V> ? V : never

type FilterConfigFilter<T> =
  T extends FilterConfig<infer Tag> ? Extract<Filter, { _tag: Tag }> : never

export type ValueOfFiltersConfig<T> = T extends Filters
  ? { [K in keyof T]: FilterValue<FilterConfigFilter<T[K]>> }
  : never

export type FilterValues<T> = T extends Filters
  ? { [K in keyof T]: O.Option<FilterValue<FilterConfigFilter<T[K]>>> }
  : never

export const isEmptyFilters = <T extends FilterValues<any>>(f: T) =>
  pipe(f, RR.every(O.isNone))

export const evolveFilters =
  <
    A extends FilterValues<any>,
    T extends { [K in keyof A]: (a: A[K]) => unknown }
  >(
    t: T
  ) =>
  (a: A): { [K_1 in keyof T]: ReturnType<T[K_1]> } =>
    pipe(a, s.evolve(t))
