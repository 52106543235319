/* eslint-disable max-params */
/* eslint-disable complexity */
import { absurd, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as RA from 'fp-ts/ReadonlyArray'
import * as D from 'io-ts/Decoder'
import { Email, Location } from '@woorcs/types'

import * as V from './validation'
import { textValidator, numberValidator } from './validators'
import { FieldDescriptor } from './FieldDescriptor'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export interface FieldType<T extends string, E, A, O>
  extends D.Decoder<unknown, A> {
  readonly name: T
  readonly validate: (options: O) => V.Validator<E, A>
  readonly empty: A | null
  readonly make: (
    descriptor: Omit<FieldDescriptor<T, A, O>, '_tag' | 'optional'> &
      Partial<Pick<FieldDescriptor<T, A, O>, 'optional'>>
  ) => FieldDescriptor<T, A, O>
}

export const fieldType = <T extends string, E, A, O>(
  name: T,
  decoder: D.Decoder<unknown, A>,
  validate: (options: O) => V.Validator<E, A> = V.identity,
  empty: A | null = null
): FieldType<T, E, A, O> => ({
  name,
  ...decoder,
  validate,
  empty,
  make: (descriptor) => ({
    _tag: name,
    optional: false,
    ...descriptor
  })
})

// -------------------------------------------------------------------------------------
// field types
// -------------------------------------------------------------------------------------

/**
 * text field
 */
export const textField = fieldType('Text', D.string, textValidator, '')

export type TextField = typeof textField
export type TextFieldValue = ValueOf<TextField>
export type TextFieldDescriptor = Descriptor<TextField>

/**
 * number field
 */
export const numberField = fieldType('Number', D.number, numberValidator)

export type NumberField = typeof numberField
export type NumberFieldValue = ValueOf<NumberField>
export type NumberFieldDescriptor = Descriptor<NumberField>

/**
 * boolean field
 */
export const booleanField = fieldType('Boolean', D.boolean)

export type BooleanField = typeof booleanField
export type BooleanFieldValue = ValueOf<BooleanField>
export type BooleanFieldDescriptor = Descriptor<BooleanField>

/**
 * location field
 */
export const locationField = fieldType('Location', Location)

export type LocationField = typeof locationField
export type LocationFieldValue = ValueOf<LocationField>
export type LocationFieldDescriptor = Descriptor<LocationField>

/**
 * image field
 */
export const emailField = fieldType('Email', Email)

export type EmailField = typeof emailField
export type EmailFieldValue = ValueOf<EmailField>
export type EmailFieldDescriptor = Descriptor<EmailField>

/**
 * image field
 */
export const imageField = fieldType('Image', D.array(D.string))

export type ImageField = typeof imageField
export type ImageFieldValue = ValueOf<ImageField>
export type ImageFieldDescriptor = Descriptor<ImageField>

/**
 * signature field
 */
export const signatureField = fieldType('Signature', D.string)

export type SignatureField = typeof signatureField
export type SignatureFieldValue = ValueOf<SignatureField>
export type SignatureFieldDescriptor = Descriptor<SignatureField>

/**
 * date field
 */
export const dateField = fieldType('Date', D.string)

export type DateField = typeof dateField
export type DateFieldValue = ValueOf<DateField>
export type DateFieldDescriptor = Descriptor<DateField>

/**
 * time field
 */
export const timeField = fieldType('Time', D.string)

export type TimeField = typeof timeField
export type TimeFieldValue = ValueOf<TimeField>
export type TimeFieldDescriptor = Descriptor<TimeField>

/**
 * date range field
 */
export const dateRangeField = fieldType(
  'DateRange',
  D.struct({
    from: D.string,
    to: D.string
  })
)

export type DateRangeField = typeof dateRangeField
export type DateRangeFieldValue = ValueOf<DateRangeField>
export type DateRangeFieldDescriptor = Descriptor<DateRangeField>

/**
 * group field
 */
export const groupField = fieldType('Group', D.UnknownRecord)

export type GroupField = typeof groupField
export type GroupFieldValue = ValueOf<GroupField>
export type GroupFieldDescriptor = Descriptor<GroupField>

/**
 * select field
 */
export interface SelectOption {
  key: string
  label: string
}

export interface SelectFieldOptions {
  options: ReadonlyArray<SelectOption>
}

export interface InvalidOptionError extends V.ValidationError {
  _tag: 'InvalidOption'
  expected: ReadonlyArray<string>
}

const invalidOptionError = (
  actual: string,
  expected: ReadonlyArray<string>
): InvalidOptionError => ({
  _tag: 'InvalidOption',
  actual,
  expected
})

const selectFieldValidator = ({ options }: SelectFieldOptions) =>
  pipe(
    options,
    RA.map((option) => option.key),
    (keys) =>
      V.validatorFromPredicate(
        (value: string) => keys.includes(value),
        (actual) => invalidOptionError(actual, keys)
      )
  )

export const selectField = fieldType('Select', D.string, selectFieldValidator)

export type SelectField = typeof selectField
export type SelectFieldValue = ValueOf<SelectField>
export type SelectFieldDescriptor = Descriptor<SelectField>

/**
 * multi select field
 */
const multiSelectFieldValidator = ({ options }: SelectFieldOptions) =>
  pipe(
    options,
    RA.map((option) => option.key),
    (keys) =>
      V.validatorFromPredicate(
        (values: string[]) =>
          pipe(
            values,
            RA.findFirst((value) => !keys.includes(value)),
            O.isSome
          ),
        (actual) => invalidOptionError(actual as any, keys)
      )
  )

export const multiSelectField = fieldType(
  'MultiSelect',
  D.array(D.string),
  multiSelectFieldValidator
)

export type MultiSelectField = typeof multiSelectField
export type MultiSelectFieldValue = ValueOf<MultiSelectField>
export type MultiSelectFieldDescriptor = Descriptor<MultiSelectField>

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export type FieldTypes =
  | TextField
  | NumberField
  | BooleanField
  | EmailField
  | ImageField
  | DateField
  | TimeField
  | DateRangeField
  | LocationField
  | SelectField
  | MultiSelectField
  | SignatureField
  | GroupField

export type FieldTypeDescriptor = Descriptor<FieldTypes>

export type FieldTypeErrors = ErrorOf<FieldTypes>

export type AnyFieldType = FieldType<any, any, any, any>

export type ValueOf<T> = T extends FieldType<any, any, infer A, any> ? A : never

export type ErrorOf<T> = T extends FieldType<any, infer E, any, any> ? E : never

export type Descriptor<T> =
  T extends FieldType<infer T, any, infer A, infer O>
    ? FieldDescriptor<T, A, O>
    : never

export type MatchFieldTypeName<R extends string> = FieldTypes extends infer T
  ? T extends FieldType<R, infer E, infer A, infer O>
    ? FieldType<R, E, A, O>
    : never
  : never

type FieldTypeNames = FieldTypes['name']

export const getFieldType = (
  name: FieldTypeNames
): MatchFieldTypeName<typeof name> => {
  switch (name) {
    case 'Text': {
      return textField
    }

    case 'Number': {
      return numberField
    }

    case 'Boolean': {
      return booleanField
    }

    case 'Date': {
      return dateField
    }

    case 'Time': {
      return dateField
    }

    case 'DateRange': {
      return dateRangeField
    }

    case 'Email': {
      return emailField
    }

    case 'Image': {
      return imageField
    }

    case 'Signature': {
      return signatureField
    }

    case 'Location': {
      return locationField
    }

    case 'Select': {
      return selectField
    }

    case 'MultiSelect': {
      return multiSelectField
    }

    case 'Group': {
      return groupField
    }

    default: {
      return absurd(name)
    }
  }
}
