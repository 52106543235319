import * as R from 'fp-ts/Reader'
import { pipe } from 'fp-ts/function'

import { useFormContext } from '../context'
import { FormEnvironment } from '../../Form'

export type FormProgram<A> = R.Reader<FormEnvironment, A>

export const useFormProgram = <A>(program: FormProgram<A>) =>
  pipe(useFormContext(), program)
