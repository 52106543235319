import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogDisclosure,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Body,
  Button,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuContent,
  MenuItem
} from '@woorcs/design-system'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import { none } from 'fp-ts/Option'
import { ReactElement, useState, memo, useCallback } from 'react'
import { DialogDismiss } from '@ariakit/react'

import { useEditorContext } from '../../../state'
import { AddPageRuleModal } from '../../AddPageRuleModal'

type ConfirmRemoveAlertProps = {
  onConfirm(): void
  children: ReactElement
}

const ConfirmRemoveAlert = memo(
  ({ onConfirm, children }: ConfirmRemoveAlertProps) => {
    return (
      <AlertDialog>
        <AlertDialogDisclosure>{children}</AlertDialogDisclosure>

        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Remove page</AlertDialogTitle>
          </AlertDialogHeader>

          <AlertDialogBody>
            <Body>Are you sure that you want to remove the page?</Body>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <DialogDismiss render={<Button colorVariant='secondary' />}>
                Cancel
              </DialogDismiss>
              <Button colorVariant='danger' onClick={onConfirm}>
                Remove
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }
)

export type PageListItemMenuProps = {
  page: InspectionFormDocument.InspectionFormPageElement
  children: ReactElement
}

export const PageListItemMenu = memo(
  ({ page, children }: PageListItemMenuProps) => {
    const { editor } = useEditorContext()
    const [visible, setVisible] = useState(false)

    const handleRemove = useCallback(() => {
      const removePage = editor.removeElement(page.id)

      removePage()
    }, [editor, page.id])

    return (
      <Menu placement='bottom-end' open={visible} setOpen={setVisible}>
        <MenuButton>{children}</MenuButton>

        <MenuContent aria-label='User menu'>
          {!page.rule && (
            <AddPageRuleModal page={page} initialValue={none}>
              <MenuItem hideOnClick={false}>Add logic rule</MenuItem>
            </AddPageRuleModal>
          )}

          <ConfirmRemoveAlert onConfirm={handleRemove}>
            <MenuItem hideOnClick={false} destructive>
              Remove
            </MenuItem>
          </ConfirmRemoveAlert>
        </MenuContent>
      </Menu>
    )
  }
)
