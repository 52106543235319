import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'
import {
  AutosizeTextarea,
  Box,
  Card,
  CardHeader,
  Flex,
  Text
} from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import * as A from 'fp-ts/Array'
import { constNull, pipe } from 'fp-ts/function'
import { UUID } from '@woorcs/types/UUID'
import { FormElement, Locale, TranslateableText } from '@woorcs/form'

import {
  usePage,
  usePageIds,
  useTranslateableText,
  useValue
} from '../../state'

type TranslateSectionProps = {
  title: string
  children: ReactNode
}

const TranslateSection = ({ title, children }: TranslateSectionProps) => (
  <Card mb={4}>
    <CardHeader p={5} borderBottom='thin' borderBottomColor='grey.50'>
      <Text fontWeight='bold'>{title}</Text>
    </CardHeader>
    <Box>{children}</Box>
  </Card>
)

type TranslateSectionRowProps = {
  locale: Locale.Locale
  id: string
  text: TranslateableText.TranslateableText
}

const TranslateSectionRow = ({
  locale,
  id,
  text
}: TranslateSectionRowProps) => {
  const [translation, setTranslation] = useTranslateableText(text, locale)
  const initialValue = pipe(
    translation,
    O.getOrElse(() => '')
  )
  const [value, setValue] = useState(initialValue)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  useEffect(() => {
    setValue(initialValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const handleBlur = useCallback(() => {
    setTranslation(value)
  }, [setTranslation, value])

  return (
    <Flex justifyContent='space-evenly' borderBottom='base'>
      <Box width='50%' color='text.emphasized' borderRight='base' px={5} py={8}>
        <Box mb={2}>
          <Text>{text.text || '-'}</Text>
        </Box>
        <Text fontSize='small' color='text.muted'>
          {id}
        </Text>
      </Box>
      <Box width='50%' px={5} py={8}>
        <AutosizeTextarea
          css={{ border: 'none' }}
          variant='inline'
          placeholder='Add a translation'
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Box>
    </Flex>
  )
}

type TranslateInputElementProps = {
  element: FormElement.FormInputElement
  locale: Locale.Locale
}

const TranslateInputElement = ({
  element,
  locale
}: TranslateInputElementProps) => (
  <>
    {FormElement.PlaceholderInputElement.is(element) && (
      <TranslateSectionRow
        text={element.placeholder}
        locale={locale}
        id={`${element.type}.placeholder`}
      />
    )}

    <TranslateSectionRow
      text={element.label}
      locale={locale}
      id={`${element.type}.label`}
    />

    {element.informativeText.text && (
      <TranslateSectionRow
        text={element.informativeText}
        locale={locale}
        id={`${element.type}.informativeText`}
      />
    )}
  </>
)

type TranslateElementProps = {
  element: FormElement.FormElement
  locale: Locale.Locale
}

const TranslateElement = ({ element, locale }: TranslateElementProps) => {
  if (FormElement.isInputElement(element)) {
    return <TranslateInputElement element={element} locale={locale} />
  }

  if (FormElement.isStaticElement(element)) {
    return pipe(
      element,
      FormElement.StaticElementType.matchStrict({
        Text: (element) => (
          <TranslateSectionRow
            text={element.text}
            locale={locale}
            id={`${element.type}.label`}
          />
        ),
        Alert: (element) => (
          <>
            {element.children.map((textElement) => (
              <TranslateSectionRow
                key={textElement.id}
                text={textElement.text}
                locale={locale}
                id={`${element.type}.text`}
              />
            ))}
          </>
        )
      })
    )
  }

  return null
}

type TranslatePageProps = {
  pageID: UUID
  locale: Locale.Locale
}

const TranslatePage = ({ pageID, locale }: TranslatePageProps) =>
  pipe(
    usePage(pageID),
    O.fold(constNull, ({ element }) => {
      return (
        <TranslateSection title={element.title.text}>
          <TranslateSectionRow
            text={element.title}
            locale={locale}
            id={'pageTitle'}
          />

          {element.description.text.length > 0 && (
            <TranslateSectionRow
              text={element.description}
              locale={locale}
              id={'pageDescription'}
            />
          )}

          {element.children.map((element) => (
            <TranslateElement
              key={element.id}
              element={element}
              locale={locale}
            />
          ))}
        </TranslateSection>
      )
    })
  )

type TranslatePagesProps = {
  locale: Locale.Locale
}

const TranslatePages = ({ locale }: TranslatePagesProps) => {
  const pageIDs = usePageIds()
  const pages = pageIDs.map((id) => (
    <TranslatePage key={id} pageID={id} locale={locale} />
  ))

  return <Box>{pages}</Box>
}

type TranslateResponseSetsProps = {
  locale: Locale.Locale
}

const TranslateResponseSets = ({ locale }: TranslateResponseSetsProps) => {
  const root = useValue()
  const labels = pipe(
    root.responseSets,
    R.collect((_, responseSet) => responseSet),
    A.chain((responseSet) =>
      pipe(
        responseSet.options,
        A.map((option) => option.label)
      )
    )
  )

  return (
    <TranslateSection title='Response sets'>
      {labels.map((label) => (
        <TranslateSectionRow
          key={label.id}
          locale={locale}
          text={label}
          id='responseSet.label'
        />
      ))}
    </TranslateSection>
  )
}

type TranslateLanguageFormProps = {
  locale: Locale.Locale
}

export const TranslateLanguageForm = ({
  locale
}: TranslateLanguageFormProps) => {
  return (
    <Box>
      <TranslatePages locale={locale} />
      <TranslateResponseSets locale={locale} />
    </Box>
  )
}
