import { ElementRule, FormElement } from '@woorcs/form'
import { pipe } from 'fp-ts/function'
import React, { ReactElement, useCallback } from 'react'

import { useEditorContext } from '../../state'
import { QuestionRuleFormModal } from '../ElementRuleFormModal'

interface AddElementRuleModalProps {
  element: FormElement.FormElement
  initialValue?: ElementRule.ElementRule
  children: ReactElement
}

export const AddElementRuleModal = ({
  element,
  initialValue,
  children
}: AddElementRuleModalProps) => {
  const { editor } = useEditorContext()

  const handleSubmit = useCallback(
    (rule: ElementRule.ElementRule) => {
      const updateElement = editor.updateElement(
        pipe(element, FormElement.addRule(rule))
      )

      updateElement()
    },
    [editor, element]
  )

  return (
    <QuestionRuleFormModal initialValue={initialValue} onSubmit={handleSubmit}>
      {children}
    </QuestionRuleFormModal>
  )
}
