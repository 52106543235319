import React, { ReactElement, useCallback, useMemo } from 'react'
import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as R from 'fp-ts/Record'
import { Locale } from '@woorcs/form'

import { useI18nConfig } from '../../state'
import { SelectLanguageMenu } from '../SelectLanguageMenu'

type AddLanguageMenuProps = {
  children: ReactElement
}

export const AddLanguageMenu = ({ children }: AddLanguageMenuProps) => {
  const [{ defaultLanguage, languages }, { addLanguage }] = useI18nConfig()
  const activeLanguages = useMemo(
    () =>
      pipe(
        languages,
        R.collect((locale) => locale),
        (languages) => A.snoc(languages, defaultLanguage)
      ),
    [defaultLanguage, languages]
  )

  const handleSelectLanguage = useCallback(
    (locale: Locale.Locale) => {
      addLanguage(locale)
    },
    [addLanguage]
  )

  return (
    <SelectLanguageMenu
      localeBlacklist={activeLanguages}
      onSelectLanguage={handleSelectLanguage}
    >
      {children}
    </SelectLanguageMenu>
  )
}
