import React, { useCallback } from 'react'
import {
  Table,
  Checkbox,
  TableBody,
  TableCheckboxCell,
  TableHead,
  TableHeadCell
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { space } from '@woorcs/utils'
import { pipe, constant } from 'fp-ts/function'
import { useViewer } from '@woorcs/graphql'

import { UserRow } from '../UserRow'
import { UserListUserFragment } from '../__generated__/UserListFragments'

interface ListProps {
  users: UserListUserFragment[]
  selectedUserIds: string[]
  onSelectedUsersChange(userIds: string[]): void
  onRowRemove(userId: string): void
  onRowDisable(user: UserListUserFragment): void
}

export const List = ({
  selectedUserIds,
  onSelectedUsersChange,
  users,
  onRowRemove,
  onRowDisable
}: ListProps) => {
  const { t } = useTranslation(['common'])
  const viewer = useViewer()
  const isViewer = useCallback(
    (user: UserListUserFragment) => user.id === viewer.id,
    [viewer.id]
  )

  const usersLength = pipe(
    users,
    A.findFirst(isViewer),
    O.fold(constant(users.length), () => users.length - 1)
  )

  const handleSelectAllUsers = useCallback(() => {
    if (selectedUserIds.length >= usersLength) {
      onSelectedUsersChange(
        selectedUserIds.filter(
          (userId) => !users.map((user) => user.id).includes(userId)
        )
      )

      return
    }

    const allIds = pipe(
      users,
      A.filter((user) => !isViewer(user)),
      A.map((user) => user.id),
      A.concat(selectedUserIds)
    )

    onSelectedUsersChange(allIds)
  }, [isViewer, onSelectedUsersChange, selectedUserIds, users, usersLength])

  const handleSelectUser = useCallback(
    (userId: string) => {
      const ids = pipe(
        selectedUserIds,
        A.findFirst((id) => id === userId),
        O.fold(
          () => pipe(selectedUserIds, A.append(userId)),
          () =>
            pipe(
              selectedUserIds,
              A.filter((id) => id !== userId)
            )
        )
      )

      onSelectedUsersChange(ids)
    },
    [onSelectedUsersChange, selectedUserIds]
  )

  return (
    <Table mb={5} outerPadding={space(14)}>
      <TableHead>
        <TableCheckboxCell as='th'>
          <Checkbox
            checked={selectedUserIds.length > 0}
            indeterminate={
              selectedUserIds.length > 0 && selectedUserIds.length < usersLength
            }
            onChange={handleSelectAllUsers}
          />
        </TableCheckboxCell>
        <TableHeadCell>{t('common:name')}</TableHeadCell>
        <TableHeadCell>{t('common:email')}</TableHeadCell>
        <TableHeadCell>{t('common:role')}</TableHeadCell>
        <TableHeadCell>{t('common:tags')}</TableHeadCell>
        {/* <TableHeadCell>{t('common:active')}</TableHeadCell> */}
        <TableHeadCell />
      </TableHead>
      <TableBody>
        {users.map((user, index) => (
          <UserRow
            key={index}
            user={user}
            isSelected={selectedUserIds.includes(user.id)}
            onSelect={handleSelectUser}
            onRemove={onRowRemove}
            onDisable={onRowDisable}
          />
        ))}
      </TableBody>
    </Table>
  )
}
