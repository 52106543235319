import { UUID } from '@woorcs/types/UUID'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as RR from 'fp-ts/ReadonlyRecord'

import { FormDocument } from './FormDocument'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export type Documents = RR.ReadonlyRecord<UUID, FormDocument.FormDocument>

// -------------------------------------------------------------------------------------
// store
// -------------------------------------------------------------------------------------

export interface DocumentsRegistry {
  get(id: UUID): O.Option<FormDocument.FormDocument>
}

export const getDocumentsRegistry = (
  documents: Documents
): DocumentsRegistry => {
  return {
    get: (id) => pipe(documents, RR.lookup(id))
  }
}
