import { pipe } from 'fp-ts/function'
import isEmail from 'validator/lib/isEmail'
import isURL from 'validator/lib/isURL'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { isValidHex } from '@woorcs/utils'

import * as S from './Schemable'
import { type, TypeOf } from './Schemable'

// -------------------------------------------------------------------------------------
// nonEmpty string
// -------------------------------------------------------------------------------------

export interface NonEmptyStringBrand {
  readonly NonEmptyString: unique symbol
}

export type NonEmptyString = string & NonEmptyStringBrand

export const NonEmptyString = S.type((S) =>
  pipe(
    S.string,
    S.refine((s): s is NonEmptyString => s.length > 0, 'nonEmptyString')
  )
)

// -------------------------------------------------------------------------------------
// trimmed
// -------------------------------------------------------------------------------------

interface TrimmedStringBrand {
  readonly TrimmedString: unique symbol
}

export type TrimmedString = string & TrimmedStringBrand

export const TrimmedString = S.type((S) =>
  pipe(
    S.string,
    S.refine(
      (s): s is TrimmedString => s.trim().length === s.length,
      'nonEmptyString'
    )
  )
)

export const NonEmptyTrimmedString = S.type((S) =>
  pipe(TrimmedString.schema(S), S.intersect(NonEmptyString.schema(S)))
)

export type NonEmptyTrimmedString = S.TypeOf<typeof NonEmptyTrimmedString>

// -------------------------------------------------------------------------------------
// location
// -------------------------------------------------------------------------------------

export const Location = type((S) =>
  S.struct({
    lat: S.number,
    lng: S.number
  })
)

export type Location = TypeOf<typeof Location>

// -------------------------------------------------------------------------------------
// time
// -------------------------------------------------------------------------------------

export type Time = string

// -------------------------------------------------------------------------------------
// date
// -------------------------------------------------------------------------------------

interface DateStringBrand {
  readonly DateStringBrand: unique symbol
}

export type DateString = string & DateStringBrand

// -------------------------------------------------------------------------------------
// email
// -------------------------------------------------------------------------------------

interface EmailBrand {
  readonly EmailBrand: unique symbol
}

export type Email = string & EmailBrand

export const Email = type((S) =>
  pipe(
    S.string,
    S.refine((s): s is Email => isEmail(s), 'email')
  )
)

// -------------------------------------------------------------------------------------
// date range
// -------------------------------------------------------------------------------------

export const DateRange = type((S) =>
  S.struct({
    from: S.string,
    to: S.string
  })
)

export type DateRange = TypeOf<typeof DateRange>

// -------------------------------------------------------------------------------------
// url
// -------------------------------------------------------------------------------------

interface UrlBrand {
  readonly UrlBrand: unique symbol
}

export type Url = string & UrlBrand

export const Url = type((S) =>
  pipe(
    S.string,
    S.refine((s): s is Url => isURL(s), 'url')
  )
)

// -------------------------------------------------------------------------------------
// phone
// -------------------------------------------------------------------------------------

interface PhoneBrand {
  readonly PhoneBrand: unique symbol
}

export type Phone = string & PhoneBrand

export const Phone = type((S) =>
  pipe(
    S.string,
    S.refine((s): s is Phone => isMobilePhone(s), 'phone')
  )
)

// -------------------------------------------------------------------------------------
// hex color
// -------------------------------------------------------------------------------------

interface HexColorBrand {
  readonly HexColorBrand: unique symbol
}

export type HexColor = string & HexColorBrand

export const HexColor = type((S) =>
  pipe(
    NonEmptyTrimmedString.schema(S),
    S.refine(
      (s): s is HexColor & NonEmptyTrimmedString => isValidHex(s),
      'hexColor'
    )
  )
)
