import { useState, useEffect, useRef, Ref } from 'react'
import { forkRefs, hasFocusWithin } from '@woorcs/utils'

import {
  useTabbable,
  UseTabbableProps,
  UseTabbableReturnProps
} from './useTabbable'

export type UseFocusableProps = UseTabbableProps

interface UseFocusableReturnProps extends UseTabbableReturnProps {
  focused: boolean
}

type UseFocusableReturn<T> = [Ref<T | null>, UseFocusableReturnProps]

export const useFocusable = <T extends HTMLElement>(
  props: UseFocusableProps
): UseFocusableReturn<T> => {
  const ref = useRef<T | null>(null)
  const [tabbableRef, { disabled, ...tabbableProps }] = useTabbable(props)
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const hasFocus = hasFocusWithin(ref.current)

    if ((focused && disabled && hasFocus) || (!focused && hasFocus)) {
      setFocused(false)

      ref.current.blur()

      return
    }

    if (focused && !hasFocus) {
      ref.current.focus()
    }
  }, [focused, disabled, ref])

  useEffect(() => {
    if (!ref.current) {
      return undefined
    }

    const node = ref.current

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    node.addEventListener('focus', onFocus, true)
    node.addEventListener('blur', onBlur, true)

    return () => {
      if (node) {
        node.removeEventListener('focus', onFocus, true)
        node.removeEventListener('blur', onBlur, true)
      }
    }
  }, [ref])

  return [
    forkRefs(ref, tabbableRef),
    {
      focused,
      disabled,
      ...tabbableProps
    }
  ]
}
