import React from 'react'
import Highlight, { defaultProps, Language } from 'prism-react-renderer'
import { useClipboard } from '@woorcs/hooks'

import { Card, Box, Flex } from '../../layout'
import { system } from '../../../system'
import { IconButton } from '../../buttons'
import { CopyIcon } from '../../icons'

import { prismTheme } from './prismTheme'
import { InlineCode } from './InlineCode'

const Container = system(Card)({
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: 'accent.50',
  border: 'none'
})

interface CodeProps {
  language?: Language
  src?: string
  isInline?: boolean
  showHeader?: boolean
  children: string
}

export const Code = ({
  language = 'typescript',
  src,
  isInline,
  showHeader = true,
  children,
  ...other
}: CodeProps) => {
  const { onCopy } = useClipboard(children)

  if (isInline) {
    return <InlineCode>{children}</InlineCode>
  }

  return (
    <Container borderRadius='medium' {...other}>
      {showHeader && (
        <Flex
          justifyContent='space-between'
          alignItems='center'
          bg='accent.100'
          px={4}
          py={2}
        >
          <system.pre fontSize='small'>{src ?? language}</system.pre>
          <IconButton variant='plain' onClick={onCopy}>
            <CopyIcon />
          </IconButton>
        </Flex>
      )}

      <Box px={6} py={6}>
        <Highlight
          {...defaultProps}
          code={children.trim()}
          language={language}
          theme={prismTheme}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <system.pre
              className={`${language} ${className}`}
              style={{ ...style, whiteSpace: 'pre-wrap' }}
              fontSize='small'
            >
              {tokens.map((line, i) => (
                <div key={i} {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <system.span
                      key={key}
                      display='inline-block'
                      {...getTokenProps({ token, key })}
                    />
                  ))}
                </div>
              ))}
            </system.pre>
          )}
        </Highlight>
      </Box>
    </Container>
  )
}
