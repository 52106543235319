import { Box, Modal, ModalDialog, ModalDisclosure } from '@woorcs/design-system'
import { ReactElement } from 'react'

import { Report } from './Report'
import { ReportModalSubmissionRevisionFragment } from './__generated__/ReportModal'

interface ReportModalProps {
  children: ReactElement
  submissionRevision: ReportModalSubmissionRevisionFragment
}

export const ReportModal = ({
  children,
  submissionRevision
}: ReportModalProps) => {
  return (
    <Modal>
      <ModalDisclosure>{children}</ModalDisclosure>

      <ModalDialog maxWidth='800px' aria-label='Search' centerContent={false}>
        <Box p={8}>
          <Report submissionRevision={submissionRevision} />
        </Box>
      </ModalDialog>
    </Modal>
  )
}
