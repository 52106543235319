import { ReactNode } from 'react'
import { isFunction } from '@woorcs/utils'
import * as Ariakit from '@ariakit/react'

export interface PopoverProps extends Ariakit.PopoverStoreProps {
  children: ReactNode | ((state: Ariakit.PopoverStore) => ReactNode)
}

export const Popover = ({ children, ...props }: PopoverProps) => {
  const store = Ariakit.usePopoverStore(props)

  return (
    <Ariakit.PopoverProvider store={store}>
      {isFunction(children) ? children(store) : children}
    </Ariakit.PopoverProvider>
  )
}
