import React, { useEffect, useState } from 'react'
import { Box } from '@woorcs/design-system'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import { useDragDropManager } from 'react-dnd'
import { UUID } from '@woorcs/types/UUID'
import * as O from 'fp-ts/Option'
import { constFalse, pipe } from 'fp-ts/function'

import { useValue } from '../../state'

import { SortablePageListItem } from './Page'

type PageListProps = {
  selectedPageId: O.Option<UUID>
  onPageClick(page: InspectionFormDocument.InspectionFormPageElement): void
}

export const PageList = ({ selectedPageId, onPageClick }: PageListProps) => {
  const root = useValue()
  const [isDragging, setIsDragging] = useState(false)
  const manager = useDragDropManager()
  const monitor = manager.getMonitor()

  const isSelected = (page: InspectionFormDocument.InspectionFormPageElement) =>
    pipe(
      selectedPageId,
      O.fold(constFalse, (id) => id === page.id)
    )

  useEffect(
    () =>
      monitor.subscribeToStateChange(() => {
        if (monitor.isDragging() !== isDragging) {
          setIsDragging(monitor.isDragging())
        }
      }),
    [isDragging, monitor]
  )

  return (
    <Box>
      {root.children.map((page, index) => (
        <Box key={`page-list-item-${page.id}`} mb={4}>
          {/* <Flex alignItems='center'> */}
          {/* <Flex
              justifyContent='center'
              alignItems='center'
              flexShrink={0}
              style={{
                width: 20,
                height: 20,
                borderRadius: '100%',
                opacity: isDragging ? 0 : 1,
                transition: 'opacity 0.3s ease-out',
                willChange: 'opacity'
              }}
              bg='primary.50'
              mr={4}
            >
              <Text fontWeight='bold' fontSize='mini'>
                {index + 1}
              </Text>
            </Flex> */}
          <SortablePageListItem
            key={page.id}
            page={page}
            index={index}
            isSelected={isSelected(page)}
            onClick={onPageClick}
          />
          {/* </Flex> */}
        </Box>
      ))}
    </Box>
  )
}
