import { ViewerFragment } from '@woorcs/graphql/src/react/__generated__/viewer'

export type OnboardingStep<K extends string = any> = {
  key: K
  title: string
  isCompleted(viewer: ViewerFragment): boolean
}

export const onboardingStep = <K extends string>(
  key: K,
  step: Omit<OnboardingStep<K>, 'key'>
): OnboardingStep<K> => ({
  key,
  ...step
})
