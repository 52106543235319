import React, { useCallback } from 'react'
import {
  H4,
  Box,
  Button,
  ConfirmAlertDialog,
  List,
  ListItemActionField,
  ListItemTextField,
  SelectableListItem,
  Flex
} from '@woorcs/design-system'
import * as R from 'fp-ts/Record'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import { ResponseSet } from '@woorcs/form'
import { InspectionFormDefinition } from '@woorcs/inspection-form'

import { useValue, useEditorContext } from '../../state'
import { AddResponseSetModal } from '../AddResponseSetModal'
import { EditResponseSetModal } from '../EditResponseSetModal'

type ManageResponseSetsRowProps = {
  responseSet: ResponseSet.ResponseSet
  onRemove(responseSet: ResponseSet.ResponseSet): void
}

const ManageResponseSetsRow = ({
  responseSet,
  onRemove
}: ManageResponseSetsRowProps) => {
  const handleRemove = useCallback(
    () => onRemove(responseSet),
    [onRemove, responseSet]
  )

  return (
    <SelectableListItem>
      <EditResponseSetModal responseSet={responseSet}>
        <ListItemTextField css={{ cursor: 'pointer ' }}>
          {responseSet.name}
        </ListItemTextField>
      </EditResponseSetModal>
      <ListItemActionField>
        <ConfirmAlertDialog
          title='Remove response set'
          body='Are you sure that you want to remove the response set?'
          confirmLabel='Remove'
          destructive
          onConfirm={handleRemove}
        >
          <Button colorVariant='danger' size='small'>
            Delete
          </Button>
        </ConfirmAlertDialog>
      </ListItemActionField>
    </SelectableListItem>
  )
}

export const ManageResponseSets = () => {
  const root = useValue()
  const { editor } = useEditorContext()

  const handleRemove = useCallback(
    (responseSet: ResponseSet.ResponseSet) => {
      const removeResponseSet = pipe(
        root,
        InspectionFormDefinition.removeResponseSet(responseSet),
        editor.updateElement
      )

      removeResponseSet()
    },
    [editor.updateElement, root]
  )

  const renderResponseSetList = () =>
    pipe(
      root.responseSets,
      R.collect((_, responseSet) => responseSet),
      A.map((responseSet) => (
        <ManageResponseSetsRow
          key={responseSet.id}
          responseSet={responseSet}
          onRemove={handleRemove}
        />
      ))
    )

  return (
    <Box>
      <Box mb={8} px={4}>
        <H4>Manage response sets</H4>
      </Box>

      <Box mb={8}>
        <List>{renderResponseSetList()}</List>
      </Box>

      <Flex px={4} justifyContent='flex-end'>
        <AddResponseSetModal>
          <Button>Add response set</Button>
        </AddResponseSetModal>
      </Flex>
    </Box>
  )
}
