import React from 'react'
import { useFormikContext } from 'formik'
import { ButtonProps, PrimaryButton } from '@woorcs/design-system'

interface SubmitButtonProps
  extends Omit<ButtonProps, 'disabled' | 'loading' | 'type'> {
  label: string
}

export const SubmitButton = ({ label, ...other }: SubmitButtonProps) => {
  const { isSubmitting, isValid, dirty } = useFormikContext()

  return (
    <PrimaryButton
      type='submit'
      disabled={!isValid || isSubmitting || !dirty}
      loading={isSubmitting}
      {...other}
    >
      {label}
    </PrimaryButton>
  )
}
