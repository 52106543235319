import { variant } from 'styled-system'
import { space } from '@woorcs/utils'

import { system, css } from '../../../system'

export type FormFieldMessageStatus = 'info' | 'warning' | 'error' | 'success'

const statusColor = variant({
  prop: 'status',
  variants: {
    neutral: {
      color: 'grey.500'
    },
    info: {
      color: 'info'
    },
    warning: {
      color: 'warning'
    },
    error: {
      color: 'danger'
    },
    success: {
      color: 'success'
    }
  }
})

interface FormFieldMessageProps {
  status: FormFieldMessageStatus
}

export const FormFieldMessage = system('div')<FormFieldMessageProps>(
  css({
    fontSize: 'mini',
    fontWeight: 'regular',
    fontStyle: 'italic',
    lineHeight: 16 / 14,
    minHeight: space(3)
  }),
  statusColor
)
