/* eslint-disable @typescript-eslint/no-use-before-define */
import { sequenceS } from 'fp-ts/Apply'
import * as A from 'fp-ts/Array'
import * as E from 'fp-ts/Either'
import * as O from 'fp-ts/Option'
import { constVoid, pipe } from 'fp-ts/function'
import * as t from 'io-ts'

type FormErrorFromBrandedType<T> =
  T extends t.Brand<infer B>
    ? keyof B
    : T extends O.Option<t.Brand<infer C>>
      ? keyof C
      : never

type FormErrors<P extends t.Props> = Partial<{
  [K in keyof P]: FormErrorFromBrandedType<t.TypeOf<P[K]>>[]
}>

const tErrorsToFormErrors = <P extends t.Props>(
  errors: t.Errors
): FormErrors<P> =>
  errors.reduce(
    (acc, error) =>
      pipe(
        [...error.context],
        (context) =>
          // When wrong type is passed, object to string etc., length === 2.
          // It can not happen until type checking is disabled.
          context.length < 3
            ? O.none
            : sequenceS(O.option)({
                key: pipe(
                  A.lookup(1, context),
                  O.map(({ key }) => key)
                ),
                error: pipe(
                  A.last(context),
                  O.map((entry) => entry.type),
                  O.chain((type) =>
                    (type as any)._tag === 'RefinementType'
                      ? O.some(type.name)
                      : O.none
                  )
                )
              }),
        O.fold(
          () => acc,
          ({ key, error }) => ({ ...acc, [key]: [...(acc[key] || []), error] })
        )
      ),
    {} as FormErrors<P>
  )

type Validated<P extends t.Props> = E.Either<FormErrors<P>, t.TypeOfProps<P>>

/**
 * @deprecated use getValidator or getFormikValidator
 */
export const validate = <P extends t.Props>(
  type: t.TypeC<P>,
  values: unknown //t.OutputOfProps<P>
): Validated<P> =>
  pipe(
    type.decode(values),
    E.mapLeft((errors) => tErrorsToFormErrors<P>(errors))
  )

export const getValidator =
  <P extends t.Props>(type: t.TypeC<P>) =>
  (values: unknown): Validated<P> =>
    pipe(
      type.decode(values),
      E.mapLeft((errors) => tErrorsToFormErrors<P>(errors))
    )

export const getFormikValidator =
  <P extends t.Props>(type: t.TypeC<P>) =>
  (values: unknown) =>
    pipe(values, getValidator(type), E.fold(t.identity, constVoid))

// declare module 'formik' {
//   interface FormikConfig<Values> {
//     validate?: (
//       values: unknown
//     ) => void | FormikErrors<Values> | Promise<FormikErrors<Values>>
//   }
// }
