import React from 'react'

import { Box } from '../../layout'
import { system, css } from '../../../system'

import { isFocused, isInvalid } from './states'

export const StyledBorder = system(Box)<{
  focused?: boolean
  invalid?: boolean
}>(
  css({
    position: 'absolute',
    bottom: '-2px',
    left: 0,
    right: 0,
    height: 1,
    width: '100%',
    backgroundColor: 'currentColor',
    transform: 'scale(0, 1)',
    transformOrigin: '0 0',
    transition: 'transform 0.15s ease-out'
  }),
  isFocused({
    transform: 'scale(1, 1)',
    backgroundColor: 'primary.500'
  }),
  isInvalid({
    backgroundColor: 'currentColor'
  })
)

interface InputActiveBorderProps {
  focused?: boolean
}

export const InputActiveBorder = ({ focused }: InputActiveBorderProps) => {
  return <StyledBorder focused={focused} />
}
